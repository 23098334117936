import React from "react";
import { Menu, MenuButton, MenuList, MenuItem, Button, Tag, HStack } from "@chakra-ui/react";
import { PiCaretDownBold } from "react-icons/pi";
import PropTypes from "prop-types";

const TagMenu = ({ options, selectedValue = "", field, handleBlurSave, subtaskId = null, taskId = null }) => {
  if (!options) {
    console.error(`TagMenu for ${field} is missing 'options' prop.`);
    return null;
  }

  const selectedOption = options.find((option) => option.value === selectedValue);

  return (
    <Menu closeOnSelect={true} matchWidth>
      <MenuButton
        as={Button}
        borderRadius="full"
        variant="ghost"
        p={0}
        aria-label={`Select ${field}`}
        display="flex"
        alignItems="center"
        onClick={(e) => {
          e.stopPropagation();
        }}
        pr={2}
      >
        <HStack alignItems="center">
          <Tag size="lg" borderRadius="full" colorScheme={selectedOption?.color || "gray"} cursor="pointer">
            {selectedValue || `Select ${field}`}
          </Tag>
          <PiCaretDownBold fontSize="18px" mr={1} />
        </HStack>
      </MenuButton>
      <MenuList>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={(e) => {
              e.stopPropagation();
              handleBlurSave(subtaskId || taskId, field, option.value);
            }}
          >
            <Tag size="lg" borderRadius="full" colorScheme={option.color}>
              {option.label}
            </Tag>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

TagMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedValue: PropTypes.string,
  field: PropTypes.string.isRequired,
  handleBlurSave: PropTypes.func.isRequired,
  subtaskId: PropTypes.string,
  taskId: PropTypes.string,
};

export default TagMenu;
