// src/components/3 - Modal/8 - Settings Modals/InviteUserModal.jsx
import React, { useState } from "react";
import { Button, VStack, FormControl, FormLabel, Input, Flex } from "@chakra-ui/react";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { useInviteUser } from "../../5 - General/Hooks/useUpdateCompany";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";

const InviteUserModal = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUserCompanyId, currentUserId } = React.useContext(WorkspaceContext);
  const inviteUserMutation = useInviteUser();


  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const form = e.target;
    const newUser = {
      firstName: form.firstName.value.trim(),
      lastName: form.lastName.value.trim(),
      email: form.email.value.trim(),
      companyId: currentUserCompanyId,
    };
    inviteUserMutation.mutate({ newUser: newUser, invitedBy: currentUserId });
    onClose();
    form.reset();
    setIsLoading(false);
  };

  const footerButtons = (
    <Flex justify="space-between" width="100%">
      <Button variant="outline" onClick={onClose} width="30%">
        Cancel
      </Button>
      <Button colorScheme="primary" type="submit" form="inviteUserForm" width="30%" isLoading={isLoading}>
        Invite
      </Button>
    </Flex>
  );

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Invite a member to join your team" footerButtons={footerButtons}>
      <VStack spacing={4} as="form" onSubmit={handleSubmit} id="inviteUserForm" width="100%">
        <FormControl mb={4} isRequired>
          <FormLabel>First Name</FormLabel>
          <Input type="text" id="firstName" name="firstName" placeholder="John" required />
        </FormControl>
        <FormControl mb={4} isRequired>
          <FormLabel>Last Name</FormLabel>
          <Input type="text" id="lastName" name="lastName" placeholder="Doe" required />
        </FormControl>
        <FormControl mb={4} isRequired>
          <FormLabel>Email</FormLabel>
          <Input type="email" id="email" name="email" placeholder="john.doe@example.com" required />
        </FormControl>
      </VStack>
    </ReusableModal>
  );
};

export default InviteUserModal;