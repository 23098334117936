// client\src\components\3 - Modal\10 - Customers\CompanyFormModal.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import useCustomToast from '../../5 - General/Utils/UtilsNotification';

const CompanyFormModal = ({
  isOpen,
  onClose,
  onSave,
  initialData,
}) => {
  const customToast = useCustomToast();
  const [isLoading, setIsLoading] = useState(false);

  // Default State
  const defaultState = useCallback(() => ({
    companyName: '',
    companyRegistrationNumber: '',
    createdBy: '',
    companyRole: 'Customer Company',
    primaryColor: 'primary',
    modules: {
      messages: true,
      projects: true,
      files: true,
      contracts: true,
      billing: true,
      scheduleACall: true,
    },
  }), []);

  // State variables
  const [formState, setFormState] = useState(defaultState);

  // Helper Functions
  const initializeForm = useCallback((data) => {
    if (!data) {
      setFormState(defaultState);
      return;
    }

    const {
      companyName = '',
      companyRegistrationNumber = '',
      createdBy = '',
      companyRole = 'Customer Company',
      primaryColor = '#000e5a',
      logoColorPath = '',
      logoWhitePath = '',
      modules = {
        messages: true,
        projects: true,
        files: true,
        contracts: true,
        billing: true,
        scheduleACall: true,
      },
    } = data;

    setFormState({
      companyName,
      companyRegistrationNumber,
      createdBy,
      companyRole,
      primaryColor,
      logoColorPath,
      logoWhitePath,
      modules,
    });
  }, [defaultState]);

  useEffect(() => {
    initializeForm(initialData);
  }, [initialData, initializeForm]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const companyData = {
        companyName: formState.companyName,
        companyRegistrationNumber: formState.companyRegistrationNumber,
        createdBy: formState.createdBy,
        companyRole: formState.companyRole,
        primaryColor: formState.primaryColor,
        modules: formState.modules,
      };

      // Remove undefined properties
      const cleanCompanyData = JSON.parse(JSON.stringify(companyData));

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };

      let response;
      if (initialData) {
        response = await fetch(
          `http://localhost:5000/api/v1/companies/${initialData._id}`,
          {
            method: 'PUT',
            headers,
            body: JSON.stringify(cleanCompanyData),
          }
        );
      } else {
        response = await fetch('http://localhost:5000/api/v1/companies', {
          method: 'POST',
          headers,
          body: JSON.stringify(cleanCompanyData),
        });
      }

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to save company');
      }

      customToast({
        status: 'success',
        title: 'Success',
        description: initialData
          ? 'Company updated successfully.'
          : 'Company created successfully.',
      });

      onSave();
      onClose();
    } catch (error) {
      console.error('Error saving company:', error);
      customToast({
        status: 'error',
        title: 'Error',
        description: 'An error occurred. Please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>
          {initialData ? 'Edit Company' : 'Add New Company'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl id="company-name" isRequired>
              <FormLabel>Company Name</FormLabel>
              <Input
                placeholder="Example: Acme Corp"
                value={formState.companyName}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, companyName: e.target.value }))
                }
              />
            </FormControl>

            <FormControl id="company-registration-number" isRequired>
              <FormLabel>Company Registration Number</FormLabel>
              <Input
                placeholder="Example: 123456789"
                value={formState.companyRegistrationNumber}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, companyRegistrationNumber: e.target.value }))
                }
              />
            </FormControl>

            <FormControl id="created-by" isRequired>
              <FormLabel>Created By</FormLabel>
              <Input
                placeholder="Example: John Doe"
                value={formState.createdBy}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, createdBy: e.target.value }))
                }
              />
            </FormControl>

            <FormControl id="company-role">
              <FormLabel>Company Role</FormLabel>
              <Input
                placeholder="Example: Admin Company"
                value={formState.companyRole}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, companyRole: e.target.value }))
                }
              />
            </FormControl>

            <FormControl id="primary-color">
              <FormLabel>Primary Color</FormLabel>
              <Input
                placeholder="Example: #000e5a"
                value={formState.primaryColor}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, primaryColor: e.target.value }))
                }
              />
            </FormControl>

            <FormControl id="logo-color-path">
              <FormLabel>Logo Color Path</FormLabel>
              <Input
                placeholder="Example: /path/to/logo-color.png"
                value={formState.logoColorPath}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, logoColorPath: e.target.value }))
                }
              />
            </FormControl>

            <FormControl id="logo-white-path">
              <FormLabel>Logo White Path</FormLabel>
              <Input
                placeholder="Example: /path/to/logo-white.png"
                value={formState.logoWhitePath}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, logoWhitePath: e.target.value }))
                }
              />
            </FormControl>

            <FormControl id="modules">
              <FormLabel>Modules</FormLabel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                {Object.keys(formState.modules).map((module) => (
                  <Checkbox
                    key={module}
                    isChecked={formState.modules[module]}
                    onChange={(e) =>
                      setFormState((prev) => ({
                        ...prev,
                        modules: {
                          ...prev.modules,
                          [module]: e.target.checked,
                        },
                      }))
                    }
                  >
                    {module.charAt(0).toUpperCase() + module.slice(1)}
                  </Checkbox>
                ))}
              </SimpleGrid>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="primary" onClick={handleSubmit} isLoading={isLoading}>
            {initialData ? 'Update' : 'Create'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CompanyFormModal;
