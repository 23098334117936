import React, { useState, useContext, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";
import TagMenu from "../../2 - Components/Reusable/TagMenu";
import { Select, chakraComponents } from "chakra-react-select";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import { format, isBefore } from "date-fns";
import { PiArrowRightBold, PiXBold } from "react-icons/pi";

const EditProjectModal = ({ isOpen, onClose, project }) => {
  const { editProject, companies, fetchUsersFromCompany } = useContext(ProjectContext);
  const customToast = useCustomToast();

  // State variables
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(project.startDate || null);
  const [endDate, setEndDate] = useState(project.dueDate || null);
  const [status, setStatus] = useState("Not Started");
  const [priority, setPriority] = useState("Medium");

  const arrowColor = "gray.700";

  // State variables for companies and members
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isUserLoading, setIsUserLoading] = useState(false);

  // Initialize form fields when project changes
  useEffect(() => {
    if (project) {
      setName(project.name);
      setStartDate(project.startDate || null);
      setEndDate(project.dueDate || null);
      setStatus(project.status || "Not Started");
      setPriority(project.priority || "Medium");

      // Pre-select companies from assignedToCompanies
      if (project.assignedToCompanies) {
        const initialSelectedCompanies = project.assignedToCompanies.map((company) => ({
          value: company._id,
          label: company.companyName,
        }));
        setSelectedCompanies(initialSelectedCompanies);
      }

      // Pre-select members (this will be updated after fetching users)
      if (project.members) {
        const formattedMembers = project.members.map((member) => ({
          value: member._id || member,
          label: member.name || `${member.firstName} ${member.lastName}`,
          picture: member.picture,
        }));
        setSelectedMembers(formattedMembers);
      }
    }
  }, [project]);

  // Populate company options
  useEffect(() => {
    if (companies) {
      const options = companies.map((company) => ({
        value: company._id,
        label: company.companyName,
      }));
      setCompanyOptions(options);
    }
  }, [companies]);

  // Fetch company users when selectedCompanies change and update selectedMembers
  useEffect(() => {
    const fetchCompanyUsers = async () => {
      setIsUserLoading(true);
      try {
        let aggregatedUsers = [];
        for (const company of selectedCompanies) {
          const users = await fetchUsersFromCompany(company.value);
          const formattedUsers = users.map((user) => ({
            value: user._id,
            label: `${user.firstName} ${user.lastName}`,
            picture: user.picture,
          }));
          aggregatedUsers = [...aggregatedUsers, ...formattedUsers];
        }
        setMemberOptions(aggregatedUsers);

        // Automatically select users who are already members of the project
        if (project.members) {
          const projectMemberIds = project.members.map((member) => member._id || member);
          const preselectedMembers = aggregatedUsers.filter((user) => projectMemberIds.includes(user.value));
          setSelectedMembers(preselectedMembers);
        }
      } catch (error) {
        console.error("Error fetching company users:", error);
      } finally {
        setIsUserLoading(false);
      }
    };

    if (selectedCompanies.length > 0) {
      fetchCompanyUsers();
    } else {
      setMemberOptions([]);
      setSelectedMembers([]);
    }
  }, [selectedCompanies, fetchUsersFromCompany, project.members]);

  // Handle saving the project updates
  const handleSubmit = async () => {
    if (project && name.trim()) {
      try {
        const updatedProjectData = {
          name,
          startDate,
          dueDate: endDate,
          status,
          priority,
          companies: selectedCompanies.map((company) => company.value),
          members: selectedMembers.map((member) => member.value),
        };
        await editProject(project._id, updatedProjectData);
        customToast({
          status: "success",
          title: "Project Updated",
          description: "The project has been updated successfully.",
        });
        onClose();
      } catch (error) {
        customToast({
          status: "error",
          title: "Error Updating Project",
          description: "An error occurred while updating the project.",
        });
      }
    } else {
      customToast({
        status: "error",
        title: "Invalid Data",
        description: "Please provide a valid project name.",
      });
    }
  };

  // Handle startDate change
  const handleStartDateChange = (date) => {
    setStartDate(date);
    // If endDate is before the new startDate, clear it
    if (endDate && isBefore(endDate, date)) {
      setEndDate(null);
    }
  };

  // Status and Priority options
  const statusOptions = [
    { value: "Not Started", label: "Not Started", color: "gray" },
    { value: "In Progress", label: "In Progress", color: "blue" },
    { value: "Completed", label: "Completed", color: "green" },
    { value: "On Hold", label: "On Hold", color: "orange" },
  ];

  const priorityOptions = [
    { value: "High", label: "High", color: "red" },
    { value: "Medium", label: "Medium", color: "yellow" },
    { value: "Low", label: "Low", color: "green" },
  ];

  // Custom Option Component for Users
  const CustomUserOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Box ref={innerRef} {...innerProps} _hover={{ bg: "gray.100" }}>
        <HStack spacing={3} px={6} py={1}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="xs" showInfo={true} />
          <Box>{data.label}</Box>
        </HStack>
      </Box>
    );
  };

  // Custom MultiValue Component for Users
  const CustomUserMultiValue = (props) => {
    const { data, innerRef, innerProps, removeProps } = props;
    return (
      <Box ref={innerRef} {...innerProps} mb={2} bg="gray.50" borderRadius="md">
        <HStack spacing={3} px={6} py={1} justify="space-between">
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="xs" showInfo={true} />
          <IconButton
            icon={<PiXBold fontSize="16px" />}
            colorScheme="gray"
            variant="ghost"
            onClick={removeProps.onClick}
            aria-label="Remove member"
          />
        </HStack>
      </Box>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Project - {name || "..."}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Project Name */}
          <FormControl>
            <FormLabel>Project Name</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>

          {/* Start and End Dates */}
          <FormControl isRequired mt={4}>
            <FormLabel fontSize="md" fontWeight="500">
              Project Duration
            </FormLabel>
            <HStack spacing={2}>
              {/* Start Date Picker */}
              <UtilsDatePicker
                value={startDate}
                onChange={handleStartDateChange}
                width="250px"
                placeholder="Start Date"
                inputVariant="default"
                isDisabled={false}
              />
              <PiArrowRightBold color={arrowColor} />
              {/* Due Date Picker */}
              <UtilsDatePicker
                value={endDate}
                onChange={setEndDate}
                width="250px"
                placeholder="End Date"
                inputVariant="default"
                isDisabled={!startDate}
                startDate={startDate ? startDate : null}
                highlightDates={startDate ? [startDate] : []}
                highlightTooltips={startDate ? [`Project Start Date: ${format(startDate, "dd/MM/yyyy")}`] : []}
              />
            </HStack>
          </FormControl>

          {/* Status and Priority */}
          <FormControl isRequired mt={4}>
            <FormLabel fontSize="md" fontWeight="500">
              Initial Project Status and Priority
            </FormLabel>
            <HStack spacing={4}>
              <TagMenu options={statusOptions} selectedValue={status} field="status" handleBlurSave={(id, field, value) => setStatus(value)} />
              <TagMenu
                options={priorityOptions}
                selectedValue={priority}
                field="priority"
                handleBlurSave={(id, field, value) => setPriority(value)}
              />
            </HStack>
          </FormControl>

          {/* Company Selection */}
          <FormControl mt={4}>
            <FormLabel>Companies</FormLabel>
            <Select
              options={companyOptions}
              value={selectedCompanies}
              isMulti
              onChange={setSelectedCompanies}
              placeholder="Select companies..."
              closeMenuOnSelect={false}
              components={chakraComponents}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </FormControl>

          {/* Member Selection */}
          <FormControl mt={4}>
            <FormLabel>Members</FormLabel>
            <Select
              options={memberOptions}
              value={selectedMembers}
              isMulti
              onChange={setSelectedMembers}
              placeholder="Select members..."
              isLoading={isUserLoading}
              closeMenuOnSelect={false}
              components={{
                Option: CustomUserOption,
                MultiValue: CustomUserMultiValue,
              }}
              menuPlacement="auto"
              menuPosition="fixed"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button m={4} size="md" variant="solid" colorScheme="blue" onClick={handleSubmit}>
            Save
          </Button>
          <Button m={4} size="md" variant="ghost" onClick={onClose} ml={2}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditProjectModal;
