import React from 'react';
import { Flex, Box, Text, VStack, Image, IconButton } from '@chakra-ui/react';
import { formatFileSize } from './UtilsFormatData';
import { renderFileIcon } from './UtilsFileIcon';
import { PiXBold } from 'react-icons/pi';

const FilePreview = ({ file, handleRemoveFile }) => {
  // Check if the file object is defined and has the necessary properties
  if (!file || !file.name || !file.size) {
    return null; // Don't render anything if the file object is missing or invalid
  }

  const isImage = file.type?.startsWith('image/'); // Safe check for 'type'

  return (
    <Flex align="center" mb={2} p={2} borderRadius="md">
      {isImage ? (
        <Image src={file.preview} boxSize="50px" objectFit="cover" borderRadius="md" alt="preview" />
      ) : (
        <Box>{renderFileIcon(file.name)}</Box>
      )}
      <VStack align="start" spacing={0} ml={3} flex="1" color="gray.600">
        <Text fontWeight="bold" fontSize="md">Selected File:</Text>
        <Text fontSize="md" isTruncated>
          {file.name.length > 30 ? `${file.name.substring(0, 25)}...` : file.name} - {formatFileSize(file.size)}
        </Text>
      </VStack>
      <IconButton
        icon={<PiXBold />}
        aria-label="Remove file"
        onClick={() => handleRemoveFile(file)}
        size="sm"
      />
    </Flex>
  );
};

export default FilePreview;
