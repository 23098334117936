// src/components/Stores/filterStore.js
import { create } from "zustand";

export const useFilterStore = create((set) => ({
  filters: {},
  setFilterValue: (key, value) =>
    set((state) => ({
      filters: {
        ...state.filters,
        [key]: {
          ...state.filters[key],
          filterValue: value,
        },
      },
    })),
  setCurrentPage: (key, page) =>
    set((state) => ({
      filters: {
        ...state.filters,
        [key]: {
          ...state.filters[key],
          currentPage: page,
        },
      },
    })),
  setItemsPerPage: (key, itemsPerPage) =>
    set((state) => ({
      filters: {
        ...state.filters,
        [key]: {
          ...state.filters[key],
          itemsPerPage,
        },
      },
    })),
}));
