import React from "react";
import { Box, Heading, Text, Center, Icon, Button, SimpleGrid, Divider } from "@chakra-ui/react";
import { PiSignature } from "react-icons/pi";

const Contracts = () => {
  return (
    <Box bg="gray.50" w="100%" minH="100vh" p={5} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Center w="100%" flexDirection="column">
        <Icon as={PiSignature} boxSize={20} color="primary.500" mb={4} />
        <Heading as="h1" size="2xl" color="primary.700" textAlign="center">
          Contracts Module Coming Soon
        </Heading>
        <Text fontSize="lg" color="gray.600" textAlign="center" maxW="800px" mt={4}>
          Our new Contracts Module is on its way! Soon, you'll be able to manage all your contracts and agreements directly within your client portal.
        </Text>
        <Button colorScheme="primary" size="lg" mt={6} disabled>
          Notify Me When Available
        </Button>
      </Center>

      <Divider my={10} />

      {/* Feature Highlights */}
      <Box maxW="800px" mx="auto">
        <Heading as="h2" size="lg" color="primary.600" mb={4} textAlign="center">
          Upcoming Features
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              ✍️ E-Signatures
            </Heading>
            <Text color="gray.600">Sign contracts electronically with legally binding e-signatures, streamlining the agreement process.</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              📄 Template Library
            </Heading>
            <Text color="gray.600">Access a library of customizable contract templates to save time and maintain consistency.</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              🔒 Secure Storage
            </Heading>
            <Text color="gray.600">Store all your contracts securely with encrypted storage and role-based access controls.</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              🛎️ Renewal Reminders
            </Heading>
            <Text color="gray.600">Receive automatic reminders for contract renewals, expirations, or required actions.</Text>
          </Box>
        </SimpleGrid>
      </Box>
      <Box w="10px" h="100px" />
    </Box>
  );
};

export default Contracts;
