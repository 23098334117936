import React from 'react'

const SettingsPageReports = () => {

  return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
        <h3>Report Settings</h3>
      </div>
  );
};

export default SettingsPageReports;
