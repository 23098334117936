// client\src\components\2 - Components\ProjectsViewTaskView\ProjectsTaskViewTabsFiles.js
import React, { useState, useContext, useCallback } from "react";
import {
  Box,
  HStack,
  SimpleGrid,
  Input,
  Flex,
  Button,
  IconButton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Text,
} from "@chakra-ui/react";
import { PiDownloadSimple, PiTrash } from "react-icons/pi";
import FileCard from "../../5 - General/Utils/UtilsFileCard";
import { useDropzone } from "react-dropzone";
import UserAvatar from "../Reusable/UserAvatar";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";

const FileSection = () => {
  const { currentUser } = useContext(WorkspaceContext);
  const { currentTask, uploadFilesToTask, deleteFileFromTask, downloadFileFromTask } = useContext(ProjectContext);
  const [fileSearchQuery, setFileSearchQuery] = useState("");
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isDeleteFileOpen, setIsDeleteFileOpen] = useState(false);
  const cancelRef = React.useRef();
  const customToast = useCustomToast();

  const handleFileUpload = useCallback(
    async (acceptedFiles) => {
      try {
        await uploadFilesToTask(currentTask._id, acceptedFiles);
      } catch (error) {
        console.error("Error uploading files:", error);
        customToast({
          title: "Error",
          description: "Error uploading files. Try again later.",
          status: "error",
        });
      }
    },
    [uploadFilesToTask, currentTask._id, customToast]
  );

  const handleDeleteFile = useCallback((file) => {
    setFileToDelete(file);
    setIsDeleteFileOpen(true);
  }, []);

  const confirmDeleteFile = useCallback(async () => {
    try {
      await deleteFileFromTask(currentTask._id, fileToDelete._id);
      setIsDeleteFileOpen(false);
      setFileToDelete(null);
    } catch (error) {
      console.error("Error deleting file:", error);
      customToast({
        title: "Error",
        description: "Error deleting file. Try again later.",
        status: "error",
      });
    }
  }, [deleteFileFromTask, currentTask._id, fileToDelete, customToast]);

  const handleDownloadFile = useCallback(
    (file) => {
      downloadFileFromTask(currentTask?._id, file?._id);
    },
    [downloadFileFromTask, currentTask?._id]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileUpload,
  });

  const safeFileSearchQuery = fileSearchQuery || "";
  const filteredFiles = currentTask.files.filter((file) => file?.originalName?.toLowerCase().includes(safeFileSearchQuery.toLowerCase()));

  return (
    <Flex flexDir="column" w='100%' pb={2}>
      {/* File Upload and Search */}
      <Flex mx={4} my={8} p={4} borderWidth="1px" borderColor="gray.200" borderRadius="xl" bg="gray.50" flexDir="column">
      <Text fontWeight="400">Shared Files</Text>
        <Input placeholder="Search files..." value={fileSearchQuery} onChange={(e) => setFileSearchQuery(e.target.value)} my={4} />
        <Box p={4} border="2px dashed" borderColor="gray.500" bg="white" borderRadius="xl" textAlign="center" cursor="pointer" {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? <p>Drop the files here ...</p> : <p>Drag and drop or click to select files</p>}
        </Box>
      </Flex>

      {/* Files List */}
      <SimpleGrid columns={[1, 1]} spacing={4} mx={4}>
        {filteredFiles.map((file, idx) => (
          <HStack
            key={file._id || idx}
            p={2}
            boxShadow="base"
            border="1px solid"
            borderColor="gray.100"
            borderRadius="xl"
            justifyContent="space-between"
            alignItems="center"
          >
            <HStack w="60%" alignItems="center">
              <FileCard
                file={{
                  name: file.originalName,
                  size: file.size,
                  type: file.mimeType,
                }}
              />
              <UserAvatar userIds={[file.uploadedBy]} size="sm" showInfo={true} />
            </HStack>
            <HStack spacing={2}>
              <Button leftIcon={<PiDownloadSimple fontSize='18px' />} size="sm" onClick={() => handleDownloadFile(file)}>
                Download
              </Button>
              {file.uploadedBy === currentUser._id && (
                <IconButton icon={<PiTrash fontSize='18px' />} size="sm" color="red.600" onClick={() => handleDeleteFile(file)} />
              )}
            </HStack>
          </HStack>
        ))}
      </SimpleGrid>

      {/* Delete Confirmation Dialog */}
      <AlertDialog isOpen={isDeleteFileOpen} leastDestructiveRef={cancelRef} isCentered onClose={() => setIsDeleteFileOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete File
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this file? This action cannot be undone and will permanently remove the file from the system.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef}>Cancel</Button>
              <Button colorScheme="red" onClick={confirmDeleteFile} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default FileSection;
