// src/components/3 - Modal/2 - Messages Modals/CreateMessageModal.js

import React, { useState, useContext, useEffect } from "react";
import { Box, VStack, Button, Input, FormLabel, Text, Spinner, Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { MessagesContext } from "../../5 - General/Context/MessagesContext";

const CreateMessageModal = ({ isOpen, onClose }) => {
  const { currentUser, availableUsers, isAvailableUsersLoading, availableUsersError, handleCreateChannel } = useContext(MessagesContext);

  const [newChannelName, setNewChannelName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const maxChannelNameLength = 30;

  useEffect(() => {
    if (!isOpen) {
      setNewChannelName("");
      setSelectedUsers([]);
    }
  }, [isOpen]);

  const footerButtons = (
    <Button
      colorScheme="primary"
      onClick={() => {
        if (newChannelName.trim() && selectedUsers.length >= 2) {
          const memberIds = selectedUsers.map((user) => user.value);
          handleCreateChannel(newChannelName, memberIds, false);
          onClose();
        }
      }}
      disabled={!newChannelName.trim() || selectedUsers.length < 2}
    >
      Create
    </Button>
  );

  const options = availableUsers
    .filter((user) => user.value !== currentUser._id)
    .map((user) => ({
      value: user.value,
      label: user.label,
    }));

  const renderContent = () => {
    if (isAvailableUsersLoading) {
      return (
        <VStack spacing={4} pt={4}>
          <Spinner />
          <Text>Loading users...</Text>
        </VStack>
      );
    }

    if (availableUsersError) {
      return (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Failed to load users.</AlertTitle>
          <AlertDescription>{availableUsersError.message}</AlertDescription>
        </Alert>
      );
    }

    return (
      <VStack spacing={4} pt={4}>
        <Box width="100%">
          <FormLabel>Conversation Name</FormLabel>
          <Input
            type="text"
            value={newChannelName}
            onChange={(e) => e.target.value.length <= maxChannelNameLength && setNewChannelName(e.target.value)}
            placeholder="Conversation Name"
          />
        </Box>
        <Box width="100%">
          <FormLabel>Search Users</FormLabel>
          <Select
            isMulti
            options={options}
            value={selectedUsers}
            onChange={(selected) => setSelectedUsers(selected)}
            placeholder="Search Users..."
            components={{
              DropdownIndicator: null,
              IndicatorSeparator: null,
            }}
            chakraStyles={{
              menu: (provided) => ({ ...provided, mt: "2px", zIndex: 3 }),
              control: (provided) => ({ ...provided, border: "1px solid", borderColor: "gray.200" }),
            }}
          />
        </Box>
      </VStack>
    );
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Create a new group conversation" footerButtons={footerButtons}>
      {renderContent()}
    </ReusableModal>
  );
};

export default CreateMessageModal;
