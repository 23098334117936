import React from "react";
import { toast, Slide } from "react-toastify";
import { Box, Text, Button, Flex, useColorModeValue, useColorMode, useTheme } from "@chakra-ui/react";
import "react-toastify/dist/ReactToastify.css";
import { PiCheckCircleFill, PiInfoFill, PiWarningCircleFill, PiWarningFill } from "react-icons/pi";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";

const useCustomToast = () => {
  const theme = useTheme();

  const iconColorSuccess = useColorModeValue(theme.colors.green[500], theme.colors.green[500]);
  const iconColorError = useColorModeValue(theme.colors.red[500], theme.colors.red[500]);
  const iconColorWarning = useColorModeValue(theme.colors.yellow[500], theme.colors.yellow[500]);
  const iconColorInfo = useColorModeValue(theme.colors.primary[500], theme.colors.primary[500]);
  const textColor = useColorModeValue(theme.colors.black, theme.colors.white);

  const { colorMode } = useColorMode();
  const toastTheme = colorMode === "dark" ? "dark" : "light";

  // Destructure the options parameter (an object) instead of multiple arguments
  const customToast = React.useCallback(
    ({ status, title, description, buttonTitle = "", buttonLink = "", senderId = "" }) => {
      const getStatusIcon = () => {
        const iconProps = { size: "28px", style: { marginRight: "12px" } };

        switch (status) {
          case "success":
            return <PiCheckCircleFill {...iconProps} color={iconColorSuccess} />;
          case "error":
            return <PiWarningCircleFill {...iconProps} color={iconColorError} />;
          case "warning":
            return <PiWarningFill {...iconProps} color={iconColorWarning} />;
          case "info":
          default:
            return <PiInfoFill {...iconProps} color={iconColorInfo} />;
        }
      };

      if (status === "message") {
        toast(
          <Box p={2} color={textColor} pointerEvents="auto">
            <Flex justifyContent="space-between" alignItems="center">
              <Box p={2} display="flex" alignItems="center" mr={2}>
                <Box display="flex" alignItems="center" mr={2}>
                  <UserAvatar size="md" userIds={[senderId]} showInfo={false} withTooltip={false} />
                </Box>
                <Box flex="1" mr={2}>
                  <Text fontSize="md" fontWeight="600" mb={1}>
                    New Message Recieved
                  </Text>
                  {description && (
                    <Text fontSize="sm" isTruncated maxW="250px" mb={0}>
                      {title}: {description}
                    </Text>
                  )}
                </Box>
              </Box>
              {buttonTitle && buttonLink && (
                <Button ml={4} size="sm" colorScheme="primary" onClick={() => window.open(buttonLink, "_self")}>
                  {buttonTitle}
                </Button>
              )}
            </Flex>
            {/* Custom CSS for Toastify */}
            <style>
              {`
                .Toastify__toast-container {
                  z-index: 9999;
                  pointer-events: none;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  justify-content: flex-end;
                  width: 100% !important;
                }
                .Toastify__toast {
                  pointer-events: auto;
                  width: fit-content !important;
                }
                :root {
                  --toastify-font-family: inherit !important;
                }
              `}
            </style>
          </Box>,
          {
            type: "info",
            position: "bottom-right",
            autoClose: 3500,
            closeOnClick: true,
            transition: Slide,
            theme: toastTheme,
            icon: false,
          }
        );
      } else {
        toast(
          <Box p={3} color={textColor} pointerEvents="auto">
            <Flex justifyContent="space-between" alignItems="center">
              <Box display="flex" alignItems="center" mr={2}>
                {getStatusIcon()}
              </Box>
              <Box flex="1" mr={4}>
                <Text fontSize='lg' fontWeight="bold" mb={1}>
                  {title}
                </Text>
                <Text fontSize='md' mb={0}>{description}</Text>
              </Box>
            </Flex>
            {/* Custom CSS for Toastify */}
            <style>
              {`
                .Toastify__toast-container {
                  z-index: 9999;
                  pointer-events: none;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  justify-content: flex-end;
                  width: 100% !important;
                }
                .Toastify__toast {
                  pointer-events: auto;
                  width: fit-content !important;
                }
                :root {
                  --toastify-font-family: inherit !important;
                }
              `}
            </style>
          </Box>,
          {
            type: status,
            position: "bottom-right",
            autoClose: 3500,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: toastTheme,
            icon: false,
            transition: Slide,
          }
        );
      }
    },
    [iconColorSuccess, iconColorError, iconColorWarning, iconColorInfo, textColor, toastTheme]
  );

  return customToast;
};

export default useCustomToast;
