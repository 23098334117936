// client\src\components\4 - API\API-Company.js

import { fetchData } from "./FetchData";
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

// Fetch a single company by ID
export const fetchCompanyById = async (companyId) => {
  const url = `${API_BASE_URL}/api/v1/company/${companyId}`;
  const data = await fetchData(url);
  return data;
};

// Fetch admin company by user ID
export const fetchAdminCompany = async (userId) => {
  const url = `${API_BASE_URL}/api/v1/users/${userId}/admin-company`;
  const data = await fetchData(url);
  return data;
};

// Fetch company details by ID
export const fetchCompanyDetailsById = async (companyId) => {
  const url = `${API_BASE_URL}/api/v1/company/company-details/${companyId}`;
  const data = await fetchData(url);
  return data;
};

// Fetch companies with an array of companyIds
export const fetchCompanies = async (companyIds) => {
  const url = `${API_BASE_URL}/api/v1/company/companies`;
  const data = await fetchData(url, "POST", { companyIds });
  return data;
};

// Fetch companies for workspace
export const fetchCompaniesForWorkspace = async () => {
  const url = `${API_BASE_URL}/api/v1/company/workspaces`;
  const data = await fetchData(url);
  return data;
};

export const fetchCompaniesCRM = async ({ search, searchColumns, page, limit }) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (searchColumns && searchColumns.length > 0) {
    params.append("searchColumns", searchColumns.join(","));
  }
  if (page) params.append("page", page);
  if (limit) params.append("limit", limit);
  const url = `${API_BASE_URL}/api/v1/company/companies?${params.toString()}`;
  const data = await fetchData(url);
  return data;
};

// Fetch users from company
export const fetchUsersFromCompany = async (companyId) => {
  const url = `${API_BASE_URL}/api/v1/company/companies/${companyId}/users`;
  const data = await fetchData(url);
  return data;
};

// Update company settings
export const updateCompany = async (companyId, updates) => {
  const url = `${API_BASE_URL}/api/v1/company/${companyId}`;
  const data = await fetchData(url, "PATCH", updates);
  return data;
};

export const uploadColorLogo = async (companyId, file) => {
  const url = `${API_BASE_URL}/api/v1/company/companies/${companyId}/upload-color-logo`;
  const formData = new FormData();
  formData.append("colorLogo", file);

  const data = await fetchData(url, "POST", formData, true);
  return data;
};

export const uploadWhiteLogo = async (companyId, file) => {
  const url = `${API_BASE_URL}/api/v1/company/companies/${companyId}/upload-white-logo`;
  const formData = new FormData();
  formData.append("whiteLogo", file);

  const data = await fetchData(url, "POST", formData, true);
  return data;
};
