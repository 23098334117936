import React, { useContext, useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, HStack, Text, useColorModeValue, Badge } from "@chakra-ui/react";
import CommentSection from "./ProjectsTaskViewTabsComments";
import FileSection from "./ProjectsTaskViewTabsFiles";
import TimeLogSection from "./ProjectsTaskViewTabsTimeLogs";
import DescriptionSection from "./ProjectsTaskViewTabsDescription";
import SubtaskSection from "./ProjectsTaskViewTabsSubTasks";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import { PiArticle, PiChats, PiClockUser, PiFolders, PiListChecks } from "react-icons/pi";

const TaskViewTabs = ({ task, onSubtaskClick, onAddSubtask }) => {
  const { subtasks } = useContext(ProjectContext);
  const taskSubtasks = subtasks[task._id] || [];

  const tabTextColor = useColorModeValue("gray.500", "gray.300");

  const tabStyles = {
    color: tabTextColor,
    fontWeight: "600",
    fontSize: "lg",
    borderBottom: "3px solid",
    borderColor: "transparent",
    mx: 2,
    _selected: {
      color: "primary.500",
      borderColor: "primary.500",
    },
  };

  const tabPanelStyles = {
    p: 0,
    m: 0,
    flex: 1,
    overflowY: "auto",
    maxHeight: "calc(100vh - 230px)",
  };

  // 1. Track the selected tab index
  const [tabIndex, setTabIndex] = useState(0);

  // 2. Handler to update the selected tab index
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  return (
    <Tabs variant="unstyled" display="flex" flexDirection="column" height="100%" isLazy index={tabIndex} onChange={handleTabsChange}>
      <TabList h="40px" borderBottom="1px solid" borderColor="gray.300" pl={8}>
        {/* Subtasks Tab */}
        <Tab {...tabStyles}>
          <HStack spacing={2} align="center">
            <PiListChecks fontSize="20px" />
            <Text fontWeight="500" fontSize="15px">
              Subtasks
            </Text>
            <Badge py={1} px={2} colorScheme={tabIndex === 0 ? "primary" : "blackAlpha"} borderRadius='8px'>
              {task.subtasks ? task.subtasks.length : 0}
            </Badge>
          </HStack>
        </Tab>

        {/* Description Tab */}
        <Tab {...tabStyles}>
          <HStack spacing={2} align="center">
            <PiArticle fontSize="20px" />
            <Text fontWeight="500" fontSize="15px">
              Description
            </Text>
          </HStack>
        </Tab>

        {/* Comments Tab */}
        <Tab {...tabStyles}>
          <HStack spacing={2} align="center">
            <PiChats fontSize="20px" />
            <Text fontWeight="500" fontSize="15px">
              Comments
            </Text>
            <Badge py={1} px={2} colorScheme={tabIndex === 2 ? "primary" : "blackAlpha"} borderRadius='8px'>
              {task.comments ? task.comments.length : 0}
            </Badge>
          </HStack>
        </Tab>

        {/* Files Tab */}
        <Tab {...tabStyles}>
          <HStack spacing={2} align="center">
            <PiFolders fontSize="20px" />
            <Text fontWeight="500" fontSize="15px">
              Files
            </Text>
            <Badge py={1} px={2} colorScheme={tabIndex === 3 ? "primary" : "blackAlpha"} borderRadius='8px'>
              {task.files ? task.files.length : 0}
            </Badge>
          </HStack>
        </Tab>

        {/* Time Logs Tab */}
        <Tab {...tabStyles}>
          <HStack spacing={2} align="center">
            <PiClockUser fontSize="20px" />
            <Text fontWeight="500" fontSize="15px">
              Time Logs
            </Text>
            <Badge py={1} px={2} colorScheme={tabIndex === 4 ? "primary" : "blackAlpha"} borderRadius='8px'>
              {task.timeLogs ? task.timeLogs.length : 0}
            </Badge>
          </HStack>
        </Tab>
      </TabList>

      {/* TabPanels Container */}
      <TabPanels flex="1" overflow="hidden">
        <TabPanel {...tabPanelStyles}>
          <SubtaskSection task={task} subtasks={taskSubtasks} onSubtaskClick={onSubtaskClick} onAddSubtask={onAddSubtask} />
        </TabPanel>
        <TabPanel {...tabPanelStyles}>
          <DescriptionSection task={task} />
        </TabPanel>
        <TabPanel {...tabPanelStyles}>
          <CommentSection task={task} />
        </TabPanel>
        <TabPanel {...tabPanelStyles}>
          <FileSection task={task} />
        </TabPanel>
        <TabPanel {...tabPanelStyles}>
          <TimeLogSection task={task} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default TaskViewTabs;
