// src/components/Reusable/GanttDependencyLine.js

import React, { useState, useRef, useContext } from "react";
import { Popover, PopoverContent, PopoverBody, Button, HStack, useDisclosure, Portal } from "@chakra-ui/react";
import { PiTrash } from "react-icons/pi";
import { GanttContext } from "./GanttContext";

const DependencyLine = ({ fromTask, toTask, chartRef }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { isOpen: isPopoverOpen, onOpen: onPopoverOpen, onClose: onPopoverClose } = useDisclosure();
  const pathRef = useRef(null);

  const { handleDeleteDependency } = useContext(GanttContext); // Access the delete function from context

  const { left: sourceLeft, top: sourceTop, width: sourceWidth, height: sourceHeight, _id: sourceTaskId } = fromTask;
  const { left: targetLeft, top: targetTop, height: targetHeight, _id: targetTaskId } = toTask;

  // Calculate starting point (end of source task)
  const startX = sourceLeft + sourceWidth + 20;
  const startY = sourceTop + sourceHeight / 2;

  // Calculate ending point (start of target task)
  const endX = targetLeft - 20;
  const endY = targetTop + targetHeight / 2;

  // Define fixed offsets and dimensions
  const straightRightOffset = 20; // Move straight right by 20px
  const moveLeftOffset = 20; // Move left by 20px towards target
  const rowHeight = 60; // Should match the Gantt chart's row height
  const halfRowHeight = rowHeight / 2; // 30px

  // Calculate horizontal distance
  const horizontalDistance = targetLeft - (sourceLeft + sourceWidth);

  // Define a threshold
  const threshold = 20;

  // Determine whether to skip steps 3-5
  const shouldSkipSteps3_5 = horizontalDistance > threshold;

  // Determine the vertical difference between tasks
  const deltaRows = (targetTop - sourceTop) / rowHeight;

  // Check if the curve should go down or up
  const isCurvingDown = deltaRows > 0;

  // Initialize pathData
  let pathData = `M${startX},${startY} `;

  // Step 1: Move straight right from the start point
  const line1EndX = startX + straightRightOffset;
  const line1EndY = startY;
  pathData += `L${line1EndX},${line1EndY} `;

  // Step 2: 90° quadratic curve with height of halfRowHeight / 2
  const curve1ControlX = line1EndX + halfRowHeight / 2;
  const curve1ControlY = startY;
  const curve1EndX = curve1ControlX;
  const curve1EndY = startY - (halfRowHeight / 2) * (isCurvingDown ? -1 : 1);

  pathData += `Q${curve1ControlX},${curve1ControlY} ${curve1EndX},${curve1EndY} `;

  let curve3EndX; // Declare curve3EndX in the outer scope

  if (!shouldSkipSteps3_5) {
    // Step 3: 90° quadratic curve with height of halfRowHeight / 2
    const curve2ControlX = line1EndX + halfRowHeight / 2;
    const curve2ControlY = curve1ControlY - halfRowHeight * (isCurvingDown ? -1 : 1);
    const curve2EndX = line1EndX;
    const curve2EndY = startY - halfRowHeight * (isCurvingDown ? -1 : 1);

    pathData += `Q${curve2ControlX},${curve2ControlY} ${curve2EndX},${curve2EndY} `;

    // Step 4: Move straight left towards the target task
    const line2EndY = curve2EndY;
    const line2EndX = endX - moveLeftOffset;
    pathData += `L${line2EndX},${line2EndY} `;

    // Step 5: 90° quadratic curve with height of halfRowHeight / 2 to go up or down
    const curve3ControlX = line2EndX - halfRowHeight / 2;
    const curve3ControlY = line2EndY;
    curve3EndX = curve3ControlX;
    const curve3EndY = line2EndY + halfRowHeight / 2;

    pathData += `Q${curve3ControlX},${curve3ControlY} ${curve3EndX},${curve3EndY} `;
  }

  // Step 6: Move vertically to align with the target task's center
  const line3EndX = shouldSkipSteps3_5 ? curve1EndX : curve3EndX;
  const line3EndY = endY - halfRowHeight / 2; // Removed + verticalAdjustment
  pathData += `L${line3EndX},${line3EndY} `;

  // Step 7: 90° quadratic curve with height of halfRowHeight / 2 to left
  const curve4ControlX = line3EndX;
  const curve4ControlY = line3EndY + halfRowHeight / 2;
  const curve4EndX = line3EndX + halfRowHeight / 2;
  const curve4EndY = line3EndY + halfRowHeight / 2;

  pathData += `Q${curve4ControlX},${curve4ControlY} ${curve4EndX},${curve4EndY} `;

  // Step 8: Move right to the target task's left edge
  const line4EndX = endX;
  const line4EndY = curve4EndY;
  pathData += `L${line4EndX},${line4EndY} `;

  // Get the position for the popover
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  const handleClick = () => {
    if (pathRef.current && typeof pathRef.current.getBoundingClientRect === "function") {
      const rect = pathRef.current.getBoundingClientRect();
      setPopoverPosition({
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
      });
      onPopoverOpen();
    } else {
      console.warn("pathRef is null or does not have getBoundingClientRect");
    }
  };

  const handleDelete = () => {
    if (!sourceTaskId || !targetTaskId) {
      console.error("Source or Target Task _id is missing:", { sourceTaskId, targetTaskId });
      onPopoverClose();
      return;
    }

    handleDeleteDependency(sourceTaskId, targetTaskId);
    onPopoverClose();
  };

  return (
    <>
      {/* Container SVG */}
      <svg style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
        {/* Invisible clickable path */}
        <path
          d={pathData}
          stroke="transparent"
          strokeWidth="40"
          fill="none"
          ref={pathRef}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        />

        {/* Visible line */}
        <path
          d={pathData}
          stroke={isHovered ? "#2076ff" : "#a4c7ff"}
          transition="stroke 0.3s ease-in-out, background-color 0.3s ease-in-out"
          strokeWidth="2"
          fill="none"
          markerEnd="url(#arrow)"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleClick}
          style={{ cursor: "pointer", pointerEvents: "none" }}
        />
      </svg>

      {/* Popover */}
      <Portal>
        <Popover isOpen={isPopoverOpen} onClose={onPopoverClose} placement="top">
          <PopoverContent w="fit-content" position="absolute" top={popoverPosition.top} left={popoverPosition.left} zIndex={1000}>
            <PopoverBody>
              <HStack spacing={2}>
                {/* Removed the Edit button */}
                <Button leftIcon={<PiTrash />} colorScheme="red" onClick={handleDelete}>
                  Delete Dependency
                </Button>
              </HStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Portal>
    </>
  );
};

export default DependencyLine;
