/* eslint-disable no-unused-vars */
// ProjectsHistory.js
import React, { useContext, useEffect, useState } from "react";
import { Box, Flex, Heading, Text, useColorModeValue} from "@chakra-ui/react";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import ProjectsHistoryModal from "../3 - Modal/3 - Projects Modals/ProjectsHistoryModal";
 
const ProjectsHistory = () => {
  const { currentProjectHistory, fetchProjectHistory, currentProject } = useContext(ProjectContext);
  const [events, setEvents] = useState([]);
  const [modalEvents, setModalEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");

  // Fetch project history
  useEffect(() => {
    if (currentProject) {
      fetchProjectHistory(currentProject._id);
    }
  }, [fetchProjectHistory, currentProject]);

  // Map project and task history to calendar events
  useEffect(() => {
    if (currentProjectHistory) {
      let eventsData = [];

      // Project history events
      if (currentProjectHistory.history && Array.isArray(currentProjectHistory.history)) {
        eventsData = currentProjectHistory.history.map((entry) => ({
          _id: entry._id || entry._id,
          title: `${entry.action}`,
          start: new Date(entry.changedAt),
          extendedProps: {
            ...entry,
            isProject: true,
            field: entry.field,
            oldValue: entry.oldValue,
            newValue: entry.newValue,
            changedBy: entry.changedBy ? `${entry.changedBy.firstName} ${entry.changedBy.lastName}` : "Unknown",
            changedById: entry.changedBy ? `${entry.changedBy._id}` : "Unknown",
          },
        }));
      }

      // Task history events
      if (currentProjectHistory.tasks && Array.isArray(currentProjectHistory.tasks)) {
        currentProjectHistory.tasks.forEach((task) => {
          if (task.history && Array.isArray(task.history)) {
            task.history.forEach((entry) => {
              eventsData.push({
                id: entry._id || entry._id,
                title: `${task.name} - ${entry.action}`,
                start: new Date(entry.changedAt),
                extendedProps: {
                  ...entry,
                  taskName: task.name,
                  isProject: false,
                  field: entry.field,
                  oldValue: entry.oldValue,
                  newValue: entry.newValue,
                  changedBy: entry.changedBy ? `${entry.changedBy.firstName} ${entry.changedBy.lastName}` : "Unknown",
                  changedById: entry.changedBy ? `${entry.changedBy._id}` : "Unknown",
                },
              });
            });
          }
        });
      }

      setEvents(eventsData);
    }
  }, [currentProjectHistory]);

  return (
    <Box w="100%" h="100%" p={8} bg={bgColor} borderRadius="lg" boxShadow="lg" display="flex" flexDirection="column">
      {/* Header Section */}
      <Flex justify="space-between" align="center" mb={4}>
        <Heading size="lg">
          <Text as="span" color={textColor} fontWeight="bold">
            {currentProjectHistory?.name}
          </Text>{" "}
          <Text as="span" fontWeight="600" color="gray.500">
            - Project History
          </Text>
        </Heading>
      </Flex>

      {/* Calendar Section */}
      <Box flex="1" display="flex" flexDirection="column">
      </Box>

      {/* Modal */}
      <ProjectsHistoryModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} events={modalEvents} selectedDate={selectedDate} />
    </Box>
  );
};

export default ProjectsHistory;
