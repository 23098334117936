// EnhancedConnectionLostModal.jsx

import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalFooter, Button, Text, Icon, useColorModeValue, VStack, HStack } from "@chakra-ui/react";
import { WarningIcon, RepeatIcon } from "@chakra-ui/icons";

const ConnectionLostModal = ({ isOpen, onReconnect, onLoginAgain, isLoading }) => {
  // Define color schemes based on the current color mode
  const bgColor = useColorModeValue("white", "gray.700");
  const headerColor = useColorModeValue("primary.500", "primary.500");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const subTextColor = useColorModeValue("gray.500", "gray.400");

  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered closeOnOverlayClick={false} closeOnEsc={false}>
      <ModalOverlay />
      <ModalContent bg={bgColor} borderRadius="xl" boxShadow="2xl" overflow="hidden">
        <VStack spacing={4} p={6} align="center">
          {/* Warning Icon */}
          <Icon as={WarningIcon} w={8} h={8} color={headerColor} />

          {/* Modal Header */}
          <Text fontSize="3xl" fontWeight="bold" color={textColor} textAlign="center">
            Connection Lost
          </Text>

          {/* Modal Body */}
          <VStack spacing={3} textAlign="center">
            <Text fontSize="md" color={subTextColor}>
              Lost connection to the server. Please check your internet connection and try reconnecting. If the connection cannot be re-established,
              please log in again.
            </Text>
          </VStack>
        </VStack>

        {/* Modal Footer */}
        <ModalFooter justifyContent="center" pb={6}>
          <HStack spacing={4}>
            {/* Reconnect Button */}
            <Button
              loadingText="Reconnecting"
              isLoading={isLoading}
              leftIcon={<RepeatIcon />}
              colorScheme="gray"
              variant="outline"
              onClick={onReconnect}
              size="md"
            >
              Retry Connection
            </Button>

            {/* Login Again Button */}
            <Button colorScheme="primary" onClick={onLoginAgain} size="md">
              Login Again
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConnectionLostModal;
