import React from 'react';

const SettingsPageMessages = () => {

  return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
        <h3>Messages Settings</h3>
      </div>
  );
};

export default SettingsPageMessages;
