import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
  Box,
  Select,
  useDisclosure,
  UnorderedList,
  ListItem,
  useColorModeValue,
  Code,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import FilePreview from "../../5 - General/Utils/UtilsSelectedFilePreview";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";

const CsvUploadModal = ({ onSuccess }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [csvData, setCsvData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const customToast = useCustomToast();

  const bgColor = useColorModeValue("gray.50", "gray.800");

  // Categories corresponding to your models
  const categories = [
    { _id: "user", name: "Users" },
    { _id: "company", name: "Companies" },
  ];

  // CSV format definitions
  const csvFormats = {
    user: {
      instructions: "The file must contain the following columns:",
      columns: [
        "Email",
        "Password",
        "Role",
        "Is Verified",
        "Profile Completed",
        "First Name",
        "Last Name",
        "Phone Number",
        "Date of Birth",
        "Street",
        "City",
        "Province",
        "Postal Code",
        "Country",
        "Preferred Language",
        "Job Title",
        "Industry",
        "Profile Picture",
        "Company ID",
        "Last Logged In",
        "Status",
        "Modules",
      ],
    },
    company: {
      instructions: "The file must contain the following columns:",
      columns: [
        "Company Name",
        "Company Registration Number",
        "User ID",
        "Created By",
        "Company Role",
        "Primary Color",
        "Logo Color Path",
        "Logo White Path",
        "Street",
        "City",
        "State",
        "Postal Code",
        "Country",
        "Phone",
        "Email",
        "Industry",
        "Number of Employees",
        "Website",
        "LinkedIn",
        "Twitter",
        "Facebook",
        "Modules",
      ],
    },
  };

  // Mapping between CSV column names and backend field names
  const fieldMapping = {
    user: {
      Email: "email",
      Password: "password",
      Role: "role",
      "Is Verified": "isVerified",
      "Profile Completed": "profileCompleted",
      "First Name": "firstName",
      "Last Name": "lastName",
      "Phone Number": "phoneNumber",
      "Date of Birth": "dateOfBirth",
      Street: "street",
      City: "city",
      Province: "province",
      "Postal Code": "postalCode",
      Country: "country",
      "Preferred Language": "preferredLanguage",
      "Job Title": "jobTitle",
      Industry: "industry",
      "Profile Picture": "profilePicture",
      "Company ID": "companyId",
      "Last Logged In": "lastLoggedIn",
      Status: "status",
      Modules: "modules",
    },
    company: {
      "Company Name": "companyName",
      "Company Registration Number": "companyRegistrationNumber",
      "User ID": "userId",
      "Created By": "createdBy",
      "Company Role": "companyRole",
      "Primary Color": "primaryColor",
      "Logo Color Path": "logoColorPath",
      "Logo White Path": "logoWhitePath",
      Street: "address.street",
      City: "address.city",
      State: "address.state",
      "Postal Code": "address.postalCode",
      Country: "address.country",
      Phone: "contactInfo.phone",
      Email: "contactInfo.email",
      Industry: "industry",
      "Number of Employees": "numberOfEmployees",
      Website: "website",
      LinkedIn: "socialMedia.linkedIn",
      Twitter: "socialMedia.twitter",
      Facebook: "socialMedia.facebook",
      Modules: "modules",
    },
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      setSelectedFile({
        name: file.path || file.name,
        size: file.size || 0,
        preview: URL.createObjectURL(file),
      });

      // Parse the CSV file
      Papa.parse(file, {
        header: true, // Use the first row as headers
        skipEmptyLines: true,
        complete: (result) => {
          // Map the field names to match backend expectations
          const mappedData = result.data.map((row) => {
            const mappedRow = {};
            for (const [key, value] of Object.entries(row)) {
              const newKey = fieldMapping[selectedCategory][key] || key;
              mappedRow[newKey] = value;
            }
            return mappedRow;
          });
          setCsvData(mappedData);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
          customToast("error", "Error", "Problem reading the CSV file.");
        },
      });
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setCsvData(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "text/csv": [".csv"],
    },
    maxFiles: 1,
  });

  useEffect(() => {
    return () => {
      if (selectedFile?.preview) {
        URL.revokeObjectURL(selectedFile.preview);
      }
    };
  }, [selectedFile]);

  const handleUpload = async () => {
    if (!selectedCategory) {
      customToast("error", "Error", "Please select a category.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch("http://localhost:5000/api/v1/blueprint/import-csv", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ categoryName: selectedCategory, csvData }),
      });

      const result = await response.json();

      if (response.ok) {
        const { successfulCount, failedCount } = result;
        let description = `${successfulCount} row(s) successfully imported.`;
        if (failedCount > 0) {
          description += ` ${failedCount} row(s) failed.`;
        }

        customToast(failedCount > 0 ? "warning" : "success", "Import completed", description);

        onSuccess();
        handleModalClose();
      } else {
        customToast("error", "Error", result.message || "Import failed.");
      }
    } catch (error) {
      customToast("error", "Error", "Communication problem with the server.");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setSelectedFile(null);
    setSelectedCategory("");
    setCsvData(null);
  };

  const handleModalClose = () => {
    resetForm();
    onClose();
  };

  return (
    <>
      <Button colorScheme='gray' my={2} ml={3} onClick={onOpen}>
        Import CSV
      </Button>

      <Modal scrollBehavior="inside" isOpen={isOpen} onClose={handleModalClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import a CSV file</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>Please upload a CSV file with the following format:</Text>

            {selectedCategory && csvFormats[selectedCategory] && (
              <Box bg={bgColor} p={4} mb={4} borderRadius="md">
                <Text fontWeight="bold" mb={2}>
                  {csvFormats[selectedCategory]?.instructions}
                </Text>
                <UnorderedList mb={4}>
                  {csvFormats[selectedCategory]?.columns.map((col, index) => (
                    <ListItem key={index}>
                      <Code>{col}</Code>
                    </ListItem>
                  ))}
                </UnorderedList>
              </Box>
            )}
            <Select placeholder="Select a category" onChange={(e) => setSelectedCategory(e.target.value)} mb={4} value={selectedCategory}>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </Select>

            {selectedFile ? (
              <FilePreview file={selectedFile} handleRemoveFile={handleRemoveFile} />
            ) : (
              <Box {...getRootProps()} p={8} border="2px dashed gray" borderRadius="md" textAlign="center" cursor="pointer" bg={bgColor}>
                <input {...getInputProps()} />
                <Text>Drag and drop a file here, or click to select a file</Text>
              </Box>
            )}
          </ModalBody>

          <ModalFooter>
            <Button onClick={handleUpload} isLoading={loading} isDisabled={!csvData || !selectedCategory} colorScheme="primary" mr={3}>
              Import
            </Button>
            <Button variant="outline" colorScheme="gray" onClick={handleModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CsvUploadModal;
