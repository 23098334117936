// AuthPage.js

import React, { useState } from "react";
import { Flex, Box, Image, useColorModeValue, useBreakpointValue, Link, Text } from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import LoginPage from "./loginPage"; // Ensure correct casing
import RegisterPage from "./RegisterPage";
import RegisterImage from "../../img/Login.png";
import LoginImage from "../../img/Register.png";

const MotionBox = motion.create(Box);

const smoothTransition = {
  duration: 0.5,
  ease: "easeInOut",
};

// Define animation variants for images
const imageVariants = {
  initial: { opacity: 0, x: -50 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 50 },
};

function AuthPage() {
  const [isLogin, setIsLogin] = useState(true);
  const bgGradient = useColorModeValue("linear(to-r, white, primary.100)", "linear(to-r, primary.700, blue.800)");
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleSwitch = () => {
    setIsLogin(!isLogin);
  };

  return (
    <Flex
      align="center"
      justify="center"
      minH="100vh"
      bgGradient={bgGradient}
      fontFamily="'Inter', 'Poppins', sans-serif"
      width="100%"
      px={{ base: 6, md: 4 }}
      pb={{ base: 20, md: 4 }}
      position="relative"
      overflow="hidden"
    >
      {/* Main Container */}
      <Box 
        w={{ base: "100%", md: "70%" }} 
        h={{ base: "80vh", md: "700px" }} 
        position="relative" 
        overflow="hidden" 
        borderRadius="2xl" 
        boxShadow="2xl"
      >
        {/* Forms Container */}
        <Flex w="100%" h="100%" position="relative" direction={{ base: "column", md: "row" }}>
          {/* Login Form */}
          <Box
            w={{ base: "100%", md: "50%" }}
            h={{ base: "100%", md: "100%" }}
            bg={useColorModeValue("white", "gray.800")}
            position="relative"
            zIndex="1"
            display={isLogin || isMobile ? "flex" : "none"}
            alignItems="center"
            justifyContent="center"
            p={0}
          >
            <AnimatePresence wait>
              {isLogin && (
                <MotionBox
                  key="login"
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 50 }}
                  transition={smoothTransition}
                  width="100%"
                >
                  <LoginPage switchToRegister={handleSwitch} />
                </MotionBox>
              )}
            </AnimatePresence>
          </Box>

          {/* Register Form */}
          <Box
            w={{ base: "100%", md: "50%" }}
            h={{ base: "100%", md: "100%" }}
            bg={useColorModeValue("white", "gray.800")}
            position="relative"
            zIndex="1"
            display={!isLogin || isMobile ? "flex" : "none"}
            alignItems="center"
            justifyContent="center"
            p={{ base: 4, md: 6 }}
          >
            <AnimatePresence wait>
              {!isLogin && (
                <MotionBox
                  key="register"
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 50 }}
                  transition={smoothTransition}
                  width="100%"
                >
                  <RegisterPage switchToLogin={handleSwitch} />
                </MotionBox>
              )}
            </AnimatePresence>
          </Box>

          {/* Sliding Image */}
          {!isMobile && (
            <Box w="50%" h="100%" position="relative" zIndex="0">
              <AnimatePresence>
                <MotionBox
                  key={isLogin ? "registerImage" : "loginImage"}
                  variants={imageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={smoothTransition}
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                >
                  <Image src={isLogin ? RegisterImage : LoginImage} alt="Auth illustration" objectFit="cover" w="100%" h="100%" borderRadius="2xl" />
                </MotionBox>
              </AnimatePresence>
            </Box>
          )}
        </Flex>
      </Box>
      <Text
        fontSize={{ base: "sm", md: "md" }}
        position="absolute"
        bottom="4"
        color="gray.500"
        textAlign="center"
        px={{ base: 2, md: 0 }}
        width="100%"
      >
        © 2024 Fab Innov. All rights reserved. Made by Fabien Gaudreau :{" "}
        <Link href="https://fabinnov.ca" isExternal color="primary.500" textDecoration="underline">
          Back to main site
        </Link>
      </Text>
    </Flex>
  );
}

export default AuthPage;
