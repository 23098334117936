// src/components/5 - General/Hooks/useUpdateCompany.js

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSocket } from "../Socket/SocketContext";
import { uploadColorLogo, uploadWhiteLogo } from "../../4 - API/API-Company";
import useCustomToast from "../Utils/UtilsNotification";
import { queryKeys } from "../../../queryKeys";

// Hook for updating company
export const useUpdateCompany = () => {
  const { socket } = useSocket();
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  const updateCompany = async ({ companyId, updates }) => {
    return new Promise((resolve, reject) => {
      socket.emit("updateCompanySettings", { companyId, updates }, (response) => {
        if (response.status === "ok") {
          resolve(response.company);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: updateCompany,
    mutationKey: "updateCompany",
    onSuccess: (company) => {
      queryClient.setQueryData(queryKeys.company(company._id), company);
      const companyId = company._id;
      const companyAvatarQueries = queryClient.getQueriesData({ queryKey: ["companyAvatar"] });
      companyAvatarQueries.forEach(([queryKey]) => {
        const companyIds = queryKey[1];
        if (Array.isArray(companyIds) && companyIds.includes(companyId)) {
          queryClient.setQueryData(queryKey, (oldCacheData) => ({
            ...oldCacheData,
            [companyId]: {
              ...oldCacheData?.[companyId],
              firstName: company.companyName,
            },
          }));
        }
      });

      customToast({
        title: "Company Updated.",
        description: "Company settings have been updated successfully.",
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        status: "error",
        title: "Update Failed",
        description: error.message || "Failed to update company settings.",
      });
      console.error("Error updating company settings:", error);
    },
  });
};

// Hook for uploading logos
export const useUploadLogo = () => {
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  const uploadLogo = async ({ companyId, file, logoType }) => {
    if (typeof companyId === "object" && companyId._id) {
      companyId = companyId._id;
    }
    return new Promise((resolve, reject) => {
      if (logoType === "colorLogo") {
        uploadColorLogo(companyId, file)
          .then((response) => {
            resolve(response.logoColorPath);
          })
          .catch((error) => {
            reject(error);
          });
      } else if (logoType === "whiteLogo") {
        uploadWhiteLogo(companyId, file)
          .then((response) => {
            resolve(response.logoWhitePath);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Invalid logo type."));
      }
    });
  };

  return useMutation({
    mutationFn: uploadLogo,
    mutationKey: "uploadLogo",
    onSuccess: (logoPath, variables) => {
      queryClient.invalidateQueries([queryKeys.company(variables.companyId)]);

      customToast({
        title: "Logo Uploaded.",
        description: `${variables.logoType} logo has been uploaded successfully.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        status: "error",
        title: "Upload Failed",
        description: error.message || "Failed to upload logo.",
      });
      console.error("Error uploading logo:", error);
    },
  });
};

// Hook for inviting a user
export const useInviteUser = () => {
  const { socket } = useSocket();
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  const inviteUser = async ({ newUser, invitedBy }) => {
    const { firstName, lastName, email, companyId } = newUser;
    return new Promise((resolve, reject) => {
      socket.emit("inviteUser", { firstName, lastName, email, companyId, invitedBy }, (response) => {
        if (response.status === "ok") {
          resolve(response.user);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: inviteUser,
    mutationKey: "inviteUser",
    onSuccess: (user) => {
      queryClient.invalidateQueries(queryKeys.companyUsers(user.companyId));
      customToast({
        title: "User Invited",
        description: `${user.firstName} ${user.lastName} has been invited successfully. An email has been sent to ${user.email}.`,
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        status: "error",
        title: "Invitation Failed",
        description: error.message || "Failed to invite user.",
      });
      console.error("Error inviting user:", error);
    },
  });
};

export const useDeleteCompany = () => {
  const { socket } = useSocket();
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  const deleteCompany = async (companyId) => {
    return new Promise((resolve, reject) => {
      socket.emit("deleteCompany", companyId, (response) => {
        if (response.status === "ok") {
          resolve();
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: deleteCompany,
    mutationKey: "deleteCompany",
    onSuccess: () => {
      queryClient.clear();
      localStorage.clear();
      customToast({
        title: "Company Deleted",
        description: "Company has been deleted successfully.",
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        status: "error",
        title: "Deletion Failed",
        description: error.message || "Failed to delete company.",
      });
      console.error("Error deleting company:", error);
    },
  });
};
