import React from "react";
import { Box, Heading, Text, Center, Icon, Button, SimpleGrid, Divider } from "@chakra-ui/react";
import { PiInvoice } from "react-icons/pi";

const Billing = () => {
  return (
    <Box bg="gray.50" w="100%" minH="100vh" p={5} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Center w="100%" flexDirection="column">
        <Icon as={PiInvoice} boxSize={20} color="primary.500" mb={4} />
        <Heading as="h1" size="2xl" color="primary.700" textAlign="center">
          Billing Module Coming Soon
        </Heading>
        <Text fontSize="lg" color="gray.600" textAlign="center" maxW="800px" mt={4}>
          We're excited to announce that our new Billing Module is on the way! Soon, you'll be able to manage all your invoices, payments, and
          financial reports directly from your client portal.
        </Text>
        <Button colorScheme="primary" size="lg" mt={6} disabled>
          Notify Me When Available
        </Button>
      </Center>

      <Divider my={10} />

      {/* Feature Highlights */}
      <Box maxW="800px" mx="auto">
        <Heading as="h2" size="lg" color="primary.600" mb={4} textAlign="center">
          Upcoming Features
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              💳 Online Payments
            </Heading>
            <Text color="gray.600">Accept credit card payments directly through the portal with secure payment processing.</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              🧾 Invoice Management
            </Heading>
            <Text color="gray.600">Create, send, and track invoices with ease. Customize invoice to match your branding.</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              📊 Financial Reports
            </Heading>
            <Text color="gray.600">Generate detailed financial reports to monitor revenue, expenses, and profitability.</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              ⏱️ Time Tracking Integration
            </Heading>
            <Text color="gray.600">Integrate time tracking data to automatically calculate billable hours and costs.</Text>
          </Box>
        </SimpleGrid>
      </Box>
      <Box w="10px" h="100px" />
    </Box>
  );
};

export default Billing;
