// client/src/components/Projects.js

import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import {
  Flex,
  Spinner,
  HStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { PiCaretRightBold } from "react-icons/pi";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import ProjectCards from "./ProjectsCards";
import CreateProjectModal from "../3 - Modal/3 - Projects Modals/ProjectsCreateProjectModal";
import TaskGroups from "./ProjectsTaskGroup";
import ProjectsHistory from "./ProjectsHistory";

const Projects = () => {
  const {
    companies,
    currentCompany,
    setCurrentCompany,
    setCurrentProject,
    setCurrentTask,
    fetchProjectsForCompany,
    loadingProjects,
    currentProject,
    setCurrentProjectHistory,
    currentTask,
  } = useContext(ProjectContext);

  const { isOpen, onClose } = useDisclosure();
  const [selectedHistoryProject, setSelectedHistoryProject] = useState(false);

  const borderColor = useColorModeValue("gray.300", "gray.700");
  const breadcrumbTextColor = useColorModeValue("black", "white");
  const breadcrumbLinkColor = useColorModeValue("gray.500", "gray.400");
  const spinnerColor = useColorModeValue("primary.500", "primary.200");
  const bgColor = useColorModeValue("white", "gray.800");

  // **Automatically select the first company and fetch its projects**
  useEffect(() => {
    if (companies.length > 0 && !currentCompany) {
      const firstCompany = companies.find((company) => company);
      if (firstCompany) {
        setCurrentCompany(firstCompany);
      } else {
        console.warn("No valid companies found.");
      }
    }
  }, [companies, currentCompany, setCurrentCompany]);

  // **Fetch projects when currentCompany is set**
  useEffect(() => {
    if (currentCompany) {
      fetchProjectsForCompany(currentCompany._id);
    } else {
      setCurrentProject(null);
      setSelectedHistoryProject(false);
      setCurrentProjectHistory(null);
    }
  }, [currentCompany, fetchProjectsForCompany, setCurrentProject, setSelectedHistoryProject, setCurrentProjectHistory]);

  // **Compute Breadcrumb Dynamically**
  const breadcrumb = useMemo(() => {
    const crumbs = ["Projects"];

    if (currentCompany) {
      crumbs.push(currentCompany.companyName || "Select a Company");
    }

    if (currentProject) {
      crumbs.push(currentProject.name || "Unnamed Project");
    }

    if (selectedHistoryProject) {
      crumbs.push("History");
    }

    if (currentTask) {
      crumbs.push(currentTask.name || "Unnamed Task");
    }

    return crumbs;
  }, [currentCompany, currentProject, selectedHistoryProject, currentTask]);

  const handleBreadcrumbClick = useCallback(
    (crumb) => {
      if (crumb === currentCompany?.companyName) {
        // Navigate back to the company level, showing the project list
        setCurrentProject(null);
        setCurrentTask(null);
        setSelectedHistoryProject(false);
        setCurrentProjectHistory(null);
      } else if (crumb === currentProject?.name) {
        // If clicking on the current project, display TaskGroups
        setCurrentTask(null);
        setSelectedHistoryProject(false);
        setCurrentProjectHistory(null);
      } else if (crumb === currentTask?.name) {
        // If in history mode and task is clicked, return to TaskView
        if (selectedHistoryProject) {
          setSelectedHistoryProject(false);
        } else {
          // Just clear the task selection otherwise
          setCurrentTask(null);
        }
      }
    },
    [
      currentCompany,
      currentProject,
      currentTask,
      selectedHistoryProject,
      setCurrentProject,
      setCurrentTask,
      setSelectedHistoryProject,
      setCurrentProjectHistory,
    ]
  );

  // **Handle Edge Case: currentCompany Still Null After Companies are Fetched**
  if (!currentCompany) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" color={spinnerColor} />
      </Flex>
    );
  }

  return (
    <Flex height="100%" flex="1" boxShadow="lg" borderRadius="lg" flexDirection="column" overflow="auto" bg={bgColor}>
      {/* Top Bar with Breadcrumb and Company Dropdown */}
      <Flex
        justify="space-between"
        align="center"
        position="sticky"
        top={0}
        zIndex={1}
        pl={6}
        pr={6}
        h="80px"
        minH="80px"
        borderBottom="1px solid"
        borderColor={borderColor}
        bg={bgColor}
      >
        {/* Breadcrumb */}
        <HStack spacing={4}>
          <Breadcrumb spacing="8px" fontSize="lg" separator={<PiCaretRightBold size="12px" color="gray.500" />}>
            {breadcrumb.map((crumb, index) => (
              <BreadcrumbItem key={index}>
                {index === 0 ? (
                  // Make the first breadcrumb item unclickable and inactive
                  <Text fontWeight="normal" color="gray.500" fontSize="lg">
                    {crumb}
                  </Text>
                ) : index === breadcrumb.length - 1 ? (
                  // Last breadcrumb item (active, not clickable)
                  <Text color={breadcrumbTextColor} fontWeight="500">
                    {crumb}
                  </Text>
                ) : (
                  // All other breadcrumb items (clickable)
                  <BreadcrumbLink onClick={() => handleBreadcrumbClick(crumb)} cursor="pointer" color={breadcrumbLinkColor} fontWeight="normal">
                    {crumb}
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </HStack>
      </Flex>

      {/* Main Content */}
      <Flex flex="1" height="100%" overflow="auto">
        {currentCompany ? (
          loadingProjects ? (
            // Show spinner until the fetch completes
            <Flex justify="center" align="center" height="100%" width="100%">
              <Spinner size="xl" color={spinnerColor} />
            </Flex>
          ) : selectedHistoryProject ? (
            <ProjectsHistory />
          ) : currentProject ? (
            <TaskGroups />
          ) : (
            <ProjectCards loadingProjects={loadingProjects} />
          )
        ) : (
          <Flex justify="center" align="center" height="100%" width="100%">
            <Text fontSize="xl" color={breadcrumbTextColor}>
              Select a company to view projects
            </Text>
          </Flex>
        )}
      </Flex>

      <CreateProjectModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default Projects;
