// client/src/components/5 - General/Hooks/useUpdateMessages.js

import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useSocket } from "../Socket/SocketContext";
import useCustomToast from "../Utils/UtilsNotification";
import { queryKeys } from "../../../queryKeys";
import { uploadFileToChannel } from "../../4 - API/API-Messages";

/**
 * Hook: useSendMessage
 * Purpose: Send a new message to a channel
 */
export const useSendMessage = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const sendMessage = ({ channelId, content, file }) => {
    return new Promise(async (resolve, reject) => {
      let messageData = { channelId, content };
      if (file) {
        try {
          const fileData = await uploadFileToChannel(channelId, file);
          messageData = {
            ...messageData,
            includesFile: true,
            originalFileName: fileData.originalFileName,
            backendFileName: fileData.backendFileName,
            fileSize: file.size,
          };
        } catch (error) {
          customToast({
            title: "File Upload Error",
            description: error.message || "Failed to upload file.",
            status: "error",
          });
          reject(error);
          return;
        }
      }

      socket.emit("sendMessage", messageData, (response) => {
        if (response.status === "ok") {
          resolve(response.message);
        } else {
          customToast({
            title: "Send Message Error",
            description: response.message || "Failed to send message.",
            status: "error",
          });
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationKey: ["sendMessage"],
    mutationFn: sendMessage,
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to send message.",
        status: "error",
      });
      console.error("Error sending message:", error);
    },
  });
};

/**
 * Hook: useUpdateMessage
 * Purpose: Handle editing and deleting messages
 */
export const useUpdateMessage = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const updateMessage = ({ channelId, action, payload }) => {
    return new Promise((resolve, reject) => {
      socket.emit("updateMessage", { channelId, action, payload }, (response) => {
        if (response.status === "ok") {
          resolve(response.message, action);
        } else {
          customToast({
            title: "Update Message Error",
            description: response.message || "Failed to update message.",
            status: "error",
          });
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationKey: ["updateMessage"],
    mutationFn: updateMessage,
    onSuccess: (data) => {
      if (data.action === "delete") {
        customToast({
          title: "Message Deleted",
          description: "Your message has been deleted.",
          status: "success",
        });
      } else if (data.action === "edit") {
        customToast({
          title: "Message Updated",
          description: "Your message has been updated.",
          status: "success",
        });
      }
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to update message.",
        status: "error",
      });
      console.error("Error updating message:", error);
    },
  });
};

/**
 * Hook: useCreateChannel
 * Purpose: Create a new channel
 */
export const useCreateChannel = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const createChannel = ({ name, members }) => {
    return new Promise((resolve, reject) => {
      socket.emit("createChannel", { name, members }, (response) => {
        if (response.status === "ok") {
          resolve(response.newChannel);
        } else {
          customToast({
            title: "Create Channel Error",
            description: response.message || "Failed to create channel.",
            status: "error",
          });
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationKey: ["createChannel"],
    mutationFn: createChannel,
    onSuccess: (data) => {
      customToast({
        title: "Channel Created",
        description: "Your new channel has been created.",
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to create channel.",
        status: "error",
      });
      console.error("Error creating channel:", error);
    },
  });
};

/**
 * Hook: useDeleteChannel
 * Purpose: Delete an existing channel
 */
export const useDeleteChannel = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const deleteChannel = ({ channelId }) => {
    return new Promise((resolve, reject) => {
      socket.emit("deleteChannel", { channelId }, (response) => {
        if (response.status === "ok") {
          resolve(channelId);
        } else {
          customToast({
            title: "Delete Channel Error",
            description: response.message || "Failed to delete channel.",
            status: "error",
          });
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationKey: ["deleteChannel"],
    mutationFn: deleteChannel,
    onSuccess: (data) => {
      customToast({
        title: "Channel Deleted",
        description: "The channel has been deleted.",
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to delete channel.",
        status: "error",
      });
      console.error("Error deleting channel:", error);
    },
  });
};

/**
 * Hook: useUpdateChannel
 * Purpose: Handle adding/removing users and renaming channels
 */
export const useUpdateChannel = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const updateChannel = ({ channelId, action, payload }) => {
    return new Promise((resolve, reject) => {
      socket.emit("updateChannel", { channelId, action, payload }, (response) => {
        if (response.status === "ok") {
          resolve(response.channel);
        } else {
          customToast({
            title: "Update Channel Error",
            description: response.message || "Failed to update channel.",
            status: "error",
          });
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationKey: ["updateChannel"],
    mutationFn: updateChannel,
    onSuccess: (data) => {
      customToast({
        title: "Channel Updated",
        description: "The channel has been updated.",
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to update channel.",
        status: "error",
      });
      console.error("Error updating channel:", error);
    },
  });
};

/**
 * Hook: useMarkMessagesAsRead
 * Purpose: Mark messages as read for a user in a channel
 */
export const useMarkMessagesAsRead = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const markAsRead = ({ channelId }) => {
    return new Promise((resolve, reject) => {
      socket.emit("updateMessage", { channelId, action: "markAsRead", payload: {} }, (response) => {
        if (response.status === "ok") {
          resolve(response.message);
        } else {
          customToast({
            title: "Mark as Read Error",
            description: response.message || "Failed to mark messages as read.",
            status: "error",
          });
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationKey: ["markMessagesAsRead"],
    mutationFn: markAsRead,
    onError: (error) => {
      customToast({
        title: "Error",
        description: error.message || "Failed to mark messages as read.",
        status: "error",
      });
      console.error("Error marking messages as read:", error);
    },
  });
};

/**
 * Hook: useGetChannelUsers
 * Purpose: Fetch all users belonging to a specific channel
 */
export const useGetChannelUsers = (channelId) => {
  const { socket } = useSocket();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: queryKeys.channelUsers(channelId),
    queryFn: () => {
      return new Promise((resolve, reject) => {
        socket.emit("getChannelUsers", { channelId }, (response) => {
          if (response.status === "ok") {
            resolve(response.members);
          } else {
            reject(new Error(response.message));
          }
        });
      });
    },
    onSuccess: (members) => {
      queryClient.setQueryData(queryKeys.channelUsers(channelId), members);
    },
    onError: (error) => {
      // Handle error, possibly with a toast notification
    },
    enabled: Boolean(socket && channelId),
  });
};

/**
 * Utility function to update a message within paginated data.
 *
 * @param {Object} oldData - The existing query data from useInfiniteQuery.
 * @param {String} messageId - The ID of the message to update.
 * @param {Function} updateFn - A function that takes a message object and returns the updated message.
 * @returns {Object} - The new query data with the updated message.
 */
export const updateMessageInPaginatedData = (oldData, messageId, updateFn) => {
  if (!oldData) return oldData;

  const newPages = oldData.pages.map((page) => {
    const newMessages = page.messages.map((msg) => (msg._id === messageId ? updateFn(msg) : msg));
    return { ...page, messages: newMessages };
  });

  return { ...oldData, pages: newPages };
};

/**
 * Utility function to remove a message within paginated data.
 *
 * @param {Object} oldData - The existing query data from useInfiniteQuery.
 * @param {String} messageId - The ID of the message to remove.
 * @returns {Object} - The new query data with the message removed.
 */
export const removeMessageInPaginatedData = (oldData, messageId) => {
  if (!oldData) return oldData;

  const newPages = oldData.pages.map((page) => {
    const newMessages = page.messages.filter((msg) => msg._id !== messageId);
    return { ...page, messages: newMessages };
  });

  return { ...oldData, pages: newPages };
};

/**
 * Utility function to add a new message to the paginated data.
 * Prepends to the first page to ensure newest messages are on page 1.
 * Allows the first page to exceed the limit without moving messages to subsequent pages.
 *
 * @param {Object} oldData - The existing query data from useInfiniteQuery.
 * @param {Object} newMessage - The new message object to add.
 * @param {Number} [limit=35] - The maximum number of messages per page (applied only to subsequent pages).
 * @returns {Object} - The new query data with the message added.
 */
export const addMessageToPaginatedData = (oldData, newMessage, limit = 35) => {
  if (!oldData || !oldData.pages || oldData.pages.length === 0) {
    // If there's no existing data, initialize with the new message in the first page
    return {
      pages: [
        {
          skip: 0,
          limit: limit,
          totalMessages: 1,
          totalPages: 1,
          messages: [newMessage],
        },
      ],
      pageParams: [null],
    };
  }

  // Clone the existing pages to avoid direct mutations
  const newPages = [...oldData.pages];

  // Prepend the new message to the first page's messages array
  newPages[0] = {
    ...newPages[0],
    messages: [newMessage, ...newPages[0].messages],
  };

  // Optionally, you can update totalMessages and totalPages if needed
  const totalMessages = newPages.reduce((acc, page) => acc + page.messages.length, 0);
  const totalPages = newPages.length;

  return {
    ...oldData,
    pages: newPages,
    totalPages: totalPages,
    totalMessages: totalMessages,
  };
};
