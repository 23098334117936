// src/components/FilesContent.jsx

import React, { useContext } from "react";
import { Flex, Box, useColorModeValue } from "@chakra-ui/react";
import { FilesContext } from "../5 - General/Context/FilesContext";
import FilesTopBar from "./FilesTopBar";
import FilesList from "./FilesList";
import ImportFileModal from "../3 - Modal/4 - Files Modals/FileUploadModal";
import CreateFolderModal from "../3 - Modal/4 - Files Modals/FileCreateFolderModal";
import RenameModal from "../3 - Modal/4 - Files Modals/FileRenameModal";
import DeleteConfirmationModal from "../3 - Modal/4 - Files Modals/FileDeleteConfirmationModal";
import ImagePreviewModal from "../3 - Modal/9 - General Modals/GeneralImagePreviewModal";
import FileCreateLinkModal from "../3 - Modal/4 - Files Modals/FileCreateLinkModal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const FilesContent = () => {
  const bgColor = useColorModeValue("white", "gray.800");
  const { isImagePreviewModalOpen, onCloseImagePreviewModal, selectedImage } = useContext(FilesContext);

  return (
    <Flex direction="row" height="100vh" overflow="hidden" w="100%">
      <Box flex="1" display="flex" flexDirection="column" overflow="hidden" bg={bgColor}>
      <DndProvider backend={HTML5Backend}>
        <FilesTopBar />
        <FilesList />
        </DndProvider>
      </Box>

      {/* Modals */}
      <ImportFileModal />
      <CreateFolderModal />
      <RenameModal />
      <DeleteConfirmationModal />
      <ImagePreviewModal
        isOpen={isImagePreviewModalOpen}
        onClose={onCloseImagePreviewModal}
        selectedImage={selectedImage}
      />
      <FileCreateLinkModal />
    </Flex>
  );
};

export default FilesContent;
