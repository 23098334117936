// src/components/3 - Modal/2 - Messages Modals/CreateOneOnOneModal.js

import React, { useState, useContext, useEffect } from "react";
import { Box, VStack, Button, Text, Spinner, Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { MessagesContext } from "../../5 - General/Context/MessagesContext";

const CreateOneOnOneModal = ({ isOpen, onClose }) => {
  const { currentUser, availableUsers, isAvailableUsersLoading, availableUsersError, handleCreateChannel } = useContext(MessagesContext);

  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (!isOpen) {
      setSelectedUser(null);
    }
  }, [isOpen]);

  const footerButtons = (
    <Button
      colorScheme="primary"
      onClick={() => {
        if (selectedUser) {
          const conversationName = `${selectedUser.firstName} ${selectedUser.lastName}`;
          const memberIds = [selectedUser.value, currentUser._id];
          handleCreateChannel(conversationName, memberIds, true);
          onClose();
        }
      }}
      disabled={!selectedUser}
    >
      Create
    </Button>
  );

  const renderContent = () => {
    if (isAvailableUsersLoading) {
      return (
        <VStack spacing={4} pt={4}>
          <Spinner />
          <Text>Loading users...</Text>
        </VStack>
      );
    }

    if (availableUsersError) {
      return (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Failed to load users.</AlertTitle>
          <AlertDescription>{availableUsersError.message}</AlertDescription>
        </Alert>
      );
    }

    if (availableUsers.length === 0) {
      return (
        <Alert status="info">
          <AlertIcon />
          <AlertTitle mr={2}>No users available.</AlertTitle>
          <AlertDescription>There are no users available to create a conversation.</AlertDescription>
        </Alert>
      );
    }

    const options = availableUsers
      .filter((user) => user.value !== currentUser._id)
      .map((user) => ({
        value: user.value,
        label: user.label,
        firstName: user.firstName,
        lastName: user.lastName,
      }));

    return (
      <VStack spacing={4} pt={4}>
        <Box width="100%">
          <Select
            options={options}
            value={selectedUser}
            onChange={(selected) => setSelectedUser(selected)}
            placeholder="Select a user..."
            chakraStyles={{
              menu: (provided) => ({ ...provided, mt: "2px", zIndex: 3 }),
              control: (provided) => ({ ...provided, border: "1px solid", borderColor: "gray.200" }),
            }}
          />
        </Box>
      </VStack>
    );
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Create a One-on-One Conversation" footerButtons={footerButtons}>
      {renderContent()}
    </ReusableModal>
  );
};

export default CreateOneOnOneModal;
