export const isColorLight = (hexColor) => {
    if (!hexColor || typeof hexColor !== 'string') {
        throw new Error('Invalid hex color');
    }

    // Remove the '#' character if it exists
    let color = hexColor.replace('#', '');

    // Handle 3-digit hex colors
    if (color.length === 3) {
        color = color.split('').map(char => char + char).join('');
    }

    if (color.length !== 6) {
        throw new Error('Invalid hex color length');
    }

    // Parse the hex color
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);

    if (isNaN(r) || isNaN(g) || isNaN(b)) {
        throw new Error('Invalid hex color');
    }

    // Calculate brightness (YIQ formula)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Return true if the color is light, otherwise false
    return brightness > 128; // Higher than 128 means it's a light color
};