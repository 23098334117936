// client/src/components/MessagesActiveChannel.js

import React, { useRef, useState, useCallback, useContext, useEffect } from "react";
import {
  Box,
  Flex,
  IconButton,
  Text,
  VStack,
  Image,
  Spinner,
  useColorModeValue,
  Editable,
  EditableInput,
  EditablePreview,
  Tooltip,
  Button,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { renderFileIcon } from "../5 - General/Utils/UtilsFileIcon";
import { PiPaperPlaneTiltFill, PiPlus, PiTrash, PiUser, PiXBold, PiArrowDownBold } from "react-icons/pi";
import DOMPurify from "dompurify";
import MessagesItem from "./MessagesItem";
import { MessagesContext } from "../5 - General/Context/MessagesContext";
import { formatFileSize } from "../5 - General/Utils/UtilsFormatData";
import TextareaAutosize from "react-textarea-autosize";
import useCustomToast from "../5 - General/Utils/UtilsNotification";

const MessagesActiveChannel = () => {
  const {
    currentChannelId,
    messages,
    sendMessage,
    handleRenameChannel,
    handleImagePreview,
    setModals,
    formatTimestamp,
    isMessagesLoading,
    channelName,
    handleFileChange,
    currentUser,
    handleLoadMoreMessages,
    hasNextPage,
    isFetchingNextPage,
    messagesError,
    currentChannelType,
    groupedMessages,
    isLoadingChannels,
    handleDownloadFile,
  } = useContext(MessagesContext);

  const customToast = useCustomToast(); // For displaying notifications

  // ** Set newTitle when channelName changes **
  const [newTitle, setNewTitle] = useState(channelName || "");
  useEffect(() => {
    setNewTitle(channelName);
  }, [channelName]);

  const isOneOnOne = currentChannelType === "1on1";
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const maxChannelNameLength = 30;
  const messageRef = useRef(null);
  const chatRef = useRef(null);
  const bottomRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isScrolledUp, setIsScrolledUp] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const headerBgColor = useColorModeValue("white", "gray.700");
  const filePreviewBgColor = useColorModeValue("gray.100", "gray.600");
  const chatBgColor = useColorModeValue("gray.50", "gray.800");
  const overlayBgColor = useColorModeValue("rgba(0, 0, 0, 0.2)", "rgba(0, 0, 0, 0.2)");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const imageOverlayTextColor = useColorModeValue("gray.100", "gray.100");
  const filePreviewTextColor = useColorModeValue("gray.600", "gray.300");
  const inputBgColor = useColorModeValue("white", "gray.700");
  const spinnerColor = useColorModeValue("primary.500", "primary.300");
  const scrollThumbHoverColor = useColorModeValue("gray.300", "gray.600");
  const conversationNameColor = useColorModeValue("gray.800", "gray.100");
  const errorColor = useColorModeValue("red.500", "red.300");

  const handleInput = (e) => {
    setMessage(e.target.value); // Update to use e.target.value
  };

  const handleSendMessage = async () => {
    if (!message.trim() && files.length === 0) return; // Prevent sending empty messages

    const fileToSend = files[0] || null;
    handleFileSelection(fileToSend); // Update selected file in context

    try {
      await sendMessage(message.trim());
      setMessage("");
      setFiles([]);
      if (messageRef.current) {
        messageRef.current.value = ""; // Reset TextareaAutosize value
      }
    } catch (error) {
      console.error("Error sending message:", error);
      customToast({
        title: "Send Message Error",
        description: "Failed to send your message.",
        status: "error",
      });
    }
  };

  const handleFileSelection = (file) => {
    if (file) {
      setFiles([file]);
      handleFileChange(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        handleFileSelection(file);
      }
    },
    noClick: true,
    multiple: false,
  });

  const scrollToBottom = useCallback(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleScroll = useCallback(() => {
    const chatContainer = chatRef.current;
    if (!chatContainer) return;

    const { scrollTop, scrollHeight, clientHeight } = chatContainer;
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50;
    setIsScrolledUp(!isAtBottom);
    setShowScrollButton(!isAtBottom);
  }, []);

  useEffect(() => {
    const chatElement = chatRef.current;
    if (chatElement) {
      chatElement.addEventListener("scroll", handleScroll);
      return () => {
        chatElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  const handleScrollToBottomClick = () => {
    scrollToBottom();
    setIsScrolledUp(false);
    setShowScrollButton(false);
  };

  // ** Clean up object URLs to prevent memory leaks **
  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  const renderFilePreview = () => {
    return files.map((file, index) => {
      const objectUrl = URL.createObjectURL(file);
      return (
        <Flex key={index} align="center" p={2} borderRadius="md">
          {file.type.startsWith("image/") ? (
            <Image src={objectUrl} boxSize="50px" objectFit="cover" borderRadius="md" alt="preview" />
          ) : (
            <Box>{renderFileIcon(file.name)} </Box>
          )}
          <VStack align="start" spacing={0} ml={3} flex="1" color={filePreviewTextColor}>
            <Text fontWeight="bold" fontSize="md">
              Selected file:
            </Text>
            <Text fontSize="md" isTruncated>
              {file.name && file.name.length > 30 ? `${file.name.substring(0, 25)}...` : file.name} - {formatFileSize(file.size)}
            </Text>
          </VStack>
          <Tooltip label="Remove file" aria-label="Remove file" hasArrow>
            <IconButton
              ml={4}
              icon={<PiXBold fontSize="18px" />}
              variant="outline"
              aria-label="Remove selected file"
              onClick={() => {
                setFiles([]);
                handleFileChange(null);
              }}
              size="sm"
            />
          </Tooltip>
        </Flex>
      );
    });
  };

  // ** Function to render message content with type checks **
  const renderMessageContent = useCallback(
    (msg) => {
      if (typeof msg.content !== "string") {
        console.error("Invalid message content:", msg);
      }
      const formattedContent = typeof msg.content === "string" ? msg.content.replace(/\n/g, "<br />") : "";

      const messageContent = (
        <Box
          maxW="650px"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(formattedContent, { USE_PROFILES: { html: true } }),
          }}
          fontSize="md"
          fontWeight="400"
        />
      );
      const isUserMessage = msg.sender?._id === currentUser?._id;

      if (msg.isSystemMessage) {
        return (
          <Box p={4} bg="transparent" textAlign="center" fontSize="sm" color={filePreviewTextColor}>
            {msg.content}
          </Box>
        );
      }

      if (msg.isDeleted) {
        return (
          <Box fontStyle="italic" fontSize="md" fontWeight="400">
            {msg.content}
          </Box>
        );
      }

      const editedIndicator =
        msg.editedAt && !msg.isDeleted ? (
          <Text fontSize="xs" color={isUserMessage ? "gray.200" : "gray.600"}>
            (edited)
          </Text>
        ) : null;

      if (msg.includesFile && msg.backendFileName) {
        const fileUrl = `/uploads/channels/${currentChannelId}/${msg.backendFileName}`;
        const fileName = msg.originalFileName;
        const fileSize = formatFileSize(msg.fileSize);
        const isImage = fileName && fileName.match(/\.(jpeg|jpg|gif|png)$/i);
        const API_URL = process.env.REACT_APP_API_ENDPOINT;
        const fullUrl = `${API_URL}${fileUrl}`;

        if (isImage) {
          return (
            <>
              <Box>
                {messageContent}
                {editedIndicator}
              </Box>
              <Box
                position="relative"
                cursor="pointer"
                onClick={() =>
                  handleImagePreview({
                    url: fileUrl,
                    originalFileName: fileName,
                  })
                }
              >
                <Image src={fullUrl} alt={fileName} maxW="325px" h="auto" borderRadius="5px" mt="10px" />
                <Box
                  borderRadius="5px"
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  bg={overlayBgColor}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="white"
                  opacity="0"
                  transition="opacity 0.3s"
                  _hover={{ opacity: 1 }}
                >
                  <Text position="relative" top="0" right="0" color={imageOverlayTextColor}>
                    Preview Image
                  </Text>
                </Box>
              </Box>
            </>
          );
        } else {
          return (
            <>
              <Box>
                {messageContent}
                {editedIndicator}
              </Box>
              <Box p={2} mt={2} bg={filePreviewBgColor} borderRadius="md" borderWidth="1px" borderColor={borderColor} color={filePreviewTextColor}>
                <Flex ml={2} align="center" cursor="pointer" onClick={() => handleDownloadFile(msg)}>
                  {renderFileIcon(fileName)}
                  <VStack align="start" spacing={0} ml={5} mr={5}>
                    <Text color={filePreviewTextColor} fontWeight="normal" fontSize="14px">
                      {fileName}
                    </Text>
                    <Text color={filePreviewTextColor} fontSize="12px">
                      {fileSize} - Download
                    </Text>
                  </VStack>
                </Flex>
              </Box>
            </>
          );
        }
      } else {
        return (
          <>
            <Box>
              {messageContent}
              {editedIndicator}
            </Box>
          </>
        );
      }
    },
    [
      currentChannelId,
      currentUser,
      filePreviewTextColor,
      overlayBgColor,
      imageOverlayTextColor,
      borderColor,
      filePreviewBgColor,
      handleImagePreview,
      handleDownloadFile,
    ]
  );

  // Determine if there are more messages to load
  const showLoadMoreButton = hasNextPage && messages.length > 0;

  // ** Handle scrolling after messages are rendered **
  useEffect(() => {
    if (!isScrolledUp) {
      scrollToBottom();
    }
  }, [groupedMessages, isScrolledUp, scrollToBottom]);

  return (
    <Flex direction={{ base: "column", md: "row" }} width="100%" height="100%" flex="1">
      <Box {...getRootProps()} bg={chatBgColor} height="100%" width="100%" position="relative">
        {isDragActive && (
          <Box
            position="absolute"
            bottom="0"
            right="0"
            left="0"
            top="80px"
            bg={overlayBgColor}
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="black"
            zIndex="10"
            overflow="hidden"
          >
            <Text p={6} bg="white" borderRadius="xl" color="black" fontSize="lg" fontWeight="bold" textAlign="center">
              To add a file to this conversation, drag and drop it here.
            </Text>
          </Box>
        )}
        <VStack spacing={0} align="stretch" height="100%">
          {/* Header */}
          <Flex justify="space-between" align="center" bg={headerBgColor} px={4} h="80px" borderBottomWidth="1px" borderTopRadius="md" boxShadow="sm">
            {/* Header content */}
            <Flex align="center">
              {isOneOnOne ? (
                <Text fontSize="xl" fontWeight="medium" ml={3} color={conversationNameColor}>
                  {channelName || "Unnamed Conversation"}
                </Text>
              ) : (
                <Editable
                  // Removed defaultValue to prevent conflicts with controlled component
                  value={newTitle}
                  onChange={(value) => {
                    if (value.length <= maxChannelNameLength) {
                      setNewTitle(value);
                    }
                  }}
                  onSubmit={() => {
                    if (newTitle !== channelName && currentChannelId) {
                      handleRenameChannel(newTitle);
                    }
                  }}
                >
                  <EditablePreview fontSize="xl" fontWeight="medium" ml={3} color={conversationNameColor} />
                  <EditableInput ml={3} fontSize="xl" fontWeight="medium" placeholder="New channel name" color={conversationNameColor} />
                </Editable>
              )}
            </Flex>
            <Flex>
              {currentChannelId && (
                <>
                  {!isOneOnOne && (
                    <>
                      <IconButton
                        onClick={() =>
                          setModals((prev) => ({
                            ...prev,
                            viewUsers: true,
                          }))
                        }
                        icon={<PiUser fontSize="18px" />}
                        aria-label="View users"
                        mr={2}
                        variant="outline"
                        colorScheme="gray"
                      />
                      <IconButton
                        onClick={() =>
                          setModals((prev) => ({
                            ...prev,
                            addUser: true,
                          }))
                        }
                        icon={<PiPlus fontSize="18px" />}
                        aria-label="Add user"
                        mr={2}
                        variant="outline"
                        colorScheme="gray"
                      />
                    </>
                  )}
                  <IconButton
                    onClick={() =>
                      setModals((prevModals) => ({
                        ...prevModals,
                        delete: true,
                      }))
                    }
                    icon={<PiTrash fontSize="18px" />}
                    aria-label="Delete chat"
                    variant="outline"
                    colorScheme="gray"
                  />
                </>
              )}
            </Flex>
          </Flex>

          {/* Messages Area */}
          <Flex direction="column" flex="1" overflow="hidden" position="relative">
            <Box
              ref={chatRef}
              overflowX="hidden"
              flex="1"
              overflowY="auto"
              p={6}
              pt={8}
              pb={1}
              sx={{
                "::-webkit-scrollbar": {
                  width: "0px",
                  backgroundColor: chatBgColor,
                },
                "::-webkit-scrollbar-thumb": {
                  backgroundColor: chatBgColor,
                  borderRadius: "4px",
                  "&:hover": {
                    backgroundColor: scrollThumbHoverColor,
                  },
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: chatBgColor,
                },
              }}
            >
              {/* Show a spinner while channels are loading */}
              {isLoadingChannels && (
                <Flex justify="center" align="center" height="100%" width="100%">
                  <Spinner size="xl" color={spinnerColor} />
                </Flex>
              )}

              {/* Show a spinner while messages are loading */}
              {!isLoadingChannels && isMessagesLoading ? (
                <Flex justify="center" align="center" height="100%" width="100%">
                  <Spinner size="xl" color={spinnerColor} />
                </Flex>
              ) : messagesError ? (
                <Box p={4} bg="red.100" borderRadius="md">
                  <Text color={errorColor}>{messagesError.message || "Error loading messages."}</Text>
                </Box>
              ) : (
                <>
                  {/* "View More Messages" Button */}
                  {showLoadMoreButton && (
                    <Flex justify="center" mb={4}>
                      <Button
                        onClick={handleLoadMoreMessages}
                        isLoading={isFetchingNextPage}
                        loadingText="Loading..."
                        variant="outline"
                        colorScheme="primary"
                        leftIcon={<PiArrowDownBold />}
                      >
                        View More Messages
                      </Button>
                    </Flex>
                  )}
                  {groupedMessages &&
                    groupedMessages.map((msg, index) => (
                      <MessagesItem
                        key={msg.key || `message-${index}`}
                        msg={msg}
                        renderMessageContent={renderMessageContent}
                        formatTimestamp={formatTimestamp}
                      />
                    ))}
                  <Box ref={bottomRef}></Box> {/* Dummy div for scrolling */}
                  {!isMessagesLoading && showScrollButton && (
                    <IconButton
                      icon={<PiArrowDownBold fontSize="18px" />}
                      aria-label="Scroll to bottom"
                      onClick={handleScrollToBottomClick}
                      position="fixed"
                      bottom="120px"
                      right="10px"
                      size="md"
                      colorScheme="primary"
                    />
                  )}
                </>
              )}
            </Box>

            {/* File Preview Overlay */}
            {files.length > 0 && (
              <Box
                position="absolute"
                bottom="0px"
                left="0"
                p={2}
                m={4}
                boxShadow="lg"
                borderRadius="md"
                borderWidth="1px"
                borderColor={borderColor}
                bg={filePreviewBgColor}
                zIndex="5"
              >
                {renderFilePreview()}
              </Box>
            )}
          </Flex>

          {/* Input Bar */}
          {currentChannelId && (
            <Flex align="stretch" p={4}>
              <IconButton
                onClick={() => fileInputRef.current.click()}
                icon={<PiPlus />}
                size="sm"
                variant="outline"
                colorScheme="gray"
                mr={2}
                h="40px"
                w="40px"
                aria-label="Add file"
              />
              <Box flex="1">
                <TextareaAutosize
                  ref={messageRef}
                  value={message}
                  onChange={handleInput}
                  minRows={1}
                  maxRows={5}
                  placeholder="Type a message..."
                  style={{
                    width: "100%",
                    padding: "9px 12px",
                    backgroundColor: inputBgColor,
                    borderRadius: "6px",
                    border: "1px solid #ccc",
                    resize: "none",
                    overflowY: "auto",
                    fontSize: "14px",
                    lineHeight: "1.5",
                    color: "#333",
                    transition: "all 0.2s ease",
                    boxSizing: "border-box",
                    outline: "none",
                  }}
                  onFocus={(e) => {
                    e.target.style.borderColor = "#0020cb";
                    e.target.style.boxShadow = "0 0 3px rgba(0, 32, 203, 0.5)";
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#ccc";
                    e.target.style.boxShadow = "none";
                  }}
                />
              </Box>
              <IconButton
                onClick={handleSendMessage}
                icon={<PiPaperPlaneTiltFill fontSize="18px" />}
                aria-label="Send message"
                size="sm"
                variant="solid"
                colorScheme="primary"
                ml={2}
                h="40px"
                w="100px"
              />
              <input
                {...getInputProps({ multiple: false })}
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileSelection(e.target.files[0])}
                multiple={false}
              />
            </Flex>
          )}
        </VStack>
      </Box>
    </Flex>
  );
};

export default MessagesActiveChannel;
