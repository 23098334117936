// client/src/components/5 - General/Utils/UtilsFormatData.js

export const formatFileSize = (size) => {
  if (size < 1024) return `${size} B`;
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  return `${(size / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
};

export const getFileExtension = (filename) => {
  if (!filename) return "";
  return filename.split(".").pop().toLowerCase();
};

// Function to debounce other functions
export const debounce = (func, delay) => {
  let debounceTimer;
  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }
  const now = new Date();
  const diff = now - date;
  const diffMinutes = Math.floor(diff / (1000 * 60));
  const diffHours = Math.floor(diff / (1000 * 60 * 60));
  const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffDays / 365);

  if (diffMinutes < 3) return "Just now";
  if (diffMinutes < 60) return `${diffMinutes}m`;
  if (diffHours < 24) return `${diffHours}h`;
  if (diffDays < 30) return `${diffDays}d`;
  if (diffMonths < 12) return `${diffMonths}m`;
  return `${diffYears}y`;
};

export const formatReadableDate = (dateInput) => {
  let date;

  if (typeof dateInput === "string" || typeof dateInput === "number") {
    date = new Date(dateInput);
  } else if (dateInput instanceof Date) {
    date = dateInput;
  } else {
    return "Invalid Date";
  }

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  // Explicitly set locale to English ('en-US')
  let formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
};

export const formatDueDate = (timestamp) => {
  const date = new Date(timestamp);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const now = new Date();
  const diffTime = date - now;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  let relativeTime = "";

  if (diffDays > 1) {
    relativeTime = `Due in ${diffDays} days`;
  } else if (diffDays === 1) {
    relativeTime = "Due in 1 day";
  } else if (diffDays === 0) {
    relativeTime = "Due today";
  } else if (diffDays === -1) {
    relativeTime = "Due yesterday";
  } else {
    relativeTime = `Due ${Math.abs(diffDays)} days ago`;
  }

  return relativeTime;
};
