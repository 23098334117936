// LoginPage.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Image,
  Text,
  Flex,
  Link,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  VStack,
  useDisclosure,
  useColorMode,
  useColorModeValue,
  Box,
  Heading,
  Stack,
  Divider,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useSocket } from "../5 - General/Socket/SocketContext";
import { useQueryClient } from "@tanstack/react-query";
import useCustomToast from "../5 - General/Utils/UtilsNotification";
import LoginImg from "../../img/logoBlueText.png";

function LoginPage({ switchToRegister }) {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const customToast = useCustomToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { socket, connectSocket } = useSocket();
  const { setColorMode } = useColorMode();
  const queryClient = useQueryClient();
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Define color mode values for the modal
  const textColor = useColorModeValue("gray.700", "gray.200");

  useEffect(() => {
    localStorage.clear();
    queryClient.clear();
    setColorMode("light");
  }, [setColorMode, queryClient]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value.trim() === "") {
      setEmailError("Email is required");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value.trim() === "") {
      setPasswordError("Password is required");
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;
    localStorage.clear();
    queryClient.clear();

    if (email.trim() === "") {
      setEmailError("Email is required");
      valid = false;
    }

    if (password.trim() === "") {
      setPasswordError("Password is required");
      valid = false;
    }

    if (valid) {
      setIsLoading(true);
      try {
        const response = await fetch(`${apiUrl}/api/v1/auth/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        });

        const data = await response.json();

        if (response.ok) {

          localStorage.setItem("token", data.token);
          localStorage.setItem("userId", data.user._id);
          setColorMode(data.user.theme);

          if (socket && socket.connected) {
            console.log("Socket already connected:", socket.id);
          } else {
            console.log("Connecting socket after login");
            await connectSocket();
          }

          console.log("User authenticated, navigating to dashboard");
          navigate("/dashboard");
        } else {
          setGeneralError(data.message);
        }
      } catch (error) {
        console.error("Login error:", error);
        setGeneralError("Server error, please try again later");
      }
      setIsLoading(false);
    }
  };

  const handleForgotSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/api/v1/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: forgotEmail }),
      });

      const data = await response.json();

      if (response.ok) {
        customToast({
          title: "Success!",
          description: "Password reset link has been sent to your email address.",
          status: "success",
        });
        onClose();
      } else {
        customToast({
          title: "Error!",
          description: data.message || "Error sending password reset email.",
          status: "error",
        });
        onClose();
      }
    } catch (error) {
      console.error("Error:", error);
      customToast({
        title: "Error!",
        description: "Error sending password reset email.",
        status: "error",
      });
      onClose();
    }
  };

  return (
    <Flex align="center" justify="center" width="100%" minH="100vh" px={{ base: 4, md: 0 }}>
      <Box width={{ base: "100%", md: "500px" }} p={{ base: 4, md: 8 }}>
        <Stack spacing={6} as="form" onSubmit={handleSubmit}>
          {/* Logo and Heading */}
          <Flex direction="column" align="center" mb={{ base: 4, md: 6 }}>
            <Image src={LoginImg} alt="Logo" w={{ base: "100px", md: "120px" }} mb={2} />
            <Heading
              as="h2"
              size="xl"
              bgGradient="linear(to-r, primary.500, blue.400)"
              bgClip="text"
              mb={1}
              fontSize={{ base: "2xl", md: "3xl" }}
              textAlign="center"
            >
              Welcome Back!
            </Heading>
            <Text color={useColorModeValue("gray.600", "gray.400")} fontSize={{ base: "sm", md: "md" }} textAlign="center">
              Log in to your account
            </Text>
          </Flex>

          {/* General Error Alert */}
          {generalError && (
            <Alert status="error" borderRadius="md">
              <AlertIcon />
              {generalError}
            </Alert>
          )}

          {/* Email Field */}
          <FormControl id="email" isInvalid={emailError} isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={handleEmailChange}
              onBlur={handleEmailChange}
              placeholder="Enter your email"
              bg={useColorModeValue("gray.50", "gray.700")}
              _placeholder={{ color: useColorModeValue("gray.500", "gray.400") }}
              focusBorderColor="primary.500"
              size="lg"
              transition="background-color 0.2s"
            />
            {emailError && (
              <Text color="red.500" fontSize="sm" mt={1}>
                {emailError}
              </Text>
            )}
          </FormControl>

          {/* Password Field */}
          <FormControl id="password" isInvalid={passwordError} isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              onBlur={handlePasswordChange}
              placeholder="Enter your password"
              bg={useColorModeValue("gray.50", "gray.700")}
              _placeholder={{ color: useColorModeValue("gray.500", "gray.400") }}
              focusBorderColor="primary.500"
              size="lg"
              transition="background-color 0.2s"
            />
            {passwordError && (
              <Text color="red.500" fontSize="sm" mt={1}>
                {passwordError}
              </Text>
            )}
            <Link
              color="primary.500"
              mt={2}
              fontSize={{ base: "sm", md: "md" }}
              onClick={onOpen}
              _hover={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Forgot password?
            </Link>
          </FormControl>

          {/* Submit Button */}
          <Button
            type="submit"
            colorScheme="primary"
            size="lg"
            isLoading={isLoading}
            loadingText="Logging in"
            borderRadius="md"
            _hover={{ bg: "primary.600" }}
            transition="background-color 0.2s"
          >
            Log in to the portal
          </Button>

          {/* Divider */}
          <Flex align="center" mb={{ base: 4, md: 6 }}>
            <Divider />
            <Text px={2} color={useColorModeValue("gray.600", "gray.400")} fontSize={{ base: "sm", md: "md" }}>
              OR
            </Text>
            <Divider />
          </Flex>

          {/* Registration Link */}
          <Text textAlign="center" color={useColorModeValue("gray.600", "gray.400")} fontSize={{ base: "sm", md: "md" }}>
            Don't have an account?{" "}
            <Link onClick={switchToRegister} color="primary.500" fontWeight="bold" _hover={{ textDecoration: "underline", cursor: "pointer" }}>
              Create one
            </Link>
          </Text>
        </Stack>

        {/* Forgot Password Modal */}
        <Modal isOpen={isOpen} onClose={onClose} isCentered={isMobile ? false : true} motionPreset="scale">
          <ModalOverlay />
          <ModalContent borderRadius="md" boxShadow="2xl" maxW="90%">
            <ModalHeader borderBottom="1px solid" borderColor="gray.200" fontSize={{ base: "lg", md: "2xl" }} color={textColor}>
              Reset Your Password
            </ModalHeader>
            <ModalBody>
              <VStack mt={2} spacing={4} align="stretch">
                <Text fontSize={{ base: "sm", md: "md" }} color={textColor}>
                  Enter the email address associated with your account, and we'll send you instructions to reset your password.
                </Text>
                <FormControl id="forgot-email" isRequired>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    type="email"
                    value={forgotEmail}
                    onChange={(e) => setForgotEmail(e.target.value)}
                    placeholder="Enter your email"
                    bg={useColorModeValue("gray.50", "gray.700")}
                    _placeholder={{ color: useColorModeValue("gray.500", "gray.400") }}
                    focusBorderColor="primary.500"
                    size="lg"
                    transition="background-color 0.2s"
                  />
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose} variant="outline" mr={3} size={{ base: "sm", md: "md" }}>
                Cancel
              </Button>
              <Button
                colorScheme="primary"
                onClick={handleForgotSubmit}
                isDisabled={!forgotEmail.trim()}
                _hover={{ bg: "primary.600" }}
                transition="background-color 0.2s"
                size={{ base: "sm", md: "md" }}
              >
                Send Email
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
}

export default LoginPage;
