// client/src/components/2 - Components/Reusable/ModularTable.js

import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  VStack,
  Box,
  Text,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Button,
  useColorModeValue,
  Spinner,
  useBreakpointValue,
  ButtonGroup,
} from "@chakra-ui/react";
import { PiCaretDownBold } from "react-icons/pi";

const ModularTable = ({
  columns,
  data,
  actionColumn,
  headerStyles = {},
  rowStyles = {},
  cellStyles = {},
  headerCellStyles = {},
  currentPage,
  totalPages,
  maxPerPage,
  totalItems,
  onPageChange,
  searchValue,
  onSearchChange,
  searchColumns,
  onSearchColumnsChange,
  maxHeight,
  isLoading,
  isError,
  error,
}) => {
  // Initialize visible columns based on defaultVisible property
  const initialVisibleColumns = columns.reduce((acc, col) => {
    acc[col.accessor] = col.defaultVisible !== false;
    return acc;
  }, {});

  const [visibleColumns, setVisibleColumns] = React.useState(initialVisibleColumns);

  // Define colors for light and dark modes
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const bgColor = useColorModeValue("white", "gray.800");
  const headerBgColor = useColorModeValue("gray.50", "gray.800");
  const rowHoverBgColor = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const inputBgColor = useColorModeValue("white", "gray.700");
  const inputPlaceholderColor = useColorModeValue("gray.500", "gray.400");
  const paginationButtonBg = useColorModeValue("white", "gray.800");
  const paginationButtonHoverBg = useColorModeValue("gray.100", "gray.700");

  // Responsive values
  const isMobile = useBreakpointValue({ base: true, md: false });
  const searchInputWidth = useBreakpointValue({ base: "100%", md: "250px" });
  const controlsFlexDirection = useBreakpointValue({ base: "column", md: "row" });
  const paginationFlexDirection = useBreakpointValue({ base: "column", md: "row" });
  const paginationAlign = useBreakpointValue({ base: "stretch", md: "center" });
  const paginationButtonSize = useBreakpointValue({ base: "sm", md: "sm" });

  // Handle column visibility toggle
  const handleColumnChange = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  return (
    <Box
      border="1px solid"
      borderColor={borderColor}
      borderRadius="md"
      p={4}
      bg={bgColor}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      maxH={maxHeight || "100%"}
    >
      {/* Controls: Search and Column Visibility */}
      <VStack spacing={4} align="stretch" mb={4} flexDirection={controlsFlexDirection}>
        <HStack spacing={4} width="100%" flexDirection={isMobile ? "column" : "row"}>
          {/* Search Input */}
          <Input
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => onSearchChange(e.target.value)}
            width={searchInputWidth}
            bg={inputBgColor}
            color={textColor}
            _placeholder={{ color: inputPlaceholderColor }}
          />
          {!isMobile && (
            <HStack spacing={4}>
              <Menu closeOnSelect={false}>
                <MenuButton as={Button} rightIcon={<PiCaretDownBold fontSize="18px" />}>
                  Search Columns
                </MenuButton>
                <MenuList minWidth="240px">
                  <MenuOptionGroup
                    title="Select columns to search"
                    type="checkbox"
                    value={searchColumns}
                    onChange={(values) => onSearchColumnsChange(values)}
                  >
                    {columns.map((col) => (
                      <MenuItemOption key={col.accessor} value={col.accessor}>
                        {col.label}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
              <Menu closeOnSelect={false}>
                <MenuButton as={Button} rightIcon={<PiCaretDownBold fontSize="18px" />}>
                  Hide Columns
                </MenuButton>
                <MenuList minWidth="240px">
                  <MenuOptionGroup title="Show Columns" type="checkbox">
                    {columns.map((col) => (
                      <MenuItemOption
                        key={col.accessor}
                        value={col.accessor}
                        isChecked={visibleColumns[col.accessor]}
                        onClick={() => handleColumnChange(col.accessor)}
                      >
                        {col.label}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>
            </HStack>
          )}
        </HStack>
      </VStack>

      {/* Table Container */}
      <Box flex="1" overflowX="auto" overflowY="auto">
        <Table variant="simple" width="100%" size="sm">
          <Thead position="sticky" top={0} bg={headerBgColor} zIndex={1} {...headerStyles}>
            <Tr>
              {columns.map(
                (col) =>
                  visibleColumns[col.accessor] && (
                    <Th key={col.accessor} color={textColor} {...headerCellStyles}>
                      <HStack justify="space-between">
                        <Text fontSize={isMobile ? "sm" : "md"}>{col.label}</Text>
                        {/* Sorting icons removed as sorting is server-side */}
                      </HStack>
                    </Th>
                  )
              )}
              {actionColumn && (
                <Th textAlign="right" color={textColor} {...headerCellStyles}>
                  {actionColumn.label || "Actions"}
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading ? (
              <>
                {data && data.length > 0 ? (
                  data.map((row) => (
                    <Tr
                      key={row._id || row.id || `${row.firstName}-${row.lastName}-${row.email}`}
                      _hover={{ bg: rowHoverBgColor }}
                      cursor="pointer"
                      {...rowStyles}
                    >
                      {columns.map(
                        (col) =>
                          visibleColumns[col.accessor] && (
                            <Td key={col.accessor} color={textColor} {...cellStyles}>
                              <Text fontSize={isMobile ? "sm" : "md"}>{col.render ? col.render(row[col.accessor], row) : row[col.accessor]}</Text>
                            </Td>
                          )
                      )}
                      {actionColumn && (
                        <Td textAlign="right" {...cellStyles}>
                          {actionColumn.render(row)}
                        </Td>
                      )}
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={columns.length + (actionColumn ? 1 : 0)}>
                      <Text textAlign="center" color="gray.500">
                        No data available.
                      </Text>
                    </Td>
                  </Tr>
                )}
              </>
            ) : !isError ? (
              <Tr>
                <Td bg={bgColor} colSpan={columns.length + (actionColumn ? 1 : 0)} rowSpan={maxPerPage}>
                  <Text textAlign="center" color="gray.500">
                    {error}
                  </Text>
                </Td>
              </Tr>
            ) : (
              <Tr>
                <Td bg={bgColor} colSpan={columns.length + (actionColumn ? 1 : 0)} rowSpan={maxPerPage}>
                  <Box display="flex" justifyContent="center" alignItems="center" py={4}>
                    <Spinner color="primary.500" />
                  </Box>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      {/* Pagination Controls */}
      <VStack spacing={4} align="stretch" mt={4} flexDirection={paginationFlexDirection}>
        <Text w='30%' fontSize={isMobile ? "sm" : "md"}>
          Showing {totalItems === 0 ? 0 : (currentPage - 1) * maxPerPage + 1} - {Math.min(currentPage * maxPerPage, totalItems)} of {totalItems} items
        </Text>
        <HStack spacing={2} justify={paginationAlign} width="100%" flexDirection={isMobile ? "column" : "row"}>
          <ButtonGroup isAttached>
            <Button
              size={paginationButtonSize}
              variant="outline"
              onClick={() => onPageChange(currentPage - 1)}
              isDisabled={currentPage === 1 || totalPages === undefined}
              bg={paginationButtonBg}
              _hover={{ bg: paginationButtonHoverBg }}
              width="auto"
            >
              Previous
            </Button>
            <Button bg={paginationButtonBg} variant="outline" _hover={{ bg: "transparent" }} cursor="default" size={paginationButtonSize}>
              Page {currentPage} of {totalPages}
            </Button>
            <Button
              size={paginationButtonSize}
              variant="outline"
              onClick={() => onPageChange(currentPage + 1)}
              isDisabled={currentPage === totalPages}
              bg={paginationButtonBg}
              _hover={{ bg: paginationButtonHoverBg }}
              width="auto"
            >
              Next
            </Button>
          </ButtonGroup>
        </HStack>
      </VStack>
    </Box>
  );
};

export default React.memo(ModularTable);
