import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Text, Button, Image, VStack, useColorModeValue, Container, Fade, Icon } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import LoginImg from "../../img/logoBlueText.png";

function VerificationSuccessPage() {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate("/login");
  };
  const bgModalColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "gray.300");
  const buttonColorScheme = useColorModeValue("primary", "primary");

  return (
    <Fade in>
      <Box
        bgGradient={useColorModeValue("linear(to-r, primary.50, blue.50)", "linear(to-r, primary.900, blue.900)")}
        minH="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
        px={4}
        py={10}
      >
        <Container maxW="lg" centerContent py={12} px={8} borderRadius="3xl" boxShadow="2xl" bg={bgModalColor} textAlign="center">
          <VStack spacing={6} align="center">
            <Image src={LoginImg} alt="Company Logo" boxSize={["100px", "150px"]} objectFit="cover" />
            <Icon as={CheckCircleIcon} w={8} h={8} color="primary.500" />
            <Heading as="h2" size="lg" color={textColor} fontWeight="bold" lineHeight="shorter">
              Account Activated Successfully!
            </Heading>
            <Text fontSize={["md", "lg"]} color={textColor} maxW="md" px={4}>
              Your email has been verified. You can now proceed to log in to your account.
            </Text>
            <Button
              colorScheme={buttonColorScheme}
              size="lg"
              onClick={handleLoginRedirect}
              px={8}
              rightIcon={<CheckCircleIcon />}
              _hover={{
                transform: "translateY(-1px)",
                boxShadow: "lg",
              }}
              transition="all 0.2s"
            >
              Go to Login
            </Button>
          </VStack>
        </Container>
      </Box>
    </Fade>
  );
}

export default VerificationSuccessPage;
