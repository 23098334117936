// src/components/3 - Modal/8 - Settings Modals/DeactivateUserModal.jsx
import React, { useState } from "react";
import { Button, VStack, Text, Flex, Divider } from "@chakra-ui/react";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { useDeleteUser } from "../../5 - General/Hooks/useUpdateUser";

const DeactivateUserModal = ({ isOpen, onClose, userToDelete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteUserMutation = useDeleteUser();

  const handleConfirm = async () => {
    if (userToDelete && userToDelete._id) {
      setIsLoading(true);
      try {
        deleteUserMutation.mutate({ userId: userToDelete._id });
        onClose();
      } catch (error) {
        // Error handling is already managed in the mutation hook
      } finally {
        setIsLoading(false);
      }
    }
  };

  const footerButtons = (
    <Flex justify="space-between" width="100%">
      <Button variant="outline" onClick={onClose} width="30%">
        Cancel
      </Button>
      <Button colorScheme="red" onClick={handleConfirm} width="30%" isLoading={isLoading}>
        Delete
      </Button>
    </Flex>
  );

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Confirm User Deletion" footerButtons={footerButtons}>
      <VStack spacing={4} alignItems="center">
        {userToDelete && (
          <>
            <Text>
              Are you sure you want to delete the account of{" "}
              <strong>
                {userToDelete.firstName} {userToDelete.lastName}
              </strong>
              ? This action will remove the user from your team and cannot be undone.
            </Text>
            <Divider />
            <Text>
              The user will lose access to the platform and won't be able to login. We will make sure that their data is kept in the system for
              the modules he was contributing to.
            </Text>
          </>
        )}
      </VStack>
    </ReusableModal>
  );
};

export default DeactivateUserModal;
