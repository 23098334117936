import React from 'react';
import { Outlet } from 'react-router-dom';
import SettingsSideBarLayout from '../Layout/LayoutSettingsSideBar';

const LayoutSettings = () => {
  return (
    <SettingsSideBarLayout>
      <Outlet />
    </SettingsSideBarLayout>
  );
};

export default LayoutSettings;
