// client/src/components/Files/Modals/RenameModal.jsx

import React, { useContext, useState, useEffect } from "react";
import { Button, Input, FormControl, FormLabel, useColorModeValue, InputGroup, InputRightElement } from "@chakra-ui/react";
import { FilesContext } from "../../5 - General/Context/FilesContext";
import ReusableModal from "../9 - General Modals/ReusableModal";

const RenameModal = () => {
  const { onCloseRenameModal, isRenameModalOpen, handleRenameSubmit, fileToRename, files } = useContext(FilesContext);

  const [baseName, setBaseName] = useState("");
  const [extension, setExtension] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (fileToRename) {
      const item = files.find((f) => f._id === fileToRename);
      if (item) {
        if (item.type === "file") {
          const dotIndex = item.originalFileName.lastIndexOf(".");
          if (dotIndex !== -1) {
            setBaseName(item.originalFileName.substring(0, dotIndex));
            setExtension(item.originalFileName.substring(dotIndex));
          } else {
            setBaseName(item.originalFileName);
            setExtension("");
          }
        } else {
          setBaseName(item.originalFileName);
          setExtension("");
        }
      }
    }
  }, [fileToRename, files]);

  const handleSubmit = async () => {
    if (!baseName.trim()) return;
    setIsSubmitting(true);
    try {
      // Concatenate baseName and extension to preserve the file extension
      const fullName = extension ? `${baseName}${extension}` : baseName;
      await handleRenameSubmit(fullName);
      setBaseName("");
      setExtension("");
      onCloseRenameModal();
    } catch (error) {
      console.error("Error renaming file:", error);
      // Optionally, notify the user about the error
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    onCloseRenameModal();
    setBaseName("");
    setExtension("");
  };

  const fileItem = fileToRename ? files.find((f) => f._id === fileToRename) : null;

  const footerButtons = (
    <>
      <Button
        variant="ghost"
        color={useColorModeValue("gray.600", "gray.300")}
        _hover={{
          color: useColorModeValue("black", "white"),
          bg: useColorModeValue("gray.100", "gray.500"),
        }}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button colorScheme="blue" onClick={handleSubmit} isLoading={isSubmitting} disabled={!baseName.trim()}>
        Rename
      </Button>
    </>
  );

  return (
    <ReusableModal
      isOpen={isRenameModalOpen}
      onClose={handleClose}
      title={`Rename ${fileItem ? (fileItem.type === "folder" ? "Folder" : fileItem.type === "link" ? "Link" : "File") : ""}`}
      footerButtons={footerButtons}
    >
      <FormControl>
        <FormLabel>New Name</FormLabel>
        <InputGroup>
          <Input
            placeholder={`New name for ${fileItem ? fileItem.originalFileName : ""}`}
            value={baseName}
            onChange={(e) => {
              // Prevent entering a dot to avoid altering the extension
              if (e.target.value.includes(".")) {
                return;
              }
              setBaseName(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
            autoFocus
          />
          {extension && (
            <InputRightElement mr={4} pointerEvents="none" color="gray.500">
              {extension}
            </InputRightElement>
          )}
        </InputGroup>
      </FormControl>
    </ReusableModal>
  );
};

export default RenameModal;
