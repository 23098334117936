// client/src/components/Projects/Modals/AssignMembersModal.jsx

import React, { useState, useEffect, useContext } from "react";
import { Button, Spinner, IconButton, HStack, Box, FormControl, FormLabel, Checkbox, Text } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import { PiXBold } from "react-icons/pi";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";

const AssignMembersModal = ({ isOpen, onClose, currentProject }) => {
  const { fetchUsersFromCompany, editProject, companies } = useContext(ProjectContext);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const customToast = useCustomToast();

  // Set companyOptions when companies change
  useEffect(() => {
    if (companies && companies.length > 0) {
      const companyOpts = companies.map((company) => ({
        value: company._id,
        label: company.companyName,
      }));
      setCompanyOptions(companyOpts);
    }
  }, [companies]);

  // Pre-select companies when currentProject changes and companies are loaded
  useEffect(() => {
    if (currentProject && companies && companies.length > 0) {
      const companyIds = new Set();

      // Add the primary company ID
      if (currentProject.companyId) {
        const companyId = currentProject.companyId._id || currentProject.companyId;
        companyIds.add(companyId);
      }

      // Add assigned companies IDs
      if (currentProject.assignedToCompanies && currentProject.assignedToCompanies.length > 0) {
        currentProject.assignedToCompanies.forEach((company) => {
          const companyId = company._id || company;
          companyIds.add(companyId);
        });
      }

      const selectedCompanyOptions = Array.from(companyIds).map((companyId) => {
        const company = companies.find((c) => c._id === companyId);
        return {
          value: companyId,
          label: company?.companyName || "",
        };
      });

      setSelectedCompanies(selectedCompanyOptions);
    }
  }, [currentProject, companies]);

  // Fetch company users when selectedCompanies change
  useEffect(() => {
    const fetchCompanyUsers = async () => {
      try {
        setIsUserLoading(true);
        if (selectedCompanies.length > 0) {
          let aggregatedUsers = [];
          for (const selectedCompany of selectedCompanies) {
            try {
              const users = await fetchUsersFromCompany(selectedCompany.value);
              const formattedUsers = users.map((user) => ({
                value: user._id,
                label: `${user.firstName} ${user.lastName}`,
                user,
              }));
              aggregatedUsers = [...aggregatedUsers, ...formattedUsers];
            } catch (error) {
              console.error("Error fetching users from company:", error);
            }
          }
          setCompanyUsers(aggregatedUsers);
          setOptions(aggregatedUsers);

          // Set selectedOptions based on currentProject members
          if (currentProject && currentProject.members) {
            const selectedUserOptions = aggregatedUsers.filter((userOption) =>
              currentProject.members.some((member) => {
                const memberId = member._id || member;
                return memberId === userOption.value;
              })
            );
            setSelectedOptions(selectedUserOptions);
          }
        } else {
          setCompanyUsers([]);
          setOptions([]);
          setSelectedOptions([]);
        }
      } catch (error) {
        console.error("Error fetching company users:", error);
      } finally {
        setIsUserLoading(false);
      }
    };
    fetchCompanyUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanies, currentProject]);

  // Handle saving the selected members and companies
  const handleSave = async () => {
    try {
      setIsLoading(true);
      const selectedUserIds = selectAllUsers ? companyUsers.map((user) => user.value) : selectedOptions.map((option) => option.value);
      const selectedCompanyIds = selectedCompanies.map((company) => company.value);

      // Update the project with members and assignedToCompanies
      await editProject(currentProject._id, {
        members: selectedUserIds,
        assignedToCompanies: selectedCompanyIds,
      });

      customToast({
        title: "Success",
        description: "Project members and companies have been updated.",
        status: "success",
      });
      onClose();
    } catch (error) {
      console.error("Error updating project members and companies:", error);
      customToast({
        title: "Error",
        description: "Unable to update project members and companies.",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Custom Option Component for Users
  const CustomUserOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Box ref={innerRef} {...innerProps} _hover={{ bg: "gray.100" }}>
        <HStack spacing={3} px={6} py={1}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
          <Text>{data.label}</Text>
        </HStack>
      </Box>
    );
  };

  // Custom MultiValue Component for Users
  const CustomUserMultiValue = (props) => {
    const { data, innerRef, innerProps, removeProps } = props;
    return (
      <Box
        ref={innerRef}
        {...innerProps}
        mb={2}
        bg="gray.50"
        borderRadius="md"
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={2}
      >
        <HStack spacing={3}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
          <Text>{data.label}</Text>
        </HStack>
        <IconButton
          icon={<PiXBold fontSize="16px" />}
          colorScheme="gray"
          variant="ghost"
          size="sm"
          onClick={removeProps.onClick}
          aria-label="Remove"
        />
      </Box>
    );
  };

  // Define the footer buttons
  const footerButtons = (
    <HStack ml='auto' spacing={3}>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
      <Button colorScheme="primary" onClick={handleSave} isLoading={isLoading}>
        Save
      </Button>
    </HStack>
  );

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Assign Members" footerButtons={footerButtons} size="xl" isCentered>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" py={10}>
          <Spinner size="lg" />
        </Box>
      ) : (
        <>
          <FormControl isRequired p={4}>
            <FormLabel>Select Companies</FormLabel>
            <Select
              isMulti
              placeholder="Select companies..."
              options={companyOptions}
              value={selectedCompanies}
              onChange={(companies) => setSelectedCompanies(companies)}
              components={chakraComponents}
              closeMenuOnSelect={true}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </FormControl>
          {isUserLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" py={4}>
              <Spinner />
            </Box>
          ) : (
            <FormControl isRequired p={4}>
              {!selectAllUsers && (
                <>
                  <FormLabel>Select Members</FormLabel>
                  <Select
                    isMulti
                    placeholder="Select members..."
                    options={options}
                    value={selectedOptions}
                    onChange={setSelectedOptions}
                    components={{
                      Option: CustomUserOption,
                      MultiValue: CustomUserMultiValue,
                    }}
                    closeMenuOnSelect={false}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </>
              )}
              <Checkbox isChecked={selectAllUsers} onChange={(e) => setSelectAllUsers(e.target.checked)} mt={2}>
                Select all users from selected companies
              </Checkbox>
            </FormControl>
          )}
        </>
      )}
    </ReusableModal>
  );
};

export default AssignMembersModal;
