// client\src\components\3 - Modal\10 - Customers\CustomerFormModal.js

import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  VStack,
  SimpleGrid,
} from "@chakra-ui/react";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";

const CustomerFormModal = ({ isOpen, onClose, onSave, initialData }) => {
  const customToast = useCustomToast();
  const [isLoading, setIsLoading] = useState(false);

  // Default State
  const defaultState = useCallback(
    () => ({
      email: "",
      role: "Customer",
      isVerified: false,
      profileCompleted: false,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      dateOfBirth: "",
      street: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
      preferredLanguage: "",
      jobTitle: "",
      industry: "",
      profilePicture: "",
      companyId: "",
      lastLoggedIn: "",
      status: "Invité",
      modules: {
        messages: "Customer Access",
        projects: "Customer Access",
        files: "Customer Access",
        contracts: "Customer Access",
        billing: "Customer Access",
        scheduleACall: "Customer Access",
      },
    }),
    []
  );

  // State variables
  const [formState, setFormState] = useState(defaultState);

  // Helper Functions
  const initializeForm = useCallback(
    (data) => {
      if (!data) {
        setFormState(defaultState);
        return;
      }

      const {
        email = "",
        role = "Customer",
        isVerified = false,
        profileCompleted = false,
        firstName = "",
        lastName = "",
        phoneNumber = "",
        dateOfBirth = "",
        street = "",
        city = "",
        province = "",
        postalCode = "",
        country = "",
        preferredLanguage = "",
        jobTitle = "",
        industry = "",
        companyId = "",
        lastLoggedIn = "",
        status = "Invité",
        modules = {
          messages: "Customer Access",
          projects: "Customer Access",
          files: "Customer Access",
          contracts: "Customer Access",
          billing: "Customer Access",
          scheduleACall: "Customer Access",
        },
      } = data;

      setFormState({
        email,
        role,
        isVerified,
        profileCompleted,
        firstName,
        lastName,
        phoneNumber,
        dateOfBirth,
        street,
        city,
        province,
        postalCode,
        country,
        preferredLanguage,
        jobTitle,
        industry,
        companyId,
        lastLoggedIn,
        status,
        modules,
      });
    },
    [defaultState]
  );

  useEffect(() => {
    initializeForm(initialData);
  }, [initialData, initializeForm]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Add your save logic here
      onSave();
      onClose();
    } catch (error) {
      customToast({
        status: "error",
        title: "Error",
        description: "An error occurred. Please try again later.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>
          {initialData ? "Edit Customer" : "Add New Customer"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                placeholder="Example: john.doe@example.com"
                value={formState.email}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, email: e.target.value }))
                }
              />
            </FormControl>

            <FormControl id="first-name">
              <FormLabel>First Name</FormLabel>
              <Input
                placeholder="Example: John"
                value={formState.firstName}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    firstName: e.target.value,
                  }))
                }
              />
            </FormControl>

            <FormControl id="last-name">
              <FormLabel>Last Name</FormLabel>
              <Input
                placeholder="Example: Doe"
                value={formState.lastName}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    lastName: e.target.value,
                  }))
                }
              />
            </FormControl>

            <FormControl id="phone-number">
              <FormLabel>Phone Number</FormLabel>
              <Input
                placeholder="Example: +123456789"
                value={formState.phoneNumber}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    phoneNumber: e.target.value,
                  }))
                }
              />
            </FormControl>

            <FormControl id="date-of-birth">
              <FormLabel>Date of Birth</FormLabel>
              <Input
                type="date"
                value={formState.dateOfBirth}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    dateOfBirth: e.target.value,
                  }))
                }
              />
            </FormControl>

            <FormControl id="street">
              <FormLabel>Street</FormLabel>
              <Input
                placeholder="Example: 123 Main St"
                value={formState.street}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, street: e.target.value }))
                }
              />
            </FormControl>

            <FormControl id="city">
              <FormLabel>City</FormLabel>
              <Input
                placeholder="Example: New York"
                value={formState.city}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, city: e.target.value }))
                }
              />
            </FormControl>

            <FormControl id="province">
              <FormLabel>Province</FormLabel>
              <Input
                placeholder="Example: NY"
                value={formState.province}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    province: e.target.value,
                  }))
                }
              />
            </FormControl>

            <FormControl id="postal-code">
              <FormLabel>Postal Code</FormLabel>
              <Input
                placeholder="Example: 10001"
                value={formState.postalCode}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    postalCode: e.target.value,
                  }))
                }
              />
            </FormControl>

            <FormControl id="country">
              <FormLabel>Country</FormLabel>
              <Input
                placeholder="Example: USA"
                value={formState.country}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, country: e.target.value }))
                }
              />
            </FormControl>

            <FormControl id="preferred-language">
              <FormLabel>Preferred Language</FormLabel>
              <Input
                placeholder="Example: English"
                value={formState.preferredLanguage}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    preferredLanguage: e.target.value,
                  }))
                }
              />
            </FormControl>

            <FormControl id="job-title">
              <FormLabel>Job Title</FormLabel>
              <Input
                placeholder="Example: Software Engineer"
                value={formState.jobTitle}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    jobTitle: e.target.value,
                  }))
                }
              />
            </FormControl>

            <FormControl id="industry">
              <FormLabel>Industry</FormLabel>
              <Input
                placeholder="Example: Technology"
                value={formState.industry}
                onChange={(e) =>
                  setFormState((prev) => ({
                    ...prev,
                    industry: e.target.value,
                  }))
                }
              />
            </FormControl>

            <FormControl id="status">
              <FormLabel>Status</FormLabel>
              <Input
                placeholder="Example: Actif"
                value={formState.status}
                onChange={(e) =>
                  setFormState((prev) => ({ ...prev, status: e.target.value }))
                }
              />
            </FormControl>

            <FormControl id="modules">
              <FormLabel>Modules</FormLabel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                {Object.keys(formState.modules).map((module) => (
                  <Checkbox
                    key={module}
                    isChecked={formState.modules[module] === "Customer Access"}
                    onChange={(e) =>
                      setFormState((prev) => ({
                        ...prev,
                        modules: {
                          ...prev.modules,
                          [module]: e.target.checked
                            ? "Customer Access"
                            : "No Access",
                        },
                      }))
                    }
                  >
                    {module.charAt(0).toUpperCase() + module.slice(1)}
                  </Checkbox>
                ))}
              </SimpleGrid>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="primary"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            {initialData ? "Update" : "Create"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomerFormModal;
