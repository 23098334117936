// client/src/components/5 - General/Hooks/useUserOnlineStatusSocketQuery.js
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSocket } from "../Socket/SocketContext";
import { queryKeys } from "../../../queryKeys";
import useCustomToast from "../Utils/UtilsNotification";

export const useUserOnlineStatusSocketQuery = (userIds) => {
  const { socket, connected } = useSocket();
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  return useQuery({
    queryKey: queryKeys.userAvatar(userIds),
    queryFn: () =>
      new Promise((resolve, reject) => {
        if (!socket) {
          console.error("Socket is not available.");
          return reject(new Error("Socket is not available."));
        }


        const timeout = setTimeout(() => {
          reject(new Error("Request timed out"));
        }, 2000);

        socket.emit("getOnlineStatuses", { userIds }, (response) => {
          clearTimeout(timeout);
          if (response.status === "ok") {
            resolve(response.statuses);
          } else {
            reject(new Error(response.message || "Failed to fetch user online statuses"));
          }
        });
      }),
    enabled: connected && userIds && userIds.length > 0,
    initialData: () => {
      const data = queryClient.getQueryData(queryKeys.userAvatar(userIds));
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(queryKeys.userAvatar(userIds), data);
    },
    onError: (error) => {
      console.error("Error fetching user online statuses:", error);
      customToast({
        title: "Error",
        description: error.message || "Failed to fetch user online statuses.",
        status: "error",
      });
    },
    retry: 3,
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
  });
};