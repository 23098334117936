import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  HStack,
  Flex,
  Step,
  Stepper,
  StepStatus,
  StepIndicator,
  StepSeparator,
  useSteps,
  Text,
  StepTitle,
  Box,
  Textarea,
  Tag,
  TagCloseButton,
  TagLabel,
} from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import { PiCheck, PiPlus } from "react-icons/pi";

const CreateTaskModal = ({ isOpen, onClose, projectId, parentTaskId, parentTask }) => {
  const { tasks, createTask, fetchUsersFromCompany, currentProject } = useContext(ProjectContext);

  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [status, setStatus] = useState("Not Started");
  const [dueDate, setDueDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [priority, setPriority] = useState("");
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [selectedParentTask, setSelectedParentTask] = useState(null);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: 2,
  });

  const customToast = useCustomToast();

  useEffect(() => {
    if (isOpen) {
      if (parentTaskId && tasks.length > 0) {
        const parentTaskOption = tasks.find((task) => task._id === parentTaskId);
        if (parentTaskOption) {
          setSelectedParentTask({ value: parentTaskOption._id, label: parentTaskOption.name });
        }
      } else {
        setSelectedParentTask(null);
      }
    }
  }, [isOpen, parentTaskId, tasks]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        if (!currentProject || !currentProject.assignedToCompanies || currentProject.assignedToCompanies.length === 0) {
          console.error("Current project or assigned companies are undefined or empty.");
          return;
        }

        let aggregatedUsers = [];

        for (const company of currentProject.assignedToCompanies) {
          const companyId = company._id;
          if (!companyId) continue;

          const users = await fetchUsersFromCompany(companyId);
          aggregatedUsers = aggregatedUsers.concat(users);
        }

        // Remove duplicate users
        const uniqueUsersMap = new Map();
        aggregatedUsers.forEach((user) => {
          uniqueUsersMap.set(user._id, user);
        });
        const uniqueUsers = Array.from(uniqueUsersMap.values());

        setAvailableUsers(
          uniqueUsers.map((user) => ({
            label: `${user.firstName} ${user.lastName}`,
            value: user._id,
          }))
        );
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (isOpen) {
      loadUsers();
    }
  }, [isOpen, fetchUsersFromCompany, currentProject]);

  const taskOptions = useMemo(() => {
    return tasks.map((task) => ({ value: task._id, label: task.name }));
  }, [tasks]);

  const handleTaskSubmit = async () => {
    if (!projectId) {
      console.error("Project ID is undefined.");
      customToast("error", "Error", "Project ID is undefined.");
      return;
    }

    const taskData = {
      name: taskName,
      description: taskDescription,
      status,
      startDate,
      dueDate,
      priority,
      assignedTo: selectAllUsers ? availableUsers.map((user) => user.value) : assignedUsers.map((user) => user.value),
      tags,
      parentTaskId: selectedParentTask ? selectedParentTask.value : null,
    };

    try {
      await createTask(projectId, taskData);
      customToast("success", "Task Created", "The task has been created successfully.");
      onClose();
      resetForm();
    } catch (error) {
      console.error("Error creating task:", error);
      customToast("error", "Error", "Error creating the task.");
    }
  };

  const resetForm = () => {
    setTaskName("");
    setTaskDescription("");
    setStatus("Not Started");
    setDueDate(null);
    setStartDate(null);
    setPriority("");
    setAssignedUsers([]);
    setTags([]);
    setActiveStep(0);
  };

  const steps = [
    { title: "Step 1", description: "Task Details" },
    { title: "Step 2", description: "Assign Members" },
  ];

  const handleAddTag = () => {
    if (tagInput.trim()) {
      setTags([...tags, tagInput.trim()]);
      setTagInput("");
    }
  };

  const handleRemoveTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent borderRadius="20px">
        <ModalHeader p={0}>
          <Flex w="100%" justifyContent="center" p={8} pt={10}>
            <Text fontSize="22px" fontWeight="bold">
              Create a New Task
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex w="100%" justifyContent="center" borderBottom="1px solid" borderColor="gray.200" pb={8} mb={4}>
            {/* Stepper */}
            <Stepper index={activeStep} colorScheme="blue" w="90%" size="lg">
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator size="lg">
                    <StepStatus
                      complete={
                        <Box as="span">
                          <PiCheck />
                        </Box>
                      }
                      incomplete={<Box as="span">{index + 1}</Box>}
                      active={<Box as="span">{index + 1}</Box>}
                    />
                  </StepIndicator>
                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <Box fontSize="sm" fontWeight="400" color="gray.500">
                      {step.description}
                    </Box>
                  </Box>
                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </Flex>

          {/* Step 1: Task Details */}
          {activeStep === 0 && (
            <>
              <FormControl isRequired p={4}>
                <FormLabel fontSize="md" fontWeight="500">
                  Task Name
                </FormLabel>
                <Input value={taskName} onChange={(e) => setTaskName(e.target.value)} borderRadius="xl" />
              </FormControl>

              <FormControl p={4}>
                <FormLabel fontSize="md" fontWeight="500">
                  Parent Task (Optional)
                </FormLabel>
                <Select
                  placeholder="Select parent task..."
                  value={selectedParentTask}
                  onChange={(option) => setSelectedParentTask(option)}
                  options={taskOptions}
                  isClearable
                />
              </FormControl>

              <FormControl isRequired p={4}>
                <FormLabel fontSize="md" fontWeight="500">
                  Task Description
                </FormLabel>
                <Textarea value={taskDescription} onChange={(e) => setTaskDescription(e.target.value)} borderRadius="xl" bg="gray.100" />
              </FormControl>

              <FormControl p={4}>
                <FormLabel fontSize="md" fontWeight="500">
                  Start Date
                </FormLabel>
                <UtilsDatePicker value={startDate} onChange={setStartDate} inputVariant="default" />
              </FormControl>

              <FormControl p={4}>
                <FormLabel fontSize="md" fontWeight="500">
                  Due Date
                </FormLabel>
                <UtilsDatePicker value={dueDate} onChange={setDueDate} inputVariant="default" />
              </FormControl>

              <FormControl isRequired p={4}>
                <FormLabel fontSize="md" fontWeight="500">
                  Priority
                </FormLabel>
                <Select
                  placeholder="Choose a priority..."
                  value={priority ? { value: priority, label: priority } : null}
                  onChange={(selectedOption) => setPriority(selectedOption?.value || "")}
                  options={[
                    { value: "Low", label: "Low" },
                    { value: "Medium", label: "Medium" },
                    { value: "High", label: "High" },
                  ]}
                  isMulti={false}
                  closeMenuOnSelect={true}
                />
              </FormControl>
              <Flex w="100%" justifyContent="center">
                <Button m={4} size="xl" variant="outline" colorScheme="blue" w="200px" h="50px" onClick={() => setActiveStep(1)}>
                  Next Step
                </Button>
              </Flex>
            </>
          )}

          {/* Step 2: Assign Members */}
          {activeStep === 1 && (
            <>
              <FormControl isRequired p={4}>
                <FormLabel fontSize="md" fontWeight="500">
                  Assign Members
                </FormLabel>
                {!selectAllUsers && (
                  <Select
                    isMulti
                    placeholder="Select members..."
                    options={availableUsers}
                    value={assignedUsers}
                    onChange={(users) => setAssignedUsers(users || [])}
                    components={chakraComponents}
                    menuPortalTarget={document.body}
                    maxMenuHeight={200}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  />
                )}
                <Checkbox isChecked={selectAllUsers} onChange={(e) => setSelectAllUsers(e.target.checked)} mt={2}>
                  Select all users
                </Checkbox>
              </FormControl>

              <FormControl id="tags" mt={4}>
                <FormLabel>Tags</FormLabel>
                <Flex>
                  <Input
                    placeholder="Add a tag"
                    value={tagInput}
                    onChange={(e) => setTagInput(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleAddTag()}
                  />
                  <Button ml={2} onClick={handleAddTag} borderRadius="xl" h="40px" variant="outline" colorScheme="gray" borderColor="gray.300">
                    <PiPlus />
                  </Button>
                </Flex>
                <Box mt={2}>
                  {tags.map((tag, index) => (
                    <Tag key={index} borderRadius="full" colorScheme="blue" mr={1} pl={3} pr={3}>
                      <TagLabel>{tag}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveTag(index)} />
                    </Tag>
                  ))}
                </Box>
              </FormControl>

              <HStack mt={4} justify="space-between">
                <Button m={4} size="xl" variant="outline" colorScheme="gray" w="200px" h="50px" onClick={() => setActiveStep(0)}>
                  Back
                </Button>
                <Button m={4} size="xl" variant="solid" colorScheme="blue" w="200px" h="50px" onClick={handleTaskSubmit}>
                  Create Task
                </Button>
              </HStack>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateTaskModal;
