import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Grid,
  GridItem,
  Tag,
  Text,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { formatReadableDate, formatTimestamp } from "../../5 - General/Utils/UtilsFormatData";

export const MobileActionsModal = ({
  user,
  isOpen,
  onClose,
  handleModifyRights,
  handleDeactivateUser,
  handleResendInvitation,
  handleSendPasswordReset,
  handleActivateUser,
  handleDeleteUser,
  currentUserRole,
  canModifyUser,
}) => {
  const isDisabled = !canModifyUser(currentUserRole, user.role);
  const isDisabledForAdmins = user.role === "Admin";

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {user.firstName} {user.lastName}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="start" spacing={4}>
            {/* Detailed Info */}
            <Grid templateColumns="100px 1fr" gap={2} w="100%">
              <GridItem>
                <Text fontSize="md">Email:</Text>
              </GridItem>
              <GridItem>
                <Tag size="sm" colorScheme="gray">
                  {user.email || "No Email"}
                </Tag>
              </GridItem>

              <GridItem>
                <Text fontSize="md">Role:</Text>
              </GridItem>
              <GridItem>
                <Tag size="sm" colorScheme="blue">
                  {user.role}
                </Tag>
              </GridItem>

              <GridItem>
                <Text fontSize="md">Joined:</Text>
              </GridItem>
              <GridItem>
                <Tag size="sm" colorScheme="gray">
                  {formatReadableDate(user.createdAt)}
                </Tag>
              </GridItem>

              <GridItem>
                <Text fontSize="md">Last Login:</Text>
              </GridItem>
              <GridItem>
                <Tag size="sm" colorScheme={user.lastLoggedIn ? "gray" : "red"}>
                  {user.lastLoggedIn ? formatTimestamp(user.lastLoggedIn) : "Never"}
                </Tag>
              </GridItem>

              <GridItem>
                <Text fontSize="md">Status:</Text>
              </GridItem>
              <GridItem>
                <Tag size="sm" colorScheme={user.status === "Deactivated" ? "red" : "green"}>
                  {user.status === "Deactivated" ? "Deactivated" : "Active"}
                </Tag>
              </GridItem>

              <GridItem>
                <Text fontSize="md">Email Verified:</Text>
              </GridItem>
              <GridItem>
                <Tag size="sm" colorScheme={user.isVerified ? "green" : "red"}>
                  {user.isVerified ? "Verified" : "Not Verified"}
                </Tag>
              </GridItem>

              <GridItem>
                <Text fontSize="md">Profile:</Text>
              </GridItem>
              <GridItem>
                <Tag size="sm" colorScheme={user.profileCompleted ? "green" : "red"}>
                  {user.profileCompleted ? "Complete" : "Incomplete"}
                </Tag>
              </GridItem>
            </Grid>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <VStack w="100%" spacing={2}>
            {/* Actions */}
            {!user.isVerified && (
              <Tooltip label="Resend the invitation email for verification">
                <Button size="sm" variant="outline" colorScheme="blue" onClick={() => handleResendInvitation(user)} isDisabled={isDisabled} w="100%">
                  Resend Invitation
                </Button>
              </Tooltip>
            )}

            {user.isVerified && (
              <Button size="sm" variant="outline" colorScheme="gray" onClick={() => handleSendPasswordReset(user)} isDisabled={isDisabled} w="100%">
                Send Password Reset
              </Button>
            )}

            <Tooltip label="Modify this user's rights to individual modules">
              <Button size="sm" variant="outline" onClick={() => handleModifyRights(user)} isDisabled={isDisabled} w="100%">
                Modify Rights
              </Button>
            </Tooltip>

            {user.status !== "Deactivated" ? (
              <Tooltip label="Suspend user account">
                <Button
                  size="sm"
                  colorScheme="gray"
                  variant="outline"
                  onClick={() => handleDeactivateUser(user)}
                  isDisabled={isDisabled || isDisabledForAdmins}
                  w="100%"
                >
                  Suspend
                </Button>
              </Tooltip>
            ) : (
              <Tooltip label="Reactivate User Account">
                <Button size="sm" variant="outline" colorScheme="green" onClick={() => handleActivateUser(user)} isDisabled={isDisabled} w="100%">
                  Reactivate
                </Button>
              </Tooltip>
            )}

            <Tooltip label={user.status === "Deactivated" ? "Delete user account" : "User account must be suspended to delete"}>
              <Button
                size="sm"
                variant="outline"
                colorScheme="red"
                onClick={() => handleDeleteUser(user)}
                isDisabled={isDisabled || user.status !== "Deactivated"}
                w="100%"
              >
                Delete
              </Button>
            </Tooltip>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
