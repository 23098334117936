// client/src/components/3 - Modal/3 - Projects Modals/CreateProjectModal.jsx

import React, { useState, useEffect, useContext } from "react";
import { Button, Spinner, IconButton, HStack, Box, FormControl, FormLabel, Input, Checkbox, useColorModeValue } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import { PiArrowRightBold, PiXBold } from "react-icons/pi";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import TagMenu from "../../2 - Components/Reusable/TagMenu";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";

const CreateProjectModal = ({ isOpen, onClose }) => {
  const { currentUser } = useContext(WorkspaceContext);
  const { createProject, setCurrentProject, companies, currentCompany, fetchUsersFromCompany } = useContext(ProjectContext);
  const [name, setName] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [status, setStatus] = useState("Not Started");
  const [priority, setPriority] = useState("Medium");

  // New state variables for companies and members
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [isUserLoading, setIsUserLoading] = useState(false);

  const customToast = useCustomToast();

  // Populate company options
  useEffect(() => {
    if (companies && companies.length > 0) {
      const companyOpts = companies.map((company) => ({
        value: company._id,
        label: company.companyName,
      }));
      setCompanyOptions(companyOpts);
    }
  }, [companies]);

  // Automatically select the current company and fetch its users on load
  useEffect(() => {
    if (currentCompany) {
      const companyOption = {
        value: currentCompany._id,
        label: currentCompany.companyName,
      };
      setSelectedCompanies([companyOption]);

      // Fetch users from current company
      fetchUsersFromCompany(currentCompany._id)
        .then((users) => {
          const formattedUsers = users.map((user) => ({
            value: user._id,
            label: `${user.firstName} ${user.lastName}`,
            user,
          }));
          setCompanyUsers(formattedUsers);
          setMemberOptions(formattedUsers);

          // Select current user by default
          const currentUserOption = formattedUsers.find((user) => user.value === currentUser?._id);
          if (currentUserOption) {
            setSelectedMembers([currentUserOption]);
          }
        })
        .catch((error) => {
          console.error("Error fetching users from company:", error);
        });
    }
  }, [currentCompany, fetchUsersFromCompany, currentUser]);

  // Fetch company users when selectedCompanies change
  useEffect(() => {
    const fetchCompanyUsers = async () => {
      try {
        setIsUserLoading(true);
        if (selectedCompanies.length > 0) {
          let aggregatedUsers = [];
          for (const selectedCompany of selectedCompanies) {
            try {
              const users = await fetchUsersFromCompany(selectedCompany.value);
              const formattedUsers = users.map((user) => ({
                value: user._id,
                label: `${user.firstName} ${user.lastName}`,
                user,
              }));
              aggregatedUsers = [...aggregatedUsers, ...formattedUsers];
            } catch (error) {
              console.error("Error fetching users from company:", error);
            }
          }
          setCompanyUsers(aggregatedUsers);
          setMemberOptions(aggregatedUsers);

          // Set selectedMembers based on fetched users
          if (currentUser && !selectAllUsers) {
            const currentUserOption = aggregatedUsers.find((user) => user.value === currentUser._id);
            if (currentUserOption) {
              setSelectedMembers([currentUserOption]);
            }
          }
        } else {
          setCompanyUsers([]);
          setMemberOptions([]);
          setSelectedMembers([]);
        }
      } catch (error) {
        console.error("Error fetching company users:", error);
      } finally {
        setIsUserLoading(false);
      }
    };
    fetchCompanyUsers();
  }, [selectedCompanies, fetchUsersFromCompany, currentUser, selectAllUsers]);

  const handleSubmit = async () => {
    if (!currentCompany || !currentCompany._id) {
      console.error("Current company is not defined or is missing an _id.");
      customToast({
        title: "Error",
        description: "Please select a company.",
        status: "error",
      });
      return;
    }

    const projectData = {
      name,
      startDate,
      endDate,
      status,
      priority,
      members: selectAllUsers ? companyUsers.map((user) => user.value) : selectedMembers.map((user) => user.value),
      assignedToCompanies: selectedCompanies.map((company) => company.value),
      companyId: currentCompany._id,
      createdBy: currentUser?._id,
    };

    try {
      const createdProject = await createProject(currentCompany._id, projectData);

      // Set currentProject to the entire project object
      setCurrentProject(createdProject);

      customToast({
        title: "Project Created",
        description: "The project has been successfully created.",
        status: "success",
      });

      onClose();
      resetForm();
    } catch (error) {
      console.error("Error creating project:", error);
      customToast({
        title: "Error Creating Project",
        description: error.message || "An error occurred.",
        status: "error",
      });
    }
  };

  const resetForm = () => {
    setName("");
    setStartDate(null);
    setEndDate(null);
    setSelectedMembers([]);
    setSelectedCompanies([]);
    setStatus("Not Started");
    setPriority("Medium");
  };

  // Status and Priority options
  const statusOptions = [
    { value: "Not Started", label: "Not Started", color: "gray" },
    { value: "In Progress", label: "In Progress", color: "blue" },
    { value: "Completed", label: "Completed", color: "green" },
    { value: "On Hold", label: "On Hold", color: "orange" },
  ];

  const priorityOptions = [
    { value: "High", label: "High", color: "red" },
    { value: "Medium", label: "Medium", color: "yellow" },
    { value: "Low", label: "Low", color: "green" },
  ];

  // Custom Option Component for Users
  const CustomUserOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Box ref={innerRef} {...innerProps} _hover={{ bg: "gray.100" }}>
        <HStack spacing={3} px={6} py={1}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
        </HStack>
      </Box>
    );
  };

  // Custom MultiValue Component for Users
  const CustomUserMultiValue = (props) => {
    const { data, innerRef, innerProps, removeProps } = props;
    return (
      <Box
        ref={innerRef}
        {...innerProps}
        mb={1}
        bg="gray.50"
        borderRadius="xl"
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        py={1}
      >
        <HStack spacing={1}>
          <UserAvatar userIds={[data.value]} maxAvatars={1} size="sm" showInfo={true} />
        </HStack>
        <IconButton
          icon={<PiXBold fontSize="16px" />}
          colorScheme="gray"
          variant="ghost"
          size="sm"
          onClick={removeProps.onClick}
          aria-label="Remove"
        />
      </Box>
    );
  };

  const arrowColor = useColorModeValue("gray.500", "gray.300");

  // Define the footer buttons
  const footerButtons = (
    <HStack ml='auto' spacing={3}>
      <Button variant="outline" onClick={onClose}>
        Cancel
      </Button>
      <Button colorScheme="primary" onClick={handleSubmit}>
        Create Project
      </Button>
    </HStack>
  );

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Create a New Project" footerButtons={footerButtons} size="lg" isCentered>
      <FormControl isRequired p={4} pt={2}>
        <FormLabel fontSize="md" fontWeight="500">
          Project Name
        </FormLabel>
        <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter project name..." />
      </FormControl>

      {/* Start and End Dates Side by Side */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Project Duration
        </FormLabel>
        <HStack spacing={2}>
          <UtilsDatePicker value={startDate} placeholder="Start Date" onChange={setStartDate} inputVariant="default" />
          <PiArrowRightBold color={arrowColor} />
          <UtilsDatePicker value={endDate} placeholder="End Date" onChange={setEndDate} inputVariant="default" />
        </HStack>
      </FormControl>

      {/* Status and Priority */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Initial Project Status and Priority
        </FormLabel>
        <HStack spacing={4}>
          <TagMenu options={statusOptions} selectedValue={status} field="status" handleBlurSave={(id, field, value) => setStatus(value)} />
          <TagMenu options={priorityOptions} selectedValue={priority} field="priority" handleBlurSave={(id, field, value) => setPriority(value)} />
        </HStack>
      </FormControl>

      {/* Companies Selection */}
      <FormControl isRequired p={4}>
        <FormLabel fontSize="md" fontWeight="500">
          Select Companies
        </FormLabel>
        <Select
          isMulti
          placeholder="Select companies..."
          options={companyOptions}
          value={selectedCompanies}
          onChange={(companies) => setSelectedCompanies(companies)}
          components={chakraComponents}
          closeMenuOnSelect={true}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />
      </FormControl>

      {isUserLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" py={4}>
          <Spinner />
        </Box>
      ) : (
        <FormControl isRequired p={4}>
          {!selectAllUsers && (
            <>
              <FormLabel fontSize="md" fontWeight="500">
                Select Members
              </FormLabel>
              <Select
                isMulti
                placeholder="Select members..."
                options={memberOptions}
                value={selectedMembers}
                onChange={setSelectedMembers}
                components={{
                  Option: CustomUserOption,
                  MultiValue: CustomUserMultiValue,
                }}
                closeMenuOnSelect={false}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </>
          )}
          <Checkbox isChecked={selectAllUsers} onChange={(e) => setSelectAllUsers(e.target.checked)} mt={2}>
            Select all users from selected companies
          </Checkbox>
        </FormControl>
      )}
    </ReusableModal>
  );
};

export default CreateProjectModal;
