// src/components/3 - Modal/2 - Messages Modals/ViewUsersInChannelModal.js

import React, { useState, useContext } from "react";
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  IconButton,
  Grid,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import { PiTrash } from "react-icons/pi";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import { MessagesContext } from "../../5 - General/Context/MessagesContext";

const ViewUsersInChannelModal = ({ isOpen, onClose }) => {
  const { channelUsers, handleRemoveUserFromChannel } = useContext(MessagesContext);
  const [confirmRemoveUser, setConfirmRemoveUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const modalBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const overlayBg = useColorModeValue("rgba(0, 0, 0, 0.5)", "rgba(0, 0, 0, 0.5)");

  const filteredUsers = channelUsers.filter(
    (user) =>
      `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.companyName || "N/A").toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg={overlayBg} />
      <ModalContent
        bg={modalBg}
        borderRadius="md"
        borderWidth="1px"
        borderColor={borderColor}
        h="70vh"
        maxW="90%"
        w="50%"
        minW="50%"
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <ModalHeader fontSize="lg" fontWeight="medium" p={8} pb={2} borderBottomWidth="1px" borderBottomColor={borderColor}>
          Users in the Conversation
        </ModalHeader>
        <Box pr={8} pl={4} pt={4} justifyContent="start">
          <Input placeholder="Search users or companies..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} mb={4} />
        </Box>
        <ModalBody p={4} overflowY="auto" flex="1">
          <Grid
            templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
            gap={2}
            sx={{
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: useColorModeValue("gray.300", "gray.600"),
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: useColorModeValue("gray.400", "gray.700"),
              },
            }}
          >
            {filteredUsers.map((user) => (
              <Flex
                key={user._id}
                alignItems="center"
                justify="space-between"
                p={2}
                borderRadius="md"
                borderWidth="1px"
                borderColor={borderColor}
                minWidth="300px"
              >
                <UserAvatar userIds={[user._id]} mr={3} size="md" />
                <Flex>
                  {confirmRemoveUser === user._id ? (
                    <Button
                      colorScheme="red"
                      size="sm"
                      onClick={() => {
                        handleRemoveUserFromChannel(user._id);
                        setConfirmRemoveUser(null);
                      }}
                      className="view-users-in-channel-modal-confirm-remove"
                    >
                      Remove?
                    </Button>
                  ) : (
                    <IconButton aria-label="Remove User" icon={<PiTrash />} onClick={() => setConfirmRemoveUser(user._id)} variant="ghost" />
                  )}
                </Flex>
              </Flex>
            ))}
          </Grid>
        </ModalBody>
        <ModalFooter borderTopWidth="1px" borderTopColor={borderColor}>
          <Button onClick={onClose} colorScheme="primary">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewUsersInChannelModal;
