// client/src/components/2 - Components/ProjectsViewTaskView/ProjectsTaskViewDetails.js

import React, { useState, useEffect, useContext } from "react";
import { Box, Divider, Grid, Heading, HStack, Switch, Text, VStack, useColorModeValue, Tooltip } from "@chakra-ui/react";
import UserAvatar from "../Reusable/UserAvatar";
import UtilsDatePicker from "../../5 - General/Utils/UtilsDatePicker";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import { CgSpinner } from "react-icons/cg";
import { PiBell } from "react-icons/pi";

const TaskViewDetails = ({ task, handleTaskChange }) => {
  const { subtasks } = useContext(ProjectContext);
  const taskSubtasks = subtasks[task._id] || [];
  const [startDate, setStartDate] = useState(task.startDate);
  const [dueDate, setDueDate] = useState(task.dueDate);

  useEffect(() => {
    setStartDate(task.startDate);
    setDueDate(task.dueDate);
  }, [task.startDate, task.dueDate]);

  const daysRemaining = task.dueDate ? Math.ceil((new Date(task.dueDate) - new Date()) / (1000 * 60 * 60 * 24)) : null;

  const totalSubtasks = taskSubtasks.length || 1; // Avoid division by zero

  // Calculate the counts for each status
  const statusCounts = {
    Completed: taskSubtasks.filter((st) => st.status === "Completed").length,
    "In Progress": taskSubtasks.filter((st) => st.status === "In Progress").length,
    "Not Started": taskSubtasks.filter((st) => st.status === "Not Started").length,
    "On Hold": taskSubtasks.filter((st) => st.status === "On Hold").length,
  };

  // Calculate percentages for each status
  const statusPercentages = {
    Completed: (statusCounts["Completed"] / totalSubtasks) * 100,
    "In Progress": (statusCounts["In Progress"] / totalSubtasks) * 100,
    "Not Started": (statusCounts["Not Started"] / totalSubtasks) * 100,
    "On Hold": (statusCounts["On Hold"] / totalSubtasks) * 100,
  };

  const assignedTo = task.assignedTo || [];

  // Color schemes for each status
  const statusColors = {
    Completed: "green.500",
    "In Progress": "blue.500",
    "Not Started": useColorModeValue("gray.300", "gray.600"),
    "On Hold": "orange.500",
  };

  return (
    <Box flex="none" overflow="hidden" pt={4} mt={4}>
      <Heading fontSize="2xl" fontWeight="600" mb={6}>
        Task Details
      </Heading>
      {/* Task Details Grid */}
      <Grid templateColumns={{ base: "1fr", md: "120px 1fr" }} gap={4} alignItems="center">
        {/* Assigned To */}
        <Text fontWeight={500} fontSize="md" color="gray.500" textAlign="left" minWidth="120px">
          Assigned to:
        </Text>
        <UserAvatar userIds={assignedTo} size="sm" maxAvatars={10} />

        {/* Start Date */}
        <Text fontWeight={500} fontSize="md" color="gray.500" textAlign="left" minWidth="120px">
          Start Date:
        </Text>
        <UtilsDatePicker
          inputVariant="editable"
          value={startDate}
          onChange={(date) => {
            setStartDate(date);
            handleTaskChange("startDate", date);
          }}
          hasEditButton={true}
          fontWeight={500}
          fontSize={"md"}
          color={"gray.700"}
        />

        {/* Due Date */}
        <Text fontWeight={500} fontSize="md" color="gray.500" textAlign="left" minWidth="120px">
          Due Date:
        </Text>
        <UtilsDatePicker
          inputVariant="editable"
          value={dueDate}
          onChange={(date) => {
            setDueDate(date);
            handleTaskChange("dueDate", date);
          }}
          hasEditButton={true}
          fontWeight={500}
          fontSize={"md"}
          color={"gray.700"}
        />
      </Grid>
      {/* Divider */}
      <Divider my={4} />
      <VStack align="stretch" spacing={6} mt={6}>
        {/* Project Stats Title */}
        <Heading fontSize="2xl" fontWeight="600">
          Statistics
        </Heading>

        {/* Time Remaining Box */}
        <Box p={4} borderRadius="xl" border="1px solid" borderColor="gray.200" bg="white" mr="50px">
          <HStack justify="space-between" mb={2}>
            <HStack spacing={2}>
              <PiBell fontSize='18px' />
              <Text fontSize="md" fontWeight="600">
                Time Remaining
              </Text>
            </HStack>
            <Text fontSize="md" fontWeight="600" color={daysRemaining > 0 ? "green.500" : "red.500"}>
              {daysRemaining > 0 ? `${daysRemaining} days` : "Overdue"}
            </Text>
          </HStack>
          <Box bg="gray.50" p={2} borderRadius="md">
            <HStack justify="space-between">
              <Text fontSize="md" fontWeight="500" color="gray.500">
                {daysRemaining > 0 ? "Activate" : "Deactivate"} Reminders
              </Text>
              <Switch size="md" colorScheme="gray" />
            </HStack>
          </Box>
        </Box>

        {/* Custom Progress Bar with Tooltips */}
        <Box p={4} borderRadius="xl" border="1px solid" borderColor="gray.200" bg="white" mr="50px">
          <HStack justify="space-between" mb={2}>
            <HStack spacing={2}>
              <CgSpinner  fontSize='18px'/>
              <Text fontSize="md" fontWeight="600">
                Progress
              </Text>
            </HStack>
            <Text fontSize="md" fontWeight="600" color="gray.500">
              {((statusCounts["Completed"] / totalSubtasks) * 100).toFixed(2)}%
            </Text>
          </HStack>
          <Box display="flex" height="20px" borderRadius="full" overflow="hidden" bg={useColorModeValue("gray.200", "gray.700")}>
            {["Completed", "In Progress", "Not Started", "On Hold"].map((status) => {
              const percentage = statusPercentages[status];
              if (percentage > 0) {
                return (
                  <Tooltip
                    key={status}
                    label={`${status}: ${statusCounts[status]} (${percentage.toFixed(2)}%)`}
                    aria-label={`${status} tooltip`}
                    placement="bottom"
                    hasArrow
                  >
                    <Box width={`${percentage}%`} bg={statusColors[status]} transition="width 0.3s ease-in-out" cursor="pointer" />
                  </Tooltip>
                );
              }
              return null;
            })}
          </Box>
        </Box>

        {/* Divider */}
        <Divider />

        {/* Activity Timeline */}
        <Box>
          <Heading fontSize="2xl" fontWeight="600" mb={6}>
            Activity Timeline
          </Heading>
          <VStack mx={4} align="stretch" spacing={2} mt={2}>
            {task.history?.length > 0 ? (
              task.history.map((log, index) => (
                <Text key={index} fontSize="sm">
                  {log.changedBy} {log.action} on {new Date(log.changedAt).toLocaleDateString()} at {new Date(log.changedAt).toLocaleTimeString()}
                </Text>
              ))
            ) : (
              <Text fontSize="sm" color="gray.500">
                No activity yet
              </Text>
            )}
          </VStack>
        </Box>

        {/* Divider */}
        <Divider />

        {/* Created By */}
        <Box>
          <Heading fontSize="2xl" fontWeight="600" mb={6}>
            Created By
          </Heading>
          <VStack mx={4} align="stretch" spacing={2} mt={2}>
            <UserAvatar userIds={[task.createdBy]} size="md" showInfo={true} withTooltip={false} />
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default TaskViewDetails;
