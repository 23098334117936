// client/src/components/ProjectsTasksList.jsx
import React, { useState, useContext, useCallback } from "react";
import {
  Box,
  Flex,
  Heading,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spinner,
  TabIndicator,
  useColorModeValue,
  HStack,
  Text,
} from "@chakra-ui/react";
import { PiKanban, PiChartBarHorizontal, PiArticle, PiListChecks } from "react-icons/pi";
import ProjectsTasksList from "./ProjectsTasksList";
import CreateTaskModal from "../3 - Modal/3 - Projects Modals/ProjectsCreateTaskModal";
import TaskView from "./ProjectsViewTaskView/ProjectsTaskView";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import ProjectsTasksBoard from "./ProjectsTasksBoard";
import ProjectsTasksGanttChart from "./ProjectsTasksGanttChart";
import AssignMembersModal from "../3 - Modal/3 - Projects Modals/ProjectsAssignMembersModal";
import UserAvatar from "../2 - Components/Reusable/UserAvatar";
import ProjectsDescription from "./ProjectsDescription";

const TaskGroups = () => {
  const { currentProject, loadingTasks, currentTask, setCurrentTask, fetchSubtasksForTask } = useContext(ProjectContext);
  const [isAssignMembersModalOpen, setIsAssignMembersModalOpen] = useState(false);
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const [parentTaskId, setParentTaskId] = useState(null);
  const [parentTask, setParentTask] = useState(null);

  const bgColor = useColorModeValue("white", "gray.800");

  const handleTaskClick = useCallback(
    async (task) => {
      setCurrentTask(task);
      try {
        await fetchSubtasksForTask(task._id);
      } catch (error) {
        console.error("Error fetching subtasks for task:", error);
      }
    },
    [fetchSubtasksForTask, setCurrentTask]
  );

  const handleAddTask = useCallback(() => {
    setCurrentTask(null);
    setParentTaskId(null);
    setParentTask(null);
    setIsCreateTaskModalOpen(true);
  }, [setCurrentTask, setParentTaskId, setParentTask, setIsCreateTaskModalOpen]);

  const handleCreateTaskModalClose = useCallback(() => {
    setIsCreateTaskModalOpen(false);
    setParentTaskId(null);
    setParentTask(null);
  }, [setIsCreateTaskModalOpen, setParentTaskId, setParentTask]);

  if (loadingTasks) {
    return (
      <Flex justify="center" align="center" w="100%" height="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box w="100%" bg={bgColor} overflow="hidden">
      {!currentTask ? (
        <>
          <Flex justifyContent="space-between" alignItems="center" px={8} h="100px">
            <Heading alignContent='center' as="h2" size="lg">
              {currentProject?.name}
            </Heading>
            <HStack>
              <UserAvatar userIds={currentProject?.members} size="md" useGroup={true} maxAvatars={5} spacing={-3} />
              <Button ml={4} colorScheme="primary" onClick={() => setIsAssignMembersModalOpen(true)}>
                Add Members
              </Button>
            </HStack>
          </Flex>
          <Tabs variant="unstyled" mx={8}>
            <TabList borderBottom="1px solid" borderColor="gray.300">
              <Tab _selected={{ color: "primary.500" }} color="gray.600">
                <HStack spacing={2} align="center" display="flex">
                  <PiArticle fontSize='18px'  />
                  <Text fontWeight="500" fontSize="15px">
                    Description
                  </Text>
                </HStack>
              </Tab>
              <Tab _selected={{ color: "primary.500" }} color="gray.600">
                <HStack spacing={2} align="center" display="flex">
                  <PiListChecks fontSize='18px' />
                  <Text fontWeight="500" fontSize="15px">
                    Task List
                  </Text>
                </HStack>
              </Tab>
              <Tab _selected={{ color: "primary.500" }} color="gray.600">
                <HStack spacing={2} align="center" display="flex">
                  <PiKanban fontSize='18px'  />
                  <Text fontWeight="500" fontSize="15px">
                    Task Board
                  </Text>
                </HStack>
              </Tab>
              <Tab _selected={{ color: "primary.500" }} color="gray.600">
                <HStack spacing={2} align="center" display="flex">
                  <PiChartBarHorizontal fontSize='18px'  />
                  <Text fontWeight="500" fontSize="15px">
                    Gantt Chart
                  </Text>
                </HStack>
              </Tab>
            </TabList>

            <TabIndicator mt="-4px" height="3px" bg="primary.500" borderRadius="2px" />

            <Box overflowY="auto" overflowX="hidden" maxHeight="calc(100vh - 232px)">
              <TabPanels>
                <TabPanel>
                  <ProjectsDescription />
                </TabPanel>
                <TabPanel>
                  <ProjectsTasksList onTaskClick={handleTaskClick} onAddTask={handleAddTask} />
                </TabPanel>
                <TabPanel>
                  <ProjectsTasksBoard onTaskClick={handleTaskClick} />
                </TabPanel>
                <TabPanel>
                  <ProjectsTasksGanttChart onTaskClick={handleTaskClick} onAddTask={handleAddTask} />
                </TabPanel>
              </TabPanels>
            </Box>
          </Tabs>
          <CreateTaskModal
            isOpen={isCreateTaskModalOpen}
            onClose={handleCreateTaskModalClose}
            projectId={currentProject?._id}
            parentTaskId={parentTaskId}
            parentTask={parentTask}
          />
          <AssignMembersModal isOpen={isAssignMembersModalOpen} onClose={() => setIsAssignMembersModalOpen(false)} currentProject={currentProject} />
        </>
      ) : (
        <TaskView task={currentTask} />
      )}
    </Box>
  );
};

export default TaskGroups;
