// src/components/MessagesItem.js

import React, { useState, useContext } from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Button,
  PopoverFooter,
  PopoverHeader,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { PiPencilSimpleLine } from "react-icons/pi";
import UserAvatar from "./Reusable/UserAvatar";
import ReusableModal from "../3 - Modal/9 - General Modals/ReusableModal";
import useCustomToast from "../5 - General/Utils/UtilsNotification";
import { MessagesContext } from "../5 - General/Context/MessagesContext";
import PropTypes from "prop-types"; // Import PropTypes

const MessagesItem = React.memo(({ msg, renderMessageContent, formatTimestamp }) => {
  const { handleEditMessage, handleDeleteMessage, currentUser } = useContext(MessagesContext);

  const [isHovered, setIsHovered] = useState(false);
  const [newContent, setNewContent] = useState(msg.content);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editError, setEditError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);

  const { isOpen: isPopoverOpen, onOpen: onPopoverOpen, onClose: onPopoverClose } = useDisclosure();

  const customToast = useCustomToast();

  const messageBgColor = useColorModeValue("white", "gray.700");
  const messageTextColor = useColorModeValue("black", "white");
  const messageBgColorActiveUser = useColorModeValue("primary.500", "primary.900");
  const messageTextColorActiveUser = useColorModeValue("white", "black");

  const isUserMessage = msg.sender?._id === currentUser?._id;

  const confirmDelete = async () => {
    try {
      await handleDeleteMessage(msg._id);
      setIsModalOpen(false);
      onPopoverClose(); // Close the Popover
      customToast({
        status: "success",
        title: "Message Deleted",
        description: "Your message has been deleted.",
      });
    } catch (error) {
      customToast({
        status: "error",
        title: "Delete Failed",
        description: "Failed to delete the message.",
      });
      setDeleteError("Failed to delete the message.");
    }
  };

  const handleSaveEdit = async () => {
    try {
      await handleEditMessage(msg._id, newContent);
      onPopoverClose();
    } catch (error) {
      setEditError("Failed to edit the message.");
    }
  };

  return (
    <Flex
      align="center"
      justifyContent={msg.isSystemMessage ? "center" : isUserMessage ? "flex-end" : "flex-start"}
      mb="1px"
      className="message-container"
      position="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {msg.isSystemMessage ? (
        <Box p={4} bg="transparent" textAlign="center" fontSize="md" color="gray.500">
          {msg.content}
        </Box>
      ) : (
        <>
          {/* Avatar on the left for other users */}
          {!isUserMessage && msg.sender && msg.showAvatarOther ? (
            <UserAvatar userIds={[msg.sender._id]} size="sm" showInfo={false} alignSelf="flex-start" withTooltip={false} />
          ) : (
            !isUserMessage && <Box mr='36px' />
          )}

          <VStack
            align={isUserMessage ? "end" : "start"}
            spacing={1}
            flex="1"
            textAlign="left"
            mr={isUserMessage ? "15px" : "0"}
            ml={!isUserMessage ? "15px" : "0"}
          >
            {/* Display sender's name if showName is true */}
            {msg.showName && (
              <Text fontWeight="bold" fontSize="md" justifySelf="center">
                {msg.sender?.firstName} {msg.sender?.lastName}
              </Text>
            )}

            <HStack spacing={1} alignItems="flex-start" position="relative">
              {/* Edit Button for User's Own Messages */}
              {isUserMessage && !msg.isDeleted && (
                <Popover placement="left" isLazy isOpen={isPopoverOpen} onClose={onPopoverClose}>
                  <PopoverTrigger>
                    <IconButton
                      icon={<PiPencilSimpleLine fontSize="14px" />}
                      aria-label="Edit message"
                      size="xs"
                      variant="ghost"
                      position="absolute"
                      left="-30px"
                      top="50%"
                      transform="translateY(-50%)"
                      opacity={isHovered ? "1" : "0"}
                      transition="opacity 0.2s"
                      onClick={onPopoverOpen}
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader border="none" fontSize="lg" fontWeight="500">
                      Edit Message
                    </PopoverHeader>
                    <PopoverBody>
                      <VStack spacing={2}>
                        <Textarea value={newContent} onChange={(e) => setNewContent(e.target.value)} mb={2} placeholder="Edit your message..." />
                        {editError && <Text color="red.500">{editError}</Text>}
                      </VStack>
                    </PopoverBody>
                    <PopoverFooter textAlign="end">
                      <Button ml="auto" variant="outline" colorScheme="red" mr={2} onClick={() => setIsModalOpen(true)}>
                        Delete message
                      </Button>
                      <Button colorScheme="primary" onClick={handleSaveEdit}>
                        Save
                      </Button>
                    </PopoverFooter>
                  </PopoverContent>
                </Popover>
              )}

              <Box
                p={2}
                bg={isUserMessage ? messageBgColorActiveUser : messageBgColor}
                color={isUserMessage ? messageTextColorActiveUser : messageTextColor}
                borderRadius="xl"
              >
                {renderMessageContent(msg)}
              </Box>
            </HStack> 

            {msg.showDate && (
              <Text fontSize="xs" color="gray.500">
                {formatTimestamp(msg.timestamp)}
              </Text>
            )}
            {deleteError && (
              <Text fontSize="xs" color="red.500">
                {" "}
                {deleteError}{" "}
              </Text>
            )}
          </VStack>

          {/* Avatar on the right for user's own messages */}
          {isUserMessage && msg.sender && msg.showAvatarUser ? (
            <UserAvatar userIds={[msg.sender._id]} size="sm" showInfo={false} alignSelf="flex-start" withTooltip={false} />
          ) : (
            isUserMessage && <Box ml='36px' />
          )}
        </>
      )}

      {/* Confirmation Modal */}
      <ReusableModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Confirm Deletion"
        footerButtons={
          <>
            <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
            <Button colorScheme="red" onClick={confirmDelete}>
              Delete
            </Button>
          </>
        }
      >
        <Text>Are you sure you want to delete this message?</Text>
        {deleteError && <Text color="red.500">{deleteError}</Text>}
      </ReusableModal>
    </Flex>
  );
});

MessagesItem.propTypes = {
  msg: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    sender: PropTypes.shape({
      _id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    content: PropTypes.string,
    isSystemMessage: PropTypes.bool,
    isDeleted: PropTypes.bool,
    showName: PropTypes.bool,
    showAvatarOther: PropTypes.bool,
    showAvatarUser: PropTypes.bool,
    showDate: PropTypes.bool,
    includesFile: PropTypes.bool,
    backendFileName: PropTypes.string,
    originalFileName: PropTypes.string,
    fileSize: PropTypes.number,
    editedAt: PropTypes.string,
    timestamp: PropTypes.string,
  }).isRequired,
  renderMessageContent: PropTypes.func.isRequired,
  formatTimestamp: PropTypes.func.isRequired,
};

export default MessagesItem;
