// CustomRadioCard.jsx
import React from "react";
import { useRadio, Box, Text, Stack, HStack, Icon, useColorModeValue } from "@chakra-ui/react";

/**
 * Custom RadioCard Component
 * This component transforms a Radio input into a stylized button with dynamic colors based on the option's color property.
 *
 * Props:
 * - children: The label text for the radio option.
 * - icon: (Optional) An icon to display alongside the label.
 * - color: The color scheme for the selected state (e.g., 'primary', 'green', 'red').
 * - isDisabled: If true, the radio card is disabled.
 * - ...rest: Any other props to pass to the Radio component.
 */
const RadioCard = (props) => {
  const { children, icon, color, isDisabled, ...rest } = props;
  const { getInputProps, getRadioProps, state } = useRadio({ ...rest, isDisabled });

  const input = getInputProps();
  const radio = getRadioProps();

  const bgColor = useColorModeValue("gray.100", "gray.700");
  const colorUnchecked = useColorModeValue("gray.800", "white");
  const colorChecked = useColorModeValue("white", "white");

  let checkedBgColor;
  switch (color) {
    case "green":
      checkedBgColor = "green.500";
      break;
    case "red":
      checkedBgColor = "red.500";
      break;
    case "yellow":
      checkedBgColor = "yellow.500";
      break;
    case "gray":
      checkedBgColor = "gray.500";
      break;
    case "primary":
    default:
      checkedBgColor = "primary.500";
      break;
  }

  return (
    <Box as="label">
      <input {...input} disabled={isDisabled} />

      <Box
        {...radio}
        cursor={isDisabled ? "not-allowed" : "pointer"}
        borderWidth="1px"
        borderRadius="md"
        boxShadow={state.isChecked ? "md" : "sm"}
        px={2}
        py={1}
        bg={isDisabled ? "gray.300" : state.isChecked ? checkedBgColor : bgColor}
        color={isDisabled ? "gray.500" : state.isChecked ? colorChecked : colorUnchecked}
        _checked={{
          bg: checkedBgColor,
          color: colorChecked,
          borderColor: checkedBgColor,
        }}
        _focus={{
          boxShadow: "none",
        }}
        transition="background-color 0.2s, color 0.2s, box-shadow 0.2s"
        display="flex"
        alignItems="center"
        opacity={isDisabled ? 0.6 : 1}
      >
        {icon && (
          <HStack spacing={1}>
            <Icon fontSize="20px" as={icon} />
            <Text fontSize="md">{children}</Text>
          </HStack>
        )}
        {!icon && <Text fontSize='md'>{children}</Text>}
      </Box>
    </Box>
  );
};

/**
 * CustomRadioCardGroup Component
 * This component renders a group of CustomRadioCard components.
 *
 * Props:
 * - options: An array of objects representing the radio options. Each object should have a 'label', 'value', 'color', and optionally 'isDisabled'.
 * - alignment: The alignment of the radio buttons ('horizontal' or 'vertical').
 * - value: The currently selected value.
 * - onChange: Function to call when the selected value changes.
 */
const CustomRadioCardGroup = ({ options, alignment = "vertical", value, onChange }) => {
  return (
    <Stack direction={alignment === "horizontal" ? "row" : "column"} spacing={4}>
      {options.map((option) => (
        <RadioCard
          key={option.value}
          value={option.value}
          color={option.color}
          isDisabled={option.isDisabled}
          isChecked={value === option.value}
          onChange={() => onChange(option.value)}
          _focus={{ boxShadow: "none" }}
        >
          {option.label}
        </RadioCard>
      ))}
    </Stack>
  );
};

export default CustomRadioCardGroup;
