import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Text,
  Button,
  Input,
  VStack,
  useColorModeValue,
  Container,
  Spinner,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Alert,
  AlertIcon,
  Image,
  Fade,
} from "@chakra-ui/react";
import LoginImg from "../../img/logoBlueText.png";

const ResetPasswordPage = () => {
  const { token } = useParams();
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const bgModalColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const buttonBg = useColorModeValue("blue.500", "blue.300");
  const buttonHover = useColorModeValue("blue.600", "blue.400");

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value.trim() === "") {
      setNewPasswordError("A new password is required");
    } else if (e.target.value.length < 8) {
      setNewPasswordError("Password must be at least 8 characters long");
    } else {
      setNewPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value.trim() === "") {
      setConfirmPasswordError("Please confirm your password");
    } else if (e.target.value !== newPassword) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;

    if (newPassword.trim() === "") {
      setNewPasswordError("A new password is required");
      valid = false;
    } else if (newPassword.length < 8) {
      setNewPasswordError("Password must be at least 8 characters long");
      valid = false;
    }

    if (confirmPassword.trim() === "") {
      setConfirmPasswordError("Please confirm your password");
      valid = false;
    } else if (confirmPassword !== newPassword) {
      setConfirmPasswordError("Passwords do not match");
      valid = false;
    }

    if (valid) {
      setIsLoading(true);
      try {
        const response = await fetch(`${apiUrl}/api/v1/auth/reset-password`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, newPassword }),
        });

        const data = await response.json();

        if (response.ok) {
          setMessage("Your password has been successfully reset.");
          setResetSuccess(true);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          setMessage(data.message || "Error resetting password.");
        }
      } catch (error) {
        console.error("Error:", error);
        setMessage("Error resetting password.");
      }
      setIsLoading(false);
    }
  };

  return (
    <Fade in>
      <Box bg={bgColor} minH="100vh" display="flex" alignItems="center" justifyContent="center" px={4}>
        <Container maxW="md" centerContent py={8} borderRadius="md" boxShadow="2xl" bg={bgModalColor} p={8}>
          <Image src={LoginImg} alt="Logo" w="100px" h="100px" mb={4} />
          <VStack spacing={6} align="center" w="full">
            <Text textAlign="center" fontWeight="bold" fontSize="2xl" size="lg" color={textColor}>
              Set a new password
            </Text>

            {message && (
              <Alert status={resetSuccess ? "success" : "error"} borderRadius="md" w="full">
                <AlertIcon />
                {message}
              </Alert>
            )}

            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <VStack spacing={4} align="stretch">
                <FormControl isInvalid={!!newPasswordError}>
                  <FormLabel htmlFor="newPassword" color={textColor}>
                    New Password
                  </FormLabel>
                  <Input
                    id="newPassword"
                    type="password"
                    placeholder="Enter your new password"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    onBlur={handleNewPasswordChange}
                    isInvalid={!!newPasswordError}
                    required
                  />
                  {newPasswordError && <FormErrorMessage>{newPasswordError}</FormErrorMessage>}
                </FormControl>

                <FormControl isInvalid={!!confirmPasswordError}>
                  <FormLabel htmlFor="confirmPassword" color={textColor}>
                    Confirm Password
                  </FormLabel>
                  <Input
                    id="confirmPassword"
                    type="password"
                    placeholder="Confirm your password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    onBlur={handleConfirmPasswordChange}
                    isInvalid={!!confirmPasswordError}
                    required
                  />
                  {confirmPasswordError && <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>}
                </FormControl>

                <Button type="submit" colorScheme="primary" bg={buttonBg} _hover={{ bg: buttonHover }} isLoading={isLoading} size="lg">
                  {isLoading ? <Spinner size="sm" /> : "Reset Password"}
                </Button>
                {resetSuccess && (
                  <Button type="button" colorScheme="gray" variant="outline" onClick={() => navigate("/login")} size="md">
                    Back to Login
                  </Button>
                )}
              </VStack>
            </form>
          </VStack>
        </Container>
      </Box>
    </Fade>
  );
};

export default ResetPasswordPage;
