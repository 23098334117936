// src/components/3 - Modal/2 - Messages Modals/DeleteChannelModal.js

import React, { useState, useContext } from "react";
import { Button, Input, Text, HStack, useColorModeValue } from "@chakra-ui/react";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { MessagesContext } from "../../5 - General/Context/MessagesContext";

const DeleteChannelModal = ({ isOpen, onClose }) => {
  const { handleDeleteChannel } = useContext(MessagesContext);
  const [confirmationText, setConfirmationText] = useState("");
  const isTypedField = confirmationText === "delete";

  const footerButtons = (
    <HStack spacing={3} w="100%" mt={3}>
      <Button
        colorScheme={isTypedField ? "red" : "gray"}
        onClick={() => {
          if (isTypedField) {
            handleDeleteChannel();
            onClose();
          }
        }}
        isDisabled={!isTypedField}
        w="100%"
      >
        Delete
      </Button>
      <Button onClick={onClose} colorScheme="primary" w="100%">
        Cancel
      </Button>
    </HStack>
  );

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Delete Conversation" footerButtons={footerButtons}>
      <Text mt={5} mb={5} fontSize="md">
        Enter <b>"delete"</b> in the box below to confirm. This action cannot be undone and will permanently delete messages and related files.
      </Text>
      <Input
        type="text"
        value={confirmationText}
        onChange={(e) => setConfirmationText(e.target.value)}
        placeholder="Type 'delete' to confirm"
        borderColor={useColorModeValue("gray.300", "gray.600")}
      />
    </ReusableModal>
  );
};

export default DeleteChannelModal;
