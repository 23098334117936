import React, { useState, useCallback } from "react";
import { Box, Button, Text, Input, useColorModeValue } from "@chakra-ui/react";
import Cropper from "react-easy-crop";
import ReusableModal from "../9 - General Modals/ReusableModal";

const ProfilePictureModal = ({ isOpen, onClose, onCropSubmit, initialPreview }) => {
  const [temporaryPreview, setTemporaryPreview] = useState(initialPreview || null);
  const [cropStep, setCropStep] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const borderColor = useColorModeValue("gray.300", "gray.600");

  const getCroppedImg = (imageSrc, croppedAreaPixels) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
          resolve(blob);
        }, "image/jpeg");
      };
      image.onerror = () => {
        reject(new Error("Failed to load image"));
      };
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTemporaryPreview(URL.createObjectURL(file));
      setCropStep(2);
    }
  };

  const handleCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const handleCropSubmit = async () => {
    try {
      const croppedBlob = await getCroppedImg(temporaryPreview, croppedArea);
      onCropSubmit(croppedBlob); // Pass the Blob to the parent
      onClose();
    } catch (error) {
      console.error("Error cropping image:", error);
      // Optionally, show a toast or error message to the user
    }
  };

  const footerButtons =
    cropStep === 1 ? (
      <>
        <Button variant="ghost" onClick={onClose} border="1px solid" borderColor="gray.200">
          Cancel
        </Button>
        <Button colorScheme="primary" onClick={() => setCropStep(2)}>
          Next
        </Button>
      </>
    ) : (
      <>
        <Button variant="ghost" onClick={() => setCropStep(1)} border="1px solid" borderColor="gray.200">
          Go back
        </Button>
        <Button colorScheme="primary" onClick={handleCropSubmit}>
          Apply
        </Button>
      </>
    );

  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title={cropStep === 1 ? "Upload your profile picture" : "Modify your profile picture"}
      footerButtons={footerButtons}
    >
      {cropStep === 1 ? (
        <Box
          width="100%"
          p={4}
          border="2px dashed"
          borderColor={borderColor}
          borderRadius="md"
          textAlign="center"
          cursor="pointer"
          onClick={() => document.getElementById("fileInput").click()}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            if (file) handleFileChange({ target: { files: [file] } });
          }}
        >
          <Text mb={2}>Drag and drop an image or click to select a file</Text>
          <Input type="file" id="fileInput" accept="image/*" onChange={handleFileChange} display="none" />
        </Box>
      ) : (
        <Box position="relative" width="100%" height="400px">
          <Cropper
            image={temporaryPreview}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={handleCropComplete}
          />
          {/* Optionally, add zoom controls */}
        </Box>
      )}
    </ReusableModal>
  );
};

export default ProfilePictureModal;
