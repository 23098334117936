import React from "react";
import { Box, Text, CircularProgress, CircularProgressLabel, Heading, Button, useColorModeValue } from "@chakra-ui/react";
import PropTypes from "prop-types";

const ProgressCard = ({
  title = "Account Setup",
  percentage = 50,
  description = "You're halfway there! Complete a few more steps to finish setting up your account.",
  onNextSteps = () => alert("Go to settings"),
  bgLight = "white",
  bgDark = "gray.800",
  themeColor = "primary",
}) => {
  const cardBgColor = useColorModeValue(bgLight, bgDark);
  const cardBorderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Box border="1px solid" borderColor={cardBorderColor} borderRadius="md" bg={cardBgColor} p={4} boxShadow="base">
      <Heading as="h3" size="sm" mb={2}>
        {title}
      </Heading>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
        <Box flex="1" mr={4}>
          <Text fontSize="sm">{description}</Text>
        </Box>
        <CircularProgress value={percentage} color={`${themeColor}.500`} size="50px">
          <CircularProgressLabel fontSize="sm">{`${percentage}%`}</CircularProgressLabel>
        </CircularProgress>
      </Box>
      <Button size="xs" colorScheme={themeColor} onClick={onNextSteps}>
        Next Steps
      </Button>
    </Box>
  );
};

ProgressCard.propTypes = {
  title: PropTypes.string,
  percentage: PropTypes.number,
  description: PropTypes.string,
  onNextSteps: PropTypes.func,
  bgLight: PropTypes.string,
  bgDark: PropTypes.string,
  themeColor: PropTypes.string,
};

export default ProgressCard;
