// src/components/3 - Modal/8 - Settings Modals/ModifyRightsModal.js
import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  VStack,
  FormControl,
  FormLabel,
  Flex,
  Icon,
  Text,
  Tooltip,
  HStack,
  Box,
  Spinner,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import ReusableModal from "../9 - General Modals/ReusableModal";
import CustomRadioCard from "../../2 - Components/Reusable/CustomRadioCard";
import { PiChat, PiFiles, PiAlignLeft, PiVideoConference, PiSignature, PiInvoice, PiChartLineUp, PiInfo } from "react-icons/pi";
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";

const ModifyRightsModal = ({ isOpen, onClose, selectedUser }) => {
  const { updateUserMutation } = useContext(WorkspaceContext);
  const [moduleRights, setModuleRights] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedUser && selectedUser.modules) {
      setModuleRights(selectedUser.modules);
    } else {
      setModuleRights({});
    }
  }, [selectedUser]);

  if (!selectedUser) return null;

  const handleChange = (module, value) => {
    setModuleRights((prev) => ({
      ...prev,
      [module]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      updateUserMutation.mutate({ userId: selectedUser._id, updates: { modules: moduleRights } });
      onClose();
    } catch (error) {
      // Error handling is already managed in the mutation hook
    } finally {
      setIsLoading(false);
    }
  };

  // Enhanced accessOptions with color property
  const accessOptions = [
    { label: "Editor Access", value: "Editor Access", color: "primary" },
    { label: "Customer Access", value: "Customer Access", color: "green" },
    { label: "No Access", value: "No Access", color: "red" },
  ];

  // Define the mapping between module names and their respective icons
  const moduleIconMap = {
    messages: PiChat,
    files: PiFiles,
    projects: PiAlignLeft,
    meetings: PiVideoConference,
    contracts: PiSignature,
    billing: PiInvoice,
    reports: PiChartLineUp,
  };

  const moduleTooltipMap = {
    messages:
      "Manage and respond to user messages. Editor Access allows full control, Customer Access allows viewing, and No Access restricts all actions.",
    files:
      "Control access to file management. Editor Access enables uploading and editing files, Customer Access allows viewing, and No Access denies access.",
    projects:
      "Manage projects. Editor Access provides full project management capabilities, Customer Access allows viewing project details, and No Access restricts access.",
    meetings:
      "Manage meetings and schedules. Editor Access can create and modify meetings, Customer Access can view schedules, and No Access denies access.",
    contracts:
      "Handle contracts. Editor Access allows creating and editing contracts, Customer Access permits viewing, and No Access restricts access.",
    billing:
      "Manage billing and invoices. Editor Access can create and modify billing information, Customer Access can view invoices, and No Access denies access.",
    reports:
      "Access and generate reports. Editor Access can create and modify reports, Customer Access can view reports, and No Access restricts access.",
  };

  const footerButtons = (
    <Flex justify="space-between" width="100%">
      <Button variant="outline" onClick={onClose} width="30%">
        Cancel
      </Button>
      <Button type="submit" onClick={handleSubmit} colorScheme="primary" width="30%" isLoading={isLoading}>
        Save
      </Button>
    </Flex>
  );

  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title={`Modify Rights for ${selectedUser.firstName} ${selectedUser.lastName}`}
      footerButtons={footerButtons}
    >
      <VStack spacing={2} as="form" onSubmit={handleSubmit} width="100%">
        {/* Display an alert for admin users */}
        {selectedUser.role === "Admin" && (
          <Alert status="info" borderRadius="md">
            <AlertIcon />
            <AlertDescription>
              Admin users must have all modules set to Editor. To modify module rights, please change the role of the user to <b>Customer</b> or{" "}
              <b>Editor</b>.
            </AlertDescription>
          </Alert>
        )}

        {Object.keys(moduleRights).length > 0 ? (
          Object.keys(moduleRights).map((module) => {
            const ModuleIcon = moduleIconMap[module.toLowerCase()];
            const moduleTooltip = moduleTooltipMap[module.toLowerCase()];

            return (
              <FormControl
                as="fieldset"
                key={module}
                mb={2}
                border="1px solid"
                borderColor="gray.300"
                px={4}
                pb={3}
                borderRadius="xl"
                isDisabled={selectedUser.role === "Admin"} // Disable for admin users
              >
                <FormLabel as="legend" display="flex" alignItems="center" mb={2}>
                  {ModuleIcon && <Icon as={ModuleIcon} fontSize="24px" mr={2} color="primary.500" mx={2} aria-hidden="true" />}
                  <HStack spacing={1}>
                    <Text fontSize="lg" fontWeight="600">
                      {module.charAt(0).toUpperCase() + module.slice(1)}
                    </Text>
                    {moduleTooltip && (
                      <Tooltip label={moduleTooltip} aria-label={`${module} tooltip`} hasArrow placement="top" fontSize="md">
                        <Box>
                          <PiInfo fontSize="18px" color="gray.500" cursor="pointer" />
                        </Box>
                      </Tooltip>
                    )}
                  </HStack>
                </FormLabel>
                <CustomRadioCard
                  options={accessOptions}
                  alignment="horizontal"
                  value={moduleRights[module]}
                  onChange={(value) => handleChange(module, value)}
                />
              </FormControl>
            );
          })
        ) : (
          <Flex align="center" justify="center" w="100%" py={10}>
            <Spinner />
          </Flex>
        )}
      </VStack>
    </ReusableModal>
  );
};

export default ModifyRightsModal;
