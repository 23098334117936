// client\src\components\2 - Components\ProjectsViewTaskView\ProjectsTaskViewTabsSubTasks.js
import React, { useContext } from "react";
import { Box, Tag, Text, Flex, useColorModeValue } from "@chakra-ui/react";
import { formatDueDate } from "../../5 - General/Utils/UtilsFormatData";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import UserAvatar from "../Reusable/UserAvatar";
import TagMenu from "../Reusable/TagMenu";

const SubtaskSection = () => {
  const { currentTask, subtasks, editSubtask, loadingSubtasks, setCurrentTask } = useContext(ProjectContext);
  const currentSubtasks = subtasks[currentTask?._id] || [];

  const borderColor = useColorModeValue("gray.200", "gray.600");
  const bgColor = useColorModeValue("white", "gray.800");
  const hoverBg = useColorModeValue("gray.50", "gray.700");

  const statusOptions = [
    { value: "Not Started", label: "Not Started", color: "gray" },
    { value: "In Progress", label: "In Progress", color: "primary" },
    { value: "Completed", label: "Completed", color: "green" },
    { value: "On Hold", label: "On Hold", color: "orange" },
  ];

  const priorityOptions = [
    { value: "High", label: "High", color: "red" },
    { value: "Medium", label: "Medium", color: "yellow" },
    { value: "Low", label: "Low", color: "green" },
  ];

  const onSubtaskClick = (subtask) => {
    setCurrentTask(subtask);
  };
  const onStatusChange = (subtaskId, field, value) => {
    editSubtask(subtaskId, { [field]: value });
  };

  const onPriorityChange = (subtaskId, field, value) => {
    editSubtask(subtaskId, { [field]: value });
  };

  const getDueDateColorScheme = (dueDate) => {
    if (!dueDate) return "gray"; // Undefined date
    const now = new Date();
    const due = new Date(dueDate);
    const diffDays = Math.ceil((due - now) / (1000 * 60 * 60 * 24));

    if (diffDays < 0) return "red"; // Overdue
    if (diffDays < 3) return "yellow"; // Due in less than 3 days
    if (diffDays < 10) return "green"; // Due in less than 10 days
    return "gray"; // Due in 10 or more days
  };

  return (
    <Box>
      {/* Subtask Headers */}
      {currentSubtasks.length > 0 && (
        <Box
          display="grid"
          gridTemplateColumns={"8fr 2fr 2fr 2fr 2fr"}
          bg="gray.100"
          p={4}
          borderBottom="1px solid"
          borderColor={borderColor}
          pl="40px"
        >
          <Box>
            <Flex alignItems="center" fontWeight="600" fontSize="16px">
              Subtasks
            </Flex>
          </Box>
          <Box>
            <Flex alignItems="center" fontWeight="600" fontSize="16px">
              Due Date
            </Flex>
          </Box>
          <Box>
            <Flex alignItems="center" fontWeight="600" fontSize="16px">
              Status
            </Flex>
          </Box>
          <Box>
            <Flex alignItems="center" fontWeight="600" fontSize="16px">
              Priority
            </Flex>
          </Box>
          <Box>
            <Flex alignItems="center" fontWeight="600" fontSize="16px">
              Assigned To
            </Flex>
          </Box>
        </Box>
      )}

      {/* Subtasks List */}
      {currentSubtasks.length > 0
        ? currentSubtasks.map((subtask) => (
            <Box
              key={subtask._id}
              display="grid"
              gridTemplateColumns={"8fr 2fr 2fr 2fr 2fr"}
              bg={bgColor}
              _hover={{ backgroundColor: hoverBg }}
              transition="background-color 0.2s"
              alignItems="center"
              cursor="pointer"
              borderBottom="1px solid"
              borderColor={borderColor}
              onClick={() => onSubtaskClick(subtask)}
              py="10px"
              pl={4}
            >
              {/* Subtask Name */}
              <Box display="flex" alignItems="center">
                <Box w="10px" h="30px" bg={subtask.color} mr={4} borderRadius="md" />
                <Text fontWeight="400" fontSize="lg" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                  {subtask.name}
                </Text>
              </Box>

              {/* Due Date */}
              <Box>
                <Tag size="lg" borderRadius="full" colorScheme={getDueDateColorScheme(subtask.dueDate)}>
                  {subtask.dueDate
                    ? new Date(subtask.dueDate) < new Date()
                      ? `Overdue by ${Math.abs(Math.ceil((new Date() - new Date(subtask.dueDate)) / (1000 * 60 * 60 * 24)))} day${
                          Math.abs(Math.ceil((new Date() - new Date(subtask.dueDate)) / (1000 * 60 * 60 * 24))) > 1 ? "s" : ""
                        }`
                      : formatDueDate(subtask.dueDate)
                    : "Undefined"}
                </Tag>
              </Box>

              {/* Status */}
              <Box>
                <TagMenu
                  options={statusOptions}
                  selectedValue={subtask.status}
                  field="status"
                  handleBlurSave={onStatusChange}
                  subtaskId={subtask._id}
                />
              </Box>

              {/* Priority */}
              <Box>
                <TagMenu
                  options={priorityOptions}
                  selectedValue={subtask.priority}
                  field="priority"
                  handleBlurSave={onPriorityChange}
                  subtaskId={subtask._id}
                />
              </Box>

              {/* Assigned To */}
              <Box>
                <UserAvatar userIds={subtask.assignedTo} size="sm" useGroup={true} maxAvatars={3} spacing={-1} />
              </Box>
            </Box>
          ))
        : !loadingSubtasks && (
            <Text mt={4} color="gray.500" pl={4}>
              No subtasks available for this task.
            </Text>
          )}
      {/* Loading Indicator */}
      {loadingSubtasks && (
        <Text mt={4} color="gray.500" pl={4}>
          Loading subtasks...
        </Text>
      )}
    </Box>
  );
};

export default SubtaskSection;
