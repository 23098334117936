// client/src/components/ProjectCards.js
import React, { useState, useContext, useEffect } from "react";
import {
  HStack,
  Flex,
  Box,
  Text,
  IconButton,
  Button,
  Menu,
  Portal,
  MenuButton,
  MenuList,
  MenuItem,
  InputGroup,
  InputLeftElement,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Spinner,
  useColorModeValue,
  Tag,
  Tooltip,
  Progress,
} from "@chakra-ui/react";
import {
  PiPencilSimpleLine,
  PiTrash,
  PiDotsThreeBold,
  PiPlus,
  PiFunnel,
  PiCalendar,
  PiArchive,
  PiMagnifyingGlass,
  PiGridFour,
} from "react-icons/pi";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import EditProjectModal from "../3 - Modal/3 - Projects Modals/ProjectsEditProjectModal";
import useCustomToast from "../5 - General/Utils/UtilsNotification";
import CreateProjectModal from "../3 - Modal/3 - Projects Modals/ProjectsCreateProjectModal";
import { formatDistanceToNow } from "date-fns";
import UserAvatar from "../2 - Components/Reusable/UserAvatar";
import ProjectsTimeline from "./ProjectsTimeline";

const ProjectCards = ({ loadingProjects }) => {
  const {
    activeProjects,
    archivedProjects,
    fetchArchivedProjectsForCompany,
    archivedProjectsFetched,
    currentCompany,
    deleteProject,
    archiveProject,
    setCurrentProject,
    fetchUsersFromCompany,
  } = useContext(ProjectContext);

  // States
  // eslint-disable-next-line no-unused-vars
  const [companyUsers, setCompanyUsers] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [viewMode, setViewMode] = useState("grid"); // "grid" or "timeline"

  // Hooks
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const customToast = useCustomToast();

  // Refs
  const cancelRef = React.useRef();

  // Colors
  const bgCardColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const menuBgColor = useColorModeValue("white", "gray.700");

  // New state to check if there are any projects at all
  const hasProjects = activeProjects.length + archivedProjects.length > 0;

  useEffect(() => {
    if (showArchived && !archivedProjectsFetched) {
      fetchArchivedProjectsForCompany(currentCompany._id);
    }
  }, [showArchived, archivedProjectsFetched, fetchArchivedProjectsForCompany, currentCompany]);

  useEffect(() => {
    if (showArchived) {
      setFilteredProjects([...activeProjects, ...archivedProjects]);
    } else {
      setFilteredProjects(activeProjects);
    }
  }, [activeProjects, archivedProjects, showArchived]);

  const toggleArchived = () => setShowArchived((prev) => !prev);

  useEffect(() => {
    if (currentCompany) {
      fetchUsersFromCompany(currentCompany._id)
        .then((users) => setCompanyUsers(users))
        .catch((error) => {
          console.error("Error fetching users from company:", error);
        });
    }
  }, [currentCompany, fetchUsersFromCompany]);

  const confirmDeleteProject = () => {
    deleteProject(selectedProject._id)
      .then(() => {
        customToast({
          title: "Success",
          description: "Project deleted successfully.",
          status: "success",
        });
      })
      .catch((error) => {
        customToast({
          title: "Error",
          description: "Failed to delete project.",
          status: "error",
        });
      });
    onDeleteClose();
  };

  const handleArchiveProject = (project) => {
    archiveProject(project._id)
      .then(() => {
        customToast({
          title: "Success",
          description: project.archived ? "Project unarchived successfully." : "Project archived successfully.",
          status: "success",
        });
        // State update is handled in the context
      })
      .catch((error) => {
        customToast({
          title: "Error",
          description: "Failed to update project archive status.",
          status: "error",
        });
      });
  };

  // Filter projects based on search term and status filter
  const filteredList = filteredProjects.filter((project) => {
    const matchesSearchTerm = project.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = filterStatus ? project.status === filterStatus : true;
    return matchesSearchTerm && matchesStatus;
  });

  const statusColorScheme = (status) => {
    switch (status) {
      case "Not Started":
        return "gray";
      case "In Progress":
        return "primary";
      case "Completed":
        return "green";
      case "On Hold":
        return "yellow";
      default:
        return "gray";
    }
  };

  const handleViewToggle = () => {
    setViewMode(viewMode === "grid" ? "timeline" : "grid");
  };

  const onProjectClick = (project) => {
    setCurrentProject(project);
  };

  if (loadingProjects) {
    return (
      <Flex justify="center" align="center" w="100%" height="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Box m={4} mx={8} position="relative" flexGrow="1" width="auto" overflow="hidden">
      {/* Always display filters and search inputs */}
      <Flex mb={4} justify="space-between" align="center" flexWrap="wrap">
        <HStack p={1} spacing={4}>
          <InputGroup maxW="300px">
            <InputLeftElement pointerEvents="none">
              <PiMagnifyingGlass fontSize='18px' color="gray.400" />
            </InputLeftElement>
            <Input placeholder="Search projects..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </InputGroup>

          <Menu>
            <MenuButton as={Button} rightIcon={<PiFunnel  fontSize='18px' />} flexShrink={0}>
              Filters
            </MenuButton>
            <Portal>
              <MenuList>
                <MenuItem onClick={() => setFilterStatus("")}>
                  <HStack>
                    <Text>All Projects</Text>
                  </HStack>
                </MenuItem>
                <MenuItem onClick={() => setFilterStatus("Not Started")}>
                  <HStack>
                    <Tag colorScheme="gray" size="lg" borderRadius="full" px={2}>
                      Not Started
                    </Tag>
                  </HStack>
                </MenuItem>
                <MenuItem onClick={() => setFilterStatus("In Progress")}>
                  <HStack>
                    <Tag colorScheme="primary" size="lg" borderRadius="full" px={2}>
                      In Progress
                    </Tag>
                  </HStack>
                </MenuItem>
                <MenuItem onClick={() => setFilterStatus("Completed")}>
                  <HStack>
                    <Tag colorScheme="green" size="lg" borderRadius="full" px={2}>
                      Completed
                    </Tag>
                  </HStack>
                </MenuItem>
                <MenuItem onClick={() => setFilterStatus("On Hold")}>
                  <HStack>
                    <Tag colorScheme="yellow" size="lg" borderRadius="full" px={2}>
                      On Hold
                    </Tag>
                  </HStack>
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </HStack>
        <HStack spacing={2}>
          <Button onClick={toggleArchived} size="sm" variant="ghost" flexShrink={0}>
            {showArchived ? "Show Active Projects" : "Show Archived Projects"}
          </Button>

          <Tooltip label={viewMode === "grid" ? "Switch to Timeline View" : "Switch to Grid View"} aria-label="View Toggle Tooltip">
            <IconButton
              icon={viewMode === "grid" ? <PiCalendar  fontSize='18px' /> : <PiGridFour  fontSize='18px' />}
              onClick={handleViewToggle}
              aria-label="Toggle View"
              variant="outline"
            />
          </Tooltip>
          <Button onClick={onOpen} colorScheme="primary" leftIcon={<PiPlus  fontSize='18px' />}>
            Create Project
          </Button>
        </HStack>
      </Flex>

      {/* Conditional rendering based on whether there are projects */}
      {filteredList.length === 0 ? (
        hasProjects ? (
          // There are projects, but none match the filters
          <Flex justify="center" align="center" height="100%">
            <Text fontSize="xl" color="gray.500" mb="500px">
              No projects match your search or filters.
            </Text>
          </Flex>
        ) : (
          // No projects at all for the company
          <Flex justify="center" width="100%">
            <Box
              p={8}
              border="1px solid"
              bg="gray.50"
              borderColor={borderColor}
              borderRadius="xl"
              textAlign="center"
              boxShadow="md"
              h="fit-content"
              width={["90%", "70%", "50%"]}
              mt={12}
            >
              <Text fontSize="2xl" fontWeight="bold">
                No projects found for {currentCompany?.companyName || "this company"}.
              </Text>
              <Text mt={2} color="gray.500">
                Create a new project to get started.
              </Text>
              <Button mt={4} colorScheme="primary" onClick={onOpen}>
                Create Project
              </Button>
            </Box>
            {/* Create Project Modal */}
            <CreateProjectModal isOpen={isOpen} onClose={onClose} />
          </Flex>
        )
      ) : (
        <>
          {viewMode === "grid" ? (
            // Grid View (remains unchanged)
            <Flex
              justify="flex-start"
              align="flex-start"
              pb={8}
              height="100%"
              overflowY="auto"
              css={{
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "rgba(0, 0, 0, 0.3)",
                  borderRadius: "10px",
                },
              }}
            >
              <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6} width="100%">
                {filteredList.map((project) => {
                  const isArchived = project.archived;
                  const completedTasks = project.completedTasks || 0;
                  const totalTasks = project.totalTasks || 0;
                  const progress = totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;

                  return (
                    <Box
                      p={6}
                      border="1px solid"
                      bg={isArchived ? "gray.200" : bgCardColor}
                      borderColor={isArchived ? "gray.400" : borderColor}
                      borderRadius="xl"
                      key={project._id + "grid"}
                      boxShadow="base"
                      _hover={{
                        boxShadow: "md",
                        cursor: isArchived ? "not-allowed" : "pointer",
                      }}
                      transition={"all 0.3s"}
                      onClick={() => !isArchived && setCurrentProject(project)}
                    >
                      <Flex justify="space-between" align="start" pb={3}>
                        {/* Content with conditional opacity */}
                        <Box opacity={isArchived ? 0.6 : 1}>
                          <Text fontWeight="bold" isTruncated maxWidth="240px" fontSize="lg" color={isArchived ? "gray.500" : "inherit"}>
                            {project.name || "Unnamed Project"}
                          </Text>
                          <Tag
                            size="lg"
                            colorScheme={isArchived ? "gray" : statusColorScheme(project.status)}
                            borderRadius="full"
                            px={2}
                            py={1}
                            mt={1}
                          >
                            {project.status || "No Status"}
                          </Tag>
                          <Text fontSize="sm" color="gray.500" mt={2}>
                            Last Update:{" "}
                            {formatDistanceToNow(new Date(project.updatedAt), {
                              addSuffix: true,
                            })}
                          </Text>
                          <Text fontSize="sm" color="gray.500" mt={1}>
                            End Date: {project.endDate ? new Date(project.endDate).toLocaleDateString() : "No deadline"}
                          </Text>
                        </Box>

                        {/* Action Menu without affected opacity */}
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<PiDotsThreeBold fontSize='18px' />}
                            variant="ghost"
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            aria-label="Options"
                          />
                          <MenuList bg={menuBgColor}>
                            <MenuItem
                              icon={<PiPencilSimpleLine fontSize='18px' />}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedProject(project);
                                onEditOpen();
                              }}
                              isDisabled={isArchived}
                            >
                              Edit Project
                            </MenuItem>
                            <MenuItem
                              icon={<PiArchive fontSize='18px' />}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleArchiveProject(project);
                              }}
                            >
                              {project.archived ? "Unarchive Project" : "Archive Project"}
                            </MenuItem>
                            <MenuItem
                              icon={<PiTrash fontSize='18px' />}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedProject(project);
                                onDeleteOpen();
                              }}
                            >
                              Delete Project
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Flex>

                      {/* Project Info */}
                      <Box opacity={isArchived ? 0.6 : 1}>
                        <Progress value={progress} size="sm" colorScheme="primary" borderRadius="lg" bg={isArchived ? "gray.300" : undefined} />
                        <Flex mt={2} justify="space-between" align="center">
                          <Text fontSize="sm" color="gray.600">
                            Progress: {progress}%
                          </Text>
                          <UserAvatar
                            userIds={project.members}
                            size="sm"
                            useGroup={true}
                            maxAvatars={3}
                            spacing={-5}
                            opacity={isArchived ? 0.5 : 1}
                          />
                        </Flex>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Flex>
          ) : (
            // Timeline View
            <Box
              pb={8}
              height="100%"
              overflowY="auto"
              css={{
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "rgba(0, 0, 0, 0.3)",
                  borderRadius: "10px",
                },
              }}
            >
              <ProjectsTimeline onProjectClick={onProjectClick} />
            </Box>
          )}
        </>
      )}

      {/* Create Project Modal */}
      <CreateProjectModal isOpen={isOpen} onClose={onClose} />

      {/* Edit Project Modal */}
      {selectedProject && <EditProjectModal isOpen={isEditOpen} onClose={onEditClose} project={selectedProject} />}

      {/* Delete Confirmation Alert */}
      <AlertDialog isOpen={isDeleteOpen} leastDestructiveRef={cancelRef} onClose={onDeleteClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Project
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text>Are you sure you want to delete the project:</Text>
              <Text fontWeight="bold" mt={2} mb={2}>
                {selectedProject?.name || "this project"}?
              </Text>
              <Text>This action cannot be undone.</Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDeleteProject} ml={3}>
                Delete Project
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ProjectCards;
