// client/src/components/5 - General/Hooks/useCompanyAvatarSocketQuery.js
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSocket } from "../Socket/SocketContext";
import { queryKeys } from "../../../queryKeys";
import useCustomToast from "../Utils/UtilsNotification";

export const useCompanyAvatarSocketQuery = (companyIds) => {
  const { socket, connected } = useSocket();
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  return useQuery({
    queryKey: queryKeys.companyAvatar(companyIds),
    queryFn: () =>
      new Promise((resolve, reject) => {
        if (!socket) {
          console.error("Socket is not available.");
          return reject(new Error("Socket is not available."));
        }

        // Set a timeout for the response
        const timeout = setTimeout(() => {
          reject(new Error("Request timed out"));
        }, 2000);

        socket.emit("getCompanyAvatar", { companyIds }, (response) => {
          clearTimeout(timeout);
          if (response.status === "ok") {
            resolve(response.companies);
          } else {
            reject(new Error(response.message || "Failed to fetch company data"));
          }
        });
      }),
    enabled: connected && companyIds && companyIds.length > 0,
    initialData: () => {
      const data = queryClient.getQueryData(queryKeys.companyAvatar(companyIds));
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(queryKeys.companyAvatar(companyIds), data);
    },
    onError: (error) => {
      console.error("Error fetching company avatars:", error);
      customToast({
        title: "Error",
        description: error.message || "Failed to fetch company avatars.",
        status: "error",
      });
    },
    retry: 3,
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000),
  });
};
