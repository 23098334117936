import React, { useState, useContext } from "react";
import { Editor, EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Flex, Text, Button, Box, HStack } from "@chakra-ui/react";
import { PiFloppyDiskBackFill, PiPencilSimpleLine, PiXBold } from "react-icons/pi";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";

const ProjectsDescription = () => {
  const { currentProject, editProject } = useContext(ProjectContext);
  const initialContentState = ContentState.createFromBlockArray(
    convertFromHTML(currentProject.description || "")
  );
  const [editorState, setEditorState] = useState(EditorState.createWithContent(initialContentState));
  const [isEditing, setIsEditing] = useState(false);

  const handleSaveDescription = () => {
    const htmlDescription = stateToHTML(editorState.getCurrentContent());
    editProject(currentProject._id, { description: htmlDescription });
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setEditorState(EditorState.createWithContent(initialContentState));
    setIsEditing(false);
  };

  return (
    <Flex mx={4} my={8} p={4} borderWidth="1px" borderColor="gray.200" borderRadius="xl" bg="gray.50" flexDir="column">
      <Flex justifyContent="space-between" align="center" mb={2}>
        <Text h="32px" fontWeight="400">Project Description</Text>
        {isEditing ? (
          <HStack>
            <Button leftIcon={<PiXBold fontSize="18px" />} aria-label="Cancel edit" colorScheme="gray" size="sm" onClick={handleCancelEdit}>
              Cancel
            </Button>
            <Button w="fit-content" leftIcon={<PiFloppyDiskBackFill fontSize="18px" />} colorScheme="primary" onClick={handleSaveDescription}>
              Save Description
            </Button>
          </HStack>
        ) : (
          <Button
            alignSelf="end"
            w="fit-content"
            leftIcon={<PiPencilSimpleLine fontSize="18px" />}
            aria-label="Edit description"
            colorScheme="primary"
            size="sm"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        )}
      </Flex>

      {isEditing ? (
        <Box mt={4} bg="white" border="1px solid" borderColor="gray.200" borderRadius="lg" p={2}>
          <Editor editorState={editorState} onChange={setEditorState} />
        </Box>
      ) : (
        <Box
          bg="white"
          p={4}
          borderRadius="xl"
          border="1px solid"
          borderColor="gray.200"
          boxShadow="base"
          style={{ lineHeight: "1.6", whiteSpace: "pre-wrap", fontSize: "16px" }}
        >
          <div dangerouslySetInnerHTML={{ __html: currentProject.description }} />
        </Box>
      )}
    </Flex>
  );
};

export default ProjectsDescription;
