import React, { useState, useContext } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  IconButton,
  Divider,
  Textarea,
  FormControl,
  FormLabel,
  Input,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
  PopoverCloseButton,
  useDisclosure,
  useColorModeValue,
  Fade,
  Stack,
  Flex,
} from "@chakra-ui/react";
import UserAvatar from "../Reusable/UserAvatar";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import { formatReadableDate } from "../../5 - General/Utils/UtilsFormatData";
import { PiClockUser, PiPencilSimpleLine, PiTrash } from "react-icons/pi";

const TimeLogSection = () => {
  const { currentTask, logTime, editTimeLog, deleteTimeLog } = useContext(ProjectContext);
  const [timeLog, setTimeLog] = useState({ hours: "", description: "" });
  const [currentLog, setCurrentLog] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const customToast = useCustomToast();

  const handleAddTimeLog = async () => {
    const { hours, description } = timeLog;
    const timeSpent = parseFloat(hours);
    if (isNaN(timeSpent) || !description.trim()) {
      customToast({
        title: "Invalid input.",
        description: "'Hours' must be a number and 'Description' is required.",
        status: "error",
      });
      return;
    }
    try {
      await logTime(currentTask._id, { timeSpent, description: description.trim() });
      setTimeLog({ hours: "", description: "" });
      customToast({
        title: "Time log added.",
        description: "Your time has been logged successfully.",
        status: "success",
      });
    } catch (error) {
      console.error("Error adding time log:", error);
      customToast({
        title: "Error.",
        description: "There was an error adding your time log.",
        status: "error",
      });
    }
  };

  const handleEditTimeLog = async () => {
    if (!currentLog || !currentLog.timeSpent || !currentLog.description) return;
    try {
      await editTimeLog(currentTask._id, currentLog._id, {
        timeSpent: parseFloat(currentLog.timeSpent),
        description: currentLog.description.trim(),
      });
      setCurrentLog(null);
      onClose();
      customToast({
        title: "Time log updated.",
        description: "Your time log has been updated successfully.",
        status: "success",
      });
    } catch (error) {
      console.error("Error editing time log:", error);
      customToast({
        title: "Error.",
        description: "There was an error updating your time log.",
        status: "error",
      });
    }
  };

  const handleDeleteTimeLog = async (timeLogId) => {
    try {
      await deleteTimeLog(currentTask._id, timeLogId);
      customToast({
        title: "Time log deleted.",
        description: "The time log has been removed.",
        status: "info",
      });
    } catch (error) {
      console.error("Error deleting time log:", error);
      customToast({
        title: "Error.",
        description: "There was an error deleting your time log.",
        status: "error",
      });
    }
  };

  // Color modes
  const bgColor = useColorModeValue("white", "gray.700");
  const formBg = useColorModeValue("gray.50", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const buttonColor = useColorModeValue("blue.500", "blue.300");

  return (
    <>
      {/* Time Log Form */}
      <Flex mx={4} my={8} p={4} borderWidth="1px" borderColor="gray.200" borderRadius="xl" bg="gray.50" flexDir="column">
        <VStack spacing={4} align="stretch">
          <FormControl isRequired>
            <Text fontWeight="400" mb={4}>
              Time Logs
            </Text>
            <Input
              type="number"
              placeholder="Enter hours"
              value={timeLog.hours}
              onChange={(e) => setTimeLog({ ...timeLog, hours: e.target.value })}
              focusBorderColor="blue.400"
            />
          </FormControl>
          <FormControl isRequired>
            <Textarea
              placeholder="Enter description"
              value={timeLog.description}
              onChange={(e) => setTimeLog({ ...timeLog, description: e.target.value })}
              resize="vertical"
              focusBorderColor="blue.400"
              borderRadius="xl"
            />
          </FormControl>
          <HStack justifyContent="flex-end">
            <Button
              colorScheme="blue"
              leftIcon={<PiClockUser fontSize="18px" />}
              onClick={handleAddTimeLog}
              isDisabled={!timeLog.hours || !timeLog.description}
            >
              Add Time Log
            </Button>
          </HStack>
        </VStack>
      </Flex>

      {/* Time Logs List */}
      <VStack spacing={6} align="stretch">
        {currentTask?.timeLogs?.length > 0 ? (
          currentTask.timeLogs.map((log, idx) => (
            <Box key={log._id || idx} p={5} boxShadow="base" border="1px" borderColor={borderColor} borderRadius="xl" bg={bgColor}>
              <HStack justifyContent="space-between" mb={3}>
                <HStack spacing={4}>
                  <UserAvatar userIds={[log.userId]} size="md" showInfo />
                  <VStack align="flex-start" spacing={0}>
                    <HStack spacing={1} alignItems="center">
                      <PiClockUser fontSize="18px" color={buttonColor} />
                      <Text fontSize="lg" fontWeight="600" color="gray.800">
                        {log.timeSpent} Hours
                      </Text>
                    </HStack>
                    <Text fontSize="sm" color="gray.500">
                      {formatReadableDate(new Date(log.date).toLocaleString()) + " à " + new Date(log.date).toLocaleTimeString()}
                    </Text>
                  </VStack>
                </HStack>
                <HStack spacing={2}>
                  {/* Edit Button with Tooltip */}
                  <Popover
                    isOpen={currentLog?.index === idx && isOpen}
                    onOpen={() => {
                      setCurrentLog({ ...log, index: idx });
                      onOpen();
                    }}
                    onClose={() => {
                      setCurrentLog(null);
                      onClose();
                    }}
                    placement="auto"
                    closeOnBlur={false}
                  >
                    <PopoverTrigger p={0}>
                      <IconButton icon={<PiPencilSimpleLine fontSize="18px" />} size="sm" colorScheme="primary" variant='ghost' aria-label="Edit Time Log" />
                    </PopoverTrigger>
                    <PopoverContent p={4} borderRadius="lg" boxShadow="lg" w="500px">
                      <PopoverCloseButton />
                      <PopoverHeader fontWeight="bold" mb={2}>
                        Edit Time Log
                      </PopoverHeader>
                      <PopoverBody>
                        <Stack spacing={4}>
                          <FormControl isRequired>
                            <FormLabel>Hours</FormLabel>
                            <Input
                              type="number"
                              value={currentLog?.timeSpent || ""}
                              onChange={(e) => setCurrentLog({ ...currentLog, timeSpent: e.target.value })}
                            />
                          </FormControl>
                          <FormControl isRequired>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                              value={currentLog?.description || ""}
                              onChange={(e) => setCurrentLog({ ...currentLog, description: e.target.value })}
                              borderRadius="xl"
                              resize="vertical"
                              focusBorderColor="primary.500"
                              minHeight="200px"
                            />
                          </FormControl>
                        </Stack>
                      </PopoverBody>
                      <PopoverFooter d="flex" justifyContent="flex-end">
                        <Button variant="outline" mr={3} onClick={onClose}>
                          Cancel
                        </Button>
                        <Button colorScheme="primary" onClick={handleEditTimeLog}>
                          Save
                        </Button>
                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>

                  {/* Delete Button with Tooltip and Confirmation */}
                  <IconButton
                    icon={<PiTrash fontSize="18px" />}
                    size="sm"
                    colorScheme="gray"
                    variant="ghost"
                    aria-label="Delete Time Log"
                    onClick={() => handleDeleteTimeLog(log._id)}
                  />
                </HStack>
              </HStack>
              <Divider />
              <Fade in>
                <Box mt={3}>
                  <Text fontSize="md" color="gray.700">
                    {log.description}
                  </Text>
                </Box>
              </Fade>
            </Box>
          ))
        ) : (
          <Box p={6} textAlign="center" bg={formBg} borderRadius="2xl" boxShadow="md">
            <Text fontSize="lg" color="gray.500">
              No time logs yet. Start by adding a new time log above!
            </Text>
          </Box>
        )}
      </VStack>
    </>
  );
};

export default TimeLogSection;
