import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Button,
  HStack,
  VStack,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  TabIndicator,
} from "@chakra-ui/react";
import LazyTabPanel from "./Reusable/LazyTabPanel";
import ModularTable from "./Reusable/ModularTable";
import { tableConfigs } from "./Reusable/TableConfig";
import CustomersFormModal from "../3 - Modal/10 - Customers Modals/CustomerFormModal";
import CompanyFormModal from "../3 - Modal/10 - Customers Modals/CompanyFormModal";
import CsvUploadModal from "../3 - Modal/9 - General Modals/CsvUploadModal";
import CsvDownloadModal from "../3 - Modal/9 - General Modals/CsvDownloadModal";
import { PiCaretDownBold, PiPaperPlaneTilt, PiPlus } from "react-icons/pi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchUsersCRM } from "../4 - API/API-Users";
import { fetchCompaniesCRM } from "../4 - API/API-Company";
import { useDeleteUser } from "../5 - General/Hooks/useUpdateUser";
import { useDeleteCompany } from "../5 - General/Hooks/useUpdateCompany";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";
import { queryKeys } from "../../queryKeys";
import useCustomToast from "../5 - General/Utils/UtilsNotification";

// Debounce hook
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

const Customers = () => {
  const customToast = useCustomToast();
  const queryClient = useQueryClient();
  const deleteCompanyMutation = useDeleteCompany();
  const deleteUserMutation = useDeleteUser();
  const { currentUserCompanyId } = useContext(WorkspaceContext);
  const [tabIndex, setTabIndex] = useState(0);
  const [currentItem, setCurrentItem] = useState(null);

  // State for Users
  const [userSearch, setUserSearch] = useState("");
  const debouncedUserSearch = useDebounce(userSearch, 1000);
  const [userPage, setUserPage] = useState(1);
  const USERS_PER_PAGE = 10;
  const [userSearchColumns, setUserSearchColumns] = useState(["firstName", "lastName", "email"]);
  const { isOpen: isUserModalOpen, onOpen: onUserModalOpen, onClose: onUserModalClose } = useDisclosure();

  // State for Companies
  const [companySearch, setCompanySearch] = useState("");
  const debouncedCompanySearch = useDebounce(companySearch, 1000);
  const [companyPage, setCompanyPage] = useState(1);
  const COMPANIES_PER_PAGE = 10;
  const [companySearchColumns, setCompanySearchColumns] = useState(["companyName", "companyRegistrationNumber"]);
  const { isOpen: isCompanyModalOpen, onOpen: onCompanyModalOpen, onClose: onCompanyModalClose } = useDisclosure();

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const borderColor = useColorModeValue("gray.300", "gray.700");
  const textColor = useColorModeValue("gray.900", "gray.100");
  const tabIndicatorColor = useColorModeValue("primary.500", "primary.300");

  // Fetch Users
  const {
    data: userData,
    isLoading: isLoadingUsers,
    error: usersError,
  } = useQuery({
    queryKey: queryKeys.users(currentUserCompanyId, debouncedUserSearch, userSearchColumns, userPage, USERS_PER_PAGE),
    queryFn: () =>
      fetchUsersCRM({
        companyId: currentUserCompanyId,
        search: debouncedUserSearch,
        searchColumns: userSearchColumns,
        page: userPage,
        limit: USERS_PER_PAGE,
      }),
    enabled: Boolean(currentUserCompanyId) && tabIndex === 0,
    keepPreviousData: true,
  });

  const users = userData?.users || [];
  const totalUsers = userData?.total || 0;
  const totalUserPages = Math.ceil(totalUsers / USERS_PER_PAGE);

  // Fetch Companies
  const {
    data: companyData,
    isLoading: isLoadingCompanies,
    error: companiesError,
  } = useQuery({
    queryKey: queryKeys.companies(currentUserCompanyId, debouncedCompanySearch, companySearchColumns, companyPage, COMPANIES_PER_PAGE),
    queryFn: () =>
      fetchCompaniesCRM({
        search: debouncedCompanySearch,
        searchColumns: companySearchColumns,
        page: companyPage,
        limit: COMPANIES_PER_PAGE,
      }),
    enabled: true && tabIndex === 1,
    keepPreviousData: true,
  });

  const companies = companyData?.companies || [];
  const totalCompanies = companyData?.total || 0;
  const totalCompanyPages = Math.ceil(totalCompanies / COMPANIES_PER_PAGE);

  // Handle deletion
  const handleDelete = (id, type) => {
    if (type === "company") {
      deleteCompanyMutation.mutate({ companyId: id });
    } else if (type === "user") {
      deleteUserMutation.mutate({ userId: id });
    } else {
      console.warn("Unknown type for deletion.");
    }
  };

  // Handle edit
  const handleEdit = (item, type) => {
    setCurrentItem({ type, data: item });
    if (type === "user") {
      onUserModalOpen();
    } else if (type === "company") {
      onCompanyModalOpen();
    }
  };

  // Configure table for customers
  const customerTableConfig = tableConfigs.CustomersTable({
    onEdit: (user) => handleEdit(user, "user"),
    onDelete: (userId) => handleDelete(userId, "user"),
    currentPage: userPage,
    totalPages: totalUserPages,
    onPageChange: setUserPage,
    onSearchChange: setUserSearch,
    searchValue: userSearch,
    maxPerPage: USERS_PER_PAGE,
    totalItems: totalUsers,
    searchColumns: userSearchColumns,
    onSearchColumnsChange: setUserSearchColumns,
    isLoading: isLoadingUsers,
    isError: Boolean(usersError),
    error: usersError,
  });

  // Configure table for companies
  const companyTableConfig = tableConfigs.CustomersCompanyTable({
    onEdit: (company) => handleEdit(company, "company"),
    onDelete: (companyId) => handleDelete(companyId, "company"),
    currentPage: companyPage,
    totalPages: totalCompanyPages,
    onPageChange: setCompanyPage,
    onSearchChange: setCompanySearch,
    searchValue: companySearch,
    maxPerPage: COMPANIES_PER_PAGE,
    totalItems: totalCompanies,
    searchColumns: companySearchColumns,
    onSearchColumnsChange: setCompanySearchColumns,
    isLoading: isLoadingCompanies,
    isError: Boolean(companiesError),
    error: companiesError,
  });

  return (
    <Box p={0} overflow="hidden" height="100%">
      <VStack justify="start" w="100%">
        <HStack
          w="100%"
          align="center"
          justifyContent="space-between"
          mb={2}
          px={8}
          bg={bgColor}
          h="80px"
          borderBottom="1px solid"
          borderColor={borderColor}
        >
          <Text fontSize="xl" fontWeight="500" color={textColor}>
            Customer and Company Management
          </Text>
          <Menu>
            <MenuButton as={Button} colorScheme="primary" rightIcon={<PiCaretDownBold fontSize="18px" />}>
              Actions
            </MenuButton>
            <MenuList>
              <Box p={2} fontSize="lg" fontWeight="500" color={textColor}>
                Manage Customers
              </Box>
              <MenuItem
                fontSize="md"
                icon={<PiPaperPlaneTilt fontSize="16px" />}
                onClick={() => {
                  setCurrentItem({ type: "user", data: null });
                  onUserModalOpen();
                }}
              >
                Invite Customer
              </MenuItem>
              <MenuDivider />
              <Box p={2} fontSize="lg" fontWeight="500" color={textColor}>
                Manage Companies
              </Box>
              <MenuItem
                fontSize="md"
                icon={<PiPlus fontSize="18px" />}
                onClick={() => {
                  setCurrentItem({ type: "company", data: null });
                  onCompanyModalOpen();
                }}
              >
                Create Company
              </MenuItem>
              <MenuDivider />
              <CsvUploadModal
                onSuccess={() => {
                  queryClient.invalidateQueries(["users", currentUserCompanyId, debouncedUserSearch, userPage]);
                  queryClient.invalidateQueries(["companies", debouncedCompanySearch, companyPage]);
                  customToast({
                    title: "CSV uploaded successfully.",
                    status: "success",
                  });
                }}
              />
              <CsvDownloadModal />
            </MenuList>
          </Menu>
        </HStack>
      </VStack>
      <Tabs variant="unstyled" colorScheme="primary" height="100%" onChange={(index) => setTabIndex(index)}>
        <TabList p={8} pt={4} pb={0}>
          <Tab fontSize="lg" fontWeight="500" color={textColor}>
            Customers
          </Tab>
          <Tab fontSize="lg" fontWeight="500" color={textColor}>
            Companies
          </Tab>
        </TabList>
        <TabIndicator mt="-1.5px" height="4px" bg={tabIndicatorColor} borderRadius="1px" />
        <TabPanels borderTop="1px solid" borderColor={borderColor} p={8} pt={4} pb={0} height="100%">
          <LazyTabPanel>
            <VStack justify="start" w="100%">
              <ModularTable {...customerTableConfig} data={users} />
            </VStack>
          </LazyTabPanel>
          <LazyTabPanel>
            <VStack justify="start" w="100%">
              <ModularTable {...companyTableConfig} data={companies} />
            </VStack>
          </LazyTabPanel>
        </TabPanels>
      </Tabs>
      {/* Modals */}
      <CustomersFormModal isOpen={isUserModalOpen} onClose={onUserModalClose} initialData={currentItem?.type === "user" ? currentItem.data : null} />
      <CompanyFormModal
        isOpen={isCompanyModalOpen}
        onClose={onCompanyModalClose}
        initialData={currentItem?.type === "company" ? currentItem.data : null}
      />
    </Box>
  );
};

export default React.memo(Customers);
