// Custom Error Class for API Errors
export class APIError extends Error {
  constructor(message, status, data) {
    super(message);
    this.name = "APIError";
    this.status = status;
    this.data = data;
  }
}

export const fetchData = async (url, method = "GET", body = null, isFormData = false) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  if (!isFormData) {
    headers["Content-Type"] = "application/json";
  }

  const options = {
    method,
    headers,
  };

  if (body) {
    options.body = isFormData ? body : JSON.stringify(body);
  }

  try {
    const response = await fetch(url, options);

    const contentType = response.headers.get("Content-Type");
    let responseData = null;

    if (contentType && contentType.includes("application/json")) {
      responseData = await response.json();
    } else {
      responseData = await response.text();
    }

    if (!response.ok) {
      const errorMessage = responseData?.error || responseData?.message || "Error fetching data";
      throw new Error(errorMessage);
    }

    return responseData;
  } catch (error) {
    if (error instanceof APIError) {
      throw error;
    } else {
      // Handle network or unexpected errors
      throw new APIError(error.message || "An unexpected error occurred.", 500, null);
    }
  }
};
