// client/src/components/Reusable/CompanyAvatar.js
import React from "react";
import { Avatar, Box, Flex, Text, Tooltip, Spinner, Alert, AlertIcon, useColorMode } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCompanyAvatarSocketQuery } from "../../5 - General/Hooks/useCompanyAvatarSocketQuery";

const CompanyAvatar = ({
  companyIds = [],
  size = "md",
  showInfo = true,
  maxAvatars = 2,
  borderRadius = "md",
  fontSize = "md",
  fontWeight = "bold",
  showTooltip = true,
  maxW = "100%",
  bg = "transparent",
  spacing = -5,
  alignSelf = "",
  mr = "0",
  ml = "0",
  forceMode,
}) => {
  const { colorMode } = useColorMode();
  const effectiveMode = forceMode || colorMode;
  const { data: companiesData, isLoading, error } = useCompanyAvatarSocketQuery(companyIds);
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  if (!companyIds || companyIds.length === 0) {
    return null;
  }

  if (isLoading) {
    return <Spinner size={size} />;
  }

  if (error) {
    return (
      <Alert status="error" mt={4}>
        <AlertIcon />
        {error.message || "An error occurred while fetching the company data."}
      </Alert>
    );
  }

  const renderAvatar = (companyId) => {
    const company = companiesData[companyId];

    if (!company) {
      return <Avatar size={size} borderRadius={borderRadius} key={companyId} />;
    }

    const { companyName, logoColorPath, logoWhitePath } = company;
    const avatarSrc =
      effectiveMode === "dark"
        ? logoWhitePath
          ? `${apiUrl}/${logoWhitePath}`
          : null
        : logoColorPath
        ? `${apiUrl}/${logoColorPath}`
        : null;

    const avatarElement = (
      <Avatar mr={mr} ml={ml} alignSelf={alignSelf} size={size} name={companyName} src={avatarSrc} borderRadius={borderRadius} key={companyId} />
    );

    return showTooltip ? (
      <Tooltip hasArrow label={`${companyName || "Company"}`} aria-label={`${companyName} Tooltip`} key={companyId}>
        {avatarElement}
      </Tooltip>
    ) : (
      avatarElement
    );
  };

  const displayCompanies = companyIds.slice(0, maxAvatars);
  const extraCompanies = companyIds.length - maxAvatars;

  const renderExtraAvatar = () => {
    if (extraCompanies <= 0) return null;

    const avatarLabel = `+${extraCompanies}`;

    const allCompanies = companyIds.map((companyId) => {
      const company = companiesData[companyId] || {};
      return `${company.companyName || "Company"}`;
    });

    return (
      <Tooltip
        hasArrow
        label={
          <Box>
            {allCompanies.map((companyInfo, index) => (
              <Text key={index} fontSize="sm">
                {companyInfo}
              </Text>
            ))}
          </Box>
        }
        aria-label="Extra Companies Tooltip"
        key="extra-companies-tooltip"
      >
        <Avatar size={size} bg="gray.200" borderRadius={borderRadius} cursor="pointer" overflow="hidden">
          <Text fontSize={size} color="gray.700">
            {avatarLabel}
          </Text>
        </Avatar>
      </Tooltip>
    );
  };

  return (
    <Box>
      <Flex align="center" mr={1} bg={bg}>
        <Box position="relative" display="flex">
          {displayCompanies.map((companyId, index) => (
            <Box key={`${companyId}-avatar`} position="relative" marginLeft={index === 0 ? 0 : `${spacing}px`}>
              {renderAvatar(companyId)}
            </Box>
          ))}
          {extraCompanies > 0 && (
            <Box position="relative" marginLeft={`3px`}>
              {renderExtraAvatar()}
            </Box>
          )}
        </Box>
        {showInfo && companyIds.length === 1 && (
          <Box ml={1}>
            <Text maxW={maxW} fontSize={fontSize} fontWeight={fontWeight} whiteSpace="nowrap" overflow="hidden" isTruncated>
              {companiesData[companyIds[0]]?.companyName}
            </Text>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

CompanyAvatar.propTypes = {
  companyIds: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.string,
  showInfo: PropTypes.bool,
  maxAvatars: PropTypes.number,
  borderRadius: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  showTooltip: PropTypes.bool,
  maxW: PropTypes.string,
  bg: PropTypes.string,
  spacing: PropTypes.number,
  withTooltip: PropTypes.bool,
  alignSelf: PropTypes.string,
  mr: PropTypes.string,
  ml: PropTypes.string,
  forceMode: PropTypes.oneOf(["light", "dark"]),
};

export default React.memo(CompanyAvatar);
