// src/components/5 - General/Utils/UtilsDatePicker.js

import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  IconButton,
  Divider,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  VStack,
  useOutsideClick,
  Flex,
  FormControl,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { useDayzed } from "dayzed";
import * as dateFns from "date-fns";
import _ from "lodash";
import { formatReadableDate } from "./UtilsFormatData";
import { PiCaretDoubleLeftBold, PiCaretDoubleRightBold, PiCaretDown, PiCaretLeftBold, PiCaretRightBold, PiPencilSimpleLine } from "react-icons/pi";
import { WorkspaceContext } from "../Context/WorkspaceContext"; // Adjust the import path as necessary

// Month and day names
const MONTH_NAMES = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const DAY_NAMES = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const DATE_FORMAT = "dd/MM/yyyy";

// Back navigation buttons
const DatepickerBackButtons = ({ calendars, getBackProps, secondaryColor }) => (
  <HStack spacing={2}>
    <Button {...getBackProps({ calendars, offset: 12 })} variant="ghost" size="xs" color={secondaryColor} aria-label="Back 12 months">
      <PiCaretDoubleLeftBold size="16px" />
    </Button>
    <Button {...getBackProps({ calendars })} variant="ghost" size="xs" color={secondaryColor} aria-label="Back 1 month">
      <PiCaretLeftBold size="16px" />
    </Button>
  </HStack>
);

// Forward navigation buttons
const DatepickerForwardButtons = ({ calendars, getForwardProps, secondaryColor }) => (
  <HStack spacing={1}>
    <Button {...getForwardProps({ calendars })} variant="ghost" size="xs" color={secondaryColor} aria-label="Forward 1 month">
      <PiCaretRightBold size="16px" />
    </Button>
    <Button {...getForwardProps({ calendars, offset: 12 })} variant="ghost" size="xs" color={secondaryColor} aria-label="Forward 12 months">
      <PiCaretDoubleRightBold size="16px" />
    </Button>
  </HStack>
);

// Helper function to calculate month difference
const getMonthDifference = (fromDate, toDate) => {
  return (toDate.getFullYear() - fromDate.getFullYear()) * 12 + (toDate.getMonth() - fromDate.getMonth());
};

// Calendar layout
const DatepickerCalendar = ({
  calendars,
  getDateProps,
  getBackProps,
  getForwardProps,
  onTodayClick,
  highlightDates = [],
  highlightTooltips = [],
  allowedDateRange,
  startDate,
  endDate,
  onOffsetChanged,
  themeColor,
  secondaryColor,
  selectedColor,
  todayBorderColor,
  highlightColor,
  weekendColor,
}) => {
  const dayHoverColor = useColorModeValue("gray.300", "gray.500");
  const textColor = useColorModeValue("black", "white");

  // Create a map for quick tooltip lookup
  const highlightMap = React.useMemo(() => {
    const map = {};
    highlightDates.forEach((date, index) => {
      const key = dateFns.format(date, "yyyy-MM-dd");
      map[key] = highlightTooltips[index] || "";
    });
    return map;
  }, [highlightDates, highlightTooltips]);

  if (_.isEmpty(calendars)) return null;

  return (
    <Box>
      <HStack spacing={6} alignItems="baseline">
        {calendars.map((calendar) => (
          <VStack pt={4} px={4} key={`${calendar.month}${calendar.year}`} spacing={3}>
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              <DatepickerBackButtons calendars={calendars} getBackProps={getBackProps} secondaryColor={secondaryColor} />

              {/* Month and Year with Year Dropdown */}
              <Menu>
                <MenuButton
                  as={Button}
                  fontSize="sm"
                  variant="ghost"
                  colorScheme={themeColor}
                  p={2}
                  fontWeight="bold"
                  cursor="pointer"
                  rightIcon={<PiCaretDown size="16px" />}
                >
                  {MONTH_NAMES[calendar.month]} {calendar.year}
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="auto">
                  {/* Define the range of years */}
                  {(() => {
                    const currentYear = new Date().getFullYear();
                    const startYear = allowedDateRange ? allowedDateRange.start.getFullYear() : 1900;
                    const endYear = allowedDateRange ? allowedDateRange.end.getFullYear() : currentYear + 10;
                    const years = [];
                    for (let y = startYear; y <= endYear; y++) {
                      years.push(y);
                    }
                    years.reverse();

                    return years.map((year) => (
                      <MenuItem
                        key={year}
                        onClick={() => {
                          const desiredOffset = getMonthDifference(new Date(), new Date(year, calendar.month, 1));
                          onOffsetChanged(desiredOffset);
                        }}
                      >
                        {year}
                      </MenuItem>
                    ));
                  })()}
                </MenuList>
              </Menu>

              <DatepickerForwardButtons calendars={calendars} getForwardProps={getForwardProps} secondaryColor={secondaryColor} />
            </Flex>
            <Divider borderColor={secondaryColor} />
            <SimpleGrid columns={7} spacing={2} textAlign="center">
              {DAY_NAMES.map((day) => (
                <Text key={`${calendar.month}${calendar.year}${day}`} fontSize="sm" fontWeight="bold" color={secondaryColor}>
                  {day}
                </Text>
              ))}
              {calendar.weeks.map((week, weekIndex) =>
                week.map((dateObj, index) => {
                  const { date, today, prevMonth, nextMonth, selected } = dateObj;
                  const isHighlighted = highlightDates.some((highlightDate) => dateFns.isSameDay(highlightDate, date));

                  // Modify isDisabled logic:
                  // Highlighted dates should not be disabled regardless of other conditions
                  const isDisabled =
                    !isHighlighted &&
                    (prevMonth ||
                      nextMonth ||
                      (allowedDateRange && (dateFns.isBefore(date, allowedDateRange.start) || dateFns.isAfter(date, allowedDateRange.end))) ||
                      (startDate && dateFns.isBefore(date, startDate)) ||
                      (endDate && dateFns.isAfter(date, endDate)));

                  const isWeekend = date.getDay() === 0 || date.getDay() === 6; // Check if Saturday or Sunday

                  const tooltipText = highlightMap[dateFns.format(date, "yyyy-MM-dd")] || "";

                  // Dynamic styling based on state and color mode
                  const bg = selected ? selectedColor : isHighlighted ? highlightColor : isWeekend ? weekendColor : "transparent";

                  const color = isDisabled ? secondaryColor : textColor;

                  const borderColorToday = today ? todayBorderColor : "transparent";

                  const button = (
                    <Button
                      key={`${calendar.month}${calendar.year}${weekIndex}${index}`}
                      {...getDateProps({ dateObj, disabled: isDisabled })}
                      size="sm"
                      maxW="40px"
                      w="40px"
                      borderRadius="md"
                      bg={bg}
                      color={color}
                      borderColor={borderColorToday}
                      borderWidth={today ? "2px" : "1px"}
                      _hover={{
                        bg: selected ? selectedColor : isHighlighted ? highlightColor : dayHoverColor,
                      }}
                      transition="background-color 0.2s, border-color 0.2s"
                    >
                      {date.getDate()}
                    </Button>
                  );

                  return isHighlighted && tooltipText ? (
                    <Tooltip key={`${calendar.month}${calendar.year}${weekIndex}${index}`} label={tooltipText} fontSize="sm">
                      {button}
                    </Tooltip>
                  ) : (
                    <Box key={`${calendar.month}${calendar.year}${weekIndex}${index}`}>{button}</Box>
                  );
                })
              )}
            </SimpleGrid>
          </VStack>
        ))}
      </HStack>
      {/* Today Button */}
      <Box textAlign="center" mb={4}>
        <Button variant="outline" size="sm" mt={4} onClick={onTodayClick} colorScheme={themeColor}>
          Go to today: {formatReadableDate(new Date(), DATE_FORMAT)}
        </Button>
      </Box>
    </Box>
  );
};

// Date Picker component
export const UtilsDatePicker = ({
  value,
  onChange,
  inputVariant = "default",
  fontSize,
  fontWeight,
  color,
  bg,
  hasEditButton = false,
  placeholder,
  allowedDateRange,
  startDate,
  endDate,
  highlightDates = [],
  highlightTooltips = [],
  initialView = "today", // 'today' or 'value'
  isDisabled = false,
}) => {
  const ref = React.useRef();
  const initialFocusRef = React.useRef();
  const [proposedDate, setProposedDate] = useState(value ? dateFns.format(value, DATE_FORMAT) : "");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [offset, setOffset] = useState(0); // Track the calendar offset

  const { themeColor } = useContext(WorkspaceContext); // Access themeColor from WorkspaceContext

  useOutsideClick({
    ref,
    handler: () => setPopoverOpen(false),
  });

  // Define color variables based on color mode
  const secondaryColor = useColorModeValue("gray.500", "gray.300");
  const selectedColor = useColorModeValue(`${themeColor}.200`, `${themeColor}.600`);
  const todayBorderColor = themeColor; // Use themeColor for today border
  const highlightColor = themeColor; // Use themeColor for highlighted dates
  const weekendColor = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("black", "white");

  // Function to handle date change
  const handleDateChange = (date) => {
    onChange(date); // Pass the Date object to the parent
    setProposedDate(date ? dateFns.format(date, DATE_FORMAT) : "");
  };

  // Function called when a date is selected
  const onDateSelected = ({ selectable, date }) => {
    if (!selectable) return;
    handleDateChange(date);
    setPopoverOpen(false);
  };

  // Function to navigate to today
  const goToToday = () => {
    const today = new Date();
    const monthDifference = getMonthDifference(new Date(), today);
    setOffset(monthDifference);
  };

  // Function to navigate to the selected value date
  const goToValue = () => {
    if (value) {
      const monthDifference = getMonthDifference(new Date(), value);
      setOffset(monthDifference);
    }
  };

  // Calculate initial offset based on initialView when popover opens
  useEffect(() => {
    if (popoverOpen) {
      if (initialView === "today") {
        goToToday();
      } else if (initialView === "value" && value) {
        goToValue();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popoverOpen]);

  const dayzedData = useDayzed({
    showOutsideDays: true,
    onDateSelected,
    selected: value,
    offset,
    onOffsetChanged: setOffset, // Pass the offset state to Dayzed
  });

  // Styles for the input depending on the variant
  const inputStyles =
    inputVariant === "editable"
      ? {
          bg: "transparent",
          border: "none",
          width: "100%",
          cursor: "pointer",
          margin: 0,
          padding: 0,
          fontSize: fontSize || "14px",
          fontWeight: fontWeight || "normal",
          color: color || textColor,
        }
      : {
          bg: bg,
        };

  // Handle input changes for editable variant
  const handleInputChange = (e) => {
    setProposedDate(e.target.value);
  };

  // Handle input blur for validation
  const handleInputBlur = () => {
    const parsedDate = dateFns.parse(proposedDate, DATE_FORMAT, new Date());
    if (
      dateFns.isValid(parsedDate) &&
      (!allowedDateRange ||
        ((dateFns.isAfter(parsedDate, allowedDateRange.start) || dateFns.isEqual(parsedDate, allowedDateRange.start)) &&
          (dateFns.isBefore(parsedDate, allowedDateRange.end) || dateFns.isEqual(parsedDate, allowedDateRange.end)))) &&
      (!startDate || dateFns.isAfter(parsedDate, startDate) || dateFns.isEqual(parsedDate, startDate)) &&
      (!endDate || dateFns.isBefore(parsedDate, endDate) || dateFns.isEqual(parsedDate, endDate))
    ) {
      handleDateChange(parsedDate);
    } else {
      // Optionally, provide feedback to the user about the invalid date
      handleDateChange(undefined);
    }
  };

  return (
    <HStack w="100%">
      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        returnFocusOnClose={false}
        onClose={() => setPopoverOpen(false)}
        initialFocusRef={initialFocusRef}
        isLazy
      >
        <PopoverTrigger>
          <Box flex="1">
            <HStack w="100%">
              <FormControl isDisabled={isDisabled} w="100%">
                <Input
                  {...inputStyles}
                  w="100%"
                  placeholder={placeholder}
                  fontSize={fontSize || "14px"}
                  fontWeight={fontWeight || "normal"}
                  color={color || textColor}
                  value={inputVariant === "editable" ? proposedDate : value ? formatReadableDate(value) : ""}
                  isReadOnly={!isDisabled}
                  ref={initialFocusRef}
                  onClick={() => {
                    if (!isDisabled) setPopoverOpen(true);
                  }} // Open popover on input click
                  onChange={inputVariant === "editable" ? handleInputChange : undefined}
                  onBlur={inputVariant === "editable" ? handleInputBlur : undefined}
                />
              </FormControl>
            </HStack>
          </Box>
        </PopoverTrigger>
        <PopoverContent ref={ref} bg={useColorModeValue("white", "gray.700")} borderRadius="lg" boxShadow="base" zIndex={4}>
          <PopoverBody p={0}>
            <DatepickerCalendar
              {...dayzedData}
              onTodayClick={goToToday}
              highlightDates={highlightDates}
              highlightTooltips={highlightTooltips}
              allowedDateRange={allowedDateRange}
              startDate={startDate}
              endDate={endDate}
              onOffsetChanged={setOffset}
              themeColor={themeColor}
              secondaryColor={secondaryColor}
              selectedColor={selectedColor}
              todayBorderColor={todayBorderColor}
              highlightColor={highlightColor}
              weekendColor={weekendColor}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
      {hasEditButton && !isDisabled && (
        <IconButton
          icon={<PiPencilSimpleLine />}
          variant="outline"
          size="sm"
          onClick={() => setPopoverOpen(true)}
          aria-label="Edit date"
          colorScheme={themeColor}
        />
      )}
    </HStack>
  );
};

export default UtilsDatePicker;
