// RegisterPage.js

import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Image,
  Text,
  Flex,
  Link,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  VStack,
  useDisclosure,
  useColorModeValue,
  Box,
  Heading,
  Stack,
  Progress,
} from "@chakra-ui/react";
import LoginImg from "../../img/logoBlueText.png";

function RegisterPage({ switchToLogin }) {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    companyName: "",
    companyRegistrationNumber: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [step, setStep] = useState(1); // Track the current step
  const lightTextcolor = useColorModeValue("gray.600", "gray.400");

  const handleConfirmationClose = () => {
    onClose();
    switchToLogin();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  useEffect(() => {
    setFormData({
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      companyName: "",
      companyRegistrationNumber: "",
    });
  }, [switchToLogin]);

  // Validation functions for each step
  const validateStep = () => {
    let tempErrors = {};

    if (step === 1) {
      if (!formData.email) tempErrors.email = "Email is required";
      if (!formData.password) tempErrors.password = "Password is required";
      if (formData.password !== formData.confirmPassword) tempErrors.confirmPassword = "Passwords do not match";
    } else if (step === 2) {
      if (!formData.firstName) tempErrors.firstName = "First name is required";
      if (!formData.lastName) tempErrors.lastName = "Last name is required";
    } else if (step === 3) {
      if (!formData.companyName) tempErrors.companyName = "Company name is required";
      if (
        !formData.companyRegistrationNumber ||
        formData.companyRegistrationNumber.length !== 10 ||
        !/^\d{10}$/.test(formData.companyRegistrationNumber)
      )
        tempErrors.companyRegistrationNumber = "Company registration number must be 10 digits";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setErrors({});
    setStep(step - 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateStep()) return;
    setIsLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/v1/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        onOpen();
      } else {
        setErrors({ general: data.message });
      }
    } catch (error) {
      setErrors({ general: "Server error, please try again later" });
    }
    setIsLoading(false);
  };

  // Render form fields based on the current step
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            {/* Email Field */}
            <FormControl id="email" isInvalid={errors.email} isRequired>
              <FormLabel>Email</FormLabel>
              <Input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email" size="lg" width="100%" />
              {errors.email && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.email}
                </Text>
              )}
            </FormControl>

            {/* Password Field */}
            <FormControl id="password" isInvalid={errors.password} isRequired>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter your password"
                size="lg"
                width="100%"
              />
              {errors.password && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.password}
                </Text>
              )}
            </FormControl>

            {/* Confirm Password Field */}
            <FormControl id="confirmPassword" isInvalid={errors.confirmPassword} isRequired>
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm your password"
                size="lg"
                width="100%"
              />
              {errors.confirmPassword && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.confirmPassword}
                </Text>
              )}
            </FormControl>
          </>
        );
      case 2:
        return (
          <>
            {/* First Name */}
            <FormControl id="firstName" isInvalid={errors.firstName} isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Enter your first name"
                size="lg"
                width="100%"
              />
              {errors.firstName && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.firstName}
                </Text>
              )}
            </FormControl>

            {/* Last Name */}
            <FormControl id="lastName" isInvalid={errors.lastName} isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Enter your last name"
                size="lg"
                width="100%"
              />
              {errors.lastName && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.lastName}
                </Text>
              )}
            </FormControl>
          </>
        );
      case 3:
        return (
          <>
            {/* Company Name */}
            <FormControl id="companyName" isInvalid={errors.companyName} isRequired>
              <FormLabel>Company Name</FormLabel>
              <Input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                placeholder="Enter your company name"
                size="lg"
                width="100%"
              />
              {errors.companyName && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.companyName}
                </Text>
              )}
            </FormControl>

            {/* Company Registration Number */}
            <FormControl id="companyRegistrationNumber" isInvalid={errors.companyRegistrationNumber} isRequired>
              <FormLabel>Company Registration Number</FormLabel>
              <Input
                type="text"
                name="companyRegistrationNumber"
                value={formData.companyRegistrationNumber}
                onChange={handleChange}
                placeholder="Enter your company registration number"
                size="lg"
                width="100%"
              />
              {errors.companyRegistrationNumber && (
                <Text color="red.500" fontSize="sm" mt={1}>
                  {errors.companyRegistrationNumber}
                </Text>
              )}
            </FormControl>
          </>
        );
      default:
        return null;
    }
  };

  // Progress bar percentage
  const progressPercent = (step / 3) * 100;

  return (
    <Flex align="center" justify="center" width="100%" minH="100vh" px={{ base: 4, md: 0 }}>
      <Box width={{ base: "100%", md: "500px" }} p={{ base: 4, md: 8 }}>
        {/* Logo and Heading */}
        <Flex direction="column" align="center" mb={{ base: 4, md: 6 }}>
          <Image src={LoginImg} alt="Logo" w={{ base: "100px", md: "120px" }} mb={2} />
          <Heading
            as="h2"
            size="xl"
            bgGradient="linear(to-r, primary.500, blue.400)"
            bgClip="text"
            fontSize={{ base: "2xl", md: "3xl" }}
            textAlign="center"
          >
            Create Account
          </Heading>
          <Text color={lightTextcolor} fontSize={{ base: "sm", md: "md" }} textAlign="center">
            Register a new account
          </Text>
        </Flex>

        {/* Progress Bar */}
        <Progress value={progressPercent} mb={{ base: 4, md: 6 }} colorScheme="primary" borderRadius="md" size="sm" />

        {/* Form */}
        <Stack spacing={6} as="form" onSubmit={step === 3 ? handleSubmit : (e) => e.preventDefault()}>
          {/* General Error Alert */}
          {errors.general && (
            <Alert status="error" borderRadius="md">
              <AlertIcon />
              {errors.general}
            </Alert>
          )}

          {renderStep()}

          {/* Navigation Buttons */}
          <Flex justify="space-between" mt={4} flexDirection={{ base: "column", md: "row" }}>
            {step > 1 ? (
              <Button
                onClick={handleBack}
                size="lg"
                colorScheme="gray"
                variant="outline"
                mb={{ base: 2, md: 0 }}
                width={{ base: "100%", md: "auto" }}
              >
                Back
              </Button>
            ) : (
              <Box p={0} m={0} w={0} h={0} />
            )}
            {step < 3 ? (
              <Button onClick={handleNext} size="lg" colorScheme="primary" width={{ base: "100%", md: "auto" }}>
                Next
              </Button>
            ) : (
              <Button
                type="submit"
                size="lg"
                colorScheme="primary"
                isLoading={isLoading}
                loadingText="Registering"
                width={{ base: "100%", md: "auto" }}
              >
                Register
              </Button>
            )}
          </Flex>

          {/* Switch to Login */}
          <Text textAlign="center" color={lightTextcolor} fontSize={{ base: "sm", md: "md" }}>
            Already have an account?{" "}
            <Link onClick={switchToLogin} color="primary.500" fontWeight="bold" _hover={{ textDecoration: "underline", cursor: "pointer" }}>
              Log in
            </Link>
          </Text>
        </Stack>

        {/* Success Modal */}
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={{ base: "xs", md: "md" }}>
          <ModalOverlay />
          <ModalContent borderRadius="md" boxShadow="2xl" maxW="md" p={{ base: 4, md: 6 }}>
            <ModalHeader py={{ base: 4, md: 6 }} borderBottom="1px solid" borderColor="gray.200" fontSize={{ base: "lg", md: "xl" }}>
              You have successfully registered!
            </ModalHeader>
            <ModalBody>
              <VStack mt={2} spacing={4} align="stretch">
                <Text fontSize={{ base: "sm", md: "md" }}>
                  Registration successful! A verification link has been sent to your email address. The link expires in 15 minutes.
                </Text>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleConfirmationClose} size={{ base: "sm", md: "md" }} colorScheme="primary" width="100%">
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Flex>
  );
}

export default RegisterPage;
