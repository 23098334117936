// src/components/Reusable/GanttTaskBar.js

import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { Box, Text, Tooltip } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { GanttContext } from "./GanttContext";
import dayjs from "dayjs";

const MotionBox = motion.create(Box);

// StartDependency Component
const StartDependency = ({ onLinkEnd }) => (
  <Tooltip label="Complete Dependency Linking" hasArrow>
    <Box
      as="button"
      size="xs"
      width="10px"
      height="10px"
      bg="gray.400"
      borderRadius="50%"
      onClick={(e) => {
        e.stopPropagation();
        onLinkEnd();
      }}
      aria-label="Complete Dependency Linking"
      mr="10px"
    />
  </Tooltip>
);

// EndDependency Component
const EndDependency = ({ onLinkStart }) => (
  <Tooltip label="Initiate Dependency Linking" hasArrow>
    <Box
      as="button"
      size="sm"
      width="10px"
      height="10px"
      bg="gray.400"
      borderRadius="full"
      onClick={(e) => {
        e.stopPropagation();
        onLinkStart();
      }}
      aria-label="Initiate Dependency Linking"
      ml="10px"
    />
  </Tooltip>
);

// StartDateHandle Component
const StartDateHandle = ({
  onResizeStart,
  dayWidth,
  setLocalLeft,
  setLocalWidth,
  disableTextSelection,
  startDragging,
  endDragging,
  currentLeft,
  currentWidth,
  onDragStart,
  onDragEnd,
  handleDayHover,
  task,
  chartRef,
}) => {
  const handleRef = useRef(null);
  const initialLeftRef = useRef(0);
  const initialXRef = useRef(0);
  const movementRef = useRef(0);

  // Mouse Move Handler
  const handleMouseMove = useCallback(
    (e) => {
      if (!chartRef.current) {
        console.warn("chartRef.current is null in StartDateHandle handleMouseMove");
        return;
      }
      const chartRect = chartRef.current.getBoundingClientRect();
      const offsetX = e.clientX - chartRect.left + chartRef.current.scrollLeft;

      const daysMoved = Math.round(offsetX / dayWidth);
      const newDate = dayjs(task.startDate).add(daysMoved, "day");
      handleDayHover(newDate);

      setLocalLeft(initialLeftRef.current + offsetX);
      setLocalWidth(currentWidth - offsetX);
    },
    [setLocalLeft, setLocalWidth, currentWidth, dayWidth, handleDayHover, task.startDate, chartRef]
  );

  // Mouse Up Handler
  const handleMouseUp = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      endDragging();
      disableTextSelection(false);

      const daysChanged = Math.round(movementRef.current / dayWidth);
      if (daysChanged !== 0) {
        onResizeStart(daysChanged); // task._id is already bound
      }

      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);

      movementRef.current = 0;

      onDragEnd();
    },
    [dayWidth, onResizeStart, endDragging, disableTextSelection, handleMouseMove, onDragEnd]
  );

  // Mouse Down Handler
  const handleMouseDown = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      startDragging();
      onDragStart();
      initialXRef.current = e.clientX;
      initialLeftRef.current = currentLeft;
      disableTextSelection(true);

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    },
    [startDragging, disableTextSelection, handleMouseMove, handleMouseUp, currentLeft, onDragStart]
  );

  return (
    <Tooltip label="Drag to adjust start date" hasArrow>
      <Box
        ref={handleRef}
        width="10px"
        height="40px"
        borderRadius="10px 0 0 10px"
        bg="gray.300"
        cursor="ew-resize"
        onMouseDown={handleMouseDown}
        aria-label="Start Date Handle"
      />
    </Tooltip>
  );
};

// EndDateHandle Component
const EndDateHandle = ({
  onResizeEnd,
  dayWidth,
  setLocalWidth,
  disableTextSelection,
  startDragging,
  endDragging,
  currentWidth,
  onDragStart,
  onDragEnd,
  handleDayHover,
  task,
  chartRef,
}) => {
  const handleRef = useRef(null);
  const initialWidthRef = useRef(0);
  const initialXRef = useRef(0);
  const movementRef = useRef(0);

  // Mouse Move Handler
  const handleMouseMove = useCallback(
    (e) => {
      if (!chartRef.current) {
        console.warn("chartRef.current is null in EndDateHandle handleMouseMove");
        return;
      }
      const chartRect = chartRef.current.getBoundingClientRect();
      const offsetX = e.clientX - chartRect.left + chartRef.current.scrollLeft;

      const deltaX = offsetX - initialXRef.current;
      movementRef.current = deltaX;

      const newWidth = initialWidthRef.current + deltaX;
      setLocalWidth(Math.max(newWidth, dayWidth));

      const daysMoved = Math.round(deltaX / dayWidth);
      const newDate = dayjs(task.endDate).add(daysMoved, "day");
      handleDayHover(newDate);
    },
    [setLocalWidth, dayWidth, handleDayHover, task.endDate, chartRef]
  );

  // Mouse Up Handler
  const handleMouseUp = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      endDragging();
      disableTextSelection(false);

      const daysChanged = Math.round(movementRef.current / dayWidth);
      if (daysChanged !== 0) {
        onResizeEnd(daysChanged); // task._id is already bound
      }

      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);

      movementRef.current = 0;

      onDragEnd();
    },
    [dayWidth, onResizeEnd, endDragging, disableTextSelection, handleMouseMove, onDragEnd]
  );

  // Mouse Down Handler
  const handleMouseDown = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      startDragging();
      onDragStart();

      const chartRect = chartRef.current.getBoundingClientRect();
      const offsetX = e.clientX - chartRect.left + chartRef.current.scrollLeft;

      initialXRef.current = offsetX;
      initialWidthRef.current = currentWidth;
      disableTextSelection(true);

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    },
    [startDragging, disableTextSelection, handleMouseMove, handleMouseUp, currentWidth, onDragStart, chartRef]
  );

  return (
    <Tooltip label="Drag to adjust end date" hasArrow>
      <Box
        ref={handleRef}
        width="10px"
        height="40px"
        borderRadius="0 10px 10px 0"
        bg="gray.300"
        cursor="ew-resize"
        onMouseDown={handleMouseDown}
        aria-label="End Date Handle"
      />
    </Tooltip>
  );
};

// MainBox Component
const MainBox = ({
  task,
  isSelected,
  onDrag,
  dayWidth,
  setLocalLeft,
  disableTextSelection,
  startDragging,
  endDragging,
  currentLeft,
  onDragStart,
  onDragEnd,
  handleDayHover,
  chartRef,
}) => {
  const handleRef = useRef(null);
  const initialLeftRef = useRef(0);
  const initialXRef = useRef(0);
  const movementRef = useRef(0);

  // Mouse Move Handler
  const handleMouseMove = useCallback(
    (e) => {
      if (!chartRef.current) {
        console.warn("chartRef.current is null in MainBox handleMouseMove");
        return;
      }
      const chartRect = chartRef.current.getBoundingClientRect();
      const offsetX = e.clientX - chartRect.left + chartRef.current.scrollLeft;

      const deltaX = offsetX - initialXRef.current;
      movementRef.current = deltaX;
      setLocalLeft(initialLeftRef.current + deltaX);

      const daysMoved = Math.round(deltaX / dayWidth);
      const newDate = dayjs(task.startDate).add(daysMoved, "day");
      handleDayHover(newDate);
    },
    [setLocalLeft, dayWidth, handleDayHover, task.startDate, chartRef, initialLeftRef]
  );

  // Mouse Up Handler
  const handleMouseUp = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      endDragging();
      disableTextSelection(false);

      const daysMoved = Math.round(movementRef.current / dayWidth);
      if (daysMoved !== 0) {
        onDrag(daysMoved); // task._id is already bound
      }

      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);

      movementRef.current = 0;

      onDragEnd();
    },
    [movementRef, dayWidth, onDrag, endDragging, disableTextSelection, handleMouseMove, onDragEnd]
  );

  // Mouse Down Handler
  const handleMouseDown = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      startDragging();
      onDragStart();

      const chartRect = chartRef.current.getBoundingClientRect();
      const offsetX = e.clientX - chartRect.left + chartRef.current.scrollLeft;

      initialXRef.current = offsetX;
      initialLeftRef.current = currentLeft;
      disableTextSelection(true);

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    },
    [startDragging, disableTextSelection, handleMouseMove, handleMouseUp, currentLeft, onDragStart, chartRef]
  );

  return (
    <Tooltip label="Drag to move task" hasArrow>
      <Box
        ref={handleRef}
        flex="1"
        bg={isSelected ? "#bee3f8" : task.color || "white"}
        cursor="move"
        h="40px"
        onMouseDown={handleMouseDown}
        aria-label="Main Task Bar"
        position="relative"
      >
        <Text fontSize="sm" fontWeight="medium" isTruncated maxWidth="150px" p={2}>
          {task.name}
        </Text>
        <Box
          position="absolute"
          bottom="0"
          left="0"
          width={`${task.progress}%`}
          height="4px"
          bg="rgba(49, 130, 206, 0.5)"
          borderRadius="0 4px 4px 0"
        />
      </Box>
    </Tooltip>
  );
};

// GanttTaskBar Component
const GanttTaskBar = ({ task, isSelected }) => {
  const {
    onDrag,
    onResizeStart,
    onResizeEnd,
    dayWidth,
    rowHeight,
    handleLinkStart,
    handleLinkEnd,
    onDragStart: contextOnDragStart,
    onDragEnd: contextOnDragEnd,
    handleDayHover,
    chartRef,
  } = useContext(GanttContext);

  const [localLeft, setLocalLeft] = useState(task.left);
  const [localWidth, setLocalWidth] = useState(task.width);
  const [isDragging, setIsDragging] = useState(false);

  const disableTextSelection = useCallback((disable) => {
    document.body.style.userSelect = disable ? "none" : "auto";
  }, []);

  const startDraggingInternal = useCallback(() => setIsDragging(true), []);
  const endDraggingInternal = useCallback(() => setIsDragging(false), []);

  useEffect(() => {
    if (!isDragging) {
      setLocalLeft(task.left);
      setLocalWidth(task.width);
    }
  }, [task.left, task.width, isDragging]);

  return (
    <MotionBox
      position="absolute"
      top={`${task.top}px`}
      left={`${localLeft - 20}px`}
      width={`${localWidth + 40}px`}
      height={`${rowHeight}px`}
      minWidth={`${dayWidth * 2}px`} // Ensure a minimum width
      display="flex"
      alignItems="center"
      zIndex={isSelected ? 2 : 1}
      style={{ transition: isDragging ? "none" : "left 0.2s, width 0.2s" }}
      pointerEvents= "auto"
    >
      {/* Start Dependency Dot (Complete Linking) */}
      <StartDependency onLinkEnd={() => handleLinkEnd(task._id, "start")} />

      {/* Start Date Handle */}
      <StartDateHandle
        onResizeStart={(daysChanged) => onResizeStart(task._id, daysChanged)}
        dayWidth={dayWidth}
        setLocalLeft={setLocalLeft}
        setLocalWidth={setLocalWidth}
        disableTextSelection={disableTextSelection}
        startDragging={startDraggingInternal}
        endDragging={endDraggingInternal}
        currentLeft={localLeft}
        currentWidth={localWidth}
        onDragStart={contextOnDragStart}
        onDragEnd={contextOnDragEnd}
        handleDayHover={handleDayHover}
        task={task}
        chartRef={chartRef}
      />

      {/* Main Task Bar */}
      <MainBox
        task={{ ...task, progress: task.progress || 0 }}
        isSelected={isSelected}
        onDrag={(daysMoved) => onDrag(task._id, daysMoved)}
        dayWidth={dayWidth}
        setLocalLeft={setLocalLeft}
        disableTextSelection={disableTextSelection}
        startDragging={startDraggingInternal}
        endDragging={endDraggingInternal}
        currentLeft={localLeft}
        onDragStart={contextOnDragStart}
        onDragEnd={contextOnDragEnd}
        handleDayHover={handleDayHover}
        chartRef={chartRef}
      />

      {/* End Date Handle */}
      <EndDateHandle
        onResizeEnd={(daysChanged) => onResizeEnd(task._id, daysChanged)}
        dayWidth={dayWidth}
        setLocalWidth={setLocalWidth}
        disableTextSelection={disableTextSelection}
        startDragging={startDraggingInternal}
        endDragging={endDraggingInternal}
        currentWidth={localWidth}
        onDragStart={contextOnDragStart}
        onDragEnd={contextOnDragEnd}
        handleDayHover={handleDayHover}
        task={task}
        chartRef={chartRef}
      />

      {/* End Dependency Dot (Start Linking) */}
      <EndDependency onLinkStart={() => handleLinkStart(task._id, "end")} />
    </MotionBox>
  );
};

export default GanttTaskBar;
