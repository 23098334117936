import React from "react";
import { Flex, Box, Text, VStack } from "@chakra-ui/react";
import { formatFileSize } from "./UtilsFormatData";
import { renderFileIcon } from "./UtilsFileIcon";

const FileCard = ({ file }) => {
  if (!file || !file.name || !file.size) {
    return null;
  }

  return (
    <Flex align="center" p={2} borderRadius="md" w='100%'>
      <Box>{renderFileIcon(file.name)}</Box>
      <VStack align="start" spacing={0} ml={3} flex="1" color="gray.600">
        <Text fontWeight="bold" fontSize="md">
          {file.name.length > 30 ? `${file.name.substring(0, 25)}...` : file.name}
        </Text>
        <Text fontSize="md">{formatFileSize(file.size)}</Text>
      </VStack>
    </Flex>
  );
};

export default FileCard;
