// client/src/components/Projects/Modals/ProjectsEditTaskModal.jsx

import React, { useState } from "react";
import { Button, FormControl, FormLabel, Input, HStack } from "@chakra-ui/react";
import ReusableModal from "../9 - General Modals/ReusableModal";

const EditTaskModal = ({ isOpen, onClose, task, onSave }) => {
  const [taskName, setTaskName] = useState(task.name);

  const handleSave = () => {
    if (taskName.trim() === "") {
      alert("Task name cannot be empty.");
      return;
    }

    onSave({ ...task, name: taskName });
  };

  // Define the footer buttons
  const footerButtons = (
    <HStack spacing={3}>
      <Button variant="ghost" onClick={onClose}>
        Cancel
      </Button>
      <Button colorScheme="blue" onClick={handleSave}>
        Save
      </Button>
    </HStack>
  );

  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Task"
      footerButtons={footerButtons}
      size="md" // Optional: Adjust size if needed
    >
      <FormControl id="task-name" isRequired>
        <FormLabel>Task Name</FormLabel>
        <Input
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          placeholder="Enter new task name"
        />
      </FormControl>
    </ReusableModal>
  );
};

export default EditTaskModal;
