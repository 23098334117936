import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../5 - General/Socket/SocketContext";
import { useQueryClient } from "@tanstack/react-query";

const Logout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { socket } = useSocket();

  useEffect(() => {
    if (socket) {
      socket.disconnect();
    }
    queryClient.clear();
    localStorage.clear();
    navigate("/login");
  }, [socket, navigate, queryClient]);

  return null;
};

export default Logout;
