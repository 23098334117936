// src/components/Messages.js

import React, { useContext } from "react";
import { MessagesContext } from "../5 - General/Context/MessagesContext";
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Spinner, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { PiPlus } from "react-icons/pi";
import { HiUser, HiUserGroup } from "react-icons/hi2";
import MessagesConversationsContainer from "./MessagesConversationsContainer";
import MessagesActiveChannel from "./MessagesActiveChannel";
import CreateMessageModal from "../3 - Modal/2 - Messages Modals/MessagesCreateMessageModal";
import DeleteChannelModal from "../3 - Modal/2 - Messages Modals/MessagesDeleteChannelModal";
import AddUserModal from "../3 - Modal/2 - Messages Modals/MessagesAddUserModal";
import ViewUsersInChannelModal from "../3 - Modal/2 - Messages Modals/MessagesViewUsersInChannelModal";
import ImagePreviewModal from "../3 - Modal/9 - General Modals/GeneralImagePreviewModal";
import CreateOneOnOneModal from "../3 - Modal/2 - Messages Modals/MessagesCreateOneOnOneModal";

const Messages = () => {
  const {
    currentChannelId,
    channels,
    modals,
    setModals,
    channelUsers, 
    handleCreateChannel,
    handleDeleteChannel,
    handleAddUserToChannel,
    handleRemoveUserFromChannel,
    selectedImage,
    isChannelIdsLoading,
    isLoadingChannels,
  } = useContext(MessagesContext);

  const borderColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("gray.600", "gray.300");

  return (
    <Flex direction="column" height="100%">
      {isLoadingChannels || isChannelIdsLoading ? (
        <Flex justify="center" align="center" height="100%">
          <VStack spacing={4}>
            <Text color={textColor}>Loading conversations...</Text>
            <Spinner size="xl" color="primary.500" />
          </VStack>
        </Flex>
      ) : channels.length === 0 ? (
        <Flex justify="center" width="100%">
          <Box
            p={8}
            border="1px solid"
            bg="gray.50"
            borderColor={borderColor}
            borderRadius="xl"
            textAlign="center"
            boxShadow="md"
            h="fit-content"
            width={["90%", "70%", "50%"]}
            mt="200px"
          >
            <Text fontSize="xl" fontWeight="bold">
              No conversations found.
            </Text>
            <Text fontSize='md' mt={2} color="gray.500">
              Create a new conversation to get started.
            </Text>
            <Menu>
              <MenuButton mt={4} as={Button} leftIcon={<PiPlus fontSize="18px" />} fontWeight="normal" colorScheme="primary">
                Create Conversation
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setModals((prev) => ({ ...prev, oneOnOne: true }))} icon={<HiUser fontSize="18px" />} fontSize="md">
                  Create a One on One Conversation
                </MenuItem>
                <MenuItem onClick={() => setModals((prev) => ({ ...prev, create: true }))} icon={<HiUserGroup fontSize="18px" />} fontSize="md">
                  Create a Group Conversation
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      ) : (
        <Flex direction={{ base: "column", md: "row" }} flex="1" overflow="hidden">
          {/* Conversation List Container */}
          <Box width={{ base: "100%", md: "40%" }} maxWidth="500px" borderRight="1px solid" borderColor={borderColor} overflow="hidden">
            <MessagesConversationsContainer />
          </Box>

          {/* Active Channel Container */}
          <Box flex="1" overflow="hidden">
            {currentChannelId ? (
              <MessagesActiveChannel />
            ) : (
              <Box pt="100px" height="100%" textAlign="center" color={textColor} flex="1">
                Choose a conversation to start chatting
              </Box>
            )}
          </Box>
        </Flex>
      )}
      {/* Modals */}
      {modals.create && (
        <CreateMessageModal
          isOpen={modals.create}
          onClose={() => setModals((prevModals) => ({ ...prevModals, create: false }))}
          onCreate={(name, members) => handleCreateChannel(name, members, false)}
        />
      )}
      {modals.delete && (
        <DeleteChannelModal
          isOpen={modals.delete}
          onClose={() => setModals((prevModals) => ({ ...prevModals, delete: false }))}
          onDelete={handleDeleteChannel}
        />
      )}
      {modals.addUser && (
        <AddUserModal
          isOpen={modals.addUser}
          onClose={() => setModals((prevModals) => ({ ...prevModals, addUser: false }))}
          existingUsers={channelUsers.map((user) => user._id)}
          onAddUser={handleAddUserToChannel}
        />
      )}
      {modals.viewUsers && (
        <ViewUsersInChannelModal
          isOpen={modals.viewUsers}
          onClose={() => setModals((prevModals) => ({ ...prevModals, viewUsers: false }))}
          users={channelUsers}
          onRemoveUser={handleRemoveUserFromChannel}
        />
      )}
      {modals.oneOnOne && (
        <CreateOneOnOneModal
          isOpen={modals.oneOnOne}
          onClose={() => setModals((prevModals) => ({ ...prevModals, oneOnOne: false }))}
          onCreate={(name, members) => handleCreateChannel(name, members, true)}
        />
      )}
      {modals.imagePreview && selectedImage && (
        <ImagePreviewModal
          isOpen={modals.imagePreview}
          onClose={() => setModals((prevModals) => ({ ...prevModals, imagePreview: false }))}
          selectedImage={selectedImage}
        />
      )}
    </Flex>
  );
};

export default Messages;
