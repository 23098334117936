// client/src/components/4 - API/API-Messages.js

import { fetchData, APIError } from "./FetchData";
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

// Fetch messages for a specific channel
export const getMessages = async (channelId, skip = 0, limit = 35) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/channels/get-messages/${channelId}?skip=${skip}&limit=${limit}`);
  return data;
};

// Upload a file to a channel
export const uploadFileToChannel = async (channelId, file) => {
  const formData = new FormData();
  formData.append("file", file);
  const data = await fetchData(`${API_BASE_URL}/api/v1/channels/upload/${channelId}`, "POST", formData, true);
  return data;
};

export const getChannelIds = async () => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/channels/get-channel-ids`);
  return data;
};

export const getChannelById = async (channelId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/channels/get-channel/${channelId}`);
  return data;
};

/**
 * Function to trigger download of a blob
 * @param {Blob} blob - The blob data to download
 * @param {string} filename - The desired filename for the downloaded file
 */
export const downloadBlob = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

/**
 * Function to download a single file via S3 pre-signed URL
 * @param {string} backendFileName - The backend file name (S3 object key)
 * @param {string} companyId - The company ID associated with the file
 */
export const downloadFile = async (backendFileName, companyId) => {
  const fullUrl = `${API_BASE_URL}/api/v1/channels/uploads/messages/${companyId}/${backendFileName}/download`;
  try {
    const response = await fetch(fullUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData?.message || "Failed to download file.";
      throw new APIError(errorMessage, response.status, errorData);
    }

    const data = await response.json();
    if (data.status === "ok") {
      const downloadUrl = data.downloadUrl;
      const filename = data.filename || 'download'; // Fallback filename
      return { downloadUrl, filename };
    } else {
      throw new APIError(data.message || "Failed to download file.", response.status, data);
    }
  } catch (error) {
    if (error instanceof APIError) {
      throw error;
    } else {
      throw new APIError(error.message || "An unexpected error occurred.", 500, null);
    }
  }
};

/**
 * Function to download a single file using a direct download link
 * @param {string} downloadLink - The direct download link (pre-signed URL)
 */
export const downloadFileWithLink = async (downloadLink) => {
  try {
    // No need to fetch the download link; directly trigger the download
    window.location.href = downloadLink;
  } catch (error) {
    throw new APIError(error.message || "An unexpected error occurred.", 500, null);
  }
};
