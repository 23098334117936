import React, { useContext } from "react";
import { Box, SimpleGrid, Heading, Text, Flex, Button, Spinner, Image, useColorModeValue, AspectRatio } from "@chakra-ui/react";
import { FaProjectDiagram, FaChevronRight, FaEnvelope, FaFileAlt, FaFileContract, FaMoneyBillWave } from "react-icons/fa";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";

const Dashboard = () => {
  const { loadingWorkspaces, currentUser } = useContext(WorkspaceContext);
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const lightTextColor = useColorModeValue("gray.700", "gray.300");
  const headerBg = useColorModeValue("gray.50", "gray.800");

  if (loadingWorkspaces) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box height="100vh" overflowY="hidden">
      {/* Fixed Header */}
      <Box position="fixed" top="0" w="100%" h="80px" px={4} borderBottom="1px solid" borderColor={borderColor} zIndex="1000" bg={headerBg}>
        <Text fontSize={{ base: "xl", md: "2xl" }} pl={{ base: "40px", md: 8 }} fontWeight="600" color={lightTextColor} lineHeight="80px">
          Welcome, {currentUser?.firstName}!
        </Text>
      </Box>

      {/* Scrollable Content */}
      <Box mt="80px" pt={6} pb={8} overflowY="auto" height="calc(100vh - 80px)" px={{ base: 4, md: 8 }}>
        <Flex justify="start" align="center" mb={8} px={4}>
          <Box>
            <Heading size={{ base: "md", md: "lg" }} mb={1}>
              Welcome to FG Consultant!
            </Heading>
            <Text fontSize={{ base: "sm", md: "md" }} color="gray.600">
              Organize your work and improve your performance with us here!
            </Text>
          </Box>
        </Flex>

        <Box px={4}>
          <Heading size={{ base: "sm", md: "md" }} mb={4}>
            Try things out!
          </Heading>
        </Box>

        {/* Action Cards */}
        <SimpleGrid w="100%" columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={4} px={4}>
          <DashboardCard
            title="What's New"
            description="Stay up to date with the latest features and updates."
            icon={FaMoneyBillWave}
            buttonText="Learn more"
            imageSrc="https://images.pexels.com/photos/1089440/pexels-photo-1089440.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            onButtonClick={() => (window.location.href = "/dashboard")}
            button2Text="Go to Updates"
            onButton2Click={() => (window.location.href = "/dashboard")}
          />
          <DashboardCard
            title="Messages"
            description="Communicate securely with your clients and team members."
            icon={FaEnvelope}
            buttonText="Learn more"
            imageSrc="https://images.pexels.com/photos/5053765/pexels-photo-5053765.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            onButtonClick={() => (window.location.href = "/dashboard/settings/messages")}
            button2Text="Go to Messages"
            onButton2Click={() => (window.location.href = "/dashboard/messages")}
          />
          <DashboardCard
            title="Files"
            description="Manage all your important documents in one place."
            icon={FaFileAlt}
            buttonText="Learn more"
            imageSrc="https://images.pexels.com/photos/27396198/pexels-photo-27396198/free-photo-of-lumineux-leger-creatif-apple.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            onButtonClick={() => (window.location.href = "/dashboard/settings/files")}
            button2Text="Go to Files"
            onButton2Click={() => (window.location.href = "/dashboard/files")}
          />
          <DashboardCard
            title="Projects"
            description="Keep track of your ongoing projects and deadlines."
            icon={FaProjectDiagram}
            buttonText="Learn more"
            imageSrc="https://images.pexels.com/photos/416405/pexels-photo-416405.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            onButtonClick={() => (window.location.href = "/dashboard/settings/projects")}
            button2Text="Go to Projects"
            onButton2Click={() => (window.location.href = "/dashboard/projects")}
          />
          <DashboardCard
            title="Contracts"
            description="Create and manage client contracts efficiently."
            icon={FaFileContract}
            buttonText="Learn more"
            imageSrc="https://images.pexels.com/photos/48148/document-agreement-documents-sign-48148.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            onButtonClick={() => (window.location.href = "/dashboard/settings/contracts")}
            button2Text="Go to Contracts"
            onButton2Click={() => (window.location.href = "/dashboard/contracts")}
          />
        </SimpleGrid>
        <Text fontSize={{ base: "sm", md: "xs" }} color="gray.500" textAlign="center" mt={8} px={{ base: 8, md: 0 }} width="100%">
          © 2024 Fab Innov. All rights reserved. <br /> Made by Fabien Gaudreau
        </Text>
      </Box>
    </Box>
  );
};

const DashboardCard = ({ title, description, buttonText, imageSrc, onButtonClick, onButton2Click, button2Text }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const lightTextColor = useColorModeValue("gray.500", "gray.400");
  return (
    <Box maxW={{ base: "100%", sm: "300px" }} bg={bgColor} boxShadow="base" border="1px solid" borderColor={borderColor} borderRadius="md" p={5}>
      {imageSrc && (
        <AspectRatio ratio={2 / 1} mb={4}>
          <Image src={imageSrc} alt={title} borderRadius="md" />
        </AspectRatio>
      )}
      <Heading fontWeight="bold" fontSize={{ base: "xl", md: "2xl" }} mb={2}>
        {title}
      </Heading>
      <Text fontSize={{ base: "sm", md: "md" }} color={lightTextColor} mb={4}>
        {description}
      </Text>
      <Flex
        w="100%"
        direction={{ base: "column", sm: "row" }}
        justifyContent="space-between"
        align="center"
        borderTop="1px solid"
        borderColor={borderColor}
        pt={4}
      >
        <Button
          fontWeight="500"
          size={{ base: "xs", md: "xs" }}
          rightIcon={<FaChevronRight />}
          colorScheme="gray"
          variant="ghost"
          onClick={onButtonClick}
          mb={{ base: 2, sm: 0 }}
        >
          {buttonText}
        </Button>
        <Button fontWeight="400" variant="solid" color="white" bg="black" _hover={{ bg: "gray.700" }} onClick={onButton2Click}>
          {button2Text}
        </Button>
      </Flex>
    </Box>
  );
};

export default Dashboard;
