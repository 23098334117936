import React, { createContext, useState, useEffect, useCallback, useContext } from "react";
import { useSocket } from "../Socket/SocketContext";
import {
  fetchUsersFromCompany,
  fetchProjects,
  fetchArchivedProjects,
  fetchProjectsTimeline,
  createProject,
  editProject,
  deleteProject,
  archiveProject,
  fetchTasks,
  createTask,
  editTask,
  deleteTask,
  fetchSubtasks,
  updateTaskOrder,
  updateTaskColor,
  logTime,
  editTimeLog,
  deleteTimeLog,
  assignMembers,
  assignUsersToTask,
  fetchHistory,
  addComment,
  editComment,
  deleteComment,
  uploadFilesToTask,
  deleteFileFromTask,
  downloadFileFromTask,
} from "../../4 - API/API-Projects";
import { WorkspaceContext } from "./WorkspaceContext";

export const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const { currentWorkspace } = useContext(WorkspaceContext);
  const { socket } = useSocket();

  // State variables
  const [companies, setCompanies] = useState([]);
  const [activeProjects, setActiveProjects] = useState([]);
  const [archivedProjects, setArchivedProjects] = useState([]);
  const [projectsTimeline, setProjectsTimeline] = useState([]);
  const [archivedProjectsFetched, setArchivedProjectsFetched] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [subtasks, setSubtasks] = useState({});
  const [currentTask, setCurrentTask] = useState(null);
  const [currentSubtask, setCurrentSubtask] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [currentProjectHistory, setCurrentProjectHistory] = useState(null);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState(false);
  const [loadingSubtasks, setLoadingSubtasks] = useState(false);

  useEffect(() => {
    setCurrentCompany(currentWorkspace);
    setCurrentProject(null);
    setCurrentProjectHistory(null);
  }, [currentWorkspace, setCurrentCompany, setCurrentProject, setCurrentProjectHistory]);

  // **Fetch projects when the current company changes**
  useEffect(() => {
    if (currentCompany) {
      setActiveProjects([]);
      setArchivedProjects([]);
      setArchivedProjectsFetched(false);
      const fetchProjectsForCompany = async () => {
        try {
          const fetchedProjects = await fetchProjects(currentCompany._id);
          setActiveProjects(fetchedProjects);
          const fetchProjectsTimelineData = await fetchProjectsTimeline(currentCompany._id);
          setProjectsTimeline(fetchProjectsTimelineData);
        } catch (error) {
          console.error("Error fetching projects:", error);
        }
      };

      fetchProjectsForCompany();
    }
  }, [currentCompany]);

  const fetchProjectsForCompany = useCallback(async (companyId) => {
    setLoadingProjects(true);
    try {
      const fetchedProjects = await fetchProjects(companyId);
      setActiveProjects(fetchedProjects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setLoadingProjects(false);
    }
  }, []);

  const fetchTasksForProject = useCallback(async (projectId) => {
    setLoadingTasks(true);
    try {
      const response = await fetchTasks(projectId);
      if (Array.isArray(response)) {
        setTasks(response);
      } else {
        console.error("No tasks found or invalid response structure:", response);
      }
    } catch (error) {
      console.error("Error fetching main tasks:", error);
    } finally {
      setLoadingTasks(false);
    }
  }, []);

  useEffect(() => {
    if (currentCompany) {
      setActiveProjects([]);
      setArchivedProjects([]);
      setArchivedProjectsFetched(false);
      fetchProjectsForCompany(currentCompany._id);
    }
  }, [currentCompany, fetchProjectsForCompany]);

  useEffect(() => {
    if (currentProject) {
      fetchTasksForProject(currentProject._id);
    } else {
      setTasks([]);
    }
  }, [currentProject, fetchTasksForProject]);

  // **Fetch project history**
  const fetchProjectHistory = useCallback(async (projectId) => {
    try {
      const fetchedProject = await fetchHistory(projectId);
      setCurrentProjectHistory(fetchedProject);
    } catch (error) {
      console.error("Error fetching project history:", error);
    }
  }, []);

  // **Fetch tasks when the current project changes**
  useEffect(() => {
    if (currentProject) {
      const fetchMainTasks = async () => {
        try {
          const response = await fetchTasks(currentProject._id);
          if (Array.isArray(response)) {
            setTasks(response);
          } else {
            console.error("No tasks found or invalid response structure:", response);
          }
        } catch (error) {
          console.error("Error fetching main tasks:", error);
        }
      };
      fetchMainTasks();
    }
  }, [currentProject]);

  // **Fetch subtasks for a specific task**
  const fetchSubtasksForTask = useCallback(
    async (taskId) => {
      if (!currentProject || !taskId) return;

      try {
        const response = await fetchSubtasks(taskId);
        if (Array.isArray(response)) {
          setSubtasks((prev) => ({ ...prev, [taskId]: response }));
        } else {
          console.error("No subtasks found or invalid response structure:", response);
        }
      } catch (error) {
        console.error("Error fetching subtasks:", error);
      }
    },
    [currentProject]
  );

  // **Fetch subtasks for the current task**
  useEffect(() => {
    if (currentTask && currentTask._id) {
      setLoadingSubtasks(true);
      fetchSubtasksForTask(currentTask._id)
        .then(() => setLoadingSubtasks(false))
        .catch(() => setLoadingSubtasks(false));
    }
  }, [currentTask, fetchSubtasksForTask]);

  // **Set up socket listeners for real-time updates**
  useEffect(() => {
    if (!socket) return;

    const handleNewProject = (data) => {
      const project = data.project;
      if (project.archived) {
        setArchivedProjects((prevProjects) => [...prevProjects, project]);
      } else {
        setActiveProjects((prevProjects) => [...prevProjects, project]);
      }
    };

    const handleNewTask = (data) => {
      if (currentProject && data.task.projectId === currentProject._id) {
        setTasks((prevTasks) => [...prevTasks, data.task]);
      }
    };

    const handleNewComment = (data) => {
      const { taskId, comment } = data;
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId
            ? {
                ...task,
                comments: [...task.comments, comment],
              }
            : task
        )
      );
      // Additionally, update subtasks if necessary
      Object.keys(subtasks).forEach((parentId) => {
        if (subtasks[parentId].some((subtask) => subtask._id === taskId)) {
          setSubtasks((prevSubtasks) => ({
            ...prevSubtasks,
            [parentId]: prevSubtasks[parentId].map((subtask) =>
              subtask._id === taskId
                ? {
                    ...subtask,
                    comments: [...subtask.comments, comment],
                  }
                : subtask
            ),
          }));
        }
      });
    };

    // **Register socket listeners**
    socket.on("newProject", handleNewProject);
    socket.on("newTask", handleNewTask);
    socket.on("newComment", handleNewComment);

    return () => {
      // **Clean up socket listeners on unmount**
      socket.off("newProject", handleNewProject);
      socket.off("newTask", handleNewTask);
      socket.off("newComment", handleNewComment);
    };
  }, [socket, currentProject, subtasks]);

  // **Emit a socket event to add a comment**
  const addCommentWithSocket = (taskId, message, callback) => {
    if (!socket) {
      console.error("Socket is not initialized");
      return;
    }

    socket.emit("addCommentToTask", { taskId, message }, (response) => {
      if (response.success) {
        setTasks((prevTasks) =>
          prevTasks.map((task) =>
            task._id === taskId
              ? {
                  ...task,
                  comments: [...task.comments, response.comment],
                }
              : task
          )
        );
        // Update subtasks if necessary
        Object.keys(subtasks).forEach((parentId) => {
          if (subtasks[parentId].some((subtask) => subtask._id === taskId)) {
            setSubtasks((prevSubtasks) => ({
              ...prevSubtasks,
              [parentId]: prevSubtasks[parentId].map((subtask) =>
                subtask._id === taskId
                  ? {
                      ...subtask,
                      comments: [...subtask.comments, response.comment],
                    }
                  : subtask
              ),
            }));
          }
        });
      } else {
        console.error("Error adding comment:", response.message);
      }
      if (callback) callback(response);
    });
  };

  // **Project Management Functions**
  const createProjectInContext = async (companyId, projectData) => {
    try {
      const newProject = await createProject(companyId, projectData);
      setActiveProjects((prevProjects) => [...prevProjects, newProject]);
      if (socket) socket.emit("newProject", { project: newProject });
      return newProject;
    } catch (error) {
      console.error("Error creating project:", error);
      throw error;
    }
  };

  const editProjectInContext = async (projectId, updatedData) => {
    try {
      const updatedProject = await editProject(projectId, updatedData);
      if (updatedProject.archived) {
        setArchivedProjects((prevProjects) => prevProjects.map((proj) => (proj._id === projectId ? updatedProject : proj)));
        setActiveProjects((prevProjects) => prevProjects.filter((proj) => proj._id !== projectId));
      } else {
        setActiveProjects((prevProjects) => prevProjects.map((proj) => (proj._id === projectId ? updatedProject : proj)));
        setArchivedProjects((prevProjects) => prevProjects.filter((proj) => proj._id !== projectId));
      }
      if (currentProject && currentProject._id === projectId) {
        setCurrentProject(updatedProject);
      }
      return updatedProject;
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };

  const deleteProjectInContext = async (projectId) => {
    try {
      await deleteProject(projectId);
      setActiveProjects((prevProjects) => prevProjects.filter((proj) => proj._id !== projectId));
      setArchivedProjects((prevArchived) => prevArchived.filter((proj) => proj._id !== projectId));
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  const archiveProjectInContext = async (projectId) => {
    try {
      const updatedProject = await archiveProject(projectId);
      if (updatedProject.archived) {
        // Project has been archived
        setActiveProjects((prevProjects) => prevProjects.filter((proj) => proj._id !== projectId));
        setArchivedProjects((prevArchived) => [...prevArchived, updatedProject]);
      } else {
        // Project has been unarchived
        setArchivedProjects((prevArchived) => prevArchived.filter((proj) => proj._id !== projectId));
        setActiveProjects((prevProjects) => [...prevProjects, updatedProject]);
      }
    } catch (error) {
      console.error("Error archiving/unarchiving project:", error);
    }
  };

  const fetchArchivedProjectsForCompany = useCallback(async (companyId) => {
    try {
      const fetchedArchivedProjects = await fetchArchivedProjects(companyId);
      setArchivedProjects(fetchedArchivedProjects);
      setArchivedProjectsFetched(true);
    } catch (error) {
      console.error("Error fetching archived projects:", error);
    }
  }, []);

  // **Task Management Functions**
  const createTaskInContext = async (projectId, taskData, parentTaskId = null) => {
    try {
      const newTask = await createTask(projectId, taskData, parentTaskId);
      if (parentTaskId) {
        setSubtasks((prev) => ({
          ...prev,
          [parentTaskId]: [...(prev[parentTaskId] || []), newTask],
        }));
      } else {
        setTasks((prevTasks) => [...prevTasks, newTask]);
      }
      return newTask;
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  const deleteTaskInContext = async (taskId, parentTaskId = null) => {
    try {
      await deleteTask(taskId);
      if (parentTaskId) {
        setSubtasks((prev) => ({
          ...prev,
          [parentTaskId]: prev[parentTaskId].filter((subtask) => subtask._id !== taskId),
        }));
      } else {
        setTasks((prevTasks) => prevTasks.filter((task) => task._id !== taskId));
      }
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const editTaskInContext = async (taskId, updatedData) => {
    try {

      const updatedTask = await editTask(taskId, updatedData);

      setTasks((prevTasks) => prevTasks.map((task) => (task._id === taskId ? updatedTask : task)));

      Object.keys(subtasks).forEach((parentId) => {
        if (subtasks[parentId].some((subtask) => subtask._id === taskId)) {
          setSubtasks((prevSubtasks) => ({
            ...prevSubtasks,
            [parentId]: prevSubtasks[parentId].map((subtask) => (subtask._id === taskId ? updatedTask : subtask)),
          }));
        }
      });

      if (currentTask && currentTask._id === taskId) {
        setCurrentTask(updatedTask);
      }

      return updatedTask;
    } catch (error) {
      console.error("Error editing task in context:", error);
      throw error; // Re-throw to allow upstream handling
    }
  };

  const updateTaskOrderInContext = async (tasksToUpdate) => {
    try {
      await updateTaskOrder(tasksToUpdate);
      const updatedTasks = tasks.map((task) => {
        const updated = tasksToUpdate.find((t) => t._id === task._id);
        return updated ? { ...task, status: updated.status, order: updated.order } : task;
      });
      setTasks(updatedTasks);
    } catch (error) {
      console.error("Error updating task order:", error);
    }
  };

  const updateTaskColorInContext = async (taskId, color) => {
    try {
      const updatedTask = await updateTaskColor(taskId, color);
      setTasks((prevTasks) => prevTasks.map((task) => (task._id === taskId ? { ...task, color: updatedTask.color } : task)));
      // Also update in subtasks if exists
      Object.keys(subtasks).forEach((parentId) => {
        setSubtasks((prevSubtasks) => ({
          ...prevSubtasks,
          [parentId]: prevSubtasks[parentId].map((subtask) => (subtask._id === taskId ? { ...subtask, color: updatedTask.color } : subtask)),
        }));
      });
    } catch (error) {
      console.error("Error updating task color:", error);
    }
  };

  const logTimeInContext = async (taskId, timeData) => {
    try {
      const newTimeLog = await logTime(taskId, timeData);

      // Update tasks
      setTasks((prevTasks) => prevTasks.map((task) => (task._id === taskId ? { ...task, timeLogs: [...task.timeLogs, newTimeLog] } : task)));

      // Update subtasks
      Object.keys(subtasks).forEach((parentId) => {
        setSubtasks((prevSubtasks) => ({
          ...prevSubtasks,
          [parentId]: prevSubtasks[parentId].map((subtask) =>
            subtask._id === taskId ? { ...subtask, timeLogs: [...subtask.timeLogs, newTimeLog] } : subtask
          ),
        }));
      });

      // **Update currentTask**
      if (currentTask && currentTask._id === taskId) {
        setCurrentTask({
          ...currentTask,
          timeLogs: [...currentTask.timeLogs, newTimeLog],
        });
      }
    } catch (error) {
      console.error("Error logging time:", error);
    }
  };

  const assignMembersInContext = async (projectId, memberIds) => {
    try {
      const updatedProject = await assignMembers(projectId, memberIds);
      setActiveProjects((prevProjects) => prevProjects.map((proj) => (proj._id === projectId ? { ...proj, members: updatedProject.members } : proj)));
      setArchivedProjects((prevProjects) =>
        prevProjects.map((proj) => (proj._id === projectId ? { ...proj, members: updatedProject.members } : proj))
      );
    } catch (error) {
      console.error("Error assigning members:", error);
    }
  };

  const assignUsersToTaskInContext = async (taskId, userIds) => {
    try {
      const updatedTask = await assignUsersToTask(taskId, userIds);
      setTasks((prevTasks) => prevTasks.map((task) => (task._id === taskId ? { ...task, assignedUsers: updatedTask.assignedUsers } : task)));
      // Also update in subtasks if exists
      Object.keys(subtasks).forEach((parentId) => {
        setSubtasks((prevSubtasks) => ({
          ...prevSubtasks,
          [parentId]: prevSubtasks[parentId].map((subtask) =>
            subtask._id === taskId ? { ...subtask, assignedUsers: updatedTask.assignedUsers } : subtask
          ),
        }));
      });
    } catch (error) {
      console.error("Error assigning users to task:", error);
    }
  };

  // Add this function to the context
  const addCommentToTaskInContext = async (taskId, message) => {
    try {
      const newComment = await addComment(taskId, message); // newComment is the new comment object

      // Update currentTask in state by appending the new comment
      setCurrentTask((prevTask) => ({
        ...prevTask,
        comments: [...prevTask.comments, newComment],
      }));

      // Update the tasks list if necessary
      setTasks((prevTasks) => prevTasks.map((task) => (task._id === taskId ? { ...task, comments: [...task.comments, newComment] } : task)));
    } catch (error) {
      console.error("Error adding comment to task:", error);
      throw error;
    }
  };

  const editCommentInTaskInContext = async (taskId, commentId, message) => {
    try {
      const updatedComment = await editComment(taskId, commentId, message);

      // Update currentTask in state
      setCurrentTask((prevTask) => {
        if (prevTask && prevTask._id === taskId) {
          return {
            ...prevTask,
            comments: prevTask.comments.map((comment) => (comment._id === commentId ? updatedComment : comment)),
          };
        }
        return prevTask;
      });

      // Update the tasks list
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId
            ? {
                ...task,
                comments: task.comments.map((comment) => (comment._id === commentId ? updatedComment : comment)),
              }
            : task
        )
      );
    } catch (error) {
      console.error("Error editing comment in task:", error);
      throw error;
    }
  };

  const deleteCommentFromTaskInContext = async (taskId, commentId) => {
    try {
      await deleteComment(taskId, commentId);

      // Update currentTask in state
      setCurrentTask((prevTask) => {
        if (prevTask && prevTask._id === taskId) {
          return {
            ...prevTask,
            comments: prevTask.comments.filter((comment) => comment._id !== commentId),
          };
        }
        return prevTask;
      });

      // Update the tasks list
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId
            ? {
                ...task,
                comments: task.comments.filter((comment) => comment._id !== commentId),
              }
            : task
        )
      );
    } catch (error) {
      console.error("Error deleting comment from task:", error);
      throw error;
    }
  };

  const uploadFilesToTaskInContext = async (taskId, files) => {
    try {
      const uploadedFiles = await uploadFilesToTask(taskId, files);
      setCurrentTask((prevTask) => ({
        ...prevTask,
        files: [...prevTask.files, ...uploadedFiles],
      }));
    } catch (error) {
      console.error("Error uploading files to task:", error);
      throw error;
    }
  };

  const deleteFileFromTaskInContext = async (taskId, fileId) => {
    try {
      await deleteFileFromTask(taskId, fileId);
      setCurrentTask((prevTask) => ({
        ...prevTask,
        files: prevTask.files.filter((file) => file._id !== fileId),
      }));
    } catch (error) {
      console.error("Error deleting file from task:", error);
      throw error;
    }
  };

  const editSubtaskInContext = async (subtaskId, updatedData) => {
    try {
      const updatedSubtask = await editTask(subtaskId, updatedData); // Reusing existing editTask API call

      // Find the parent task ID
      const parentTaskId = Object.keys(subtasks).find((parentId) => subtasks[parentId].some((subtask) => subtask._id === subtaskId));

      if (parentTaskId) {
        setSubtasks((prevSubtasks) => ({
          ...prevSubtasks,
          [parentTaskId]: prevSubtasks[parentTaskId].map((subtask) => (subtask._id === subtaskId ? updatedSubtask : subtask)),
        }));
      }

      // If the subtask is currently selected as currentTask, update it
      if (currentTask && currentTask._id === subtaskId) {
        setCurrentTask(updatedSubtask);
      }

      return updatedSubtask;
    } catch (error) {
      console.error("Error editing subtask:", error);
      throw error;
    }
  };

  const editTimeLogInContext = async (taskId, logId, updatedLogData) => {
    try {
      const updatedLog = await editTimeLog(taskId, logId, updatedLogData);

      // Update tasks
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId
            ? {
                ...task,
                timeLogs: task.timeLogs.map((log) => (log._id === logId ? updatedLog : log)),
              }
            : task
        )
      );

      // Update subtasks
      Object.keys(subtasks).forEach((parentId) => {
        setSubtasks((prevSubtasks) => ({
          ...prevSubtasks,
          [parentId]: prevSubtasks[parentId].map((subtask) =>
            subtask._id === taskId
              ? {
                  ...subtask,
                  timeLogs: subtask.timeLogs.map((log) => (log._id === logId ? updatedLog : log)),
                }
              : subtask
          ),
        }));
      });

      // **Update currentTask**
      if (currentTask && currentTask._id === taskId) {
        setCurrentTask({
          ...currentTask,
          timeLogs: currentTask.timeLogs.map((log) => (log._id === logId ? updatedLog : log)),
        });
      }

      return updatedLog;
    } catch (error) {
      console.error("Error editing time log:", error);
      throw error;
    }
  };

  const deleteTimeLogInContext = async (taskId, logId) => {
    try {
      await deleteTimeLog(taskId, logId);

      // Update tasks
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task._id === taskId
            ? {
                ...task,
                timeLogs: task.timeLogs.filter((log) => log._id !== logId),
              }
            : task
        )
      );

      // Update subtasks
      Object.keys(subtasks).forEach((parentId) => {
        setSubtasks((prevSubtasks) => ({
          ...prevSubtasks,
          [parentId]: prevSubtasks[parentId].map((subtask) =>
            subtask._id === taskId
              ? {
                  ...subtask,
                  timeLogs: subtask.timeLogs.filter((log) => log._id !== logId),
                }
              : subtask
          ),
        }));
      });

      // **Update currentTask**
      if (currentTask && currentTask._id === taskId) {
        setCurrentTask({
          ...currentTask,
          timeLogs: currentTask.timeLogs.filter((log) => log._id !== logId),
        });
      }
    } catch (error) {
      console.error("Error deleting time log:", error);
      throw error;
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        // States
        companies,
        currentCompany,
        activeProjects,
        archivedProjects,
        archivedProjectsFetched,
        tasks,
        subtasks,
        currentTask,
        projectsTimeline,
        currentSubtask,
        currentProject,
        currentProjectHistory,
        loadingProjects,
        loadingTasks,
        loadingSubtasks,

        // Setters
        setCompanies,
        setActiveProjects,
        setArchivedProjects,
        setArchivedProjectsFetched,
        setTasks,
        setSubtasks,
        setCurrentTask,
        setCurrentSubtask,
        setCurrentCompany,
        setCurrentProject,
        setCurrentProjectHistory,

        // Fetching Data
        fetchProjectHistory,
        fetchProjects,
        fetchTasks,
        fetchUsersFromCompany,
        fetchArchivedProjects,
        fetchArchivedProjectsForCompany,
        fetchSubtasksForTask,
        fetchProjectsForCompany,
        fetchTasksForProject,
        downloadFileFromTask,

        // Project Management
        createProject: createProjectInContext,
        editProject: editProjectInContext,
        deleteProject: deleteProjectInContext,
        archiveProject: archiveProjectInContext,

        // Task Management
        createTask: createTaskInContext,
        editTask: editTaskInContext,
        deleteTask: deleteTaskInContext,
        updateTaskOrder: updateTaskOrderInContext,
        updateTaskColor: updateTaskColorInContext,
        logTime: logTimeInContext,
        assignMembers: assignMembersInContext,
        assignUsersToTask: assignUsersToTaskInContext,
        addCommentToTask: addCommentToTaskInContext,
        editCommentInTask: editCommentInTaskInContext,
        deleteCommentFromTask: deleteCommentFromTaskInContext,
        uploadFilesToTask: uploadFilesToTaskInContext,
        deleteFileFromTask: deleteFileFromTaskInContext,
        editSubtask: editSubtaskInContext,
        editTimeLog: editTimeLogInContext,
        deleteTimeLog: deleteTimeLogInContext,

        // Socket
        socket,
        addCommentWithSocket,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
