import React, { useState, useContext } from "react";
import { Button, FormControl, FormLabel, Input, useColorModeValue } from "@chakra-ui/react";
import { FilesContext } from "../../5 - General/Context/FilesContext";
import ReusableModal from "../9 - General Modals/ReusableModal";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";

const FileCreateLinkModal = () => {
  const { onCloseCreateLinkModal, isCreateLinkModalOpen, handleCreateLink } = useContext(FilesContext);
  const [linkName, setLinkName] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const customToast = useCustomToast();

  const handleSubmit = async () => {
    if (!linkName.trim() || !linkUrl.trim()) {
      customToast({
        title: "Invalid input",
        description: "Please provide both a name and a URL for the link.",
        status: "warning",
      });
      return;
    }
    setIsSubmitting(true);
    try {
      await handleCreateLink(linkName, linkUrl);
      setLinkName("");
      setLinkUrl("");
      onCloseCreateLinkModal();
    } catch (error) {
      console.error("Error creating link:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    onCloseCreateLinkModal();
    setLinkName("");
    setLinkUrl("");
  };

  const footerButtons = (
    <>
      <Button
        variant="ghost"
        color={useColorModeValue("gray.600", "gray.300")}
        _hover={{
          color: useColorModeValue("black", "white"),
          bg: useColorModeValue("gray.100", "gray.500"),
        }}
        onClick={handleClose}
      >
        Cancel
      </Button>
      <Button colorScheme="blue" onClick={handleSubmit} isLoading={isSubmitting} disabled={!linkName.trim() || !linkUrl.trim()}>
        Create Link
      </Button>
    </>
  );

  return (
    <ReusableModal isOpen={isCreateLinkModalOpen} onClose={handleClose} title="Create a New Link" footerButtons={footerButtons}>
      <FormControl id="linkName" isRequired>
        <FormLabel>Name</FormLabel>
        <Input
          placeholder="Enter a name for the link"
          value={linkName}
          onChange={(e) => setLinkName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}
        />
      </FormControl>
      <FormControl id="linkUrl" mt={4} isRequired>
        <FormLabel>URL</FormLabel>
        <Input
          placeholder="Enter the URL"
          value={linkUrl}
          onChange={(e) => setLinkUrl(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSubmit();
            }
          }}
        />
      </FormControl>
    </ReusableModal>
  );
};

export default FileCreateLinkModal;
