// src/components/ProjectsTasksGanttChart.js

import React, { useContext, useEffect, useState, useCallback } from "react";
import { Box, Spinner, Text } from "@chakra-ui/react";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import "@fontsource-variable/inter";
import GanttChart from "./Reusable/GanttChart";
import EditTaskNameModal from "../3 - Modal/3 - Projects Modals/EditTaskNameModal";

const ProjectsTasksGanttChart = ({ onTaskClick }) => {
  const { currentProject, tasks, subtasks, editTask, loadingTasks, fetchSubtasksForTask } = useContext(ProjectContext);
  const [ganttTasks, setGanttTasks] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null);

  // Transform tasks and subtasks for GanttChart
  const transformTasks = useCallback(() => {
    return (tasks || []).map((task) => ({
      _id: task._id.toString(),
      name: task.name,
      startDate: task.startDate,
      dueDate: task.dueDate,
      progress: task.progress || 0,
      dependencies: (task.dependencies || []).map((dep) => (typeof dep === "string" ? dep : dep._id.toString())),
      color: task.color || "#3182CE",
    }));
  }, [tasks]);

  useEffect(() => {
    console.log("Tasks:", tasks);
    console.log("Subtasks:", subtasks);
    console.log("Projects:", currentProject);
  }, [tasks, subtasks, currentProject]);

  useEffect(() => {
    setGanttTasks(transformTasks());
  }, [transformTasks]);

  // Handle task updates from GanttChart
  const handleTaskUpdate = async (taskId, updatedFields) => {
    try {
      const updatedTask = await editTask(taskId, updatedFields);
      return updatedTask;
    } catch (error) {
      console.error("Error updating task:", error);
      throw error;
    }
  };

  // Handle double click
  const handleDoubleClick = (task) => {
    setTaskToEdit(task);
    setIsEditModalOpen(true);
  };

  // Handle task selection
  const handleTaskSelect = (taskId) => {
    onTaskClick(taskId);
  };

  // Handle tasks deletion
  const handleTasksDelete = (taskIds) => {
    console.log("Deleting tasks:", taskIds);
  };

  // Ensure subtasks are fetched when initial load
  useEffect(() => {
    (tasks || []).forEach((task) => {
      if (task && !subtasks[task._id]) {
        fetchSubtasksForTask(task._id);
      }
    });
  }, [tasks, subtasks, fetchSubtasksForTask]);

  return (
    <Box p={2}>
      {loadingTasks ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="600px">
          <Spinner size="xl" />
        </Box>
      ) : currentProject ? (
        <Box mt={8}>
          <GanttChart
            projects={currentProject ? [currentProject] : []}
            tasks={ganttTasks}
            subtasks={subtasks}
            onTaskDoubleClick={handleDoubleClick}
            onTaskSelect={handleTaskSelect}
            onTasksDelete={handleTasksDelete}
            onTaskUpdate={handleTaskUpdate}
            height={600}
          />
        </Box>
      ) : (
        <Box p={4} textAlign="center">
          <Text>No project selected to display tasks.</Text>
        </Box>
      )}

      {/* Edit Task Modal */}
      {taskToEdit && (
        <EditTaskNameModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          task={taskToEdit}
          onSave={(updatedTask) => {
            editTask(updatedTask._id, {
              name: updatedTask.name,
              __v: updatedTask.__v,
            });
            setIsEditModalOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default ProjectsTasksGanttChart;
