// src/components/Reusable/GanttChart.js

import React, { useRef, useContext, useMemo } from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Flex,
  Tooltip,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import dayjs from "dayjs";
import GanttTaskBar from "./GanttTaskBar";
import DependencyLine from "./GanttDependencyLine";
import { PiPencilSimpleLine, PiTrash, PiEye, PiCaretUpBold, PiCaretDownBold } from "react-icons/pi";
import { GanttProvider, GanttContext } from "./GanttContext";

const GanttChart = (props) => {
  return (
    <GanttProvider {...props}>
      <GanttChartContent />
    </GanttProvider>
  );
};

const GanttChartContent = () => {
  const {
    visibleTasks,
    handleTaskSelect,
    handleTaskDoubleClick,
    handleDeleteTask,
    selectedTaskId,
    dayWidth,
    rowHeight,
    calculateTaskPosition,
    chartRef,
    linking,
    mousePosition,
    isModalOpen,
    handleDeleteConfirmed,
    setIsModalOpen,
    linkSource,
    hoveredDay,
    earliestStart,
    collapseState,
    toggleCollapse,
  } = useContext(GanttContext);

  const sidebarRef = useRef(null);

  // Define the total number of days (should match the header's length)
  const totalDays = 120;
  const totalWidth = dayWidth * totalDays;

  // Calculate the total height based on the number of visible tasks
  const totalHeight = visibleTasks.length * rowHeight;

  // Memoize the header to prevent unnecessary recalculations
  const header = useMemo(() => {
    return Array.from({ length: totalDays }).map((_, index) => {
      const date = dayjs(earliestStart).add(index, "day");
      const isHoveredDay = hoveredDay && date.isSame(hoveredDay, "day");

      return (
        <Box
          key={`header-${index}`}
          minW={`${dayWidth}px`}
          border="1px solid #e2e8f0"
          textAlign="center"
          p={2}
          transition="background-color 0.2s"
          bg={isHoveredDay ? "primary.200" : "white"}
        >
          <Text fontSize="sm">{date.format("MMM D")}</Text>
        </Box>
      );
    });
  }, [totalDays, dayWidth, earliestStart, hoveredDay]);

  return (
    <Flex>
      {/* Sidebar with Task List */}
      <Box
        bg="gray.100"
        borderRight="1px solid #e2e8f0"
        height="600px"
        minW="200px"
        overflowY="auto"
        ref={sidebarRef}
        onScroll={() => {
          if (chartRef.current) {
            chartRef.current.scrollTop = sidebarRef.current.scrollTop;
          }
        }}
        css={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        pb="8px"
      >
        <VStack align="stretch" spacing={0}>
          <Text fontSize="lg" h="60px" fontWeight="bold">
            Tasks
          </Text>
          {visibleTasks.map((task, index) => {
            const taskKey = task._id ? `${task.type}-${task._id}` : `task-${index}`;
            return (
              <Flex
                key={taskKey}
                align="center"
                justify="space-between"
                bg={selectedTaskId === task._id ? "#bee3f8" : "transparent"}
                p={2}
                borderRadius="4px"
                _hover={{ bg: "#e2e8f0" }}
                cursor="pointer"
                height={`${rowHeight}px`}
                onClick={() => handleTaskSelect(task._id)}
                role="button"
                aria-pressed={selectedTaskId === task._id}
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleTaskSelect(task._id);
                  }
                }}
              >
                {/* Collapse/Expand Icon */}
                {task.type !== "subtask" && (
                  <IconButton
                    icon={collapseState[task.type === "project" ? "projects" : "tasks"][task._id] ? <PiCaretDownBold /> : <PiCaretUpBold />}
                    size="xs"
                    aria-label={collapseState[task.type === "project" ? "projects" : "tasks"][task._id] ? "Collapse Subtasks" : "Expand Subtasks"}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleCollapse(task.type === "project" ? "projects" : "tasks", task._id);
                    }}
                  />
                )}

                {/* Task Name */}
                <Text fontSize="sm" isTruncated maxWidth="150px">
                  {task.name}
                </Text>

                {/* Action Buttons */}
                <HStack spacing={1}>
                  <Tooltip label="Open Task Details" hasArrow>
                    <IconButton
                      icon={<PiEye fontSize="18px" />}
                      size="sm"
                      variant="outline"
                      aria-label="Open Task Details"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTaskSelect(task._id);
                      }}
                    />
                  </Tooltip>
                  <Tooltip label="Edit Task" hasArrow>
                    <IconButton
                      icon={<PiPencilSimpleLine fontSize="18px" />}
                      size="sm"
                      variant="outline"
                      aria-label="Edit Task"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTaskDoubleClick(task);
                      }}
                    />
                  </Tooltip>
                  <Tooltip label="Delete Task" hasArrow>
                    <IconButton
                      icon={<PiTrash fontSize="18px" />}
                      size="sm"
                      variant="outline"
                      aria-label="Delete Task"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteTask(task._id);
                      }}
                    />
                  </Tooltip>
                </HStack>
              </Flex>
            );
          })}
        </VStack>
      </Box>

      {/* Gantt Chart Area */}
      <Box
        position="relative"
        width={`${totalWidth}px`} // Set width to totalWidth
        height="600px"
        overflow="auto"
        bg="gray.50"
        ref={chartRef}
        onScroll={() => {
          if (sidebarRef.current) {
            sidebarRef.current.scrollTop = chartRef.current.scrollTop;
          }
        }}
        css={{
          scrollbarWidth: "thin",
          msOverflowStyle: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
          },
        }}
      >
        {/* Header with Dates */}
        <Box display="flex" position="sticky" top="0" h={`${rowHeight}px`} bg="white" zIndex="10">
          {header}
        </Box>

        {/* Task Bars and Dependency Lines */}
        <Box position="relative" h={`${totalHeight}px`} w={`${totalWidth}px`}>
          {/* Dependency Lines */}
          <svg
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: `${totalWidth}px`, // Set SVG width to totalWidth
              height: `${totalHeight}px`, // Set SVG height to totalHeight
              overflow: "visible",
              zIndex: 1, // Ensure it's below task bars
            }}
          >
            <defs>
              <marker _id="arrow" markerWidth="5" markerHeight="5" refX="4.5" refY="2.5" orient="auto">
                <path d="M0,0 L5,2.5 L0,5 Z" fill="#6bb5ff" />
              </marker>
            </defs>
            {visibleTasks.map((task, index) =>
              (task.dependencies || []).map((depId) => {
                const toTaskIndex = visibleTasks.findIndex((t) => t._id === depId);
                const toTask = visibleTasks[toTaskIndex];
                const toCheckTask = visibleTasks.find((t) => t._id === depId);
                if (!toCheckTask) {
                  console.warn(`Dependency task with _id ${depId} not found for task ${task._id}`);
                  return null; // Skip rendering DependencyLine for invalid dependencies
                }
                if (!toTask) {
                  console.warn(`Dependency not found for task ${task._id}: Dependency _id ${depId}`);
                  return null;
                }

                const toPos = {
                  ...calculateTaskPosition(toTask),
                  top: toTaskIndex * rowHeight,
                };

                const fromPos = {
                  ...calculateTaskPosition(task),
                  top: index * rowHeight,
                };

                const depKey = `${toTask._id}-${task._id}`;

                return (
                  <DependencyLine
                    key={depKey}
                    fromTask={{
                      ...fromPos,
                      _id: task._id,
                      name: task.name,
                    }}
                    toTask={{
                      ...toPos,
                      _id: toTask._id,
                      name: toTask.name,
                    }}
                    chartRef={chartRef}
                  />
                );
              })
            )}
          </svg>
          {/* Dynamic Linking Line */}
          {linking && linkSource && (
            <svg
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: `${totalWidth}px`, // Ensure dynamic linking SVG matches totalWidth
                height: `${totalHeight}px`, // Set SVG height to totalHeight
                pointerEvents: "none",
                overflow: "visible",
                zIndex: 1500, // Ensure it's above dependency lines
              }}
            >
              <defs>
                <marker _id="dynamicArrow" markerWidth="10" markerHeight="10" refX="5" refY="5" orient="auto">
                  <path d="M0,0 L10,5 L0,10 Z" fill="#3182CE" />
                </marker>
              </defs>
              {(() => {
                const sourceTaskIndex = visibleTasks.findIndex((t) => t._id === linkSource.taskId);
                const sourceTask = visibleTasks[sourceTaskIndex];
                if (!sourceTask) return null;

                const fromPos = {
                  ...calculateTaskPosition(sourceTask),
                  top: sourceTaskIndex * rowHeight,
                };
                const { left: fromLeft, top: fromTop, width: fromWidth, height: fromHeight } = fromPos;

                let startX, startY;

                if (linkSource.dependencyType === "end") {
                  startX = fromLeft + fromWidth;
                  startY = fromTop + fromHeight / 2;
                } else if (linkSource.dependencyType === "start") {
                  startX = fromLeft;
                  startY = fromTop + fromHeight / 2;
                } else {
                  startX = fromLeft + fromWidth;
                  startY = fromTop + fromHeight / 2;
                }

                const currentX = mousePosition.x;
                const currentY = mousePosition.y;

                // Ensure currentX and currentY are within the SVG bounds
                if (currentX === 0 && currentY === 0) return null;

                const controlPointX = (startX + currentX) / 2;
                const controlPointY1 = startY;
                const controlPointY2 = currentY;

                const path = `M${startX},${startY} C${controlPointX},${controlPointY1} ${controlPointX},${controlPointY2} ${currentX},${currentY}`;

                return (
                  <path
                    d={path}
                    stroke="#3182CE"
                    strokeWidth="2"
                    fill="none"
                    markerEnd="url(#dynamicArrow)"
                    aria-label={`Dynamic Dependency from (${startX}, ${startY}) to (${currentX}, ${currentY})`}
                  />
                );
              })()}
            </svg>
          )}
          {/* Task Bars */}
          <AnimatePresence>
            {visibleTasks.map((task, index) => {
              const position = calculateTaskPosition(task);
              const isSelected = selectedTaskId === task._id;
              const taskBarKey = `taskBar-${task.type}-${task._id}-${index}`;

              return (
                <GanttTaskBar
                  key={taskBarKey}
                  task={{
                    ...task,
                    ...position,
                    top: index * rowHeight,
                    height: rowHeight,
                  }}
                  isSelected={isSelected}
                />
              );
            })}
          </AnimatePresence>
        </Box>
      </Box>

      {/* Confirmation Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this task?</Text>
            <HStack spacing={4} mt={4} justify="flex-end">
              <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteConfirmed}>
                Delete
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default GanttChart;
