// src/components/ProjectsHistoryModal.js

import React, { useState, useMemo, useContext } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Tag,
  AccordionIcon,
  Box,
  Text,
  Grid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Flex,
  useColorModeValue,
  HStack,
} from "@chakra-ui/react";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import UserAvatar from "../../2 - Components/Reusable/UserAvatar";
import { PiArrowLeftBold, PiArrowRightBold, PiClockUserFill, PiInfoFill, PiMagnifyingGlass, PiUserCircleBold } from "react-icons/pi";

// Helper functions remain unchanged
const isUserObject = (value) => {
  return value && typeof value === "object" && "firstName" in value && "lastName" in value && "_id" in value;
};

const isArrayOfUsers = (value) => {
  return (
    Array.isArray(value) &&
    value.length > 0 &&
    value.every((item) => typeof item === "object" && "firstName" in item && "lastName" in item && "_id" in item)
  );
};

const getStringValue = (value) => {
  if (isArrayOfUsers(value)) {
    return value.map((user) => `${user.firstName} ${user.lastName}`).join(", ");
  } else if (isUserObject(value)) {
    return `${value.firstName} ${value.lastName}`;
  } else if (typeof value === "object" && value !== null) {
    return JSON.stringify(value);
  } else {
    return String(value || "");
  }
};

const eventMatchesSearch = (event, searchTerm) => {
  const term = searchTerm.toLowerCase();

  const fields = [
    event.title,
    event.extendedProps.action,
    event.extendedProps.field,
    getStringValue(event.extendedProps.oldValue),
    getStringValue(event.extendedProps.newValue),
    // getStringValue(event.extendedProps.changedByName),
  ];

  const combinedString = fields
    .filter((field) => field !== null && field !== undefined)
    .join(" ")
    .toLowerCase();

  return combinedString.includes(term);
};

const ProjectsHistoryModal = ({ isOpen, onClose, events, selectedDate }) => {
  const { currentProjectHistory } = useContext(ProjectContext);
  const projectEvents = useMemo(() => events.filter((event) => event.extendedProps.isProject), [events]);
  const taskEvents = useMemo(() => events.filter((event) => !event.extendedProps.isProject), [events]);

  // State for search and filters
  const [projectSearch, setProjectSearch] = useState("");
  const [taskSearch, setTaskSearch] = useState("");
  const [projectFilter, setProjectFilter] = useState("");
  const [taskFilter, setTaskFilter] = useState("");

  // Get unique action types for filtering
  const projectActionTypes = useMemo(() => {
    const types = new Set(projectEvents.map((event) => event.extendedProps.action));
    return Array.from(types);
  }, [projectEvents]);

  const taskActionTypes = useMemo(() => {
    const types = new Set(taskEvents.map((event) => event.extendedProps.action));
    return Array.from(types);
  }, [taskEvents]);

  // Filtered events based on search and filter
  const filteredProjectEvents = useMemo(() => {
    return projectEvents.filter((event) => {
      const matchesSearch = eventMatchesSearch(event, projectSearch);
      const matchesFilter = projectFilter ? event.extendedProps.action === projectFilter : true;
      return matchesSearch && matchesFilter;
    });
  }, [projectEvents, projectSearch, projectFilter]);

  const filteredTaskEvents = useMemo(() => {
    return taskEvents.filter((event) => {
      const matchesSearch = eventMatchesSearch(event, taskSearch);
      const matchesFilter = taskFilter ? event.extendedProps.action === taskFilter : true;
      return matchesSearch && matchesFilter;
    });
  }, [taskEvents, taskSearch, taskFilter]);

  // Chakra UI color modes
  const modalBg = useColorModeValue("white", "gray.800");
  const accordionBg = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const accordionButtonExpandedBg = useColorModeValue("blue.100", "blue.700");

  // Function to render `oldValue` and `newValue` appropriately
  const renderValue = (value) => {
    if (isArrayOfUsers(value)) {
      return value.map((user, index) => (
        <span key={user._id}>
          {user.firstName} {user.lastName}
          {index < value.length - 1 && ", "}
        </span>
      ));
    } else if (isUserObject(value)) {
      return (
        <span>
          {value.firstName} {value.lastName}
        </span>
      );
    } else if (Array.isArray(value)) {
      // Handle arrays of non-user objects
      return (
        <ul>
          {value.map((item, index) => (
            <li key={item._id || index}>{isUserObject(item) ? `${item.firstName} ${item.lastName}` : JSON.stringify(item)}</li>
          ))}
        </ul>
      );
    } else if (typeof value === "object" && value !== null) {
      // Handle single objects
      return <pre>{JSON.stringify(value, null, 2)}</pre>;
    } else {
      return String(value || "");
    }
  };

  // Helper component for rendering event details
  const EventDetails = ({ event }) => (
    <Box p={4} bg="gray.100" borderRadius="md" boxShadow="sm" w="100%">
      <Grid templateColumns="25% 75%" gap={4}>
        {/* Action Details */}
        <Flex align="center">
          <Box as={PiInfoFill} boxSize="25px" color="blue.500" mr={2} />
          <Text fontWeight="semibold" color="gray.800">
            Action:
          </Text>
        </Flex>
        <Text color="gray.600" wordBreak="break-word" whiteSpace="normal">
          {event.extendedProps.action} at {new Date(event.start).toLocaleString()}
        </Text>

        {/* Changed By */}
        <Flex align="center">
          <Box as={PiUserCircleBold} boxSize="25px" color="purple.500" mr={2} />
          <Text fontWeight="semibold" color="gray.800">
            Changed By:
          </Text>
        </Flex>
        <Box>
          {event.extendedProps.changedById && event.extendedProps.changedById !== "Unknown" ? (
            <UserAvatar userIds={[event.extendedProps.changedById]} size="sm" showInfo={true} />
          ) : (
            <Text color="gray.500">Unknown</Text>
          )}
        </Box>

        {/* Field */}
        <Flex align="center">
          <Box as={PiClockUserFill} boxSize="25px" color="green.500" mr={2} />
          <Text fontWeight="semibold" color="gray.800">
            Field:
          </Text>
        </Flex>
        <Tag
          colorScheme="gray"
          size="lg"
          bg="blue.200"
          w="fit-content"
          borderRadius="full"
          wordBreak="break-word"
          whiteSpace="normal"
          textTransform="capitalize"
        >
          {event.extendedProps.field || "N/A"}
        </Tag>

        {/* Conditionally Render Old Value */}
        {event.extendedProps.oldValue !== undefined && event.extendedProps.oldValue !== null && (
          <>
            <Flex align="center">
              <Box as={PiArrowLeftBold} boxSize="25px" color="red.500" mr={2} />
              <Text fontWeight="semibold" color="gray.800">
                Old Value:
              </Text>
            </Flex>
            <Text color="gray.600" wordBreak="break-word" whiteSpace="normal">
              {renderValue(event.extendedProps.oldValue)}
            </Text>
          </>
        )}

        {/* Conditionally Render New Value */}
        {event.extendedProps.newValue !== undefined && event.extendedProps.newValue !== null && (
          <>
            <Flex align="center">
              <Box as={PiArrowRightBold} boxSize="25px" color="green.500" mr={2} />
              <Text fontWeight="semibold" color="gray.800">
                New Value:
              </Text>
            </Flex>
            <Text color="gray.600" wordBreak="break-word" whiteSpace="normal">
              {renderValue(event.extendedProps.newValue)}
            </Text>
          </>
        )}
      </Grid>
    </Box>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent borderRadius="lg" bg={modalBg}>
        <ModalHeader>
          Changes to {currentProjectHistory?.name} on {selectedDate ? selectedDate.toLocaleDateString() : ""}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs variant="enclosed" isFitted>
            <TabList mb="1em">
              <Tab>
                Project Events
                <Tag ml={2} colorScheme="blue" borderRadius="full">
                  {projectEvents.length}
                </Tag>
              </Tab>
              <Tab>
                Task Events
                <Tag ml={2} colorScheme="blue" borderRadius="full">
                  {taskEvents.length}
                </Tag>
              </Tab>
            </TabList>
            <TabPanels>
              {/* Project Events Tab */}
              <TabPanel>
                <Flex mb={4} direction={{ base: "column", md: "row" }} gap={4}>
                  {/* Search Input */}
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <PiMagnifyingGlass color="gray.300" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search Project Events"
                      value={projectSearch}
                      onChange={(e) => setProjectSearch(e.target.value)}
                      bg={useColorModeValue("gray.100", "gray.600")}
                      _hover={{
                        bg: useColorModeValue("gray.200", "gray.500"),
                      }}
                      _focus={{
                        bg: useColorModeValue("white", "gray.700"),
                      }}
                    />
                  </InputGroup>
                  {/* Filter Select */}
                  <Select
                    placeholder="Filter by Action Type"
                    value={projectFilter}
                    onChange={(e) => setProjectFilter(e.target.value)}
                    h="40px"
                    borderRadius="xl"
                    bg={useColorModeValue("gray.100", "gray.600")}
                    _hover={{
                      bg: useColorModeValue("gray.200", "gray.500"),
                    }}
                    _focus={{
                      bg: useColorModeValue("white", "gray.700"),
                    }}
                  >
                    {projectActionTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Select>
                </Flex>
                {/* Accordion for Project Events */}
                {filteredProjectEvents.length > 0 ? (
                  <Accordion allowMultiple>
                    {filteredProjectEvents.map((event) => (
                      <AccordionItem key={event.id} border="none" bg={accordionBg} borderRadius="md" mb={2}>
                        <AccordionButton
                          _expanded={{
                            bg: accordionButtonExpandedBg,
                          }}
                        >
                          <Box flex="1" textAlign="left" color={textColor}>
                            <HStack spacing={2} align="center" justifyContent="start">
                              <Text w="200px" fontSize="lg" textAlign="left" fontWeight="600">
                                {event.title}
                              </Text>
                              {event.extendedProps.changedById && event.extendedProps.changedById !== "Unknown" ? (
                                <UserAvatar userIds={[event.extendedProps.changedById]} size="sm" showInfo={true} />
                              ) : (
                                <Text color="gray.500">Unknown</Text>
                              )}
                            </HStack>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={6}>
                          <EventDetails event={event} />
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                ) : (
                  <Text>No project events found for this date.</Text>
                )}
              </TabPanel>
              {/* Task Events Tab */}
              <TabPanel>
                <Flex mb={4} direction={{ base: "column", md: "row" }} gap={4}>
                  {/* Search Input */}
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <PiMagnifyingGlass color="gray.300" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search Task Events"
                      value={taskSearch}
                      onChange={(e) => setTaskSearch(e.target.value)}
                      bg={useColorModeValue("gray.100", "gray.600")}
                      _hover={{
                        bg: useColorModeValue("gray.200", "gray.500"),
                      }}
                      _focus={{
                        bg: useColorModeValue("white", "gray.700"),
                      }}
                    />
                  </InputGroup>
                  {/* Filter Select */}
                  <Select
                    placeholder="Filter by Action Type"
                    value={taskFilter}
                    h="40px"
                    borderRadius="xl"
                    onChange={(e) => setTaskFilter(e.target.value)}
                    bg={useColorModeValue("gray.100", "gray.600")}
                    _hover={{
                      bg: useColorModeValue("gray.200", "gray.500"),
                    }}
                    _focus={{
                      bg: useColorModeValue("white", "gray.700"),
                    }}
                  >
                    {taskActionTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Select>
                </Flex>
                {/* Accordion for Task Events */}
                {filteredTaskEvents.length > 0 ? (
                  <Accordion allowMultiple>
                    {filteredTaskEvents.map((event) => (
                      <AccordionItem key={event.id} border="none" bg={accordionBg} borderRadius="md" mb={2}>
                        <AccordionButton
                          _expanded={{
                            bg: accordionButtonExpandedBg,
                          }}
                        >
                          <Box flex="1" textAlign="left" color={textColor}>
                            <HStack spacing={2} align="center" justifyContent="start">
                              <Text w="200px" fontSize="lg" textAlign="left" fontWeight="600">
                                {event.title}
                              </Text>
                              {event.extendedProps.changedById && event.extendedProps.changedById !== "Unknown" ? (
                                <UserAvatar userIds={[event.extendedProps.changedById]} size="sm" showInfo={true} />
                              ) : (
                                <Text color="gray.500">Unknown</Text>
                              )}
                            </HStack>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={6}>
                          <EventDetails event={event} />
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                ) : (
                  <Text>No task events found for this date.</Text>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProjectsHistoryModal;
