// client/src/components/4 - API/API-Projects.js

import { fetchData } from './FetchData';
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const fetchCompanies = async () => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/get-all-companies`);
  return data.companies;
};

export const fetchUsersFromCompany = async (companyId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/companies/${companyId}/users`);
  return data.users;
};

export const fetchProjects = async (companyId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${companyId}/get-all-projects`);
  return data.projects;
};

export const fetchArchivedProjects = async (companyId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${companyId}/get-archived`);
  return data.projects;
};

export const fetchProjectsTimeline = async (companyId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${companyId}/get-projects-timeline`);
  return data.projects;
};

export const createProject = async (companyId, projectData) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/create-project/${companyId}`, "POST", projectData);
  return data.project;
};

export const editProject = async (projectId, updatedData) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${projectId}/edit-project`, "PATCH", updatedData);
  return data.project;
};

export const deleteProject = async (projectId) => {
  await fetchData(`${API_BASE_URL}/api/v1/projects/${projectId}/delete-project`, "DELETE");
};

export const fetchTasks = async (projectId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${projectId}/get-tasks`);
  return data.tasks;
};

export const fetchSubtasks = async (taskId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${taskId}/get-subtasks`);
  return data.subtasks;
};

export const updateTaskOrder = async (tasksToUpdate) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/update-order`, "POST", { tasks: tasksToUpdate });
  return data;
};

export const updateTaskColor = async (taskId, color) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/update-color/${taskId}`, "POST", { color });
  return data;
};

export const createTask = async (projectId, taskData) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${projectId}/create-task`, "POST", taskData);
  return data.task;
};

export const editTask = async (taskId, updatedData) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${taskId}/edit-task`, "PATCH", updatedData);
  return data.task;
};

export const deleteTask = async (taskId) => {
  await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${taskId}/delete-task`, "DELETE");
};

export const addComment = async (taskId, message) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${taskId}/add-comment`, "POST", { message });
  return data.comment;
};

export const editComment = async (taskId, commentId, message) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${taskId}/comments/${commentId}`, "PATCH", { message });
  return data.comment;
};

export const deleteComment = async (taskId, commentId) => {
  await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${taskId}/comments/${commentId}`, "DELETE");
};

export const logTime = async (taskId, { timeSpent, description }) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${taskId}/log-time`, "POST", { timeSpent, description });
  return data.timeLog;
};

export const editTimeLog = async (taskId, logId, updatedLogData) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${taskId}/time-log/${logId}`, "PATCH", updatedLogData);
  return data.timeLog;
};

export const deleteTimeLog = async (taskId, logId) => {
  await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${taskId}/time-log/${logId}`, "DELETE");
};

export const archiveProject = async (projectId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${projectId}/archive`, "PATCH");
  return data.project;
};

export const fetchHistory = async (projectId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/${projectId}/get-history`);
  return data.project;
};

export const assignMembers = async (projectId, members) => {
  return await editProject(projectId, { members });
};

export const assignUsersToTask = async (taskId, assignedTo) => {
  return await editTask(taskId, { assignedTo });
};

export const fetchTaskById = async (taskId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${taskId}/get-task`);
  return data.task;
};

export const uploadFilesToTask = async (taskId, acceptedFiles) => {
  const formData = new FormData();
  acceptedFiles.forEach((file) => {
    formData.append("files", file);
  });

  const response = await fetch(`${API_BASE_URL}/api/v1/projects/tasks/${taskId}/upload-files`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: formData,
  });

  if (!response.ok) throw new Error("Error attaching file");

  const data = await response.json();
  return data.file;
};

export const deleteFileFromTask = async (taskId, fileId) => {
  const data = await fetchData(`${API_BASE_URL}/api/v1/projects/tasks/${taskId}/files/${fileId}`, "DELETE");
  return data.response;
};

export const downloadFileFromTask = async (file, taskId) => {
  const data = await fetchData(`${API_BASE_URL}/uploads/projects/tasks/${taskId}/${file.backendName}`, "GET");
  const blob = await data.blob();
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", file.originalName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
