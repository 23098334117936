// client\src\components\5 - General\Utils\UtilsFileIcon.js

import React from "react";
import {
  BsFiletypeAac,
  BsFiletypeAi,
  BsFiletypeBmp,
  BsFiletypeCs,
  BsFiletypeCss,
  BsFiletypeCsv,
  BsFiletypeDoc,
  BsFiletypeDocx,
  BsFiletypeExe,
  BsFiletypeGif,
  BsFiletypeHeic,
  BsFiletypeHtml,
  BsFiletypeJava,
  BsFiletypeJpg,
  BsFiletypeJs,
  BsFiletypeJson,
  BsFiletypeJsx,
  BsFiletypeKey,
  BsFiletypeM4P,
  BsFiletypeMd,
  BsFiletypeMdx,
  BsFiletypeMov,
  BsFiletypeMp3,
  BsFiletypeMp4,
  BsFiletypeOtf,
  BsFiletypePdf,
  BsFiletypePhp,
  BsFiletypePng,
  BsFiletypePpt,
  BsFiletypePptx,
  BsFiletypePsd,
  BsFiletypePy,
  BsFiletypeRaw,
  BsFiletypeRb,
  BsFiletypeSass,
  BsFiletypeScss,
  BsFiletypeSh,
  BsFiletypeSql,
  BsFiletypeSvg,
  BsFiletypeTiff,
  BsFiletypeTsx,
  BsFiletypeTtf,
  BsFiletypeTxt,
  BsFiletypeWav,
  BsFiletypeWoff,
  BsFiletypeXls,
  BsFiletypeXlsx,
  BsFiletypeXml,
  BsFiletypeYml,
  BsFileEarmark,
} from "react-icons/bs";

// Utility function to get file extension
const getFileExtension = (filename) => {
  if (!filename) return "";
  return filename.split(".").pop().toLowerCase();
};

// Function to render the appropriate file icon based on file extension
export const renderFileIcon = (fileName) => {
  const extension = getFileExtension(fileName);
  const fontSize = "24px";

  switch (extension) {
    case "aac":
      return <BsFiletypeAac fontSize={fontSize} />;
    case "ai":
      return <BsFiletypeAi fontSize={fontSize} />;
    case "bmp":
      return <BsFiletypeBmp fontSize={fontSize} />;
    case "cs":
      return <BsFiletypeCs fontSize={fontSize} />;
    case "css":
      return <BsFiletypeCss fontSize={fontSize} />;
    case "csv":
      return <BsFiletypeCsv fontSize={fontSize} />;
    case "doc":
      return <BsFiletypeDoc fontSize={fontSize} />;
    case "docx":
      return <BsFiletypeDocx fontSize={fontSize} />;
    case "exe":
      return <BsFiletypeExe fontSize={fontSize} />;
    case "gif":
      return <BsFiletypeGif fontSize={fontSize} />;
    case "heic":
      return <BsFiletypeHeic fontSize={fontSize} />;
    case "html":
      return <BsFiletypeHtml fontSize={fontSize} />;
    case "java":
      return <BsFiletypeJava fontSize={fontSize} />;
    case "jpg":
    case "jpeg":
      return <BsFiletypeJpg fontSize={fontSize} />;
    case "js":
      return <BsFiletypeJs fontSize={fontSize} />;
    case "json":
      return <BsFiletypeJson fontSize={fontSize} />;
    case "jsx":
      return <BsFiletypeJsx fontSize={fontSize} />;
    case "key":
      return <BsFiletypeKey fontSize={fontSize} />;
    case "m4p":
      return <BsFiletypeM4P fontSize={fontSize} />;
    case "md":
      return <BsFiletypeMd fontSize={fontSize} />;
    case "mdx":
      return <BsFiletypeMdx fontSize={fontSize} />;
    case "mov":
      return <BsFiletypeMov fontSize={fontSize} />;
    case "mp3":
      return <BsFiletypeMp3 fontSize={fontSize} />;
    case "mp4":
      return <BsFiletypeMp4 fontSize={fontSize} />;
    case "otf":
      return <BsFiletypeOtf fontSize={fontSize} />;
    case "pdf":
      return <BsFiletypePdf fontSize={fontSize} />;
    case "php":
      return <BsFiletypePhp fontSize={fontSize} />;
    case "png":
      return <BsFiletypePng fontSize={fontSize} />;
    case "ppt":
      return <BsFiletypePpt fontSize={fontSize} />;
    case "pptx":
      return <BsFiletypePptx fontSize={fontSize} />;
    case "psd":
      return <BsFiletypePsd fontSize={fontSize} />;
    case "py":
      return <BsFiletypePy fontSize={fontSize} />;
    case "raw":
      return <BsFiletypeRaw fontSize={fontSize} />;
    case "rb":
      return <BsFiletypeRb fontSize={fontSize} />;
    case "sass":
      return <BsFiletypeSass fontSize={fontSize} />;
    case "scss":
      return <BsFiletypeScss fontSize={fontSize} />;
    case "sh":
      return <BsFiletypeSh fontSize={fontSize} />;
    case "sql":
      return <BsFiletypeSql fontSize={fontSize} />;
    case "svg":
      return <BsFiletypeSvg fontSize={fontSize} />;
    case "tiff":
      return <BsFiletypeTiff fontSize={fontSize} />;
    case "tsx":
      return <BsFiletypeTsx fontSize={fontSize} />;
    case "ttf":
      return <BsFiletypeTtf fontSize={fontSize} />;
    case "txt":
      return <BsFiletypeTxt fontSize={fontSize} />;
    case "wav":
      return <BsFiletypeWav fontSize={fontSize} />;
    case "woff":
      return <BsFiletypeWoff fontSize={fontSize} />;
    case "xls":
      return <BsFiletypeXls fontSize={fontSize} />;
    case "xlsx":
      return <BsFiletypeXlsx fontSize={fontSize} />;
    case "xml":
      return <BsFiletypeXml fontSize={fontSize} />;
    case "yml":
      return <BsFiletypeYml fontSize={fontSize} />;
    default:
      return <BsFileEarmark fontSize={fontSize} />;
  }
};
