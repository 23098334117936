// client/src/components/5 - General/Hooks/useUpdateUser.js

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSocket } from "../Socket/SocketContext";
import useCustomToast from "../Utils/UtilsNotification";
import { useContext } from "react";
import { WorkspaceContext } from "../Context/WorkspaceContext";
import { uploadProfilePicture } from "../../4 - API/API-Users";
import { queryKeys } from "../../../queryKeys";

export const useUpdateUser = () => {
  const { socket } = useSocket();
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  const updateUser = async (userData) => {
    return new Promise((resolve, reject) => {
      socket.emit("updateUser", userData, (response) => {
        if (response.status === "ok") {
          resolve(response.user);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: updateUser,
    mutationKey: "updateUser",
    onSuccess: (user) => {
      queryClient.setQueryData(queryKeys.user(user._id), user);

      const userAvatarQueries = queryClient.getQueriesData({ queryKey: ["userAvatar"] });
      userAvatarQueries.forEach(([queryKey]) => {
        const userIds = queryKey[1];
        if (Array.isArray(userIds) && userIds.includes(user._id)) {
          queryClient.setQueryData(queryKey, (oldCacheData) => ({
            ...oldCacheData,
            [user._id]: {
              ...oldCacheData?.[user._id],
              firstName: user.firstName,
              lastName: user.lastName,
            },
          }));
        }
      });
      customToast({
        title: "User Updated.",
        description: "User information has been updated successfully.",
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        status: "error",
        title: "Update Failed",
        description: error.message || "Failed to update user.",
      });
      console.error("Error updating user:", error);
    },
  });
};

export const useUploadProfilePicture = () => {
  const { currentUser } = useContext(WorkspaceContext);
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  return useMutation({
    mutationFn: ({ userId, file }) => uploadProfilePicture(userId, file),
    mutationKey: "profilePictureUpdate",
    onSuccess: (data) => {
      const { profilePicture } = data;
      const userId = currentUser?._id;
      queryClient.setQueryData(["user", userId], (oldData) => ({ ...oldData, profilePicture }));

      const userAvatarQueries = queryClient.getQueriesData({ queryKey: ["userAvatar"] });
      userAvatarQueries.forEach(([queryKey]) => {
        const userIds = queryKey[1];
        if (Array.isArray(userIds) && userIds.includes(userId)) {
          queryClient.invalidateQueries(queryKey);
        }
      });
      customToast({
        title: "Profile Picture Updated",
        description: "Your profile picture has been successfully updated!",
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        title: "Error Uploading Picture",
        description: error.message || "Failed to upload profile picture. Please try again later.",
        status: "error",
      });
      console.error("Error uploading profile picture:", error);
    },
  });
};

export const useDeleteUser = () => {
  const { socket } = useSocket();
  const queryClient = useQueryClient();
  const customToast = useCustomToast();

  const deleteUser = async (userId) => {
    return new Promise((resolve, reject) => {
      socket.emit("deleteUser", userId, (response) => {
        if (response.status === "ok") {
          resolve(response.user);
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: deleteUser,
    mutationKey: "deleteUser",
    onSuccess: (user) => {
      queryClient.invalidateQueries(queryKeys.companyUsers(user.companyId));
      customToast({
        title: "User Deleted.",
        description: "User has been deleted successfully.",
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        status: "error",
        title: "Deletion Failed",
        description: error.message || "Failed to delete user.",
      });
      console.error("Error deleting user:", error);
    },
  });
};

export const usePasswordReset = () => {
  const { socket } = useSocket();
  const customToast = useCustomToast();

  const sendPasswordReset = async (userId) => {
    return new Promise((resolve, reject) => {
      socket.emit("sendPasswordReset", userId, (response) => {
        if (response.status === "ok") {
          resolve();
        } else {
          reject(new Error(response.message));
        }
      });
    });
  };

  return useMutation({
    mutationFn: sendPasswordReset,
    mutationKey: "sendPasswordReset",
    onSuccess: (userId) => {
      customToast({
        title: "Success!",
        description: "Sent password reset to user successfully.",
        status: "success",
      });
    },
    onError: (error) => {
      customToast({
        status: "error",
        title: "Server Failed",
        description: error.message || "Failed send password reset email to user.",
      });
      console.error("Error sending password reset to user:", error);
    },
  });
};
