import React, { useEffect, useState } from "react";
import { TabPanel, useTabsContext } from "@chakra-ui/react";

const LazyTabPanel = ({ children, ...props }) => {
  const { isSelected, isLazy } = useTabsContext();
  const [hasBeenSelected, setHasBeenSelected] = useState(false);

  useEffect(() => {
    if (isSelected) {
      setHasBeenSelected(true);
    }
  }, [isSelected]);

  if (isLazy && !hasBeenSelected) {
    return null; // Do not render content until the tab is selected
  }

  return <TabPanel {...props}>{children}</TabPanel>;
};

export default LazyTabPanel;
