// client/src/components/ProjectsTimeline.js

import React, { useState, useCallback, useMemo, useContext } from "react";
import {
  Box,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Flex,
  Switch,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { ProjectContext } from "../5 - General/Context/ProjectsContext";
import moment from "moment";
import { PiCalendarDots, PiCaretDownBold, PiCaretLeftBold, PiCaretRightBold, PiPalette } from "react-icons/pi";

const ProjectsTimeline = React.memo(({ onProjectClick }) => {
  const { projectsTimeline } = useContext(ProjectContext);
  const [displayDate, setDisplayDate] = useState(moment().startOf("day"));
  const [highlightDueDates, setHighlightDueDates] = useState(false);
  const [showExtendingDeadlines, setShowExtendingDeadlines] = useState(true);
  const bgColor = useColorModeValue("white", "gray.800");

  // View Type: 'Week', 'Month', 'Year'
  const [viewType, setViewType] = useState("Month");

  // Handle project click to open drawer
  const handleProjectClick = useCallback(
    (project) => {
      if (onProjectClick) {
        onProjectClick(project);
      }
    },
    [onProjectClick]
  );

  // Calculate display start and end dates based on viewType
  const displayStartDate = useMemo(() => {
    return displayDate.clone().startOf("day");
  }, [displayDate]);

  const displayEndDate = useMemo(() => {
    if (viewType === "Week") {
      return displayStartDate.clone().add(6, "days");
    } else if (viewType === "Month") {
      return displayStartDate.clone().endOf("month");
    } else if (viewType === "Year") {
      return displayStartDate.clone().endOf("year");
    } else {
      return displayStartDate.clone();
    }
  }, [displayStartDate, viewType]);

  const handleGoToToday = useCallback(() => {
    setDisplayDate(moment().startOf("day"));
  }, []);

  const displayedProjects = useMemo(() => {
    return projectsTimeline.filter((project) => {
      const projectStartDate = moment(project.startDate).startOf("day");
      const projectEndDate = project.endDate ? moment(project.endDate).endOf("day") : projectStartDate.clone();
      return projectStartDate.isSameOrBefore(displayEndDate, "day") && projectEndDate.isSameOrAfter(displayStartDate, "day");
    });
  }, [projectsTimeline, displayStartDate, displayEndDate]);

  // Month View Calculations
  const monthData = useMemo(() => {
    const firstDayOfMonth = displayStartDate.clone().startOf("month").startOf("week");
    const lastDayOfMonth = displayStartDate.clone().endOf("month").endOf("week");

    const totalDays = lastDayOfMonth.diff(firstDayOfMonth, "days") + 1;
    const weeks = [];

    // Calculate weeks in the month view using a traditional for loop
    for (let i = 0; i < totalDays; i += 7) {
      const weekStart = firstDayOfMonth.clone().add(i, "days");
      const weekEnd = weekStart.clone().add(6, "days");
      weeks.push({ weekStart, weekEnd });
    }

    // Map projects to week segments using traditional for loops
    const projectSegments = [];

    for (let p = 0; p < displayedProjects.length; p++) {
      const project = displayedProjects[p];
      let projectStart = moment.max(moment(project.startDate).startOf("day"), firstDayOfMonth);
      let projectEnd = moment.min(moment(project.endDate || project.startDate).endOf("day"), lastDayOfMonth);

      let currentStart = projectStart.clone();

      while (currentStart.isSameOrBefore(projectEnd, "day")) {
        // Find the week index
        let weekIndex = -1;
        for (let w = 0; w < weeks.length; w++) {
          if (currentStart.isSameOrAfter(weeks[w].weekStart, "day") && currentStart.isSameOrBefore(weeks[w].weekEnd, "day")) {
            weekIndex = w;
            break;
          }
        }

        if (weekIndex === -1) {
          break; // Exit if no matching week found
        }

        const week = weeks[weekIndex];
        const weekStart = week.weekStart;
        const weekEnd = week.weekEnd;

        const segmentStart = moment.max(currentStart, weekStart);
        const segmentEnd = moment.min(projectEnd, weekEnd);

        const gridColumnStart = segmentStart.diff(weekStart, "days") + 1;
        const gridColumnEnd = segmentEnd.diff(weekStart, "days") + 2;

        // Assign row index to avoid overlaps using a traditional for loop
        let rowIndex = 0;
        let overlapping = true;
        while (overlapping) {
          overlapping = false;
          for (let s = 0; s < projectSegments.length; s++) {
            const seg = projectSegments[s];
            if (
              seg.weekIndex === weekIndex &&
              seg.rowIndex === rowIndex &&
              !(seg.gridColumnEnd <= gridColumnStart || seg.gridColumnStart >= gridColumnEnd)
            ) {
              overlapping = true;
              break;
            }
          }
          if (overlapping) {
            rowIndex++;
          }
        }

        projectSegments.push({
          project,
          weekIndex,
          gridColumnStart,
          gridColumnEnd,
          rowIndex,
        });

        currentStart = weekEnd.clone().add(1, "day");
      }
    }

    // Calculate max row per week for consistent cell heights using a traditional for loop
    for (let w = 0; w < weeks.length; w++) {
      const segmentsInWeek = projectSegments.filter((seg) => seg.weekIndex === w);
      const maxRow = segmentsInWeek.reduce((max, seg) => Math.max(max, seg.rowIndex), 0);
      weeks[w].maxRow = maxRow;
    }

    return { weeks, projectSegments };
  }, [displayedProjects, displayStartDate]);

  // Year View Calculations (similar adjustments as Month View)
  const yearData = useMemo(() => {
    const months = [];
    for (let i = 0; i < 12; i++) {
      const monthDate = displayStartDate.clone().month(i).startOf("month");
      const firstDayOfMonth = monthDate.clone().startOf("week");
      const lastDayOfMonth = monthDate.clone().endOf("month").endOf("week");

      const totalDays = lastDayOfMonth.diff(firstDayOfMonth, "days") + 1;
      const weeks = [];

      for (let j = 0; j < totalDays; j += 7) {
        const weekStart = firstDayOfMonth.clone().add(j, "days");
        const weekEnd = weekStart.clone().add(6, "days");
        weeks.push({ weekStart, weekEnd });
      }

      // Map projects to week segments using traditional for loops
      const projectSegments = [];

      for (let p = 0; p < displayedProjects.length; p++) {
        const project = displayedProjects[p];
        let projectStart = moment.max(moment(project.startDate).startOf("day"), firstDayOfMonth);
        let projectEnd = moment.min(moment(project.endDate || project.startDate).endOf("day"), lastDayOfMonth);

        let currentStart = projectStart.clone();

        while (currentStart.isSameOrBefore(projectEnd, "day")) {
          if (currentStart.month() !== i) {
            currentStart.add(1, "day");
            continue;
          }
          // Find the week index
          let weekIndex = -1;
          for (let w = 0; w < weeks.length; w++) {
            if (currentStart.isSameOrAfter(weeks[w].weekStart, "day") && currentStart.isSameOrBefore(weeks[w].weekEnd, "day")) {
              weekIndex = w;
              break;
            }
          }

          if (weekIndex === -1) {
            break; // Exit if no matching week found
          }

          const week = weeks[weekIndex];
          const weekStart = week.weekStart;
          const weekEnd = week.weekEnd;

          const segmentStart = moment.max(currentStart, weekStart);
          const segmentEnd = moment.min(projectEnd, weekEnd);

          const gridColumnStart = segmentStart.diff(weekStart, "days") + 1;
          const gridColumnEnd = segmentEnd.diff(weekStart, "days") + 2;

          // Assign row index to avoid overlaps using a traditional for loop
          let rowIndex = 0;
          let overlapping = true;
          while (overlapping) {
            overlapping = false;
            for (let s = 0; s < projectSegments.length; s++) {
              const seg = projectSegments[s];
              if (
                seg.weekIndex === weekIndex &&
                seg.rowIndex === rowIndex &&
                !(seg.gridColumnEnd <= gridColumnStart || seg.gridColumnStart >= gridColumnEnd)
              ) {
                overlapping = true;
                break;
              }
            }
            if (overlapping) {
              rowIndex++;
            }
          }

          projectSegments.push({
            project,
            weekIndex,
            gridColumnStart,
            gridColumnEnd,
            rowIndex,
          });

          currentStart = weekEnd.clone().add(1, "day");
        }
      }

      // Calculate max row per week for consistent cell heights using a traditional for loop
      for (let w = 0; w < weeks.length; w++) {
        const segmentsInWeek = projectSegments.filter((seg) => seg.weekIndex === w);
        const maxRow = segmentsInWeek.reduce((max, seg) => Math.max(max, seg.rowIndex), 0);
        weeks[w].maxRow = maxRow;
      }

      months.push({ monthDate, weeks, projectSegments });
      return months;
    }
  }, [displayedProjects, displayStartDate]);

  const handlePrev = useCallback(() => {
    if (viewType === "Week") {
      setDisplayDate((prev) => prev.clone().subtract(1, "week"));
    } else if (viewType === "Month") {
      setDisplayDate((prev) => prev.clone().subtract(1, "month"));
    } else if (viewType === "Year") {
      setDisplayDate((prev) => prev.clone().subtract(1, "year"));
    }
  }, [viewType]);

  const handleNext = useCallback(() => {
    if (viewType === "Week") {
      setDisplayDate((prev) => prev.clone().add(1, "week"));
    } else if (viewType === "Month") {
      setDisplayDate((prev) => prev.clone().add(1, "month"));
    } else if (viewType === "Year") {
      setDisplayDate((prev) => prev.clone().add(1, "year"));
    }
  }, [viewType]);

  return (
    <Box w="100%" h="100%" bg={bgColor} position="relative" p={4} borderRadius="md" boxShadow="md" overflowX="auto">
      {/* Header with Navigation and View Controls */}
      <HStack justifyContent="space-between" mb={4} alignItems="center">
        {/* Navigation Controls */}
        <HStack spacing={2} flex="1" alignItems="center">
          <IconButton icon={<PiCaretLeftBold boxSize={6} />} onClick={handlePrev} aria-label="Previous" variant="ghost" />
          <IconButton icon={<PiCaretRightBold boxSize={6} />} onClick={handleNext} aria-label="Next" variant="ghost" />
          <Text fontSize="lg" fontWeight="bold" whiteSpace="nowrap">
            {viewType === "Week" && `${displayStartDate.format("MMM D, YYYY")} - ${displayEndDate.format("MMM D, YYYY")}`}
            {viewType === "Month" && displayStartDate.format("MMMM YYYY")}
            {viewType === "Year" && displayStartDate.format("YYYY")}
          </Text>
        </HStack>

        {/* View Selector */}
        <Menu>
          <MenuButton as={Button} rightIcon={<PiCaretDownBold />}>
            View: {viewType}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setViewType("Week")}>Week</MenuItem>
            <MenuItem onClick={() => setViewType("Month")}>Month</MenuItem>
            <MenuItem onClick={() => setViewType("Year")}>Year</MenuItem>
          </MenuList>
        </Menu>

        {/* Options Menu */}
        <Menu closeOnSelect={false}>
          <MenuButton as={Button} rightIcon={<PiCaretDownBold />}>
            Options
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleGoToToday} icon={<PiCalendarDots />}>
              Go to Today
            </MenuItem>
            <MenuDivider />
            <MenuItem>
              <Flex align="center">
                <PiPalette style={{ marginRight: "8px" }} />
                <Text flex="1">Due Date Color</Text>
                <Switch isChecked={highlightDueDates} onChange={(e) => setHighlightDueDates(e.target.checked)} />
              </Flex>
            </MenuItem>
            <MenuItem>
              <Flex align="center">
                <Text flex="1">Show Extending Deadlines</Text>
                <Switch isChecked={showExtendingDeadlines} onChange={(e) => setShowExtendingDeadlines(e.target.checked)} />
              </Flex>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>

      {/* Timeline Views */}
      {/* Week View remains unchanged (not shown here for brevity) */}

      {/* Month View */}
      {viewType === "Month" && (
        <Box overflowX="auto">
          {/* Month Calendar Grid */}
          <Grid
            templateColumns="repeat(7, 1fr)"
            templateRows={`repeat(${monthData.weeks.length}, 120px)`} // Fixed row height per week
            minWidth="1000px"
            position="relative"
          >
            {/* Weekday Headers */}
            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
              <GridItem key={day} textAlign="center" p={2} bg="gray.100">
                <Text fontSize="sm" fontWeight="bold">
                  {day}
                </Text>
              </GridItem>
            ))}

            {/* Day Cells */}
            {monthData.weeks.map((week, weekIndex) => (
              <React.Fragment key={weekIndex}>
                {Array.from({ length: 7 }, (_, dayIndex) => {
                  const day = week.weekStart.clone().add(dayIndex, "day");
                  const isCurrentMonth = day.month() === displayStartDate.month();
                  const isToday = day.isSame(moment(), "day");
                  const dayKey = day.format("YYYY-MM-DD");

                  return (
                    <GridItem
                      key={dayKey}
                      p={1}
                      borderWidth="1px"
                      borderColor={isCurrentMonth ? "gray.300" : "gray.100"}
                      bg={isToday ? "primary.100" : isCurrentMonth ? "white" : "gray.50"}
                      minHeight={`100px`}
                      position="relative"
                    >
                      <Text textAlign='center' fontWeight='500' w='100%' fontSize="sm" color={isCurrentMonth ? "black" : "gray.400"}>
                        {day.date()}
                      </Text>
                    </GridItem>
                  );
                })}
              </React.Fragment>
            ))}

            {/* Project Bars */}
            {monthData.projectSegments.map((seg, index) => {
              const weekHeight = 120; // Fixed row height
              const projectBarHeight = 20; // Height of each project bar
              const projectSpacing = 4; // Spacing between project bars

              // Calculate top position based on rowIndex
              const topPosition = 25 + seg.rowIndex * (projectBarHeight + projectSpacing);

              // Calculate left position and width based on grid columns
              const daysInWeek = 7;
              const dayWidthPercent = 100 / daysInWeek;
              const leftPercent = (seg.gridColumnStart - 1) * dayWidthPercent;
              const widthPercent = (seg.gridColumnEnd - seg.gridColumnStart) * dayWidthPercent;

              return (
                <Box
                  key={`${seg.project._id}-${index}`}
                  position="absolute"
                  top={`${seg.weekIndex * weekHeight + topPosition}px`}
                  left={`${leftPercent}%`}
                  width={`${widthPercent}%`}
                  zIndex={1}
                >
                  <Tooltip label={`${seg.project.name} - `} hasArrow>
                    <Box
                      bg={highlightDueDates ? getProjectColor(seg.project) : seg.project.color}
                      color="white"
                      borderRadius="md"
                      p={1}
                      cursor="pointer"
                      onClick={() => handleProjectClick(seg.project)}
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      fontSize="xs"
                      height={`${projectBarHeight}px`}
                      display="flex"
                      alignItems="center"
                      justifyContent="start"
                    >
                      {seg.project.name}
                    </Box>
                  </Tooltip>
                </Box>
              );
            })}
          </Grid>
        </Box>
      )}

      {/* Year View */}
      {viewType === "Year" && (
        <Box overflowX="auto" mb={10}>
          <Grid templateColumns="repeat(3, 1fr)" gap={4}>
            {yearData.map((month, monthIndex) => (
              <Box key={monthIndex}>
                <Text fontSize="lg" fontWeight="bold" textAlign="center" mb={2}>
                  {month.monthDate.format("MMMM")}
                </Text>
                <Grid
                  templateColumns="repeat(7, 1fr)"
                  templateRows={`repeat(${month.weeks.length}, 80px)`} // Fixed row height per week
                  gap={1}
                  position="relative"
                >
                  {/* Weekday Headers */}
                  {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                    <GridItem key={day} textAlign="center" p={1} bg="gray.100">
                      <Text fontSize="xs" fontWeight="bold">
                        {day}
                      </Text>
                    </GridItem>
                  ))}

                  {/* Day Cells */}
                  {month.weeks.map((week, weekIndex) => (
                    <React.Fragment key={weekIndex}>
                      {Array.from({ length: 7 }, (_, dayIndex) => {
                        const day = week.weekStart.clone().add(dayIndex, "day");
                        if (day.month() !== month.monthDate.month()) {
                          // Empty cell for days not in the current month
                          return (
                            <GridItem
                              key={day.format("YYYY-MM-DD")}
                              p={1}
                              borderWidth="1px"
                              borderColor="gray.100"
                              bg="gray.50"
                              minHeight={`80px`}
                              position="relative"
                            />
                          );
                        }
                        const isToday = day.isSame(moment(), "day");

                        return (
                          <GridItem
                            key={day.format("YYYY-MM-DD")}
                            p={1}
                            borderWidth="1px"
                            borderColor="gray.200"
                            bg={isToday ? "primary.100" : "white"}
                            minHeight={`80px`}
                            position="relative"
                          >
                            <Text fontSize="xs">{day.date()}</Text>
                          </GridItem>
                        );
                      })}
                    </React.Fragment>
                  ))}

                  {/* Project Bars */}
                  {month.projectSegments.map((seg, index) => {
                    const weekHeight = 80; // Fixed row height
                    const projectBarHeight = 16; // Height of each project bar
                    const projectSpacing = 4; // Spacing between project bars

                    // Calculate top position based on rowIndex
                    const topPosition = 16 + seg.rowIndex * (projectBarHeight + projectSpacing);

                    // Calculate left position and width based on grid columns
                    const daysInWeek = 7;
                    const dayWidthPercent = 100 / daysInWeek;
                    const leftPercent = (seg.gridColumnStart - 1) * dayWidthPercent;
                    const widthPercent = (seg.gridColumnEnd - seg.gridColumnStart) * dayWidthPercent;

                    return (
                      <Box
                        key={`${seg.project._id}-${index}`}
                        position="absolute"
                        top={`${seg.weekIndex * weekHeight + topPosition}px`}
                        left={`${leftPercent}%`}
                        width={`${widthPercent}%`}
                        zIndex={1}
                      >
                        <Box
                          bg={highlightDueDates ? getProjectColor(seg.project) : "primary.500"}
                          color="white"
                          borderRadius="md"
                          p={1}
                          cursor="pointer"
                          onClick={() => handleProjectClick(seg.project)}
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          fontSize="xs"
                          height={`${projectBarHeight}px`}
                          display="flex"
                          alignItems="center"
                          justifyContent="start"
                        >
                          {seg.project.name}
                        </Box>
                      </Box>
                    );
                  })}
                </Grid>
              </Box>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
});

// Helper function to get project color
const getProjectColor = (project) => {
  const now = moment();
  const endDate = moment(project.endDate);
  const threeMonthsLater = now.clone().add(3, "months");

  if (project.status.toLowerCase() === "completed" || endDate.isAfter(threeMonthsLater, "day")) {
    return "#A0AEC0"; // Gray for completed or far-future projects
  }

  if (endDate.isBefore(now, "day") && project.status.toLowerCase() !== "completed") {
    return "#E53E3E"; // Red for overdue projects
  }

  const daysUntilDue = endDate.diff(now, "days");
  const maxDays = 90;
  const transitionRatio = Math.max(0, Math.min(daysUntilDue / maxDays, 1));

  const redValue = Math.round(255 * (1 - transitionRatio));
  const greenValue = Math.round(255 * transitionRatio);

  return `rgb(${redValue}, ${greenValue}, 0)`;
};

export default ProjectsTimeline;
