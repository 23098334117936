import React from 'react';

const SettingsPageFiles = () => {

  return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh' }}>
        <h3>Files settings</h3>
      </div>
  );
};

export default SettingsPageFiles;
