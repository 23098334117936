import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBarLayout from '../Layout/LayoutSideBar';

const Layout = () => {
  return (
    <SideBarLayout>
      <Outlet />
    </SideBarLayout>
  );
};

export default Layout;
