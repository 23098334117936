import React, { useState, useContext } from "react";
import { Box, Flex, IconButton, Text, useColorModeValue, VStack, Textarea } from "@chakra-ui/react";
import { formatTimestamp } from "../../5 - General/Utils/UtilsFormatData";
import { ProjectContext } from "../../5 - General/Context/ProjectsContext";
import UserAvatar from "../Reusable/UserAvatar"
import { WorkspaceContext } from "../../5 - General/Context/WorkspaceContext";
import { PiCheckBold, PiPaperPlaneTiltFill, PiPencilSimpleLine, PiThumbsUp, PiTrash, PiXBold } from "react-icons/pi";

const CommentSection = () => {
  const { currentUser } = useContext(WorkspaceContext);
  const { currentTask, addCommentToTask, editCommentInTask, deleteCommentFromTask } = useContext(ProjectContext);

  const [commentText, setCommentText] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentText, setEditedCommentText] = useState("");

  // Define color mode values
  const commentBg = useColorModeValue("white", "gray.700");
  const containerBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const inputBg = useColorModeValue("white", "gray.600");

  const handleAddComment = async () => {
    if (!commentText.trim()) return;

    try {
      await addCommentToTask(currentTask._id, commentText);
      setCommentText("");
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleEditComment = (comment) => {
    setEditingCommentId(comment._id);
    setEditedCommentText(comment.message);
  };

  const handleSaveEditedComment = async (commentId) => {
    if (!editedCommentText.trim()) return;

    try {
      await editCommentInTask(currentTask._id, commentId, editedCommentText);
      setEditingCommentId(null);
      setEditedCommentText("");
    } catch (error) {
      console.error("Error editing comment:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this comment?");
    if (!confirmDelete) return;

    try {
      await deleteCommentFromTask(currentTask._id, commentId);
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  return (
    <Flex direction="column" height="100%">
      {/* 1. Commenting Input at the Top */}
      <Flex mx={4} my={8} p={4} borderWidth="1px" borderColor="gray.200" borderRadius="xl" bg="gray.50">
        <VStack align="start" spacing={2} mb={4} w='100%'>
          <Text fontWeight='400'>Comments</Text>
          <Flex w='100%'>
            <Textarea
              placeholder="Ask a question or post an update..."
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              mr={2}
              bg={inputBg}
              size="md"
              borderRadius="md"
            />
            <IconButton icon={<PiPaperPlaneTiltFill fontSize='18px' />} colorScheme="blue" onClick={handleAddComment} aria-label="Add comment" size="md" />
          </Flex>
        </VStack>
      </Flex>

      {/* Comments Container */}
      <Box p={4} bg={containerBg} overflowY="auto" flex="1">
        {currentTask.comments?.length > 0 ? (
          // Sort comments so that the most recent are on top
          [...currentTask.comments]
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((comment, idx) => (
              <Box key={comment._id || idx} mb={4} position="relative" border='1px solid' borderColor={borderColor} boxShadow="base" role="group" p={3} bg={commentBg} borderRadius="xl">
                <Flex align="start" justifyContent="space-between" w="100%" mb={2}>
                  <UserAvatar userIds={[comment.userId]} size="sm" showInfo={true} mr={2} />
                  <Text fontSize="sm" fontWeight="600" color="gray.500">
                    {formatTimestamp(comment.createdAt)}
                  </Text>
                </Flex>

                {/* Comment Content or Edit Mode */}
                {editingCommentId === comment._id ? (
                  <Flex align="center" mb={2}>
                    <Textarea
                      value={editedCommentText}
                      onChange={(e) => setEditedCommentText(e.target.value)}
                      mr={2}
                      size="sm"
                      fontSize='lg'
                      bg={inputBg}
                      borderRadius="md"
                    />
                    <IconButton
                      icon={<PiCheckBold />}
                      size="sm"
                      colorScheme="green"
                      mr={2}
                      onClick={() => handleSaveEditedComment(comment._id)}
                      aria-label="Save edited comment"
                    />
                    <IconButton
                      icon={<PiXBold />}
                      size="sm"
                      colorScheme="gray"
                      onClick={() => setEditingCommentId(null)}
                      aria-label="Cancel editing"
                    />
                  </Flex>
                ) : (
                  <Text ml={2} fontSize="lg" color={textColor} mb={2}>
                    {comment.message}
                  </Text>
                )}

                {/* 2. Conditional Rendering of Buttons */}
                {editingCommentId !== comment._id && (
                  <Box position="absolute" bottom="2" right="2" display="flex" alignItems="center" opacity="0" _groupHover={{ opacity: 1 }} transition='opacity 0.2s ease-in-out'>
                    {/* Like button */}
                    <IconButton icon={<PiThumbsUp fontSize="18px" />} size="sm" variant="ghost" color="gray.500" aria-label="Like comment" mr={1} />
                    {/* Edit and Delete buttons */}
                    {comment.userId.toString() === currentUser?._id && (
                      <>
                        <IconButton
                          icon={<PiPencilSimpleLine fontSize='18px' />}
                          size="sm"
                          variant="ghost"
                          color="gray.500"
                          aria-label="Edit comment"
                          onClick={() => handleEditComment(comment)}
                          mr={1}
                        />
                        <IconButton
                          icon={<PiTrash fontSize='18px' />}
                          size="sm"
                          variant="ghost"
                          color="gray.500"
                          aria-label="Delete comment"
                          onClick={() => handleDeleteComment(comment._id)}
                        />
                      </>
                    )}
                  </Box>
                )}
              </Box>
            ))
        ) : (
          <Text color={textColor}>No comments yet.</Text>
        )}
      </Box>
    </Flex>
  );
};

export default CommentSection;
