import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, Button, Text, VStack } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/react';


const ForgotPasswordPage = () => {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // Define color mode values
  const bgColor = useColorModeValue('white', 'white');
  const formBgColor = useColorModeValue('gray.100', 'gray.100');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/api/v1/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('The password reset link has been sent to your email address.');
      } else {
        setMessage(data.message || 'Error sending the password reset email.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error sending the password reset email.');
    }
  };

  return (
    <Box className="forgot-password-container" bg={bgColor} minH="100vh" display="flex" justifyContent="center" alignItems="center">
      <Box className="forgot-password-content" w="100%" maxW="md" p={8} borderRadius="md" boxShadow="lg" bg={formBgColor}>
        <form onSubmit={handleSubmit} className="forgot-password-form">
          <VStack spacing={4}>
            <Text fontSize="2xl" fontWeight="bold">Mot de passe oublié</Text>
            <FormControl id="email">
              <FormLabel>Entrez votre courriel associé au compte:</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormControl>
            <Button
              type="submit"
              colorScheme='primary'
              width="full"
            >
              Envoyer un lien de réinitialisation
            </Button>
            {message && <Text>{message}</Text>}
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;
