// client\src\components\4 - API\API-User.js

import { fetchData } from "./FetchData";
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

// Fetch a single user by ID
export const fetchUserById = async (userId) => {
  const url = `${API_BASE_URL}/api/v1/users/${userId}`;
  const data = await fetchData(url);
  return data;
};

// Fetch users by company
export const fetchUsersByCompany = async (companyId) => {
  const url = `${API_BASE_URL}/api/v1/users/company-users/${companyId}`;
  const data = await fetchData(url);
  return data;
};

export const fetchUsersCRM = async ({ search, searchColumns, page, limit }) => {
  const params = new URLSearchParams();
  if (search) params.append("search", search);
  if (searchColumns && searchColumns.length > 0) {
    params.append("searchColumns", searchColumns.join(","));
  }
  if (page) params.append("page", page);
  if (limit) params.append("limit", limit);
  const url = `${API_BASE_URL}/api/v1/users/users?${params.toString()}`;
  const data = await fetchData(url);
  return data;
};

// Update a user
export const updateUser = async (userId, updates) => {
  const url = `${API_BASE_URL}/api/v1/users/${userId}`;
  const data = await fetchData(url, "PATCH", updates);
  return data;
};

// Invite a user
export const inviteUser = async (userData) => {
  const url = `${API_BASE_URL}/api/v1/users/invite`;
  const data = await fetchData(url, "POST", userData);
  return data;
};

// Upload profile picture
export const uploadProfilePicture = async (userId, file) => {
  const url = `${API_BASE_URL}/api/v1/users/upload-profile-picture/${userId}`;
  const formData = new FormData();
  formData.append("profilePicture", file); // Ensure the field name is "profilePicture"

  const data = await fetchData(url, "POST", formData, true);
  return data;
};

export const fetchCurrentUser = async () => {
  const userId = localStorage.getItem("userId");
  if (!userId) throw new Error("User ID not found in local storage.");
  return fetchUserById(userId);
};

export const getAvailableUsers = async () => {
  const url = `${API_BASE_URL}/api/v1/users/available-users`;
  const data = await fetchData(url);
  return data;
};
