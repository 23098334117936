// client/src/components/SettingsPageCompany.js

import React, { useState, useEffect, useContext } from "react";
import { WorkspaceContext } from "../5 - General/Context/WorkspaceContext";
import {
  Box,
  Flex,
  Button,
  Input,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
  FormControl,
  FormLabel,
  Divider,
  Grid,
  GridItem,
  Switch,
  useDisclosure,
  HStack,
  Heading,
  Icon,
  Link,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import LogoUploadModal from "../3 - Modal/8 - Settings Modals/LogoUploadModal";
import CompanyAvatar from "./Reusable/CompanyAvatar";
import { PiCamera, PiLink } from "react-icons/pi";
import ReusableModal from "../3 - Modal/9 - General Modals/ReusableModal";
import { useUpdateCompany, useUploadLogo } from "../5 - General/Hooks/useUpdateCompany"; // Import both hooks
import ColorSelector from "./Reusable/ColorSelector";

const moduleDisplayNames = {
  messages: "Messages",
  projects: "Projects",
  files: "Files",
  contracts: "Contracts",
  billing: "Billing",
  reports: "Reports",
  meetings: "Meetings",
};

const moduleDescriptions = {
  messages: "Manage internal and external communications.",
  projects: "Organize and track project progress.",
  files: "Store and share important documents.",
  contracts: "Handle contract management efficiently.",
  billing: "Manage invoicing and payments.",
  reports: "Generate and analyze reports.",
  meetings: "Schedule and conduct meetings.",
};

// SidePanel Component for additional information
const SidePanelContent = ({ themeColor, isMobile }) => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.600", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const linkColor = useColorModeValue(`${themeColor}.600`, `${themeColor}.400`);
  const iconColor = useColorModeValue(`${themeColor}.600`, `${themeColor}.400`);

  return (
    <Box
      // Separate border properties to ensure correct application
      borderLeftWidth={{ base: "0", md: "1px" }}
      borderLeftStyle={{ base: "none", md: "solid" }}
      borderLeftColor={borderColor}
      position={{ base: "static", md: "sticky" }}
      top="0"
      right="0"
      minW={{ base: "full", md: "350px" }}
      w={{ base: "full", md: "350px" }}
      height={{ base: "auto", md: "100vh" }}
      bg={bgColor}
      p={8}
      overflowY="auto"
      // Custom scrollbar styles
      sx={{
        /* WebKit-based browsers */
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "4px",
          backgroundColor: useColorModeValue("gray.300", "gray.600"),
        },
        /* Firefox */
        scrollbarWidth: "thin",
        scrollbarColor: `${useColorModeValue("gray.300", "gray.600")} transparent`,
      }}
    >
      <Heading as="h2" fontWeight="600" size="md" mb={4} color={textColor}>
        Company Settings
      </Heading>
      <Text fontSize="md" mb={6} color={textColor}>
        Configure your company details, branding, and available modules to tailor the app to your organization's needs.
      </Text>

      {/* Company Information */}
      <Heading as="h3" fontWeight="600" fontSize="14px" mb={2} color={textColor}>
        Company Information
      </Heading>
      <Text fontSize="sm" mb={4} color={textColor}>
        Update your company name and business registration number to keep your organization's information current.
      </Text>

      {/* Available Modules */}
      <Heading as="h3" fontWeight="600" fontSize="14px" mb={2} color={textColor}>
        Available Modules
      </Heading>
      <Text fontSize="sm" mb={4} color={textColor}>
        Enable or disable modules to customize which features are accessible within your organization. Modules include Messages, Projects, Files,
        Contracts, Billing, Reports, and Meetings.
      </Text>

      {/* Company Logos */}
      <Heading as="h3" fontWeight="600" fontSize="14px" mb={2} color={textColor}>
        Company Logos
      </Heading>
      <Text fontSize="sm" mb={4} color={textColor}>
        Upload your company logos for both dark and light backgrounds to ensure consistent branding across the application.
      </Text>

      {/* Primary Color */}
      {!isMobile && (
        <>
          <Heading as="h3" fontWeight="600" fontSize="14px" mb={2} color={textColor}>
            Primary Color
          </Heading>
          <Text fontSize="sm" mb={4} color={textColor}>
            Select a primary color that represents your brand. This color will be used throughout the app to customize the user interface to match
            your company's identity.
          </Text>
        </>
      )}

      <Divider borderColor={textColor} my={6} />

      <HStack spacing={1} mb={4} alignContent="center">
        <Text fontSize="sm" color={textColor}>
          Learn more about
        </Text>
        <Link href="#" fontSize="sm" color={linkColor} cursor="pointer" _hover={{ textDecoration: "underline" }}>
          Company Settings
        </Link>
        <Icon as={PiLink} fontSize="18px" color={iconColor} />
      </HStack>
    </Box>
  );
};

const lightShades = ["400", "300", "200", "100"];
const darkShades = ["900", "800", "700", "600"];
const availableColors = ["primary", "gray", "red", "orange", "yellow", "green", "teal", "blue", "cyan", "purple", "pink"];

function SettingsPageCompany() {
  const { currentUserCompanyId, currentUserCompany, themeColor } = useContext(WorkspaceContext);
  const updateCompanyMutation = useUpdateCompany(); // Use the update company hook
  const uploadLogoMutation = useUploadLogo(); // Use the upload logo hook

  // State to manage initial form data for resetting
  const [initialFormData, setInitialFormData] = useState(null);
  const [isModified, setIsModified] = useState(false);

  // States for managing modals
  const { isOpen: isColorLogoOpen, onOpen: onColorLogoOpen, onClose: onColorLogoClose } = useDisclosure();
  const { isOpen: isWhiteLogoOpen, onOpen: onWhiteLogoOpen, onClose: onWhiteLogoClose } = useDisclosure();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalModuleName, setModalModuleName] = useState("");
  const [modalNewValue, setModalNewValue] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Initialize as false

  // Temporary previews for logos
  const [temporaryWhiteLogoPreview, setTemporaryWhiteLogoPreview] = useState(null);
  const [temporaryColorLogoPreview, setTemporaryColorLogoPreview] = useState(null);

  // Define additional color variables using useColorModeValue
  const bgTopBarColor = useColorModeValue("gray.50", "gray.900");
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.600", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const cardBgColor = useColorModeValue("white", "gray.700");
  const cardBgHoverColor = useColorModeValue("gray.50", "gray.800");
  const drawerBg = useColorModeValue("gray.50", "gray.900");
  const formLabelColor = useColorModeValue("gray.700", "gray.200");
  const inputBgColor = useColorModeValue("gray.50", "gray.700");
  const dividerColor = useColorModeValue("gray.200", "gray.700");

  const isAdminCompany = currentUserCompany.companyRole === "Admin Company";

  // Initialize formData with proper structure
  const [formData, setFormData] = useState({
    companyName: "",
    companyRegistrationNumber: "",
    primaryColor: "primary",
    modules: {
      messages: false,
      projects: false,
      files: false,
      contracts: false,
      billing: false,
      reports: false,
      meetings: false,
    },
    address: {
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
    },
    contactInfo: {
      phone: "",
      email: "",
    },
    socialMedia: {
      linkedIn: "",
      twitter: "",
      facebook: "",
    },
  });

  // Update formData when currentUserCompany changes
  useEffect(() => {
    if (currentUserCompany) {
      const companyData = {
        companyName: currentUserCompany.companyName || "",
        companyRegistrationNumber: currentUserCompany.companyRegistrationNumber || "",
        primaryColor: currentUserCompany.primaryColor || "primary",
        modules: currentUserCompany.modules || {
          messages: false,
          projects: false,
          files: false,
          contracts: false,
          billing: false,
          reports: false,
          meetings: false,
        },
        address: currentUserCompany.address || {
          street: "",
          city: "",
          state: "",
          postalCode: "",
          country: "",
        },
        contactInfo: currentUserCompany.contactInfo || {
          phone: "",
          email: "",
        },
        socialMedia: currentUserCompany.socialMedia || {
          linkedIn: "",
          twitter: "",
          facebook: "",
        },
      };
      setFormData(companyData);
      setInitialFormData(companyData);
    }
  }, [currentUserCompany]);

  // Handle the actual logo upload after cropping
  const handleLogoUpload = (logoType, file) => {
    const companyId = typeof currentUserCompanyId === "object" && currentUserCompanyId._id ? currentUserCompanyId._id : currentUserCompanyId;
    uploadLogoMutation.mutate({ companyId: companyId, file: file, logoType: logoType });
  };

  // Handle changes in form inputs
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Handle nested fields
    const nameParts = name.split(".");
    if (nameParts.length > 1) {
      const [section, field] = nameParts;
      if (type === "checkbox") {
        // Open confirmation modal before changing module status
        setModalModuleName(`${section}.${field}`);
        setModalNewValue(checked);
        setIsModalOpen(true);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [section]: {
            ...prevData[section],
            [field]: value,
          },
        }));
        setIsModified(true);
      }
    } else {
      if (type === "checkbox") {
        // For any top-level checkboxes if present in future
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked,
        }));
        setIsModified(true);
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setIsModified(true);
      }
    }
  };

  // Confirm module enable/disable changes
  const handleModalConfirm = () => {
    const [section, field] = modalModuleName.split(".");
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: modalNewValue,
      },
    }));
    setIsModified(true);
    setIsModalOpen(false);
  };

  // Cancel module enable/disable changes
  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  // Handle Primary Color Change
  const handleColorChange = (colorValue) => {
    setFormData((prevData) => ({
      ...prevData,
      primaryColor: colorValue,
    }));
    setIsModified(true);
  };

  // Save changes to company settings
  const handleSave = () => {
    const updatedFields = { ...formData };
    updateCompanyMutation.mutate({
      companyId: currentUserCompanyId,
      updates: updatedFields,
    });
    setIsModified(false);
  };

  // Cancel changes and reset formData to initial values
  const handleCancel = () => {
    setFormData(initialFormData);
    setIsModified(false);
    setIsPreviewOpen(false);
  };

  // Determine if the device is mobile
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Disclosure for side panel drawer
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();

  if (!currentUserCompany) {
    return (
      <Flex align="center" justify="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex direction="row" w="100%" h="100vh">
      {/* Main Content */}
      <Box
        bg={bgColor}
        flex="1"
        overflowY="auto"
        sx={{
          /* WebKit-based browsers */
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
          },
          /* Firefox */
          scrollbarWidth: "thin",
        }}
      >
        <VStack align="stretch" width="100%">
          {/* Top Bar */}
          <Flex
            width="100%"
            justify="space-between"
            bg={bgTopBarColor}
            position="sticky"
            top="0"
            zIndex={10}
            alignItems="center"
            mb={8}
            px={8}
            borderBottom="1px solid"
            borderColor={borderColor}
            height="80px"
          >
            <Flex align="center" flexDir={{ base: "row", md: "row" }} textAlign="left">
              <Text fontSize="xl" pl={{ base: "20px", md: 0 }} fontWeight="500" color={textColor}>
                Company Settings
              </Text>
            </Flex>
            {/* Buttons */}
            {isMobile ? (
              <VStack spacing={2} align="flex-end" width="30%">
                <Button variant="outline" onClick={handleCancel} colorScheme="gray" isDisabled={!isModified} width="full">
                  Cancel
                </Button>
                <Button
                  colorScheme={themeColor}
                  onClick={handleSave}
                  isDisabled={!isModified || updateCompanyMutation.isLoading}
                  isLoading={updateCompanyMutation.isLoading}
                  width="full"
                >
                  Save
                </Button>
              </VStack>
            ) : (
              <Flex>
                <Button variant="outline" onClick={handleCancel} mr={3} colorScheme="gray" isDisabled={!isModified}>
                  Cancel Changes
                </Button>
                <Button
                  colorScheme={themeColor}
                  onClick={handleSave}
                  isDisabled={!isModified || updateCompanyMutation.isLoading}
                  isLoading={updateCompanyMutation.isLoading}
                >
                  Save Changes
                </Button>
              </Flex>
            )}
          </Flex>
          {/* Form Content */}
          <VStack spacing={8} align="stretch" width="100%" px={8} py={12} pt={0}>
            {isMobile && (
              <Button alignSelf="start" color="primary.500" aria-label="Open Settings" leftIcon={<PiCamera />} variant="ghost" onClick={onDrawerOpen}>
                Learn more about Company Settings
              </Button>
            )}
            {/* Company Information Section */}
            <Heading as="h3" fontWeight="600" fontSize="16px" mb={4} color={textColor}>
              Company General Information
            </Heading>
            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
              {/* Company Name */}
              <GridItem>
                <FormControl isRequired>
                  <FormLabel color={formLabelColor}>Company Name</FormLabel>
                  <Input
                    name="companyName"
                    type="text"
                    value={formData.companyName}
                    onChange={handleChange}
                    placeholder="Enter company name"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>

              {/* Business Registration Number */}
              <GridItem>
                <FormControl isRequired>
                  <FormLabel color={formLabelColor}>Business Registration Number</FormLabel>
                  <Input
                    name="companyRegistrationNumber"
                    type="text"
                    value={formData.companyRegistrationNumber}
                    onChange={handleChange}
                    placeholder="Enter business registration number"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>
            </Grid>

            <Divider borderColor={dividerColor} />

            {/* Address Section */}
            <Heading as="h3" fontWeight="600" fontSize="16px" mb={4} color={textColor}>
              Address
            </Heading>
            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
              {/* Street */}
              <GridItem colSpan={{ base: 1, md: 2 }}>
                <FormControl>
                  <FormLabel color={formLabelColor}>Street</FormLabel>
                  <Input
                    name="address.street"
                    type="text"
                    value={formData.address.street}
                    onChange={handleChange}
                    placeholder="Enter street address"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>

              {/* City */}
              <GridItem>
                <FormControl>
                  <FormLabel color={formLabelColor}>City</FormLabel>
                  <Input
                    name="address.city"
                    type="text"
                    value={formData.address.city}
                    onChange={handleChange}
                    placeholder="Enter city"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>

              {/* State/Province */}
              <GridItem>
                <FormControl>
                  <FormLabel color={formLabelColor}>State/Province</FormLabel>
                  <Input
                    name="address.state"
                    type="text"
                    value={formData.address.state}
                    onChange={handleChange}
                    placeholder="Enter state or province"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>

              {/* Postal Code */}
              <GridItem>
                <FormControl>
                  <FormLabel color={formLabelColor}>Postal Code</FormLabel>
                  <Input
                    name="address.postalCode"
                    type="text"
                    value={formData.address.postalCode}
                    onChange={handleChange}
                    placeholder="Enter postal code"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>

              {/* Country */}
              <GridItem>
                <FormControl>
                  <FormLabel color={formLabelColor}>Country</FormLabel>
                  <Input
                    name="address.country"
                    type="text"
                    value={formData.address.country}
                    onChange={handleChange}
                    placeholder="Enter country"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>
            </Grid>

            <Divider borderColor={dividerColor} />

            {/* Contact Information Section */}
            <Heading as="h3" fontWeight="600" fontSize="16px" mb={4} color={textColor}>
              Contact Information
            </Heading>
            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
              {/* Phone Number */}
              <GridItem>
                <FormControl>
                  <FormLabel color={formLabelColor}>Phone Number</FormLabel>
                  <Input
                    name="contactInfo.phone"
                    type="tel"
                    value={formData.contactInfo.phone}
                    onChange={handleChange}
                    placeholder="Enter phone number"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>

              {/* Email */}
              <GridItem>
                <FormControl>
                  <FormLabel color={formLabelColor}>Email</FormLabel>
                  <Input
                    name="contactInfo.email"
                    type="email"
                    value={formData.contactInfo.email}
                    onChange={handleChange}
                    placeholder="Enter contact email"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>
            </Grid>

            <Divider borderColor={dividerColor} />

            {/* Social Media Links Section */}
            <Heading as="h3" fontWeight="600" fontSize="16px" mb={4} color={textColor}>
              Social Media Links
            </Heading>
            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
              {/* LinkedIn */}
              <GridItem>
                <FormControl>
                  <FormLabel color={formLabelColor}>LinkedIn</FormLabel>
                  <Input
                    name="socialMedia.linkedIn"
                    type="url"
                    value={formData.socialMedia.linkedIn}
                    onChange={handleChange}
                    placeholder="Enter LinkedIn URL"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>

              {/* Twitter */}
              <GridItem>
                <FormControl>
                  <FormLabel color={formLabelColor}>Twitter</FormLabel>
                  <Input
                    name="socialMedia.twitter"
                    type="url"
                    value={formData.socialMedia.twitter}
                    onChange={handleChange}
                    placeholder="Enter Twitter URL"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>

              {/* Facebook */}
              <GridItem colSpan={{ base: 1, md: 2 }}>
                <FormControl>
                  <FormLabel color={formLabelColor}>Facebook</FormLabel>
                  <Input
                    name="socialMedia.facebook"
                    type="url"
                    value={formData.socialMedia.facebook}
                    onChange={handleChange}
                    placeholder="Enter Facebook URL"
                    bg={inputBgColor}
                  />
                </FormControl>
              </GridItem>
            </Grid>

            <Divider borderColor={dividerColor} />

            {isAdminCompany && (
              <>
                {/* Available Modules Section */}
                <Heading as="h3" fontWeight="600" fontSize="16px" mb={4} color={textColor}>
                  Available Modules
                </Heading>
                <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
                  {formData.modules && Object.keys(formData.modules).length > 0 ? (
                    Object.keys(formData.modules).map((module, index) => {
                      const isActive = formData.modules[module];

                      return (
                        <GridItem key={index}>
                          <Box
                            minH="100px"
                            borderWidth="1px"
                            borderRadius="lg"
                            p={4}
                            borderColor={isActive ? `${themeColor}.500` : borderColor}
                            bg={cardBgColor}
                            transition="background-color 0.2s, border-color 0.2s"
                            _hover={{ bg: cardBgHoverColor }}
                          >
                            <Flex alignItems="center" justifyContent="space-between">
                              <Box>
                                <Text fontWeight="bold" color={textColor}>
                                  {moduleDisplayNames[module] || module}
                                </Text>
                                <Text fontSize="sm" color="gray.500">
                                  {moduleDescriptions[module]}
                                </Text>
                              </Box>
                              <Switch
                                id={module}
                                isChecked={isActive}
                                onChange={handleChange}
                                name={`modules.${module}`}
                                colorScheme={themeColor}
                                size="lg"
                              />
                            </Flex>
                          </Box>
                        </GridItem>
                      );
                    })
                  ) : (
                    <Flex align="center" justify="center" w="100%" py={10}>
                      <Spinner />
                    </Flex>
                  )}
                </Grid>
                <Divider borderColor={dividerColor} />
              </>
            )}

            {/* Logo Upload Section */}
            <Heading as="h3" fontWeight="600" fontSize="16px" mb={4} color={textColor}>
              Logo Upload
            </Heading>
            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
              {/* Company Logo - Dark Background */}
              <GridItem>
                <FormControl>
                  <FormLabel color={formLabelColor} fontSize="md" mb={2}>
                    Company Logo - For Dark Theme Background
                  </FormLabel>
                  <Text color={textColor} fontSize="sm" mb={4}>
                    400px by 400px image format recommended.
                  </Text>
                  <Flex alignItems="center">
                    <Box
                      position="relative"
                      cursor="pointer"
                      onClick={onWhiteLogoOpen}
                      bg="gray.700"
                      borderRadius="2xl"
                      width="150px"
                      height="150px"
                      border="1px solid"
                      borderColor={borderColor}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      _hover={{ opacity: 0.8 }}
                    >
                      <CompanyAvatar companyIds={[currentUserCompanyId]} size="xl" showInfo={false} forceMode="dark" />
                      <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        opacity={0}
                        transition="opacity 0.2s"
                        _hover={{ opacity: 1 }}
                        bg="rgba(0, 0, 0, 0.5)"
                        borderRadius="2xl"
                      >
                        <PiCamera color="white" size={20} />
                      </Box>
                    </Box>
                  </Flex>
                </FormControl>
              </GridItem>

              {/* Company Logo - Light Background */}
              <GridItem>
                <FormControl>
                  <FormLabel color={formLabelColor} fontSize="md" mb={2}>
                    Company Logo - For Light Theme Background
                  </FormLabel>
                  <Text color={textColor} fontSize="sm" mb={4}>
                    400px by 400px image format recommended.
                  </Text>
                  <Flex alignItems="center">
                    <Box
                      position="relative"
                      cursor="pointer"
                      onClick={onColorLogoOpen}
                      bg="gray.50"
                      borderRadius="xl"
                      width="150px"
                      height="150px"
                      border="1px solid"
                      borderColor={borderColor}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      _hover={{ opacity: 0.8 }}
                    >
                      <CompanyAvatar companyIds={[currentUserCompanyId]} size="xl" showInfo={false} forceMode="light" />
                      <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        opacity={0}
                        transition="opacity 0.2s"
                        _hover={{ opacity: 1 }}
                        bg="rgba(0, 0, 0, 0.5)"
                        borderRadius="xl"
                      >
                        <PiCamera color="white" size={20} />
                      </Box>
                    </Box>
                  </Flex>
                </FormControl>
              </GridItem>
            </Grid>

            {isAdminCompany && !isMobile && (
              <>
                <Divider borderColor={dividerColor} />

                {/* Primary Color Selection */}
                <Heading as="h3" fontWeight="600" fontSize="16px" mb={4} color={textColor}>
                  Main Theme Color
                </Heading>
                <FormControl isRequired>
                  <FormLabel color={formLabelColor}>Primary Theme Color</FormLabel>

                  {/* Integrate ColorSelector */}
                  <ColorSelector
                    formData={formData}
                    handleColorChange={handleColorChange}
                    availableColors={availableColors}
                    darkShades={darkShades}
                    lightShades={lightShades}
                    isPreviewOpen={isPreviewOpen}
                    textColor={textColor}
                    borderColor={borderColor}
                  />
                </FormControl>

                {/* Preview Colors Button */}
                <Button ml={8} size="sm" onClick={() => setIsPreviewOpen(!isPreviewOpen)} colorScheme="gray" width="fit-content">
                  {isPreviewOpen ? "Hide Color Shades" : "View Color Shades"}
                </Button>
              </>
            )}
          </VStack>
        </VStack>
      </Box>

      {/* Desktop Side Panel */}
      {!isMobile && <SidePanelContent themeColor={themeColor} isMobile={isMobile} />}

      {/* Mobile Side Panel Drawer */}
      {isMobile && (
        <Drawer placement="right" onClose={onDrawerClose} isOpen={isDrawerOpen}>
          <DrawerOverlay>
            <DrawerContent bg={drawerBg}>
              <DrawerCloseButton />
              <DrawerBody p={0}>
                <SidePanelContent themeColor={themeColor} isMobile={isMobile} />
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      )}

      {/* Logo Upload Modals */}
      <LogoUploadModal
        isOpen={isColorLogoOpen}
        onClose={() => {
          onColorLogoClose();
          setTemporaryColorLogoPreview(null); // Reset preview after closing
        }}
        onUpload={(file) => handleLogoUpload("colorLogo", file)}
        initialPreview={temporaryColorLogoPreview}
        logoType="color"
        companyId={currentUserCompanyId}
      />

      <LogoUploadModal
        isOpen={isWhiteLogoOpen}
        onClose={() => {
          onWhiteLogoClose();
          setTemporaryWhiteLogoPreview(null); // Reset preview after closing
        }}
        onUpload={(file) => handleLogoUpload("whiteLogo", file)}
        initialPreview={temporaryWhiteLogoPreview}
        logoType="white"
        companyId={currentUserCompanyId}
      />

      {/* Confirmation Modal for Module Changes */}
      <ReusableModal
        isOpen={isModalOpen}
        onClose={handleModalCancel}
        title="Confirm Module Change"
        footerButtons={
          <>
            <Button variant="outline" onClick={handleModalCancel}>
              Cancel
            </Button>
            <Button colorScheme={themeColor} onClick={handleModalConfirm} ml={3}>
              Confirm
            </Button>
          </>
        }
      >
        <Text>
          Are you sure you want to {modalNewValue ? "enable" : "disable"} the{" "}
          <Text as="span" fontWeight="bold">
            {moduleDisplayNames[modalModuleName.split(".")[1]] || modalModuleName.split(".")[1]}
          </Text>{" "}
          module?
        </Text>
      </ReusableModal>
    </Flex>
  );
}

export default React.memo(SettingsPageCompany);
