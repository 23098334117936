// client\src\components\4 - API\API-General.js
import { APIError } from "./FetchData";
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const downloadFileBlob = async (fileUrl) => {
    // Ensure fileUrl is a relative path
    if (fileUrl.startsWith("http://") || fileUrl.startsWith("https://")) {
      throw new Error("fileUrl should be a relative path");
    }
  
    const fullUrl = `${API_BASE_URL}${fileUrl}`;
    try {
      const response = await fetch(fullUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData?.error || "Network response was not ok";
        throw new APIError(errorMessage, response.status, errorData);
      }
  
      return await response.blob();
    } catch (error) {
      if (error instanceof APIError) {
        throw error;
      } else {
        throw new APIError(error.message || "An unexpected error occurred.", 500, null);
      }
    }
  };
  
  // Utility function to download a blob as a file
  export const downloadBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };
  