import React from "react";
import { Box, Heading, Text, Center, Icon, Button, SimpleGrid, Divider } from "@chakra-ui/react";
import { PiVideoConference } from "react-icons/pi";

const VideoConferencing = () => {
  return (
    <Box bg="gray.50" w="100%" minH="100vh" p={5} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Center w="100%" flexDirection="column">
        <Icon as={PiVideoConference} boxSize={20} color="primary.500" mb={4} />
        <Heading as="h1" size="2xl" color="primary.700" textAlign="center">
          Video Conferencing Module Coming Soon
        </Heading>
        <Text fontSize="lg" color="gray.600" textAlign="center" maxW="800px" mt={4}>
          We're thrilled to announce that our new Video Conferencing Module is on the horizon! Soon, you'll be able to host and join meetings directly
          from your client portal.
        </Text>
        <Button colorScheme="primary" size="lg" mt={6} disabled>
          Notify Me When Available
        </Button>
      </Center>

      <Divider my={10} />

      {/* Feature Highlights */}
      <Box maxW="800px" mx="auto">
        <Heading as="h2" size="lg" color="primary.600" mb={4} textAlign="center">
          Upcoming Features
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              🎥 High-Quality Video Calls
            </Heading>
            <Text color="gray.600">Experience seamless video meetings with HD quality and low latency.</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              👥 Group Meetings
            </Heading>
            <Text color="gray.600">Host meetings with multiple participants, perfect for team collaborations.</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              💻 Screen Sharing
            </Heading>
            <Text color="gray.600">Share your screen or specific applications for presentations and demonstrations.</Text>
          </Box>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              📋 Meeting Recording
            </Heading>
            <Text color="gray.600">Record your meetings and access them later for reference or sharing.</Text>
          </Box>
        </SimpleGrid>
      </Box>
      <Box w="10px" h="100px" />
    </Box>
  );
};

export default VideoConferencing;
