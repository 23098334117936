// src/components/MessagesConversationsContainer.js

import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import {
  Box,
  Button,
  Input,
  List,
  ListItem,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  IconButton,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabIndicator,
} from "@chakra-ui/react";
import { PiMagnifyingGlass, PiPlus } from "react-icons/pi";
import { HiUserGroup } from "react-icons/hi2";
import { HiUser } from "react-icons/hi";
import UserAvatar from "./Reusable/UserAvatar";
import { MessagesContext } from "../5 - General/Context/MessagesContext";
import debounce from "lodash.debounce";

const MessagesConversationsContainer = () => {
  const { channels, currentChannelId, handleChannelClick, currentUser, setModals, formatTimestamp, perChannelUnreadCounts } =
    useContext(MessagesContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const searchRef = useRef(null);

  const borderColor = useColorModeValue("gray.200", "gray.700");
  const listHoverColor = useColorModeValue("gray.50", "gray.750");
  const conversationNameColor = useColorModeValue("gray.800", "gray.100");
  const timestampColor = useColorModeValue("gray.500", "gray.400");
  const lastMessagePreviewColor = useColorModeValue("gray.600", "gray.400");
  const containerBgColor = useColorModeValue("white", "gray.800");
  const unreadCountBgColor = useColorModeValue("primary.800", "primary.500");
  const unreadCountTextColor = useColorModeValue("white", "gray.800");
  const activeBgColor = useColorModeValue("primary.50", "primary.900");
  const inactiveBgColor = useColorModeValue("white", "gray.800");

  const handleSearchClick = () => {
    setIsSearchActive((prev) => {
      if (prev) {
        setSearchTerm(""); // Clear the search term when deactivating search
        setDebouncedSearchTerm(""); // Also clear the debounced search term
      }
      return !prev;
    });
  };

  const debounceSearch = debounce(
    (value) => {
      setDebouncedSearchTerm(value);
    },
    300,
    { leading: false, trailing: true }
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    debounceSearch(e.target.value);
  };

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, [debounceSearch]);

  const handleClickOutside = useCallback(
    (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchActive(false);
        setSearchTerm("");
        setDebouncedSearchTerm(""); // Clear the debounced search term
      }
    },
    [setSearchTerm, setDebouncedSearchTerm]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const getConversationDisplayName = (channel) => {
    if (channel.name) {
      return channel.name;
    } else if (channel.conversationType === "1on1") {
      const otherUser = channel.members.find((user) => user._id !== currentUser._id);
      return otherUser ? `${otherUser.firstName} ${otherUser.lastName}` : "Unknown User";
    } else {
      return "Unnamed Channel";
    }
  };

  const getLastMessagePreview = (channel) => {
    const lastMessage = channel.lastMessage;

    if (!lastMessage) return "";

    if (!lastMessage.content && lastMessage.includesFile) {
      const senderFirstName = lastMessage.senderFirstName || "Someone";
      return `${senderFirstName} sent a file.`;
    }

    if (lastMessage.content?.length > 40) {
      return `${lastMessage.content.substring(0, 40)}...`;
    }

    return lastMessage.content || "";
  };

  // Helper function to get timestamp in milliseconds
  const getTimestamp = (channel) => {
    if (channel.lastMessage && channel.lastMessage.timestamp) {
      const time = new Date(channel.lastMessage.timestamp).getTime();
      return isNaN(time) ? 0 : time;
    }
    return 0;
  };

  // Filter and sort channels
  const filteredChannels = channels
    .filter((channel) => getConversationDisplayName(channel).toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
    .sort((a, b) => getTimestamp(b) - getTimestamp(a));

  const oneOnOneChannels = filteredChannels.filter((channel) => channel.conversationType === "1on1");
  const groupChannels = filteredChannels.filter((channel) => channel.conversationType === "group");

  return (
    <Box width={{ base: "100%", md: "100%" }} height={{ base: "100%", md: "100%" }} bg={containerBgColor}>
      <Box borderColor={borderColor} position="relative">
        <Flex px={8} h="80px" mb={4} borderBottom="1px solid" borderColor={borderColor} justify="space-between" align="center">
          <Text fontSize="lg" fontWeight="500" color={conversationNameColor}>
            Conversations
          </Text>
          <Flex align="center" gap={2}>
            <Menu>
              <MenuButton as={Button} size="xs" variant="outline" leftIcon={<PiPlus fontSize="18px" />} colorScheme="gray" fontWeight="medium">
                Create
              </MenuButton>
              <Portal>
                <MenuList>
                  <MenuItem
                    fontSize="md"
                    onClick={() => {
                      setModals((prev) => ({ ...prev, oneOnOne: true }));
                    }}
                    icon={<HiUser fontSize="18px" />}
                  >
                    Chat with a user
                  </MenuItem>
                  <MenuItem
                    fontSize="md"
                    onClick={() => {
                      setModals((prev) => ({ ...prev, create: true }));
                    }}
                    icon={<HiUserGroup fontSize="18px" />}
                  >
                    Create a group conversation
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
            {/* Wrap the search button and search input inside searchRef */}
            <Box position="relative" ref={searchRef}>
              <IconButton
                w="28px"
                aria-label="Search"
                icon={<PiMagnifyingGlass fontSize="16px" />}
                size="xs"
                onClick={handleSearchClick}
                variant="outline"
                colorScheme="gray"
              />
                <Box display={isSearchActive ? 'visible' : 'none'} position="absolute" top="40px" right="0" width="300px" bg={containerBgColor} p={2} boxShadow="md" borderRadius="md" zIndex="1">
                  <Input type="search" value={searchTerm} onChange={handleSearchChange} placeholder="Search..." autoFocus />
                </Box>
            </Box>
          </Flex>
        </Flex>
      </Box>

      {/* Chakra UI Tabs */}
      <Tabs isFitted variant="unstyled" height="90%" overflowY="auto">
        <TabList mx={6} mb={2}>
          <Tab>User Messages ({oneOnOneChannels.length})</Tab>
          <Tab>Group Chats ({groupChannels.length})</Tab>
        </TabList>
        <TabIndicator mt="-1.5px" height="3px" bg="blue.500" borderRadius="1px" />
        <TabPanels>
          <TabPanel mx={6} py={0} my={0}>
            <List mt={6} spacing={0} flexDirection="column">
              {oneOnOneChannels.map((channel) => {
                const unreadCount = perChannelUnreadCounts[channel._id] || 0;
                const isActive = channel._id === currentChannelId;
                const fontWeight = unreadCount > 0 ? "bold" : "normal";

                return (
                  <ListItem
                    key={channel._id}
                    onClick={() => handleChannelClick(channel._id)}
                    py={2}
                    px={3}
                    my={1}
                    borderRadius="md"
                    borderColor={borderColor}
                    cursor="pointer"
                    bg={isActive ? activeBgColor : inactiveBgColor}
                    _hover={{ bg: listHoverColor }}
                  >
                    <Flex align="center">
                      {(() => {
                        const otherUser = channel.members.find((user) => user._id !== currentUser._id);
                        if (otherUser) {
                          return <UserAvatar userIds={[otherUser._id]} size="md" mr={3} showInfo={false} />;
                        } else {
                          return <UserAvatar userIds={["unknown"]} size="md" mr={3} showInfo={false} />;
                        }
                      })()}
                      <Box flex="1">
                        <Flex justify="space-between" m={1}>
                          <Text fontWeight={fontWeight} isTruncated color={conversationNameColor}>
                            {getConversationDisplayName(channel)}
                          </Text>
                          <Text fontSize="xs" color={timestampColor}>
                            {channel.lastMessage?.timestamp ? formatTimestamp(channel.lastMessage.timestamp) : "No messages"}
                          </Text>
                        </Flex>
                        <Flex justify="space-between" align="center" m={1}>
                          <Text fontSize="sm" color={lastMessagePreviewColor} isTruncated flex="1" fontWeight={fontWeight}>
                            {getLastMessagePreview(channel)}
                          </Text>
                          {unreadCount > 0 && (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              ml="auto"
                              bg={unreadCountBgColor}
                              color={unreadCountTextColor}
                              borderRadius="full"
                              fontSize="xs"
                              p={1}
                              w={6}
                              h={6}
                            >
                              {unreadCount > 99 ? "99+" : unreadCount}
                            </Box>
                          )}
                        </Flex>
                      </Box>
                    </Flex>
                  </ListItem>
                );
              })}
            </List>
          </TabPanel>
          <TabPanel mx={6} py={0} my={0}>
            <List mt={6} spacing={0} flexDirection="column">
              {groupChannels.map((channel) => {
                const unreadCount = perChannelUnreadCounts[channel._id] || 0;
                const isActive = channel._id === currentChannelId;
                const fontWeight = unreadCount > 0 ? "bold" : "normal";

                return (
                  <ListItem
                    key={channel._id}
                    onClick={() => handleChannelClick(channel._id)}
                    py={2}
                    px={3}
                    my={1}
                    borderRadius="md"
                    borderColor={borderColor}
                    cursor="pointer"
                    _hover={{ bg: listHoverColor }}
                    bg={isActive ? activeBgColor : inactiveBgColor}
                  >
                    <Flex align="center">
                      <Box>
                        {Array.isArray(channel.members) && (
                          <UserAvatar
                            userIds={channel.members.map((user) => user._id)}
                            size="md"
                            showInfo={false}
                            useGroup={true}
                            maxAvatars={2}
                            spacing={-15}
                          />
                        )}
                      </Box>
                      <Box flex="1">
                        <Flex justify="space-between" m={1}>
                          <Text fontWeight={fontWeight} isTruncated color={conversationNameColor}>
                            {getConversationDisplayName(channel)}
                          </Text>
                          <Text fontSize="xs" color={timestampColor}>
                            {channel.lastMessage?.timestamp ? formatTimestamp(channel.lastMessage.timestamp) : "No messages"}
                          </Text>
                        </Flex>
                        <Flex justify="space-between" align="center" m={1}>
                          <Text fontSize="sm" color={lastMessagePreviewColor} maxW="200px" isTruncated flex="1" fontWeight={fontWeight}>
                            {getLastMessagePreview(channel)}
                          </Text>
                          {unreadCount > 0 && (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              ml="auto"
                              bg={unreadCountBgColor}
                              color={unreadCountTextColor}
                              borderRadius="full"
                              fontSize="xs"
                              p={1}
                              w={6}
                              h={6}
                            >
                              {unreadCount > 99 ? "99+" : unreadCount}
                            </Box>
                          )}
                        </Flex>
                      </Box>
                    </Flex>
                  </ListItem>
                );
              })}
            </List>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default MessagesConversationsContainer;
