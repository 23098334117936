import React from "react";
import { RadioGroup, Box, Flex, Collapse, Text } from "@chakra-ui/react";
import { PiCheckBold } from "react-icons/pi";

const ColorOption = ({ color, isSelected, onSelect, borderColor }) => (
  <Flex textAlign="center" direction="column" alignItems="center" justifyContent="center" maxW="80px">
    <Box
      height="80px"
      width="80px"
      bg={color === "white" || color === "black" ? color : `${color}.500`}
      borderRadius="md"
      _hover={{ opacity: 0.8 }}
      transition="all 0.2s"
      border={isSelected ? "3px solid" : "2px solid"}
      borderColor={isSelected ? "blue.500" : borderColor}
      cursor="pointer"
      onClick={() => onSelect(color)}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {isSelected && <PiCheckBold color={color === "white" ? "black" : "white"} fontSize="24px" />}
    </Box>
  </Flex>
);

const ShadesPreview = ({ color, shades, shadeType, borderColor }) => (
  <Box key={`${color}-${shadeType}`} textAlign="center">
    <Flex direction="column" align="center">
      {shades.map((shade) => (
        <Box
          key={`${color}-${shade}`}
          height="30px"
          w="full"
          maxW="80px"
          bg={`${color}.${shade}`}
          borderRadius="md"
          border="1px solid"
          borderColor={borderColor}
          mb={1}
        />
      ))}
    </Flex>
  </Box>
);

const ColorSelector = ({
  formData,
  handleColorChange,
  availableColors,
  darkShades,
  lightShades,
  isPreviewOpen,
  textColor = "gray.700",
  borderColor = "gray.200",
}) => {
  return (
    <Flex w="100%" wrap="nowrap" alignItems="center" gap={4}>
      {availableColors.map((color) => (
        <RadioGroup justifyContent="center" maxW="80px" name="primaryColor" value={formData.primaryColor} onChange={handleColorChange}>
          {/* Darker Shades Collapse */}
          <Collapse in={isPreviewOpen} animateOpacity>
            <Box mt={4} width="100%">
              <ShadesPreview borderColor={borderColor} color={color} shades={darkShades} shadeType="dark" />
            </Box>
          </Collapse>

          {/* Main Color Selection */}
          <Box width="100%" mt={isPreviewOpen ? 0 : 4}>
            <ColorOption
              key={color}
              color={color}
              isSelected={formData.primaryColor === color}
              onSelect={handleColorChange}
              borderColor={borderColor}
            />
          </Box>

          {/* Light Shades Collapse */}
          <Collapse in={isPreviewOpen} animateOpacity>
            <Box mt={1} width="100%">
              <ShadesPreview borderColor={borderColor} color={color} shades={lightShades} shadeType="light" />
            </Box>
          </Collapse>
          <Text w="full" maxW="80px" mx="auto" textAlign="center" mt={2} fontSize="sm" color={textColor}>
            {color.toLowerCase() === "primary" ? "Default" : color.charAt(0).toUpperCase() + color.slice(1)}
          </Text>
        </RadioGroup>
      ))}
    </Flex>
  );
};

export default ColorSelector;
