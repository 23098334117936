// src/components/3 - Modal/8 - Settings Modals/DeactivateUserModal.jsx
import React, { useState } from "react";
import { Button, VStack, Text, Flex } from "@chakra-ui/react";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { useUpdateUser } from "../../5 - General/Hooks/useUpdateUser";

const DeactivateUserModal = ({ isOpen, onClose, userToDeactivate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateUserMutation = useUpdateUser();

  const handleConfirm = async () => {
    if (userToDeactivate && userToDeactivate._id) {
      setIsLoading(true);
      try {
        updateUserMutation.mutate({ userId: userToDeactivate._id, updates: { status: "Deactivated" } });
        onClose();
      } catch (error) {
        // Error handling is already managed in the mutation hook
      } finally {
        setIsLoading(false);
      }
    }
  };

  const footerButtons = (
    <Flex justify="space-between" width="100%">
      <Button variant="outline" onClick={onClose} width="30%">
        Cancel
      </Button>
      <Button colorScheme="red" onClick={handleConfirm} width="30%" isLoading={isLoading}>
        Deactivate
      </Button>
    </Flex>
  );

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Confirm User Deactivation" footerButtons={footerButtons}>
      <VStack spacing={4} alignItems="center">
        <Text fontSize="md">
          Are you sure you want to deactivate the account of {userToDeactivate?.firstName} {userToDeactivate?.lastName}? This action will disable the
          user's access to the platform.
        </Text>
      </VStack>
    </ReusableModal>
  );
};

export default DeactivateUserModal;
