// src/components/3 - Modal/2 - Messages Modals/AddUserModal.js

import React, { useState, useContext, useEffect } from "react";
import { Box, VStack, HStack, Button, Text, Spinner, Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import ReusableModal from "../9 - General Modals/ReusableModal";
import { MessagesContext } from "../../5 - General/Context/MessagesContext";

const AddUserModal = ({ isOpen, onClose }) => {
  const { currentUser, allUsers, isAllUsersLoading, allUsersError, channelUsers, handleAddUserToChannel } = useContext(MessagesContext);

  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedUsers([]);
    }
  }, [isOpen]);

  const handleAddUsers = () => {
    const userIds = selectedUsers.map((option) => option.value);
    handleAddUserToChannel(userIds);
    onClose();
  };

  const existingUserIds = channelUsers.map((user) => user._id);

  const options = allUsers
    .filter((user) => user._id !== currentUser._id)
    .map((user) => ({
      value: user._id,
      label: `${user.firstName} ${user.lastName} - ${user.companyName || "N/A"}`,
      isDisabled: existingUserIds.includes(user._id),
    }));

  const footerButtons = (
    <HStack spacing={3} w="100%" mt={3}>
      <Button colorScheme="primary" w="100%" onClick={handleAddUsers} isDisabled={selectedUsers.length === 0}>
        Add
      </Button>
      <Button variant="ghost" w="100%" onClick={onClose} border="1px solid" borderColor="gray.200">
        Cancel
      </Button>
    </HStack>
  );

  const renderContent = () => {
    if (isAllUsersLoading) {
      return (
        <VStack spacing={4} pt={4}>
          <Spinner />
          <Text>Loading users...</Text>
        </VStack>
      );
    }

    if (allUsersError) {
      return (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle mr={2}>Failed to load users.</AlertTitle>
          <AlertDescription>{allUsersError.message}</AlertDescription>
        </Alert>
      );
    }

    return (
      <VStack spacing={4} pt={4}>
        <Box width="100%">
          <Select
            isMulti
            options={options}
            value={selectedUsers}
            onChange={(selected) => setSelectedUsers(selected)}
            placeholder="Search for users..."
            components={{
              DropdownIndicator: null,
              IndicatorSeparator: null,
              MultiValueRemove: (props) => <chakraComponents.MultiValueRemove {...props}>✕</chakraComponents.MultiValueRemove>,
            }}
            closeMenuOnSelect={false}
            chakraStyles={{
              menu: (provided) => ({ ...provided, mt: "2px", zIndex: 3 }),
              control: (provided) => ({ ...provided, border: "1px solid", borderColor: "gray.200" }),
            }}
          />
        </Box>
      </VStack>
    );
  };

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title="Add Users to the Conversation" footerButtons={footerButtons}>
      {renderContent()}
    </ReusableModal>
  );
};

export default AddUserModal;
