// client/src/components/Files/FilesList.jsx

import React, { useContext, useEffect, useRef, memo, useCallback, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Tooltip,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  HStack,
  Input,
  Button,
  Flex,
  PopoverContent,
  PopoverBody,
  Popover,
  Portal,
  Divider,
  VStack,
  Spinner,
  useOutsideClick,
  InputGroup,
  InputLeftElement,
  PopoverAnchor,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import {
  PiDotsThreeBold,
  PiDownloadSimple,
  PiTrash,
  PiEye,
  PiPencilSimpleLine,
  PiCheck,
  PiSortAscending,
  PiSortDescending,
  PiArrowsDownUp,
  PiLink,
  PiDotsSixVertical,
  PiPlus,
  PiMagnifyingGlass,
  PiFolderSimple,
} from "react-icons/pi";
import { BsFileEarmarkPlus, BsFolder, BsFolderPlus } from "react-icons/bs";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FilesContext } from "../5 - General/Context/FilesContext";
import UserAvatar from "./Reusable/UserAvatar";
import { renderFileIcon } from "../5 - General/Utils/UtilsFileIcon";
import { formatFileSize } from "../5 - General/Utils/UtilsFormatData";
import { useDrag, useDrop, useDragLayer } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import useCustomToast from "../5 - General/Utils/UtilsNotification";

const MotionTr = memo(motion.create(Tr));

const ItemTypes = {
  FILE: "file",
};

const FilesList = () => {
  const {
    files,
    selectedItems,
    handleFileSelect,
    handleSelectAll,
    handleDownloadFile,
    sortField,
    updateSortField,
    sortDirection,
    updateSortDirection,
    handleFolderClick,
    currentWorkspace,
    searchTerm,
    setSearchTerm,
    suggestions,
    handleSelectSuggestion,
    isLoadingSuggestions,
    handleRenameModalOpen,
    handleDeleteConfirmationModalOpen,
    currentFolderId,
    handleImportFolder,
    onOpenImportModal,
    onOpenCreateLinkModal,
    onOpenCreateFolderModal,
    handleImagePreviewModalOpen,
  } = useContext(FilesContext);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState({
    isOpen: false,
    x: 0,
    y: 0,
    file: null,
  });
  const inputRef = useRef(null);
  const popoverRef = useRef(null);
  const contextMenuRef = useRef(null);
  const folderInputRef = useRef(null);

  const bgHover = useColorModeValue("gray.50", "gray.700");
  const bgHoverIsSelected = useColorModeValue("primary.200", "primary.600");
  const bgDropTarget = useColorModeValue("primary.300", "primary.700");
  const textColor = useColorModeValue("black", "white");
  const textLightColor = useColorModeValue("gray.600", "gray.400");
  const menuContextBgColor = useColorModeValue("white", "gray.800");

  // Function to get file type
  const getFileType = useCallback((file) => {
    if (file.type === "folder") {
      return "Folders";
    } else if (file.type === "link") {
      return "Links";
    } else {
      return file.originalFileName.split(".").pop().toLowerCase();
    }
  }, []);

  // Compute available file types
  const availableFileTypes = React.useMemo(() => {
    const extensionsSet = new Set();
    let hasLink = false;
    files?.forEach((file) => {
      if (file.type === "file") {
        const ext = file.originalFileName.split(".").pop().toLowerCase();
        extensionsSet.add(ext);
      } else if (file.type === "link") {
        hasLink = true;
      }
    });
    return ["Folders", ...(hasLink ? ["Links"] : []), ...Array.from(extensionsSet)];
  }, [files]);

  // State for selected file types
  const [selectedFileTypes, setSelectedFileTypes] = useState(new Set(availableFileTypes));

  // Update selectedFileTypes when availableFileTypes change
  useEffect(() => {
    setSelectedFileTypes((prevSelectedTypes) => {
      const updatedSet = new Set(prevSelectedTypes);
      availableFileTypes.forEach((type) => {
        if (!updatedSet.has(type)) {
          updatedSet.add(type);
        }
      });
      return updatedSet;
    });
  }, [availableFileTypes]);

  // Compute the title for the file type filter
  const selectedFileTypesArray = Array.from(selectedFileTypes);
  let selectedFileTypeTitle;
  if (selectedFileTypes.size === availableFileTypes.length) {
    selectedFileTypeTitle = "All";
  } else if (selectedFileTypes.size === 1) {
    selectedFileTypeTitle = selectedFileTypesArray[0];
  } else {
    selectedFileTypeTitle = "Few";
  }

  const sortFilesFunction = useCallback(() => {
    if (!Array.isArray(files)) return [];
    const sorted = [...files];

    // Sort based on selected file types
    sorted.sort((a, b) => {
      const aType = getFileType(a);
      const bType = getFileType(b);
      const aMatches = selectedFileTypes.has(aType);
      const bMatches = selectedFileTypes.has(bType);

      if (aMatches && !bMatches) return -1;
      if (!aMatches && bMatches) return 1;

      // Then, sort folders before files
      if (a.type === "folder" && b.type !== "folder") return -1;
      if (a.type !== "folder" && b.type === "folder") return 1;

      let aVal, bVal;
      switch (sortField) {
        case "name":
          aVal = a.originalFileName.toLowerCase();
          bVal = b.originalFileName.toLowerCase();
          break;
        case "date":
          aVal = new Date(a.createdAt);
          bVal = new Date(b.createdAt);
          break;
        case "creator":
          aVal = a.uploadedBy.firstName.toLowerCase();
          bVal = b.uploadedBy.firstName.toLowerCase();
          break;
        default:
          return 0;
      }
      if (sortDirection === "asc") {
        return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
      } else if (sortDirection === "desc") {
        return aVal < bVal ? 1 : aVal > bVal ? -1 : 0;
      } else {
        return 0;
      }
    });

    return sorted;
  }, [files, getFileType, selectedFileTypes, sortField, sortDirection]);

  const handleSort = (field) => {
    if (sortField === field) {
      if (sortDirection === "asc") {
        updateSortDirection("desc");
      } else if (sortDirection === "desc") {
        updateSortField(null);
        updateSortDirection(null);
      } else {
        updateSortDirection("asc");
      }
    } else {
      updateSortField(field);
      updateSortDirection("asc");
    }
  };

  const isImageFile = (fileName) => {
    const extensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];
    const ext = fileName.split(".").pop().toLowerCase();
    return extensions.includes(ext);
  };

  const handleRowClick = (e, file) => {
    e.stopPropagation();

    if (file.type === "folder") {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }

      clickTimeout.current = setTimeout(() => {
        handleFileSelect(file._id);
        clickTimeout.current = null;
      }, 250);
    } else {
      handleFileSelect(file._id);
    }
  };

  const handleRowDoubleClick = (e, file) => {
    e.preventDefault();
    e.stopPropagation();

    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
    }

    if (file.type === "folder") {
      handleFolderClick(file._id, file.originalFileName);
    } else if (file.type === "link") {
      window.open(file.url, "_blank");
    }
  };

  const clickTimeout = useRef(null);

  useEffect(() => {
    return () => {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (selectedItems.size === 1) {
      const selectedId = Array.from(selectedItems)[0];
      const selectedRow = document.getElementById(`file-row-${selectedId}`);
      if (selectedRow) {
        selectedRow.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [selectedItems]);

  const triggerFolderInput = () => {
    if (folderInputRef.current) {
      folderInputRef.current.click();
    }
  };

  const onFolderSelected = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      handleImportFolder(files);
    }
    event.target.value = null;
  };

  useOutsideClick({
    ref: contextMenuRef,
    handler: () => {
      if (contextMenu.isOpen) {
        setContextMenu({ ...contextMenu, isOpen: false });
      }
    },
  });

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape" && contextMenu.isOpen) {
        setContextMenu({ ...contextMenu, isOpen: false });
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [contextMenu]);

  // Function to render sort icon
  const renderSortIcon = (field) => {
    if (sortField !== field) {
      return <PiArrowsDownUp fontSize="18px" style={{ marginLeft: "4px" }} />;
    }
    if (sortDirection === "asc") {
      return <PiSortAscending fontSize="18px" style={{ marginLeft: "4px" }} />;
    }
    if (sortDirection === "desc") {
      return <PiSortDescending fontSize="18px" style={{ marginLeft: "4px" }} />;
    }
    return <PiArrowsDownUp fontSize="18px" style={{ marginLeft: "4px" }} />;
  };

  // Custom Drag Layer to show selected items moving with the cursor
  const CustomDragLayer = () => {
    const { isDragging, currentOffset, item } = useDragLayer((monitor) => ({
      item: monitor.getItem(),
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
    }));

    if (!isDragging || !currentOffset || !item) {
      return null;
    }

    const style = {
      position: "fixed",
      pointerEvents: "none",
      left: currentOffset.x,
      top: currentOffset.y,
      transform: "translate(-50%, -50%)",
      zIndex: 1000,
    };

    return (
      <div style={style}>
        <Box bg="white" p={2} borderRadius="md" boxShadow="md">
          <Text>
            {item.files.length} item{item.files.length > 1 ? "s" : ""} selected
          </Text>
        </Box>
      </div>
    );
  };

  return (
    <>
      {/* Hidden Folder Input for Importing Folders */}
      <input
        type="file"
        webkitdirectory="true"
        directory="true"
        multiple
        ref={folderInputRef}
        style={{ display: "none" }}
        onChange={onFolderSelected}
      />
      <Box p={4} height="calc(100vh - 80px)" display="flex" flexDirection="column">
        {/* Header with Select All/Deselect All Button */}
        <Flex justify="space-between" align="center" mb={4} px={4} overflowY="hidden">
          {/* Search Bar with Auto-Suggestions */}
          {currentWorkspace && (
            <Box position="relative" width="500px">
              <Popover
                isOpen={isPopoverOpen}
                onOpen={() => setIsPopoverOpen(true)}
                onClose={() => {
                  setIsPopoverOpen(false);
                }}
                placement="bottom-start"
                matchWidth
                autoFocus={false}
              >
                <PopoverAnchor>
                  <HStack spacing={2} p={1}>
                    <InputGroup maxW="500px">
                      <InputLeftElement pointerEvents="none">
                        <PiMagnifyingGlass fontSize="18px" color="gray.400" />
                      </InputLeftElement>
                      <Input
                        ref={inputRef}
                        placeholder="Search files in the entire directory..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        variant="outline"
                        onFocus={() => setIsPopoverOpen(true)}
                        userSelect="none"
                      />
                    </InputGroup>
                    {searchTerm && (
                      <Button
                        size="sm"
                        onClick={() => {
                          setSearchTerm("");
                          setIsPopoverOpen(false);
                        }}
                        userSelect="none"
                      >
                        Clear
                      </Button>
                    )}
                  </HStack>
                </PopoverAnchor>
                <Portal>
                  <PopoverContent ref={popoverRef} maxH="500px" width="100%" overflowY="auto" zIndex={10} borderRadius="md">
                    <PopoverBody>
                      {!searchTerm ? (
                        <Box p={2}>
                          <Text color={textLightColor} userSelect="none">
                            Search for a file...
                          </Text>
                        </Box>
                      ) : isLoadingSuggestions ? (
                        <Flex justify="center" align="center" py={4}>
                          <Spinner size="md" />
                        </Flex>
                      ) : suggestions.length > 0 ? (
                        <>
                          <Text my={2} mb={4} color={textLightColor} userSelect="none">
                            Search results: {suggestions.length} corresponding file{suggestions.length > 1 ? "s" : ""} found.
                          </Text>
                          <AnimatePresence>
                            {suggestions.map((file) => (
                              <Tooltip
                                key={file._id}
                                label={`Click to navigate to the ${file.type}: ${file.originalFileName}`}
                                placement="right"
                                hasArrow
                              >
                                <Box
                                  onClick={() => handleSelectSuggestion(file)}
                                  cursor="pointer"
                                  p={2}
                                  _hover={{ bg: bgHover }}
                                  borderRadius="md"
                                  m={1}
                                  border="1px solid"
                                  borderColor="primary.50"
                                  display="flex"
                                  alignItems="center"
                                >
                                  {file.type === "folder" ? (
                                    <Box
                                      mr={4}
                                      as={BsFolder}
                                      color="yellow.600"
                                      style={{
                                        fontSize: "24px",
                                      }}
                                    />
                                  ) : (
                                    <Box mr={4}>{renderFileIcon(file.originalFileName)}</Box>
                                  )}

                                  <Text whiteSpace="nowrap" fontSize="md" isTruncated userSelect="none">
                                    {file.originalFileName}
                                  </Text>
                                </Box>
                              </Tooltip>
                            ))}
                          </AnimatePresence>
                        </>
                      ) : (
                        <Box p={2}>
                          <Text color={textLightColor} userSelect="none">
                            No results found.
                          </Text>
                        </Box>
                      )}
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </Box>
          )}
          {/* Action Buttons: Select All/Deselect All */}
          <HStack spacing={2}>
            <Button size="sm" onClick={() => handleSelectAll()} userSelect="none">
              {selectedItems.size > 0 ? "Deselect All" : "Select All"}
            </Button>
          </HStack>
        </Flex>
        {/* Conditional Rendering: No Files or Folders Found */}
        {files.length === 0 && !currentFolderId ? (
          <Flex justify="center" align="start" mt={8} flex="1">
            <Box
              p={8}
              border="1px solid"
              bg="gray.50"
              borderColor="gray.200"
              borderRadius="xl"
              textAlign="center"
              boxShadow="md"
              h="fit-content"
              width={["90%", "70%", "50%"]}
            >
              <Text fontSize="lg" fontWeight="bold">
                No files or folders found for {currentWorkspace?.companyName || "this company"}.
              </Text>
              <Text mt={2} fontSize="md" color="gray.500">
                Add files and folders to get started.
              </Text>
              {currentWorkspace && (
                <Menu>
                  <MenuButton mt={4} as={Button} leftIcon={<PiPlus fontSize="18px" />} fontSize="sm" fontWeight="normal" colorScheme="primary">
                    Add New
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => onOpenImportModal()} icon={<BsFileEarmarkPlus fontSize="18px" />} fontSize="sm">
                      Import a File
                    </MenuItem>
                    <MenuItem onClick={triggerFolderInput} icon={<BsFolderPlus fontSize="18px" />} fontSize="sm">
                      Import a Folder
                    </MenuItem>
                    <MenuItem onClick={() => onOpenCreateLinkModal()} icon={<PiLink fontSize="18px" />} fontSize="sm">
                      Add a Link
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={() => onOpenCreateFolderModal()} icon={<PiFolderSimple fontSize="18px" />} fontSize="sm">
                      Create a Folder
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Box>
          </Flex>
        ) : (
          <Box flex="1" overflowY="scroll" css={{ "&::-webkit-scrollbar": { display: "none" }, msOverflowStyle: "none", scrollbarWidth: "none" }}>
            <Table variant="unstyled" width="100%">
              <Thead>
                <Tr position="sticky" top="0" bg={menuContextBgColor} zIndex="1" borderBottom="1px solid" borderColor="gray.300">
                  {/* File Type Filter Column */}
                  <Th textTransform="capitalize" fontSize="md" pr={1} w="120px" fontWeight="600" cursor="pointer" userSelect="none">
                    {/* Flex container to align Menu to the end */}
                    <Flex align="center" width="100%">
                      <Menu closeOnSelect={false}>
                        <MenuButton as={Box} display="flex" alignItems="center" cursor="pointer">
                          {selectedFileTypeTitle}
                          <ChevronDownIcon ml={1} />
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              setSelectedFileTypes(new Set(availableFileTypes));
                            }}
                          >
                            <HStack justifyContent="space-between" w="100%">
                              <Text textTransform="capitalize" fontSize="md" fontWeight="400">
                                All Files and Folders
                              </Text>
                              {selectedFileTypes.size === availableFileTypes.length && <PiCheck color="green.500" />}
                            </HStack>
                          </MenuItem>
                          <MenuDivider />
                          {availableFileTypes.map((type) => (
                            <MenuItem
                              key={type}
                              onClick={() => {
                                setSelectedFileTypes((prev) => {
                                  if (prev.size === availableFileTypes.length) {
                                    return new Set([type]);
                                  } else {
                                    const newSet = new Set(prev);
                                    if (newSet.has(type)) {
                                      newSet.delete(type);
                                    } else {
                                      newSet.add(type);
                                    }
                                    return newSet;
                                  }
                                });
                              }}
                            >
                              <HStack justifyContent="space-between" w="100%">
                                <Text textTransform="capitalize" fontSize="md" fontWeight="400">
                                  {type}
                                </Text>
                                {selectedFileTypes.has(type) && <PiCheck color="green.500" />}
                              </HStack>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Menu>
                    </Flex>
                  </Th>
                  {/* File Name Column */}
                  <Th
                    _hover={{ bg: "gray.100" }}
                    textTransform="capitalize"
                    transition="all 0.2s"
                    fontSize="md"
                    fontWeight="600"
                    cursor="pointer"
                    onClick={() => handleSort("name")}
                    userSelect="none"
                    align="center"
                  >
                    <HStack>
                      <Tooltip label="Sort by Name" hasArrow>
                        <Text>File Name</Text>
                      </Tooltip>
                      {renderSortIcon("name")}
                    </HStack>
                  </Th>
                  {/* Creator Column */}
                  <Th
                    _hover={{ bg: "gray.100" }}
                    textTransform="capitalize"
                    transition="all 0.2s"
                    fontSize="md"
                    fontWeight="600"
                    cursor="pointer"
                    onClick={() => handleSort("creator")}
                    userSelect="none"
                    align="center"
                  >
                    <HStack>
                      <Tooltip label="Sort by Creator" hasArrow>
                        <Text>Creator</Text>
                      </Tooltip>
                      {renderSortIcon("creator")}
                    </HStack>
                  </Th>
                  {/* Creation Date Column */}
                  <Th
                    _hover={{ bg: "gray.100" }}
                    textTransform="capitalize"
                    transition="all 0.2s"
                    fontSize="md"
                    fontWeight="600"
                    cursor="pointer"
                    onClick={() => handleSort("date")}
                    userSelect="none"
                    align="center"
                  >
                    <HStack>
                      <Tooltip label="Sort by Date" hasArrow>
                        <Text>Creation Date</Text>
                      </Tooltip>
                      {renderSortIcon("date")}
                    </HStack>
                  </Th>
                  {/* Actions Column */}
                  <Th textTransform="capitalize" fontSize="md" fontWeight="600" userSelect="none">
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <AnimatePresence>
                  {sortFilesFunction()
                    .filter((file) => file && file._id && file.uploadedBy)
                    .map((file, index) => (
                      <FileRow
                        key={file._id}
                        file={file}
                        index={index}
                        selectedItems={selectedItems}
                        handleRowClick={handleRowClick}
                        handleRowDoubleClick={handleRowDoubleClick}
                        setContextMenu={setContextMenu}
                        bgHover={bgHover}
                        bgHoverIsSelected={bgHoverIsSelected}
                        bgDropTarget={bgDropTarget}
                        textColor={textColor}
                        textLightColor={textLightColor}
                        renderFileIcon={renderFileIcon}
                        formatFileSize={formatFileSize}
                        isFileTypeSelected={selectedFileTypes.has(getFileType(file))}
                        isImageFile={isImageFile}
                      />
                    ))}
                </AnimatePresence>
              </Tbody>
            </Table>
          </Box>
        )}
        {/* Custom Context Menu */}
        {contextMenu.isOpen && contextMenu.file && (
          <Portal>
            <Box
              ref={contextMenuRef}
              bg={menuContextBgColor}
              position="fixed"
              top={contextMenu.y > window.innerHeight - 200 ? window.innerHeight - 200 : contextMenu.y}
              left={contextMenu.x > window.innerWidth - 200 ? window.innerWidth - 200 : contextMenu.x}
              boxShadow="md"
              borderRadius="md"
              zIndex={1000}
              overflow="hidden"
              minW="150px"
              border="1px solid"
              borderColor="gray.200"
            >
              <VStack align="stretch" spacing={0}>
                {isImageFile(contextMenu.file.originalFileName) && (
                  <Button
                    variant="ghost"
                    size="sm"
                    fontWeight="400"
                    borderRadius="0"
                    justifyContent="flex-start"
                    w="100%"
                    leftIcon={<PiEye fontSize="18px" />}
                    onClick={() => {
                      handleImagePreviewModalOpen(contextMenu.file);
                      setContextMenu({ ...contextMenu, isOpen: false });
                    }}
                    _hover={{ bg: "gray.100" }}
                    userSelect="none"
                  >
                    Preview
                  </Button>
                )}
                {contextMenu.file.type === "file" && (
                  <Button
                    variant="ghost"
                    justifyContent="flex-start"
                    w="100%"
                    size="sm"
                    borderRadius="0"
                    fontWeight="400"
                    leftIcon={<PiDownloadSimple fontSize="18px" />}
                    onClick={() => {
                      handleDownloadFile(contextMenu.file);
                      setContextMenu({ ...contextMenu, isOpen: false });
                    }}
                    _hover={{ bg: "gray.100" }}
                    userSelect="none"
                  >
                    Download
                  </Button>
                )}
                {(contextMenu.file.type === "file" || contextMenu.file.type === "link") && <Divider />}
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  w="100%"
                  size="sm"
                  borderRadius="0"
                  fontWeight="400"
                  leftIcon={<PiPencilSimpleLine fontSize="18px" />}
                  onClick={() => {
                    handleRenameModalOpen(contextMenu.file._id);
                    setContextMenu({ ...contextMenu, isOpen: false });
                  }}
                  _hover={{ bg: "gray.100" }}
                  userSelect="none"
                >
                  Rename
                </Button>
                {contextMenu.file.type === "link" && (
                  <Button
                    variant="ghost"
                    justifyContent="flex-start"
                    w="100%"
                    size="sm"
                    fontWeight="400"
                    borderRadius="0"
                    leftIcon={<PiLink fontSize="18px" />}
                    onClick={() => {
                      window.open(contextMenu.file.url, "_blank");
                      setContextMenu({ ...contextMenu, isOpen: false });
                    }}
                    _hover={{ bg: "gray.100" }}
                    userSelect="none"
                  >
                    Open Link
                  </Button>
                )}
                <Button
                  variant="ghost"
                  justifyContent="flex-start"
                  w="100%"
                  size="sm"
                  fontWeight="400"
                  borderRadius="0"
                  leftIcon={<PiTrash fontSize="18px" />}
                  colorScheme="red"
                  onClick={() => {
                    handleDeleteConfirmationModalOpen([contextMenu.file._id]);
                    setContextMenu({ ...contextMenu, isOpen: false });
                  }}
                  _hover={{ bg: "gray.100" }}
                  userSelect="none"
                >
                  Delete
                </Button>
              </VStack>
            </Box>
          </Portal>
        )}
        <CustomDragLayer />
      </Box>
    </>
  );
};

export default FilesList;

// Define the FileRow component
const FileRow = memo(
  ({
    file,
    index,
    selectedItems,
    handleRowClick,
    handleRowDoubleClick,
    setContextMenu,
    bgHover,
    bgHoverIsSelected,
    bgDropTarget,
    textColor,
    textLightColor,
    renderFileIcon,
    formatFileSize,
    isFileTypeSelected,
    isImageFile,
  }) => {
    const { moveItems, files, handleDownloadFile, handleRenameModalOpen, handleDeleteConfirmationModalOpen, handleImagePreviewModalOpen } =
      useContext(FilesContext);
    const customToast = useCustomToast();

    const isFolder = file.type === "folder";
    const isLink = file.type === "link";

    // Implement useDrag
    const [{ isDragging }, drag, preview] = useDrag({
      type: ItemTypes.FILE,
      item: {
        files: selectedItems.has(file._id)
          ? Array.from(selectedItems)
              .map((id) => files.find((f) => f._id === id))
              .filter(Boolean)
          : [file],
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    // Disable the default drag preview
    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    // Implement useDrop for folders
    const [{ isOver }, drop] = useDrop({
      accept: ItemTypes.FILE,
      canDrop: () => isFolder,
      drop: (item) => {
        // Prevent dropping into itself or its descendants
        if (item.files.some((f) => f._id === file._id)) {
          customToast({
            title: "Invalid Move",
            description: "Cannot move a folder into itself or its subfolder.",
            status: "error",
          });
          return;
        }
        const fileIds = item.files.map((f) => f._id);
        const newParentFolderId = file._id;
        moveItems(fileIds, newParentFolderId);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    });

    const rowRef = useRef(null);

    // Apply refs
    if (isFolder) {
      drag(drop(rowRef));
    } else {
      drag(rowRef);
    }

    // Apply styles for isOver
    let backgroundColor = "transparent";
    if (isOver) {
      backgroundColor = bgDropTarget;
    } else if (selectedItems.has(file._id)) {
      backgroundColor = "primary.100";
    }

    // Apply styles for isDragging
    const opacity = isDragging ? 0.7 : 1;

    return (
      <MotionTr
        layout
        opacity={opacity}
        ref={rowRef}
        bg={backgroundColor}
        sx={{
          transition: "background-color 0.2s ease-in-out",
          "&:hover": {
            backgroundColor: isOver
              ? bgDropTarget
              : selectedItems.has(file._id)
              ? bgHoverIsSelected
              : bgHover,
          },
        }}
        cursor="pointer"
        onClick={(e) => handleRowClick(e, file)}
        onDoubleClick={(e) => handleRowDoubleClick(e, file)}
        onContextMenu={(e) => {
          e.preventDefault();
          setContextMenu({
            isOpen: true,
            x: e.clientX,
            y: e.clientY,
            file: file,
          });
        }}
        id={`file-row-${file._id}`}
      >
        {/* File Icon Cell */}
        <Td>
          <Box display="flex" alignItems="center" userSelect="none">
            <Box as={PiDotsSixVertical} mr={2} color="gray.600" fontSize="20px" />
            {isFolder ? (
              <Box as={BsFolder} color="yellow.700" fontSize="24px" />
            ) : isLink ? (
              <Box as={PiLink} color="blue.500" fontSize="24px" />
            ) : (
              renderFileIcon(file.originalFileName)
            )}
          </Box>
        </Td>
        {/* File Name Cell */}
        <Td>
          <Box>
            <Text fontSize="sm" color={textColor} userSelect="none">
              {file.originalFileName}
            </Text>
            {file.type === "file" ? (
              <Text fontWeight="500" fontSize="xs" color={textLightColor} userSelect="none">
                {formatFileSize(file.fileSize)}
              </Text>
            ) : file.type === "link" ? (
              <Text fontWeight="500" fontSize="xs" color={textLightColor} userSelect="none">
                {file.url.length > 60 ? `${file.url.substring(0, 60)}...` : file.url}
              </Text>
            ) : null}
          </Box>
        </Td>
        {/* Creator Cell */}
        <Td>
          <Box display="flex" alignItems="center" userSelect="none">
            {file.uploadedBy._id && <UserAvatar userIds={[file.uploadedBy._id]} size="sm" />}
          </Box>
        </Td>
        {/* Creation Date Cell */}
        <Td>
          <Text fontSize="sm" textTransform="none" fontWeight="500" userSelect="none">
            {new Date(file.createdAt).toLocaleString()}
          </Text>
        </Td>
        {/* Actions Cell */}
        <Td onClick={(e) => e.stopPropagation()}>
          <Menu>
            <MenuButton as={IconButton} icon={<PiDotsThreeBold fontSize="20px" />} size="sm" variant="outline" aria-label="Actions" />
            <MenuList>
              {isImageFile(file.originalFileName) && (
                <MenuItem
                  fontSize="sm"
                  icon={<PiEye fontSize="18px" />}
                  onClick={() => {
                    handleImagePreviewModalOpen(file);
                  }}
                  userSelect="none"
                >
                  Preview
                </MenuItem>
              )}
              {file.type === "file" && (
                <MenuItem fontSize="sm" icon={<PiDownloadSimple fontSize="18px" />} onClick={() => handleDownloadFile(file)} userSelect="none">
                  Download
                </MenuItem>
              )}
              {file.type === "file" && <MenuDivider />}
              <MenuItem fontSize="sm" icon={<PiPencilSimpleLine fontSize="18px" />} onClick={() => handleRenameModalOpen(file._id)} userSelect="none">
                Rename
              </MenuItem>
              {file.type === "link" && (
                <MenuItem
                  fontSize="sm"
                  icon={<PiLink fontSize="18px" />}
                  onClick={() => {
                    window.open(file.url, "_blank");
                  }}
                  userSelect="none"
                >
                  Open Link
                </MenuItem>
              )}
              <MenuItem
                fontSize="sm"
                icon={<PiTrash fontSize="18px" />}
                color="red.500"
                onClick={() => handleDeleteConfirmationModalOpen([file._id])}
                userSelect="none"
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </Td>
      </MotionTr>
    );
  }
);
