import React, { useState, useCallback } from "react";
import { Box, Button, Text, Input, Flex, useColorModeValue } from "@chakra-ui/react";
import Cropper from "react-easy-crop";
import useCustomToast from "../../5 - General/Utils/UtilsNotification";
import ReusableModal from "../9 - General Modals/ReusableModal";

const LogoUploadModal = ({ isOpen, onClose, onUpload, initialPreview, logoType, companyId }) => {
  const [preview, setPreview] = useState(initialPreview || null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const borderColor = useColorModeValue("gray.300", "gray.400");
  const customToast = useCustomToast();
  const modalTitle = logoType === "color" ? "Light Mode Logo (Use a colorful or dark logo)" : "Dark Mode Logo (Use a white or light logo)";

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, crop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, crop.width, crop.height);

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          const file = new File([blob], "cropped.png", { type: "image/png" });
          resolve(file);
        },
        "image/png",
        1
      );
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
      setStep(2);
    }
  };

  const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleApply = async () => {
    try {
      setLoading(true);
      const file = await getCroppedImg(preview, croppedAreaPixels);
      onUpload(file);
      onClose();
    } catch (error) {
      customToast({
        title: "Error",
        description: "Failed to upload logo",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const title = step === 1 ? "Upload " : "Crop ";
  const footerButtons = (
    <Flex justify="space-between" width="100%">
      {step === 1 ? (
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
      ) : (
        <>
          <Button variant="ghost" onClick={() => setStep(1)}>
            Back
          </Button>
          <Button colorScheme="blue" onClick={handleApply} isLoading={loading}>
            Apply
          </Button>
        </>
      )}
    </Flex>
  );

  return (
    <ReusableModal isOpen={isOpen} onClose={onClose} title={title + modalTitle} footerButtons={footerButtons}>
      {step === 1 ? (
        <Box
          p={4}
          border="2px dashed"
          borderColor={borderColor}
          borderRadius="md"
          textAlign="center"
          cursor="pointer"
          onClick={() => document.getElementById("fileInput").click()}
        >
          <Text mb={2}>Click or drag & drop to select a logo</Text>
          <Input type="file" id="fileInput" accept="image/*" onChange={handleFileChange} display="none" />
        </Box>
      ) : (
        <Box position="relative" width="100%" height="400px">
          <Cropper
            image={preview}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={handleCropComplete}
          />
        </Box>
      )}
    </ReusableModal>
  );
};

export default LogoUploadModal;
