import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSocket } from "../Socket/SocketContext";
import ConnectionLostModal from "./ConnectionLostModal";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const isAuthenticated = !!localStorage.getItem("token");
  const { socket, connectSocket } = useSocket(); // Correctly destructure here
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(socket?.connected || false);

  const MAX_RETRY = 3;

  useEffect(() => {
    if (!isAuthenticated) {
      console.log("User is not authenticated");
      localStorage.clear();
    }
  }, [isAuthenticated]);

  // Listen to socket connection events
  useEffect(() => {
    if (socket) {
      const handleConnect = () => {
        console.log("Socket connected");
        setIsSocketConnected(true);
      };

      const handleDisconnect = (reason) => {
        console.log("Socket disconnected:", reason);
        setIsSocketConnected(false);
      };

      socket.on("connect", handleConnect);
      socket.on("disconnect", handleDisconnect);

      // Set initial connection status
      setIsSocketConnected(socket.connected);

      return () => {
        socket.off("connect", handleConnect);
        socket.off("disconnect", handleDisconnect);
      };
    } else {
      setIsSocketConnected(false);
    }
  }, [socket]);

  // Update modal visibility based on socket connection status
  useEffect(() => {
    if (isAuthenticated && !isSocketConnected) {
      setIsModalOpen(true);
    } else if (isSocketConnected) {
      setIsModalOpen(false);
    }
  }, [isAuthenticated, isSocketConnected]);

  const handleReconnect = async () => {
    if (retryCount >= MAX_RETRY) {
      handleLoginAgain();
      return;
    }

    setIsLoading(true);
    console.log("Attempting to reconnect...");

    try {
      if (socket) {
        await socket.connect();
      } else {
        await connectSocket();
      }
      setRetryCount(0);
      setIsLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Reconnection attempt failed:", error);
      setRetryCount((prev) => prev + 1);
      setIsLoading(false);
    }
  };

  const handleLoginAgain = () => {
    setRetryCount(0);
    localStorage.clear();
    navigate("/login");
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {children}
      <ConnectionLostModal isOpen={isModalOpen} isLoading={isLoading} onReconnect={handleReconnect} onLoginAgain={handleLoginAgain} />
    </>
  );
};

export default ProtectedRoute;
