import React, { useState } from "react";
import { Button, useToast, Progress, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { PiCaretDownBold } from "react-icons/pi";

const CsvDownloadModal = () => {
  const [csvLoading, setCsvLoading] = useState(false);
  const toast = useToast();

  // Categories from your updated model structure
  const categories = [
    { id: "user", name: "Clients" },
    { id: "company", name: "Companies" },
  ];

  // Handle CSV download process based on the selected category
  const handleDownloadCsv = async (category) => {
    setCsvLoading(true);
    try {
      const response = await fetch(`http://localhost:5000/api/v1/users/download-csv`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add token to headers
        },
        body: JSON.stringify({ categoryName: category }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${category}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast({
        title: "Error",
        description: "Unable to download CSV data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setCsvLoading(false);
    }
  };

  return (
    <>
      {/* CSV Download Menu */}
      <Menu>
        <MenuButton as={Button} rightIcon={<PiCaretDownBold />} colorScheme='gray' mx={3}>
          Download CSV
        </MenuButton>
        <MenuList>
          {categories.map((category) => (
            <MenuItem key={category.id} onClick={() => handleDownloadCsv(category.id)} fontWeight="400" fontSize="md">
              {category.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      {/* Progress bar when loading */}
      {csvLoading && <Progress size="xs" isIndeterminate colorScheme="primary" mt={4} />}
    </>
  );
};

export default CsvDownloadModal;
