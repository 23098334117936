import React, { useEffect, useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalBody, IconButton, Button, Image, Spinner, useColorModeValue } from "@chakra-ui/react";
import { downloadBlob, downloadFileBlob } from "../../4 - API/API-General";
import { PiDownloadSimple, PiXBold } from "react-icons/pi";

const ImagePreviewModal = ({ isOpen, onClose, selectedImage }) => {
  // Define colors for light and dark modes
  const modalBg = useColorModeValue("white", "gray.800");
  const overlayBg = useColorModeValue("rgba(0, 0, 0, 0.5)", "rgba(0, 0, 0, 0.8)");
  const buttonColorScheme = useColorModeValue("gray", "teal");

  const [blobUrl, setBlobUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedImage || !selectedImage.url) return;

    const fetchBlob = async () => {
      setLoading(true);
      try {
        const blob = await downloadFileBlob(selectedImage.url); // Pass relative URL
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);
      } catch (error) {
        console.error("There was an error downloading the file:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlob();

    // Cleanup the blob URL when component unmounts or selectedImage changes
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);

  const downloadFile = async () => {
    if (!selectedImage.url || !selectedImage.originalFileName) return;
    try {
      const blob = await downloadFileBlob(selectedImage.url); // Pass relative URL
      downloadBlob(blob, selectedImage.originalFileName);
    } catch (error) {
      console.error("There was an error downloading the file:", error);
    }
  };

  if (!selectedImage) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay bg={overlayBg} />
      <ModalContent bg={modalBg} pt={12} pb={2} pr={12} pl={12} w="fit-content" maxW="60vw" maxH="80vh" borderRadius="xl">
        <ModalBody p={0} display="flex" flexDirection="column" alignItems="center">
          {loading ? (
            <Spinner />
          ) : (
            <Image src={blobUrl} alt={selectedImage.originalFileName} objectFit="contain" maxH="60vh" mb={4} borderRadius="md" />
          )}
          <Button
            colorScheme={buttonColorScheme}
            leftIcon={<PiDownloadSimple size="16px" />}
            onClick={(e) => {
              e.stopPropagation(); // Prevents closing the modal on download click
              downloadFile();
            }}
            size='md'
            mb={2}
          >
            Download Image
          </Button>
          <IconButton
            aria-label="Close modal"
            icon={<PiXBold size="14px" />}
            onClick={onClose}
            size="sm"
            colorScheme={buttonColorScheme}
            position="absolute"
            top={2}
            right={2}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImagePreviewModal;
